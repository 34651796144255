import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import actions from '../../../redux/actions/index';
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import Multiselect  from 'react-multi-select-component';

const lotSelector = (props) => {

    const {t, establishmentLots, establishments, selectedEstablishment} = props;

    const strings = {
        "selectSomeItems": t('selectors.lotSelector.selectSomeItems'),
        "allItemsAreSelected": t('selectors.common.allItemsAreSelected'),
        "selectAll": t('selectors.common.selectAll'),
        "search": t('selectors.lotSelector.search')
    }

    const changeValue = (lots) => {
        props.selectLot(lots)
    };

    const showDropdown = () => {
        //const validMode = selectedEstablishmentLotMode != "lotModeDisabled";
        const lotsToShow = establishmentLots && establishmentLots.length > 1;
        return lotsToShow;
        //return lotsToShow && validMode;
    }

    if(showDropdown()) {
        return (
            <div>
                {
                <Multiselect
                    options={
                        establishmentLots.map(lot => ({
                            value: lot._id,
                            label: lot.name
                        }))
                    }
                    displayValue="name"
                    name="lotsDropdown"
                    value={props.selectedLots}
                    onChange={(e)=>changeValue(e)}
                    overrideStrings={strings}
                />
                }
            </div>
        );
    }
    return null
};

const mapStateToProps = (state) => ({
    establishments: state.user.allEstablishmentsByCompany,
    selectedCompany: state.user.selectedCompany,
    selectedLot: state.user.selectedLot,
    selectedEstablishment: state.user.selectedEstablishment,
    roles: state.user.roles,
    userId: state.user.id,
    selectedEstablishmentLotMode: state.user.establishmentDetails?
        state.user.establishmentDetails.animalLots.mode : null,
    establishmentLots: state.user.allLotsByEstablishment?
        state.user.allLotsByEstablishment : null,
    selectedLots: state.user.selectedLots
});

const mapDispatchToProps = (dispatch) => ({
    getUserEstablishmentById: (currentEstablishment) =>
    dispatch(actions.user.getEstablishmentById(currentEstablishment)),
    selectLot: (lot) => dispatch(actions.user.selectLot(lot)),
    resetSelectedEstablishment: () => dispatch(actions.user.resetSelectedEstablishment()),
    getAssignedEstablishments: (userId) => dispatch(actions.user.getAssignedEstablishments(userId)),
    getPageByEstablishment: (establishmentId, lotId, page, pageSize) =>
        dispatch(actions.animal.getPageByEstablishment(establishmentId, lotId, page, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(lotSelector))
