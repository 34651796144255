import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";
import { ROLES } from "../../constants";
import CVModal from "../UI/Modals/components/CVModal";
import {withTranslation} from "react-i18next";
import {Button} from "reactstrap";
import actions from '../../redux/actions/index';
import {useAlert} from "react-alert";
import PasswordAndPinForm from '../../shared/components/cv/PasswordAndPinForm';

const DefaultDashboard = (props) => {
    const {userRoles, t, userId, addRoleToUser, encryptedRemoteWallet} = props;
    // const [professionalLicense, setProfessionalLicense] = useState();
    // const [rolLicenseInput, setRolLicenseInput] = useState();

    const alert = useAlert();

    useEffect(() => {
        // Load all the available roles
        props.getAvailableRoles();
    }, []);

    useEffect(() => {
        const dashboard = getDashboardByUserRol(getRolPriority(userRoles));
        if(dashboard) props.history.push(dashboard)
    }, userRoles);

    const getDashboardByUserRol = (rol) => {
        switch (rol) {
            case ROLES.ADMIN:
                return '/dashboard_admin';
            case ROLES.TITULAR:
                return '/manage_establishments/table';
            case ROLES.SURROGATE:
                return '/manage_establishments/table';
            case ROLES.VETERINARY:
                return '/animals/table';
            case ROLES.AGRICULTURAL_ENGINEER:
                return '/animals/table';
            case ROLES.WITH_OUT_ROLE:
                return null; // Force the user to select a role
        }
    };

    const getRolPriority = (roles) => {
        let priorityRol;
        roles.forEach(rol => rol.name === ROLES.ADMIN ? priorityRol = ROLES.ADMIN : null);
        if (priorityRol) return priorityRol;
        roles.forEach(rol => rol.name === ROLES.TITULAR ? priorityRol = ROLES.TITULAR : null);
        if (priorityRol) return priorityRol;
        roles.forEach(rol => rol.name === ROLES.AGRICULTURAL_ENGINEER ? priorityRol = ROLES.AGRICULTURAL_ENGINEER : null);
        if (priorityRol) return priorityRol;
        roles.forEach(rol => rol.name === ROLES.VETERINARY ? priorityRol = ROLES.VETERINARY : null);
        if (priorityRol) return priorityRol;
        roles.forEach(rol => rol.name === ROLES.SURROGATE ? priorityRol = ROLES.SURROGATE : null);
        if (priorityRol) return priorityRol;
        roles.forEach(rol => rol.name === ROLES.WITH_OUT_ROLE ? priorityRol = ROLES.WITH_OUT_ROLE : null);
        return priorityRol;
    };

    const selectRole = (rol) => {
        if(rol === ROLES.VETERINARY || rol === ROLES.AGRICULTURAL_ENGINEER) {
            props.history.push('/account/profile', {rolSelected: rol})
        }
        else {
            addRoleToUser(userId, rol, null, () => {
                alert.success(t('full_register.successful_role_selection_message',
                    {role: t(`common.roles.${rol}`)}), {timeout: 0});
                if(rol === 'titular') props.history.push('/manage_accounts/new_account')
            })
        }
    };

    /*const selectProfessionalRol = (rol) => {
        addRoleToUser(userId, rol, professionalLicense, () => {
            alert.success(t('full_register.successful_role_selection_message',
                {role: t(`common.roles.${rol}`)}), {timeout: 0});
        })
    };*/

    const renderRoleSelectionModalBody = () => (
        <div className="role__selection__modal">
            {props.availableRoles.map(rol => (
                <div key={rol}>
                    <Button color="primary" type="button" onClick={() => selectRole(rol)}>{t(`common.roles.${rol}`)}</Button>
                    {/*{
                        (rolLicenseInput === rol) &&
                        <div className="license_input">
                            <input type="text" name="license" placeholder={t('full_register.modals.role_selection.enter_license')}
                                   onChange={(e) => setProfessionalLicense(e.target.value)}/>
                            <button onClick={() => selectProfessionalRol(rol)}
                                    disabled={!professionalLicense || professionalLicense === ""}>{t('common.accept')}</button>
                        </div>
                    }*/}
                </div>
            ))}
        </div>
    );

    const roleSelectionModal = () => {
        if(getRolPriority(userRoles) === ROLES.WITH_OUT_ROLE){
            return (
                <CVModal
                    title={t('full_register.modals.role_selection.title')}
                    body={() => renderRoleSelectionModalBody()}
                    color="primary"
                    header
                    isOpen={true}
                    toggle={() => {
                    }}
                    hideButtons={true}
                    hideCloseButton={true}
                />
            );
        }
        else return null;
    };

    const renderDecryptWalletModalBody = () => (
        <PasswordAndPinForm onSubmit={({password, pin}) => {
            props.recreateRemoteWallet(password, pin);
        }}/>
    );

    const decryptWalletModal = () => {
        if (encryptedRemoteWallet) {
            return (
                <CVModal
                    title={t('wallet.restore.modal.title')}
                    body={() => renderDecryptWalletModalBody()}
                    color="primary"
                    header
                    isOpen={true}
                    toggle={() => {
                    }}
                    hideButtons={true}
                    hideCloseButton={true}
                />
            );
        } else return null;
    };

    // Only redirects to the dashboard if:
    // + The user has a role because no role selection is need.
    // + if the user has a local wallet (on local storage) because no pin or password is required to unlock and
    //  save the wallet fetched from the api.
    // Else redirect:
    //  - To role selection modal. If the user don't have a role
    //  - To decrypt wallet modal. If the user don't have a local wallet but it has a remote one.

    return (
        <div>
            {roleSelectionModal()}
            {/* todo uncomment when wallet is supported */}
            {/*{decryptWalletModal()}*/}
        </div>
    )

};

const mapStateToProps = (state) => ({
    userRoles: state.user.roles,
    userId: state.user.id,
    encryptedRemoteWallet: state.wallet.encryptWallet.remote,
    availableRoles: state.constant.availableRoles
});

const mapDispatchToProps = (dispatch) => ({
    addRoleToUser: (userId, rol, license, callback) => dispatch(actions.user.addRoleToUser(userId, rol, license, callback)),
    recreateRemoteWallet: (password, pin) => dispatch(actions.wallet.recreateRemoteWallet(password, pin)),
    getAvailableRoles: () => dispatch(actions.constant.getAvailableRoles())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter (DefaultDashboard)))
