import React, { PureComponent } from 'react';
import PropTypes  from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { RTLProps } from '../../prop-types/ReducerProps';
import FileUploadDefault from './FileUploadDefault';
import CloseIcon from "mdi-react/CloseIcon"
//import {formValidators} from '../../../../util/index';
import {formValidators} from '../../../util/index';

class FileUploadModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        rtl: RTLProps.isRequired,
        onSubmit: PropTypes.func,
        isOpen: PropTypes.bool,
        toggleModal: PropTypes.func,
        acceptedFormats: PropTypes.string,
        onLeftSelectChange: PropTypes.func,
        rightSelectValues: PropTypes.array,
        leftSelectValues: PropTypes.array
    };

    static defaultProps = {
        title: ''
    };

    render() {
        const { title, rtl, onSubmit, isOpen,
            toggleModal, acceptedFormats,
            rightSelectValues, leftSelectValues,
            onLeftSelectChange, onRightSelectChange } = this.props;

        if(!isOpen) return null;

        return (
            <Modal
                isOpen={isOpen}
                toggle={toggleModal}
                modalClassName={`${rtl.direction}-support`}
                className={`modal-dialog`}
            >
                <div className="modal__header">
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <CloseIcon
                    className={"lnr lnr-cross modal__close-btn"}
                    onClick={() => toggleModal(false)}
                  />
                <div className="modal__body">
                    <FileUploadDefault
                        onSubmit={onSubmit}
                        acceptedFormats={acceptedFormats}
                        leftSelectValues={leftSelectValues}
                        rightSelectValues={rightSelectValues}
                        onLeftSelectChange={onLeftSelectChange}
                        onRightSelectChange={onRightSelectChange}
                        initialValues={{
                            company: {
                                value: localStorage.getItem('selectedCompanyId')?
                                     localStorage.getItem('selectedCompanyId'):
                                    leftSelectValues[0]._id,
                                label: localStorage.getItem('selectedCompanyName')?
                                    localStorage.getItem('selectedCompanyName'):
                                    leftSelectValues[0].name,
                            },
                            establishment: {
                                value: localStorage.getItem('selectedEstablishmentId')?
                                    localStorage.getItem('selectedEstablishmentId'):
                                    rightSelectValues[0]._id,
                                label: localStorage.getItem('selectedEstablishmentName')?
                                    localStorage.getItem('selectedEstablishmentName'):
                                    rightSelectValues[0].name
                            }
                        }}
                    />
                </div>
            </Modal>
        );
    }
}

export default connect(state => ({
    rtl: state.rtl,
}))(FileUploadModal);
