import React, { PureComponent } from 'react';
import {
    Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import CalendarRangeIcon from 'mdi-react/CalendarRangeIcon';
import AccountCardDetailsOutlineIcon from 'mdi-react/AccountCardDetailsOutlineIcon';
import AccountBadgeOutlineIcon from 'mdi-react/AccountBadgeOutlineIcon';
import WebIcon from 'mdi-react/WebIcon';
import AtIcon from 'mdi-react/AtIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import CellphoneBasicIcon from 'mdi-react/CellphoneBasicIcon';
import LinkedinBoxIcon from 'mdi-react/LinkedinBoxIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import CVButton from "../../../../shared/components/cv/CVButton";
import AddressInput from "../../../../shared/components/cv/AddressInput";
import {formValidators, subtract_x_years_to_date} from "../../../../util";
import MaskedInput from "react-text-mask";
import i18n from "../../../../translations/config/i18n";
import { renderDateInputField } from '../../../../shared/components/cv/CVDatePicker';
import ReactPhoneInput from "react-phone-input-2";

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
    <div style={{width: '100%'}}>
        {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
        {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);

const renderPhoneField = ({input, placeholder, type, meta: {touched, error, warning}}) => (
  <div style={{width: '100%'}}>
      <ReactPhoneInput className="input-phone-container" {...input} defaultCountry={'ar'} style={{backgroundColor: 'white', width: '50%'}} type={type} name="phone" placeholder={placeholder}/>
      <div className="input-error-container">
          {touched && ((error && <span className="error-message">{this.props.t(`errors.${error}`)}</span>) || (warning &&
            <span className="error-message">{warning}</span>))}
      </div>
  </div>
);

const validateBirthDateRequired = formValidators.validateDateBetweenRequired(
    subtract_x_years_to_date(new Date(), 125), // 125 years ago date
    new Date(), // today date
);

class ProfileInputFormWithIcons extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        };
    }

    showPassword = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    };

    render() {
        const { handleSubmit, cancel } = this.props;

        return (
            <form className="form" onSubmit={handleSubmit}>
                <div className="form__form-group">
                    <span className="form__form-group-label">{this.props.t('profile.labels.name')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <AccountOutlineIcon />
                        </div>
                        <Field
                            name="name"
                            component={renderField}
                            validate={formValidators.validateRequired}
                            type="text"
                            placeholder={this.props.t('profile.labels.name')}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{this.props.t('profile.labels.lastname')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <AccountOutlineIcon />
                        </div>
                        <Field
                            name="lastname"
                            component={renderField}
                            validate={formValidators.validateRequired}
                            type="text"
                            placeholder={this.props.t('profile.labels.lastname')}
                        />
                    </div>
                </div>
                {
                this.props.initialValues.license ?
                <div className="form__form-group">
                    <span className="form__form-group-label">{this.props.t('profile.labels.license')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <AccountBadgeOutlineIcon />
                        </div>
                        <Field
                            name="license"
                            component={renderField}
                            validate={formValidators.validateRequired}
                            type="text"
                            disabled
                            placeholder={this.props.t('profile.labels.license')}
                        />
                    </div>
                </div>
                : null
                }
                <div className="form__form-group">
                    <span className="form__form-group-label">{this.props.t('profile.labels.birthDate')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <CalendarRangeIcon />
                        </div>
                        <Field
                            name="birthday"
                            component={renderDateInputField}
                            validate={[formValidators.validateRequired, validateBirthDateRequired]}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{this.props.t('profile.labels.dni')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <AccountCardDetailsOutlineIcon />
                        </div>
                        <Field
                            name="dni"
                            component={renderField}
                            validate={formValidators.validateRequired}
                            type="text"
                            placeholder={this.props.t('profile.labels.dni')}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{this.props.t('profile.labels.email')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <AtIcon />
                        </div>
                        <Field
                            name="email"
                            component={renderField}
                            normalize={(value) => value.toLowerCase()}
                            type="email"
                            disabled
                            placeholder={this.props.t('profile.labels.email')}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{this.props.t('profile.labels.telephone')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <PhoneIcon />
                        </div>
                        <Field
                            name="telephone"
                            component={renderField}
                            type="text"
                            placeholder={this.props.t('profile.labels.telephone')}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{this.props.t('profile.labels.cellular')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="cellular"
                            component={renderPhoneField}
                            validate={formValidators.validateRequired}
                            type="text"
                            placeholder={this.props.t('profile.labels.cellular')}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{this.props.t('profile.labels.linkedin')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <LinkedinBoxIcon />
                        </div>
                        <Field
                            name="linkedin"
                            component={renderField}
                            type="text"
                            placeholder={this.props.t('profile.labels.linkedin')}
                        />
                    </div>
                </div>

                <p style={{marginBottom: '10px', fontSize: '15px'}}><MapMarkerIcon/>{this.props.t('profile.labels.address')}</p>
                <Col md={12} lg={12}>
                    <AddressInput addressName={'homeAddress'} showLabels renderInput={renderField} editable/>
                </Col>
                <ButtonToolbar className="form__button-toolbar">
                    <CVButton color="primary" type="submit" pending={this.props.pending}>
                        {this.props.t('profile.buttons.save')}
                    </CVButton>
                    <Button type="button" onClick={cancel}>{this.props.t('profile.buttons.cancel')}</Button>
                </ButtonToolbar>
            </form>
        );
    }
}

export default reduxForm({
    form: 'profile-input-form-with-icons', // a unique identifier for this form
    touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(ProfileInputFormWithIcons));
