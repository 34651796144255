import React from 'react';
import { Container, Row } from 'reactstrap';
import EstablishmentLotForm from "./EstablishmentLotForm";
import { connect } from 'react-redux';
import actions from '../../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const AddEstablishmentLot = (props) => {
    const alert = useAlert();

    return (
        <Container>
          <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Row>
                <EstablishmentLotForm />
            </Row>
        </Container>
    )
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AddEstablishmentLot));