import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input} from "reactstrap";
import { Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import actions from '../../../redux/actions/index'
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

const AnimalLotsModal = (props) => {

  const { t, establishmentId } = props;
  const [redirectRoute, setRedirectRoute] = useState();
  const [selectedLotMode, selectLotMode] = useState();

  useEffect(() => {
    if(establishmentId) props.getUserEstablishmentById(establishmentId._id, ()=> {});
  }, [establishmentId]);

  useEffect(() => {
    if(props.establishmentDetails !== undefined) {
      if (!props.establishmentDetails.animalLots.mode){
        props.selectEstablishmentLotMode("lotModeDisabled");
        selectLotMode("lotModeDisabled");
      }else {
        props.selectEstablishmentLotMode(props.establishmentDetails.animalLots.mode);
        selectLotMode(props.establishmentDetails.animalLots.mode);
      }
    }
  }, [props.establishmentDetails]);

  useEffect(() => {
    selectLotMode(props.selectedMode)
  }, [props.selectedMode]);

  const editUserEstablishment = () => {
    let values = props.establishmentDetails
    values.animalLots.mode = props.selectedMode
    values.animalLots.lots = props.establishmentDetails.animalLots.lots;
    props.editUserEstablishmentById(establishmentId._id, props.establishmentDetails.type, values,
        () => { setRedirectRoute("/manage_establishments/establishment_lots_table")
      }, (errorMessage) => {
           ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
      });
  };

  const handleRadioButton = (mode) => {
    props.selectEstablishmentLotMode(mode)
  }

  return (
    <Table striped>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore} />
      <tbody>
      <tr>
          <th scope="row">
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="radio1"
                checked={props.selectedMode == "lotModeDisabled"}
                onClick={() => handleRadioButton("lotModeDisabled")}
                />
              Trabajo sin lotes
            </Label>
          </FormGroup>
          </th>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <th scope="row">
          <FormGroup check>
            <Label check>
              <Input type="radio"
              name="radio2"
              onClick={() => handleRadioButton("lotModePredefined")}
              checked={props.selectedMode == "lotModePredefined"}
              />{' '}
              Trabajo con lotes predefinidos
            </Label>
          </FormGroup>
          </th>
          <td>En este modo solo es posible asignar animales a lotes creados previamente</td>
          <td>
              <a style={{ cursor: "pointer", color: "blue", textDecorationLine: "underline" }}
                 href={null}
                 onClick={editUserEstablishment}
              >  Administrar
              </a>
          </td>
          <td></td>
        </tr>
        <tr>
          <th scope="row">
          <FormGroup check>
            <Label check>
              <Input type="radio"
              name="radio3"
              onClick={() => handleRadioButton("lotModeFlexible")}
              checked={props.selectedMode == "lotModeFlexible"}
              />{' '}
              Trabajo con lotes flexibles
            </Label>
          </FormGroup>
          </th>
          <td>Los lotes se crearán automáticamente si se ingresan en el alta masiva de animales</td>
          <td>
              <a style={{ cursor: "pointer", color: "blue", textDecorationLine: "underline" }}
                 href={null}
                 onClick={editUserEstablishment}
              >  Administrar
              </a>
          </td>
          <td></td>
        </tr>
      </tbody>
    { redirectRoute && <Redirect to={redirectRoute}/> }
    </Table>
  );
};

const mapStateToProps = (state) => ({
  establishmentDetails: state.user.establishmentDetails,
  selectedMode: state.user.selectedEstablishmentLotMode,
});

const mapDispatchToProps = (dispatch) => ({
  editUserEstablishmentById: (establishmentId, establishmentType, data, successCallback, errorCallback) =>
        dispatch(actions.user.editEstablishmentById(establishmentId, establishmentType, data, successCallback, errorCallback)),
  getUserEstablishmentById: (establishmentId) => dispatch(actions.user.getEstablishmentById(establishmentId)),
  selectEstablishmentLotMode: (lotMode) => dispatch(actions.user.selectEstablishmentLotMode(lotMode)),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(AnimalLotsModal)
);
