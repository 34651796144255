export const ROLES = {
    ADMIN: 'ADMIN',
    WITH_OUT_ROLE: 'WITH_OUT_ROLE',
    TITULAR: 'titular',
    VETERINARY: 'veterinary',
    AGRICULTURAL_ENGINEER: 'agriculturalEngineer',
    SURROGATE: 'surrogate'
};

export const SIDEBAR_LINKS = {
    establishments: 'ESTABLISHMENTS',
    establishmentLots: 'ESTABLISHMENTLOTS',
    animals: 'ANIMALS',
    events: 'EVENTS',
    transfer: 'TRANSFER',
    notifications: 'NOTIFICATIONS',
    users: 'USERS',
    roles: 'ROLES',
    permissions: 'PERMISSIONS',
    accounts: 'ACCOUNTS',
    generalData: 'GENERAL_DATA',
    selectRole: 'SELECT_ROLE'
};

export const ERRORS = {
    required: 'REQUIRED',
    invalidEmail: 'INVALID_EMAIL',
    invalidRENSPA: 'INVALID_RENSPA',
    shortPassword: 'SHORT_PASSWORD',
    invalidCowWeight: 'INVALID_COW_WEIGHT',
    invalidLatCoordinate: 'INVALID_LAT_COORDINATE',
    invalidLonCoordinate: 'INVALID_LON_COORDINATE',
    invalidDate: "INVALID_DATE",
    invalidMonthAge: "INVALID_MONTH_AGE",
    invalidCaravanId: "INVALID_CARAVAN_ID"
};

export const EVENT_TYPES = {
    weight: 'weightEvent',
    dentition: 'dentitionEvent',
    castration: 'castrationEvent',
    reidentification: 'reidentificationEvent',
    change_of_productive_system: 'productiveSystemChangeEvent',
    change_of_category: 'recategorizationEvent',
    fain: 'slaughterEvent',
    vaccination: 'vaccinationEvent',
    recategorization: 'recategorizationEvent',
    transfer: 'transferEvent',
    deletion: 'deletionEvent',
    lot: 'lotEvent'
};

export const ESTABLISHMENT_TYPES = {
    commercial: 'commercial',
    slaughterhouse: 'slaughterhouse',
    productive: 'productive',
    importer: 'importer'
};