import api from '../../api/index';
import { GET_AVAILABLE_ROLES, GET_AVAILABLE_ENTITY_TYPES, GET_AVAILABLE_PRODUCTIVE_SYSTEMS,
    GET_AVAILABLE_ESTABLISHMENT_TYPES, GET_AVAILABLE_GROUND_CONDITIONS, GET_AVAILABLE_RUCA_ACTIVITIES, GET_DENTITION_VALUES, GET_DENTITION_VALUES_RESPONSE, GET_DENTITION_VALUES_ERROR
} from '../actions/constantActions';
import actions from '../actions';
import mapper from './mappers/constantMapper';

const constant = ({dispatch, getState}) => next => action => {
    next(action);
    switch (action.type) {
        case GET_AVAILABLE_ROLES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableRoles(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableRolesResponse(
                            mapper.response.getAvailableRoles(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableRolesError(err))
                    });
            }));
            break;
        case GET_AVAILABLE_ENTITY_TYPES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableEntityTypes(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableEntityTypesResponse(
                            mapper.response.getAvailableEntityTypes(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableEntityTypesError(err))
                    })
            }));
            break;

        case GET_DENTITION_VALUES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getDentitionValues(jwt)
                    .then(res => {
                        dispatch(actions.constant.getDentitionValuesResponse(
                            mapper.response.getDentitionValues(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getDentitionValuesError(err))
                    })
            }));
            break;
        case GET_AVAILABLE_PRODUCTIVE_SYSTEMS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableProductiveSystems(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableProductiveSystemsResponse(
                            mapper.response.getAvailableProductiveSystems(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableProductiveSystemsError(err))
                    })
            }));
            break;
        case GET_AVAILABLE_ESTABLISHMENT_TYPES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableEstablishmentTypes(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableEstablishmentTypesResponse(
                            mapper.response.getAvailableEstablishmentTypes(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableEstablishmentTypesError(err))
                    })
            }));
            break;
        case GET_AVAILABLE_GROUND_CONDITIONS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableGroundConditions(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableGroundConditionsResponse(
                            mapper.response.getAvailableGroundConditions(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableGroundConditionsError(err))
                    })
            }));
            break;
        case GET_AVAILABLE_RUCA_ACTIVITIES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableRucaActivities(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableRucaActivitiesResponse(
                            mapper.response.getAvailableRucaActivities(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableRucaActivitiesError(err))
                    })
            }));
            break;
    }
};

export default constant;