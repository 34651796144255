/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect, Fragment } from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { withTranslation } from 'react-i18next';
import CVTable from '../../Tables/CVTable/CVTable';
import FileUploadOutlineIcon from "mdi-react/FileUploadOutlineIcon";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import ExportIcon from 'mdi-react/ExportIcon';
import QrcodeIcon from "mdi-react/QrcodeIcon";
import { withRouter } from 'react-router-dom';
import {parse_UTC_date_to_DD_MM_YYYY} from '../../../util/index';
import {useAlert} from "react-alert";
import FileDownloadOutlineIcon from "mdi-react/FileDownloadOutlineIcon";
import {Tooltip} from "react-tippy";
import settings from "../../../util/settings";
import CompanySelector from '../../Layout/selectors/companySelector';
import EstablishmentSelector from '../../Layout/selectors/establishmentSelector';
import LotSelector from '../../Layout/selectors/lotSelector';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';

const AnimalsTable = (props) =>  {

    const alert = useAlert();
    const [animals, setAnimals] = useState();
    const templatesUrl = settings.templatesUrl;
    const { total,
        totalPages,
        currentPage,
        pageSize,
        pending,
        openQrModal,
        establishmentSelected,
        animalsForDownload,
        t } = props;

    useEffect(() => {
        if(props.animals !== undefined) setAnimals(props.animals);
        props.getPageForDownload(props.currentPage);
    }, [props.animals]);

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderButtons = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <MagnifyIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => {
                    props.history.push('/animals/animal_detail', {animalId: value})
                }}/>
                <QrcodeIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => openQrModal(value)}/>
            </div>
        )
    };

    const renderBreed = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/* commented because no translation is supported yet */}
                {/*{t(`animals.table.breed.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderSpecies = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/* commented because no translation is supported yet */}
                {/*{t(`animals.table.species.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderSex = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals.table.sex.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderCategory = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/* commented because no translation is supported yet */}
                {/*{t(`animals_categories.cow.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderBirthDate = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value ?
                    parse_UTC_date_to_DD_MM_YYYY(value):
                    "-"
                }
            </div>
        )
    };

    const getExcel = (data, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const exportToCSV = (csvData, fileName) => {
          const ws = XLSX.utils.json_to_sheet(csvData);
          const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const data = new Blob([excelBuffer], {type: fileType});
          saveAs(data, fileName + fileExtension);
        };
        let fd = formatCSVData(data);
        exportToCSV(fd, fileName);
    }

    const formatCSVData = (data) => {
        let formatedData = [];
        for (let row of data){
            let formatedRow = {};
            Object.keys( row ).forEach( key => {
                if(row[key] != undefined && row[key] != '') {
                    let str = row[key].toString()
                    formatedRow[key] = str.toUpperCase();
                }
            });
            formatedData.push(formatedRow);
        }
        return formatedData;
    }

    const renderTableSelectors = () => (
       <Fragment>
        <div>
         <CompanySelector/>
        </div>
        <div>
          <EstablishmentSelector />
        </div>
        <div>
         <LotSelector/>
        </div>
       </Fragment>
    );

    const renderIconsForAddingAnimals = () => (
        <div>
            <div>
                <Tooltip
                    title="Descargar plantilla"
                    position="top"
                    trigger="mouseenter"
                    animation="scale"
                    duration="600"
                    size="small"
                    theme="transparent"
                    distance="0"
                >
                <a href={templatesUrl + "modelo animales.xlsx"} download><FileDownloadOutlineIcon/></a>
                </Tooltip>
                <Tooltip
                    title="Cargar datos"
                    position="top"
                    trigger="mouseenter"
                    animation="scale"
                    duration="600"
                    size="small"
                    theme="transparent"
                    distance="0"
                >
                <FileUploadOutlineIcon onClick={() => {
                    if(!establishmentSelected) alert.error(t('animals.table.to_add_cows_you_need_to_select_an_establishment'));
                    else props.toggleFileUploadModal(true)
                }}/>
                </Tooltip>
                <Tooltip
                    title="Exportar datos"
                    position="top"
                    trigger="mouseenter"
                    animation="scale"
                    duration="600"
                    size="small"
                    theme="transparent"
                    distance="0"
                >
                <ExportIcon onClick={()=> {
                    props.getPageForDownload(props.currentPage)
                    if(animalsForDownload) getExcel(animalsForDownload, "cv-animales")
                }}/>
                </Tooltip>
                <PlusCircleOutlineIcon onClick={() => {
                    if(!establishmentSelected) alert.error(t('animals.table.to_add_cows_you_need_to_select_an_establishment'));
                    else props.redirectTo('/animals/singleLoad')
                }}/>
                <div>
                 {/* <LotDropdown /> */}
               </div>
            </div>
        </div>
    );

    const heads = [
        {
            key: 'earId',
            name: t('animals.table.headers.caravan_id'),
            percentage: 14,
            minWidth: 140,
            formatter: renderValueDefault
        },
        {
            key: 'breed',
            name: t('animals.table.headers.breed'),
            percentage: 12,
            minWidth: 130,
            formatter: renderBreed
        },
        {
            key: 'specie',
            name: t('animals.table.headers.species'),
            percentage: 12,
            minWidth: 100,
            formatter: renderSpecies
        },
        {
            key: 'gender',
            name: t('animals.table.headers.sex'),
            percentage: 14,
            minWidth: 120,
            formatter: renderSex
        },
        {
            key: 'category',
            name: t('animals.table.headers.category'),
            percentage: 14,
            minWidth: 80,
            formatter: renderCategory
        },
        {
            key: 'birthDate',
            name: t('animals.table.headers.birth_date'),
            percentage: 20,
            minWidth: 135,
            formatter: renderValueDefault
        },
        {
            key: '_id',
            name: t('animals.table.headers.actions'),
            percentage: 14,
            minWidth: 75,
            formatter: renderButtons
        }
    ];

    const filters = [
        {
            group: 'animals',
            filterId: 'byBreed',
            label: 'breed',
            values: [
                {
                    id: 'ANGUS',
                    label: 'ANGUS'
                },
                {
                    id: 'HEREFORD',
                    label: 'HEREFORD'
                }
            ]
        },
        {
            group: 'animals',
            filterId: 'bySpecies',
            label: 'species',
            values: [
                {
                    id: 'BT',
                    label: 'BT'
                }
            ]
        },
        {
            group: 'animals',
            filterId: 'bySex',
            label: 'sex',
            values: [
                {
                    id: 'male',
                    label: 'male'
                },
                {
                    id: 'female',
                    label: 'female'
                }
            ]
        },
        {
            group: 'animals',
            filterId: 'byCategory',
            label: 'category',
            values: [
                {
                    id: 'VACA',
                    label: 'VACA'
                },
                {
                    id: 'VAQUILLONA',
                    label: 'VAQUILLONA'
                },
                {
                    id: 'NOVILLO',
                    label: 'NOVILLO'
                },
                {
                    id: 'TERNERO',
                    label: 'TERNERO'
                }
            ]
        },
    ];

    return (
        <CVTable
            getPage={props.getPage}
            data={animals}
            total={total ? total : 0}
            totalPages={totalPages}
            currentPage={currentPage ? currentPage : 1}
            pageSize={pageSize}
            heads={heads}
            title={props.title ? props.title : t('animals.table.title')}
            SubtitleComponent={renderIconsForAddingAnimals}
            CVSelect={renderTableSelectors}
            showSearchInput={false}
            showColumnFilter={true}
            //onFilter={props.filter}
            //filters={filters}
            hideFilters
            noDataLabel={t('animals.table.no_data_label')}
            pending={pending}
        />
    );
};

export default withTranslation('common')(withRouter(AnimalsTable))
