import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import sessionActions from '../../../redux/actions/sessionActions';
import {SIDEBAR_LINKS} from "../../../constants";
import { ROLES } from '../../../constants';
import { withTranslation } from "react-i18next";
import BarnIcon from 'mdi-react/BarnIcon';
import CowIcon from 'mdi-react/CowIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import BellIcon from 'mdi-react/BellIcon';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import AccountKeyIcon from 'mdi-react/AccountKeyIcon';
import DramaMasksIcon from 'mdi-react/DramaMasksIcon';
import ExitToAppIcon from 'mdi-react/ExitToAppIcon';
import FileDocumentEditIcon from 'mdi-react/FileDocumentEditIcon';
import DatabaseIcon from 'mdi-react/DatabaseIcon';

const ADMIN_SIDEBAR_LINKS = [
  SIDEBAR_LINKS.establishments,
  SIDEBAR_LINKS.establishmentLots,
  SIDEBAR_LINKS.users, SIDEBAR_LINKS.roles,
  SIDEBAR_LINKS.permissions,
  SIDEBAR_LINKS.animals,
  SIDEBAR_LINKS.events,
  SIDEBAR_LINKS.transfer,
  SIDEBAR_LINKS.accounts,
  SIDEBAR_LINKS.generalData,
  SIDEBAR_LINKS.notifications
];

const TITULAR_SIDEBAR_LINKS = [
  SIDEBAR_LINKS.establishments,
  SIDEBAR_LINKS.establishmentLots,
  SIDEBAR_LINKS.animals,
  SIDEBAR_LINKS.events,
  SIDEBAR_LINKS.transfer,
  SIDEBAR_LINKS.accounts
];

const SURROGATE_SIDEBAR_LINKS = [
  SIDEBAR_LINKS.establishments,
  SIDEBAR_LINKS.establishmentLots,
  SIDEBAR_LINKS.animals,
  SIDEBAR_LINKS.events,
  SIDEBAR_LINKS.transfer,
  SIDEBAR_LINKS.accounts
];

const PROFESSIONAL_SIDEBAR_LINKS = [
  SIDEBAR_LINKS.animals,
  SIDEBAR_LINKS.events
];

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  //TODO missing events are commented
  getSidebarLink = (name) => {
    switch (name) {
      case SIDEBAR_LINKS.establishments:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={BarnIcon} title={this.props.t('cv-sidebar.linksNames.establishments')} route="/manage_establishments/table"/>);
      case SIDEBAR_LINKS.establishmentLots:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={CalendarPlusIcon} title={this.props.t('cv-sidebar.linksNames.establishmentLots')} route="/manage_establishments/establishment_lots_table"/>);
      case SIDEBAR_LINKS.animals:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={CowIcon} title={this.props.t('cv-sidebar.linksNames.animals')} route="/animals/table"/>);
      case SIDEBAR_LINKS.events:
        return (
            <SidebarCategory key={'sidebar-category'} icon={CalendarIcon} title={this.props.t('cv-sidebar.linksNames.events')}>
              <button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/weight/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.weight')}</p>
              </button>
              <button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/change_productive_system/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.productive_system_change')}</p>
              </button>
              <button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/dentition/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.dentition')}</p>
              </button>
              <button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/reidentification/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.reidentification')}</p>
              </button>
              <button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/castration/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.castration')}</p>
              </button>
              {/* <button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/vaccination/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.vaccination')}</p>
              </button>*/}
              <button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/recategorization/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.recategorization')}</p>
              </button>
              {/*<button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/transfer/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.transfer')}</p>
              </button>*/}
              {/*<button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/recategorization/table')} >*/}
              {/*  <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.recategorization')}</p>*/}
              {/*</button>*/}
              {/*<button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/slaughter/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.slaughter')}</p>
              </button>*/}
              { this.props.userRoles[0].name == ROLES.TITULAR || this.props.userRoles[0].name == ROLES.SURROGATE?
              <button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/deletion/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.deletion')}</p>
              </button>
              : null
              }
              <button className="sidebar__link" type="button" onClick={() => this.props.history.push('/events/lot/table')} >
                <p className="sidebar__link-title">{this.props.t('cv-sidebar.linksNames.eventTypes.lot')}</p>
              </button>
            </SidebarCategory>
            );
      case SIDEBAR_LINKS.transfer:
        return(<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={CalendarIcon} title={this.props.t('cv-sidebar.linksNames.transfer')} route="/events/transfer/table" />)
      case SIDEBAR_LINKS.notifications:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={BellIcon} title={this.props.t('cv-sidebar.linksNames.notifications')} route="/notifications" />);
      case SIDEBAR_LINKS.users:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={AccountGroupIcon} title={this.props.t('cv-sidebar.linksNames.users')} route="/users/table"/>);
      case SIDEBAR_LINKS.roles:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={DramaMasksIcon} title={this.props.t('cv-sidebar.linksNames.roles')} route="/roles/table"/>);
      case SIDEBAR_LINKS.permissions:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={AccountKeyIcon} title={this.props.t('cv-sidebar.linksNames.permissions')} />);
      case SIDEBAR_LINKS.accounts:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={FileDocumentEditIcon} title={this.props.t('cv-sidebar.linksNames.companies')} route="/manage_accounts/table"/>);
      case SIDEBAR_LINKS.generalData:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={DatabaseIcon} title={this.props.t('cv-sidebar.linksNames.general_data')} route="/generalData/table"/>);
    }
  };

  getLinksToShow = (roles) => {
    let results = [];
    roles.forEach(rol => {
      switch (rol.name) {
        case ROLES.ADMIN:
          results.push(...ADMIN_SIDEBAR_LINKS);
          break;
        case ROLES.TITULAR:
          results.push(...TITULAR_SIDEBAR_LINKS);
          break;
        case ROLES.AGRICULTURAL_ENGINEER:
          results.push(...PROFESSIONAL_SIDEBAR_LINKS);
          break;
        case ROLES.VETERINARY:
          results.push(...PROFESSIONAL_SIDEBAR_LINKS);
          break;
        case ROLES.SURROGATE:
          results.push(...SURROGATE_SIDEBAR_LINKS);
          break;
      }
    });
    return [...new Set(results)];
  };

  userDoesNotHaveARol = () => {
    return (this.props.userRoles[0].name === ROLES.WITH_OUT_ROLE)
  };

  render() {
    const { changeToLight, changeToDark, logout, userRoles } = this.props;
    const sidebarLinks = this.getLinksToShow(userRoles);

    return (
      <div className="sidebar__content">
        {/* todo uncomment when dashboards are supported */}
        {/*<ul className="sidebar__block">
          {
            this.userDoesNotHaveARol() ?
                this.getSidebarLink(SIDEBAR_LINKS.accounts):
                <CVDashboards/>
          }
        </ul>*/}
        {
          (sidebarLinks.length !== 0) &&
          <ul className="sidebar__block">
            {sidebarLinks.map(linkName => this.getSidebarLink(linkName))}
          </ul>
        }
        <ul className="sidebar__block">
          <SidebarLink title="Log Out" icon={ExitToAppIcon} route="/log_in" onClick={() => logout()}/>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userRoles: state.user.roles
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(sessionActions.logout())
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarContent)));
