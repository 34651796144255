import React, {PureComponent, useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {Button, Col} from 'reactstrap';
import AddressInput from "../../../../shared/components/cv/AddressInput";
import { connect } from "react-redux";
import { formValidators, subtract_x_years_to_date } from "../../../../util";
import { renderDateInputField } from "../../../../shared/components/cv/CVDatePicker";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import moment from 'moment'
import ReactPhoneInput from 'react-phone-input-2'
import '../../../../scss/objects/phone-input.scss'

const validateBirthDateRequired = formValidators.validateDateBetweenRequired(
    subtract_x_years_to_date(new Date(), 125), // 125 years ago date
    new Date(), // today date
);

const validateAge = value => moment().diff(value, 'years') >= 18 ? undefined : 'INVALID_MONTH_AGE';


class FullRegisterForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false
        };

        this.showPassword = this.showPassword.bind(this);
    }

    completeDNI(value) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] == 0) {
          value = value.replace(value[i],'');
        } else {
          break;
        }
      }

      let res = value == 0 ? '' : value.padStart(8, "0");
      return res.substr(0,8)
    }

    showPassword(e) {
        e.preventDefault();
        this.setState(prevState => ({showPassword: !prevState.showPassword}));
    }

    renderField = ({input, placeholder, type, meta: {touched, error, warning}}) => (
        <div style={{width: '100%'}}>
            <input style={{backgroundColor: 'white'}} {...input} placeholder={placeholder} type={type}/>
            <div className="input-error-container">
                {touched && ((error && <span className="error-message">{this.props.t(`errors.${error}`)}</span>) || (warning &&
                    <span className="error-message">{warning}</span>))}
            </div>
        </div>
    );

    renderPhoneField = ({input, placeholder, type, meta: {touched, error, warning}}) => (
        <div style={{width: '100%'}}>
          <ReactPhoneInput className="input-phone-container" {...input} defaultCountry={'ar'} style={{backgroundColor: 'white', width: '50%'}} type={type} name="phone" placeholder={placeholder}/>
            <div className="input-error-container">
                {touched && ((error && <span className="error-message">{this.props.t(`errors.${error}`)}</span>) || (warning &&
                    <span className="error-message">{warning}</span>))}
            </div>
        </div>
    );

    showErrors = (invalid) => {
        if(invalid) ToastsStore.error(this.props.t('common.general_invalid_form'), 10000)
    }



    render() {
      const {handleSubmit, t} = this.props;
      return (
            <form className="form" onSubmit={handleSubmit}>
                <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
                <div className="form__form-group full_register_form">
                    <span className="form__form-group-label">{t('full_register.firstName')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="firstName"
                            component={this.renderField}
                            validate={formValidators.validateRequired}
                            type="text"
                            placeholder={t('full_register.firstName')}
                        />
                    </div>
                </div>
                <div className="form__form-group full_register_form">
                    <span className="form__form-group-label">{t('full_register.lastName')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="lastName"
                            component={this.renderField}
                            validate={formValidators.validateRequired}
                            type="text"
                            placeholder={t('full_register.lastName')}
                        />
                    </div>
                </div>
                <div className="form__form-group full_register_form">
                    <span className="form__form-group-label">{t('full_register.dni')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="dni"
                            component={this.renderField}
                            type="number"
                            maxLength="8"
                            placeholder={t('full_register.dniPlaceholder')}
                            parse={this.completeDNI}
                        />
                    </div>
                </div>
                <div className="form__form-group full_register_form">
                    <span className="form__form-group-label">{t('full_register.birthDate')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="birthDate"
                            component={renderDateInputField}
                            validate={[formValidators.validateRequired, validateBirthDateRequired, validateAge]}
                        />
                    </div>
                </div>
                <div className="form__form-group full_register_form">
                    <span className="form__form-group-label">{t('full_register.cellphone')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="cellphone"
                            component={this.renderPhoneField}
                            validate={formValidators.validateRequired}
                            type="text"
                            placeholder={t('full_register.cellphone')}
                        />
                    </div>
                </div>
                <div className="form__form-group full_register_form">
                    <span className="form__form-group-label">{t('full_register.phone')}</span>
                    <div className="form__form-group-field">
                        <Field
                          name="phone"
                            component={this.renderField}
                            type="text"
                            placeholder={t('full_register.phone')}
                        />
                    </div>
                </div>
                <div className="form__form-group full_register_form">
                    <span className="form__form-group-label">{t('full_register.linkedin')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="linkedin"
                            component={this.renderField}
                            type="text"
                            placeholder={t('full_register.linkedin')}
                        />
                    </div>
                </div>
{/*                <div className="form__form-group full_register_form ">
                    <span className="form__form-group-label">{t('full_register.web')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="web"
                            component={this.renderField}
                            type="text"
                            placeholder={t('full_register.web')}
                        />
                    </div>
                </div>*/}

                <Col md={12} lg={12} style={{marginBottom: '10px'}}>
                    <h5>{t('full_register.address')}</h5>
                </Col>
                <AddressInput addressName={'personalAddress'} showLabels renderInput={this.renderField} editable
                              classname={'full_register_form'}
                />
                <div className="error-container">
                    {
                        this.props.registerError &&
                        <span className="error-message">{t(`errors.${this.props.registerErrorMessage.code}`)}</span>
                    }
                </div>
                <div className="account__btns">
                    <Button className="btn btn-primary account__btn"
                            type="submit"
                            onClick={() => this.showErrors(this.props.invalid)}
                            >{t('full_register.register')
                            }</Button>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => ({
    initialValues: {
        personalAddress: {
            country: {
                value: 'ARGENTINA',
                label: 'Argentina'
            }
        }
    }
});

export default connect(mapStateToProps)(withTranslation('common')(reduxForm({
    form: 'full_register_form', // a unique identifier for this form
    touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(FullRegisterForm)));
