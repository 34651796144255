import React, { useEffect } from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, Col} from "reactstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const EditUserForm = (props) => {
    const {handleSubmit, userData} = props;
    const { t } = useTranslation('common');

    const renderField = ({input, placeholder, type, meta: {touched, error, warning}}) => (
        <div style={{width: '100%'}}>
            <input style={{backgroundColor: 'white'}} {...input} placeholder={placeholder} type={type}/>
            <div className="input-error-container">
                {touched && ((error && <span className="error-message">{this.props.t(`errors.${error}`)}</span>) || (warning &&
                    <span className="error-message">{warning}</span>))}
            </div>
        </div>
    );
    
    return (
        <form className="form" onSubmit={handleSubmit}>
            <Col md={6} lg={6}>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('edit_user_form.name')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="name"
                            component={renderField}
                            // validate={this.validateRequired}
                            type="text"
                            placeholder={userData.name}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('edit_user_form.cuit')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="cuit"
                            component={renderField}
                            // validate={this.validateRequired}
                            type="text"
                            placeholder={userData.cuit}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('edit_user_form.rol')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="rol"
                            component={renderField}
                            // validate={this.validateRequired}
                            type="text"
                            placeholder={t('edit_user_form.rol')}
                        />
                    </div>
                </div>
            </Col>
            <Col>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('edit_user_form.surname')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="surname"
                            component={renderField}
                            // validate={this.validateRequired}
                            type="text"
                            placeholder={userData.surname}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('edit_user_form.accounts')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="accounts"
                            component={renderField}
                            // validate={this.validateRequired}
                            type="text"
                            placeholder={t('edit_user_form.accounts')}
                        />
                    </div>
                </div>
            </Col>
            <div style={{display: 'flex', width:'100%', justifyContent: 'center'}}>
                <Button className="btn btn-primary"
                        type="submit">{t('edit_user_form.edit')}</Button>
            </div>
        </form>
    )
};

const mapStateToProps = (state) => ({
    initialValues: state.admin.userSearch
});

export default connect(mapStateToProps)((reduxForm({
    form: 'edit_user_form',
    enableReinitialize: true
}))(EditUserForm));