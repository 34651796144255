import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ManageEstablishments from '../../../ManageEstablishments/index';
import AddEstablishmentWizard from '../../../ManageEstablishments/components/AddEstablishmentWizard/AddEstablishmentWizard';
import EstablishmentDetails from '../../../ManageEstablishments/components/EstablishmentsDetails';
import EstablishmentLots from '../../../ManageEstablishments/components/EstablishmentLotsTable';
import EstablishmentLotDetails from '../../../ManageEstablishments/components/EstablishmentLotDetails';
import EstablishmentLotForm from '../../../ManageEstablishments/components/EstablishmentLotForm';
import AddEstablishmentLot from '../../../ManageEstablishments/components/AddEstablishmentLot';

export default () => (
    <Switch>
        <Route path="/manage_establishments/table" component={ManageEstablishments} />
        <Route path="/manage_establishments/new_establishment" component={AddEstablishmentWizard} />
        <Route path="/manage_establishments/establishment_details" component={EstablishmentDetails} />
        <Route path="/manage_establishments/establishment_lots_table" component={EstablishmentLots} />
        <Route path="/manage_establishments/establishment_lot_details" component={EstablishmentLotDetails} />
        <Route path="/manage_establishments/new_establishment_lot" component={EstablishmentLotForm} />
        <Route path="/manage_establishments/new_establishment_lot" component={AddEstablishmentLot} />
    </Switch>
);
