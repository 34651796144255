import api from '../../api/index';
import {
    GET_ANIMAL_PAGE,
    GET_ANIMAL_PAGE_FOR_DOWNLOAD,
    SEARCH_ANIMALS,
    FILTER_ANIMALS,
    UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD,
    ACCEPT_MASSIVE_ANIMALS_UPLOAD,
    ADD_COW, GET_USER_ANIMAL_BY_ID,
    UPLOAD_COW_FILES,
    EDIT_COW,
    GET_ANIMAL_PAGE_BY_ESTABLISHMENT
} from '../actions/animalActions';
import actions from '../actions';
import mapper from './mappers/animalsMapper';

const animal = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {
        case GET_ANIMAL_PAGE:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserAnimals(jwt, action.page, action.pageSize)
                    .then(res => {
                        dispatch(actions.animal.getPageResponse(mapper.response.getAnimals(res)))
                    })
                    .catch(err => {
                        dispatch(actions.animal.getPageError(err))
                    })
            }));
            break;
        case SEARCH_ANIMALS:
            api.searchAnimals(action.word)
                .then(res => {
                    dispatch(actions.animal.searchResponse(mapper.response.searchAnimals(res)))
                })
                .catch(err => {
                    dispatch(actions.animal.searchError(err))
                });
            break;
        case FILTER_ANIMALS:
            api.filterAnimals(action.filters)
                .then(res => {
                    dispatch(actions.animal.filterResponse(mapper.response.filterAnimals(res)))
                })
                .catch(err => {
                    dispatch(actions.animal.filterError(err))
                });
            break;
        case UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.massiveAnimalUpload(jwt, action.establishmentId, 'cow', action.file)
                    .then(res => {
                        dispatch(actions.animal.uploadFileForBulkLoadResponse(mapper.response.massiveAnimalUpload(res)))
                    })
                    .catch(err => {
                        dispatch(actions.animal.uploadFileForBulkLoadError(err))
                    })
            }));
            break;
        case ACCEPT_MASSIVE_ANIMALS_UPLOAD:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.acceptMassiveAnimalUpload(jwt, action.excelId)
                    .then(() => {
                        dispatch(actions.animal.getPage(1, 10));
                        dispatch(actions.animal.acceptMassiveAnimalsUploadResponse());
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.animal.acceptMassiveAnimalsUploadError(err.message));
                        if(action.errorCallback) action.errorCallback((err.message));
                    })
            }));
            break;
        case ADD_COW:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                //  Map all the files to be uploaded
                const files = mapper.data.uploadCowFormFiles(action.data);
                //  Upload all the files
                if(files.length > 0) {
                    dispatch(actions.animal.uploadCowFiles(files, (uploadedFilesAndUrls) => {
                        api.addAnimal(jwt, mapper.body.addCow(action.data, uploadedFilesAndUrls), 'cow')
                            .then(res => {
                                dispatch(actions.animal.addCowResponse(res));
                                if(action.successCallback) action.successCallback();
                            })
                            .catch(err => {
                                dispatch(actions.animal.addCowError(err))
                                if(action.errorCallback) action.errorCallback(err);
                            })
                    }))
                }
                else {
                    api.addAnimal(jwt, mapper.body.addCow(action.data), 'cow')
                        .then(res => {
                            dispatch(actions.animal.addCowResponse(res));
                            if(action.successCallback) action.successCallback();
                        })
                        .catch(err => {
                            dispatch(actions.animal.addCowError(err))
                            if(action.errorCallback) action.errorCallback(err);
                        })
                }
            }));
        break;
        case GET_USER_ANIMAL_BY_ID:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserAnimalById(jwt, action.animalId)
                    .then(res => {
                        dispatch(actions.animal.getByIdResponse(mapper.response.getUserAnimalById(res)))
                    })
                    .catch(err => {
                        dispatch(actions.animal.getByIdError(err))
                    })
            }));
        break;
        case UPLOAD_COW_FILES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                //  All files to upload
                let filesToUpload = action.files;
                //  All urls promises
                let urlsPromises = [];
                //  All files ans it associated ids
                let filesAndAssociatedUrls = {};

                filesToUpload.forEach(fileObj => {
                    let urlPromise = api.uploadImage(jwt, fileObj.file, fileObj.id);
                    urlsPromises.push(urlPromise);
                });

                Promise.all(urlsPromises)
                    .then(response => {
                        response.forEach(element => {
                            let fileName = element.additionalData.name;
                            let fileUrl = element.images.cvImage;
                            filesAndAssociatedUrls[`${fileName}`] = fileUrl;
                        });
                        if(action.callback) action.callback(filesAndAssociatedUrls)
                    })
                    .catch(err => {
                        console.log("Error when uploading files ", err);
                    });
            }));
        break;
        case EDIT_COW:
            // todo finish when the cv-api support this method
            dispatch(actions.auth.authenticatedRequest(jwt => {

                //  Map all the files that has change
                const files = mapper.data.editCowFormFiles(action.data);

                if(files.length > 0) {
                    //  Upload the files that change
                    dispatch(actions.animal.uploadCowFiles(files, (uploadedFilesAndUrls) => {
                        let filesUrl = {
                            DNA_analysis: action.data.DNA_analysis,
                            signal_on_ear: action.data.signal_on_ear,
                            ...uploadedFilesAndUrls
                        };
                        api.editAnimalById(jwt, action.animalId, mapper.body.editCow(action.data, filesUrl))
                            .then(res => {
                                dispatch(actions.animal.editCowResponse(res));
                                if(action.successCallback) action.successCallback();
                            })
                            .catch(err => {
                                dispatch(actions.animal.editCowError(err));
                                if(action.errorCallback) action.errorCallback(err);
                            })
                    }))
                }
                else {
                    api.editAnimalById(jwt, action.animalId, mapper.body.addCow(action.data))
                        .then(res => {
                            dispatch(actions.animal.editCowResponse(res));
                            if(action.successCallback) action.successCallback();
                        })
                        .catch(err => {
                            dispatch(actions.animal.editCowError(err));
                            if(action.errorCallback) action.errorCallback(err);
                        })
                }
            }));
        break;
        case GET_ANIMAL_PAGE_BY_ESTABLISHMENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserAnimalsByEstablishment(jwt, action.establishmentId, action.lotId, action.page, action.pageSize)
                    .then(res => {
                        dispatch(actions.animal.getPageByEstablishmentResponse(mapper.response.getAnimals(res)))
                    })
                    .catch(err => {
                        console.log(err)
                        dispatch(actions.animal.getPageByEstablishmentError(err))
                    })
            }))
        case GET_ANIMAL_PAGE_FOR_DOWNLOAD:
                if(action.establishmentId && action.lotId){
                    dispatch(actions.auth.authenticatedRequest(jwt => {
                        api.getUserAnimalsByEstablishment(jwt, action.establishmentId, action.lotId, action.page, action.pageSize)
                            .then(res => {
                                dispatch(actions.animal.getAnimalPageForDownloadResponse(mapper.response.getAnimalsForDownload(res)))
                            })
                            .catch(err => {
                                console.log(err)
                                dispatch(actions.animal.getAnimalPageForDownloadError(err))
                            })
                    }))
                }
    }
};

export default animal;