const requestTimeout = 500;

const error = (code, msj, details) => {
    return {
        code: code,
        msj: msj,
        details: details
    }
};

export function login(email, password) {
    return new Promise((_, reject) => {
        setTimeout(() => {
            reject(error('LG001', 'Login error', 'An error occurs, please try again in a few seconds'))
        }, requestTimeout)
    });
}

export function basicRegister(email, password, country) {
    return new Promise((_, reject) => {
        setTimeout(() => {
            reject(error('BRG001', 'Basic register error', 'An error occurs, please try again in a few seconds'))
        }, requestTimeout)
    })
}

export function completeRegister(name, surname, birthday, dni, cellular, particularPhone, particularAddress) {
    return new Promise((_, reject) => {
        setTimeout(() => {
            reject(error('CRG001', 'Complete register error', 'An error occurs, please try again in a few seconds'))
        }, requestTimeout)
    })
}

export function getAvailableCountries() {
    return new Promise((_, reject) => {
        setTimeout(() => {
            reject(error('GAC001', 'Get available countries error', 'An error occurs, please try again in a few seconds'))
        }, requestTimeout)
    })
}