import React from 'react';
import { Container, Row } from 'reactstrap';
import DentitionEventForm from "../NewEventForms/DentitionEventForm"
import { connect } from 'react-redux';
import actions from '../../../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const NewDentitionEventContainer = (props) => {
  const alert = useAlert();

  const addDentitionEvent = (values) => {
    props.submitDentitionEvent(values, props.facilityId, () => {
      alert.success(props.t('events.alerts.event_added'));
      props.history.push('/events/dentition/table');
    }, (errorMessage) => {
      ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
    });
  };

  return (
    <Container>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
      <Row>
        <DentitionEventForm
          onSubmit={addDentitionEvent}
          pending={props.addCowPending}
          editable/>
      </Row>
    </Container>
  )
};

const mapStateToProps = (state) => ({
  addCowPending: state.ui.addCowPending, // todo change this!
  facilityId: state.user.selectedEstablishment._id
});

const mapDispatchToProps = (dispatch) => ({
  submitDentitionEvent: (values, facilityId, successCallback, errorCallback) =>
    dispatch(actions.event.submitDentitionEvent(values, facilityId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewDentitionEventContainer));