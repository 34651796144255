import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import actions from '../../../redux/actions/index';
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import {ROLES} from "../../../constants";
import Multiselect  from 'react-multi-select-component';
import {filter} from '../../../util'
const EstablishmentSelector = (props) => {

    const { t,
        allCompanies,
        allLotsByEstablishment,
        establishmentsByCompany,
        selectedEstablishment } = props;

    // If no company is selected
    if(!props.selectedCompany) {
        //  And the user is titular don't show the establishment topbar.
        if(props.roles.some(rol => rol.name === ROLES.TITULAR)) return null;
    }

    const strings = {
        "selectSomeItems": t('selectors.establishmentSelector.selectSomeItems'),
        "allItemsAreSelected": t('selectors.common.allItemsAreSelected'),
        "selectAll": t('selectors.common.selectAll'),
        "search": t('selectors.establishmentSelector.search')
    }

    const changeValue = (establishment) => {
        props.selectEstablishment(establishment);
        if(establishment.length){
            const e = (establishment[establishment.length-1])
            // add lots from newly selected establishment
            // to selectedLots
            props.addSelectedLots(e.value)
        }
        const ids = establishment.map(el => ( el.value ));
        if(ids.length === 0) {
            props.resetAllEstablishmentLots();
        }else{
            props.getLotsByEstablishment(ids);
        }
        selectLotsFromSelectedEstablishments();
    };

    const selectLotsFromSelectedEstablishments = () => {
        const lots = allLotsByEstablishment.map(el => ( {value: el._id, label: el.name} ));
        if(establishmentsByCompany.length === selectedEstablishment.length){
            props.selectLot(lots)
        }
    }

    if (establishmentsByCompany && establishmentsByCompany.length > 0) {
        return (
            <div>
                {
                   <Multiselect
                        options={
                            establishmentsByCompany.map(el => ({
                                value: el._id,
                                label: el.name
                            }))
                        }
                        displayValue="name"
                        name="establishmentSelector"
                        value={props.selectedEstablishment}
                        onChange={(e)=>changeValue(e)}
                        overrideStrings={strings}
                    />
                }
            </div>
        )
    }
    return null;
};

const mapStateToProps = (state) => ({
    allCompanies: state.user.allCompanies,
    allLotsByEstablishment: state.user.allLotsByEstablishment,
    establishmentsByCompany: state.user.allEstablishmentsByCompany,
    establishmentsToAdd: state.user.establishmentsToAdd,
    selectedCompany: state.user.selectedCompany,
    selectedEstablishment: state.user.selectedEstablishment,
    roles: state.user.roles,
    userId: state.user.id
});

const mapDispatchToProps = (dispatch) => ({
    selectEstablishment: (establishment) => dispatch(actions.user.selectEstablishment(establishment)),
    getAssignedEstablishments: (userId) => dispatch(actions.user.getAssignedEstablishments(userId)),
    getLotsByEstablishment: (establishmentIds) => dispatch(actions.user.getLotsByEstablishment(establishmentIds)),
    resetAllEstablishmentLots: () => dispatch(actions.user.resetAllEstablishmentLots()),
    addSelectedLots: (establishmentId) => dispatch(actions.user.addSelectedLots(establishmentId)),
    selectLot: (lot) => dispatch(actions.user.selectLot(lot)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(EstablishmentSelector))
