import React, {useState, useEffect} from 'react';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import {withTranslation} from "react-i18next";
import AlertIcon from "mdi-react/AlertIcon";
import {connect} from "react-redux";
import { ClipLoader } from "react-spinners";
import actions from '../../../redux/actions/index'

const SurrogateList = (props) => {

    const { t, companyId, getSurrogatePending, surrogates } = props;

    useEffect(() => {
        if(companyId) props.getSurrogatesByCompany(companyId);
    }, [companyId]);

    const noSurrogates = () => surrogates.length === 0;

    const deleteUserFromCompany = (userId) => {
        props.deleteUserFromCompany(companyId, userId)
    }

    return (
        <div className="professional_list">
            {
                surrogates.map(surrogate => (
                    <div className="professional_list_item">
                        <div className="professional_list_item-name">
                            <label>{surrogate.name} {surrogate.lastName}</label>
                        </div>
                        <div className="professional_list_item-role">
                            <label>{t(`common.roles.${surrogate.role}`)}</label>
                        </div>
                        <div className="professional_list_item-actions">
                            <CloseCircleIcon color={'red'} onClick={() => deleteUserFromCompany(surrogate.userId)}/>
                        </div>
                    </div>
                ))
            }
            {
                getSurrogatePending ?
                    <div className="loader">
                        <ClipLoader size={15} loading={true} color={"#123abc"}/>
                    </div>:
                    noSurrogates() &&
                    <div className="no_professionals">
                        <AlertIcon/>
                        <h5>{t('accounts.modals.add_surrogate_to_company.no_professional')}</h5>
                    </div>
            }
        </div>
    )
};

const mapStateToProps = (state) => ({
    getSurrogatePending: state.ui.getSurrogatesByCompanyPending,
    surrogates: state.user.companySurrogates
});

const mapDispatchToProps = (dispatch) => ({
    getSurrogatesByCompany: (companyId) => dispatch(actions.user.getSurrogatesByCompany(companyId)),
    deleteUserFromCompany: (companyId, userId) => dispatch(actions.user.deleteUserFromCompany(companyId, userId))
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(SurrogateList))