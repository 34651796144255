import React, { useState } from 'react';
import Modal, {
  Card, CardBody, Col, Button, Row
} from 'reactstrap';
import AtIcon from 'mdi-react/AtIcon';
import CalendarRangeIcon from 'mdi-react/CalendarRangeIcon';
import AccountCardDetailsOutlineIcon from 'mdi-react/AccountCardDetailsOutlineIcon';
import AccountBadgeOutlineIcon from 'mdi-react/AccountBadgeOutlineIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import CellphoneBasicIcon from 'mdi-react/CellphoneBasicIcon';
import LinkedinBoxIcon from 'mdi-react/LinkedinBoxIcon';
import WebIcon from 'mdi-react/WebIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon'
import ProfileInputFormWithIcons from './ProfileInputFormWithIcons';
import ImageUploadModal from './ImageUploadModal';
import { withTranslation } from 'react-i18next';
import profileImage from '../../../../shared/img/no_profile.png'
import { parse_js_date_to_YYYY_MM_DD } from '../../../../util/index';
import {ROLES} from "../../../../constants";
import "./profileMain.css"
import { connect } from "react-redux";

const mapProfile = (user) => {
    return ({
        name: user.profile.name,
        lastname: user.profile.lastName,
        birthday: user.profile.birthday,
        dni: user.profile.dni,
        email: user.email,
        telephone: user.profile.telephone,
        cellular: user.profile.cellular,
        linkedin: user.profile.linkedin,
        homeAddress: user.profile.homeAddress,
        license: getLicenseNumber(user.roles),
        profileImage: user.profile.profileImage
    })
};

const getLicenseNumber = (roles) => {
    let rolesWithLicense = roles.filter((rol) => (rol.name === ROLES.VETERINARY || rol.name === ROLES.AGRICULTURAL_ENGINEER));
    if(rolesWithLicense.length > 0) return rolesWithLicense[0].licenseNumber;
    return null;
};

const ProfileMain = (props) => {
    const [showImageModal, setShowImageModal] = useState(false);

    const {user, t } = props;

    const avatar = () => props.user.profile.avatar ? props.user.profile.avatar : profileImage;
    const licenseNumber = getLicenseNumber(props.user.roles);

    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody className="profile__card cardProfile">
                    <div className="profile__information">
                        <div className="profile__avatar editable">
                            <img
                              className="profile__avatar--image"
                              src={avatar()}
                              onClick={() => setShowImageModal(true)}
                              alt="avatar"/>
                            <LeadPencilIcon
                              className="pencil--icon"
                              onClick={() => setShowImageModal(true)}
                              alt="avatar"/>
                        </div>
                        <LeadPencilIcon className="profile__editIcon" color={props.enableEditing ? 'green' : 'grey'}
                                        onClick={() => props.changeEditingMode()}/>
                        {!props.enableEditing ?
                            <div className="profile__data">
                                <div className={"mt-5"}>
                                <p className="profile__name">{props.user.profile.name} {props.user.profile.lastName}</p>
                                <p className="profile__contact"><AccountCardDetailsOutlineIcon/> {props.user.profile.dni}</p>
                                {
                                    licenseNumber ?
                                        <p className="profile__contact"><AccountBadgeOutlineIcon/> {licenseNumber}</p>:
                                        null
                                }
                                <p className="profile__contact"><CalendarRangeIcon/> {
                                    parse_js_date_to_YYYY_MM_DD(new Date(props.user.profile.birthday))
                                }</p>
                                <p className="profile__contact"><AtIcon/> {props.user.email}</p>
                                {
                                    props.user.profile.telephone &&
                                    <p className="profile__contact" dir="ltr"><PhoneIcon/>{props.user.profile.telephone}</p>
                                }
                                <p className="profile__contact" dir="ltr"><CellphoneBasicIcon/>{props.user.profile.cellular}
                                </p>
                                <p className="profile__contact"><LinkedinBoxIcon/>{props.user.profile.linkedin}</p>
                                </div>
                                </div> :
                            <div className="profile__data">
                                <ProfileInputFormWithIcons initialValues={mapProfile(props.user)} onSubmit={props.editUser}
                                                           cancel={props.changeEditingMode} pending={props.editUserPending}/>
                            </div>
                        }
                    </div>
                </CardBody>
            </Card>
            <ImageUploadModal
                isOpen={showImageModal}
                setOpen={() => setShowImageModal(!showImageModal)}
                title={t('profile.image-upload-modal.title')}
                editUserAvatar={props.editUserAvatar}
            />
        </Col>
    )
};

const mapStateToProps = state => ({
    user: state.user
});

export default withTranslation('common')(connect(mapStateToProps)(ProfileMain));
