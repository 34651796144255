/* eslint-disable react/no-array-index-key */
import React, {PureComponent} from 'react';
import {Collapse} from 'reactstrap';
import {Link} from 'react-router-dom';
import WalletIcon from 'mdi-react/WalletIcon';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import walletActions from '../../../redux/actions/walletActions'

class TopbarWallet extends PureComponent {
    state = {
        collapse: false,
    };

    toggle = () => {
        this.setState(prevState => ({collapse: !prevState.collapse}));
    };

    hasWallet() {
        return this.props.localWallet || this.props.remoteWallet
    }

    render() {
        const {t, wallet} = this.props;
        const {collapse} = this.state;
        return (
            <div className="topbar__collapse">
                <button className="topbar__btn" type="button" onClick={this.toggle}>
                    <WalletIcon/>
                </button>
                {collapse && <button className="topbar__back" type="button" onClick={this.toggle}/>}
                <Collapse
                    isOpen={collapse}
                    className="topbar__collapse-content"
                >
                    <div className="topbar__collapse-title-wrap">
                        <p className="topbar__collapse-title">{t('wallet.topbar.title')}</p>
                    </div>
                    <div className="topbar__collapse-item topbar__wallet">
                        {
                            !this.hasWallet() ?
                                <p className="topbar__collapse-message">
                                    {t('wallet.topbar.noWallet')}
                                </p> :
                                <p className="topbar__collapse-message">
                                    {t('wallet.topbar.youHaveAWallet')}
                                </p>
                        }
                    </div>
                    {
                        !this.hasWallet() &&
                        <Link className="topbar__collapse-link" to="/wallet/generate_mnemonic" onClick={this.toggle}>
                            {t('wallet.topbar.createWallet')}
                        </Link>
                    }
                </Collapse>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    localWallet: state.wallet.encryptWallet.local,
    remoteWallet: state.wallet.encryptWallet.remote,
});

export default connect(mapStateToProps)(withTranslation('common')(TopbarWallet))
