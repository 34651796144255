import React, { useState }  from 'react';
import EmailSendIcon from "mdi-react/EmailIcon";
import { ClipLoader } from "react-spinners";
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import { formValidators } from '../../../util/index'
import {ERRORS} from "../../../constants";
import MaskedInput from 'react-text-mask';
import i18n from '../../../translations/config/i18n';


const EmailInput = (props) => {

    const [inputValue, setInputValue] = useState('');

    const send = () => {
        props.send(inputValue);
    };

    const { placeholder, loading } = props;

    const handleSubmit = (event) => {
        event.preventDefault();
        send(inputValue);
    };

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
    <div style={{width: '100%'}}>
        {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
        {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);

    const validateEmail = value => !value || (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) ? undefined : ERRORS.invalidEmail


    return (
        <form className="search_input" onSubmit={handleSubmit}>
            <input placeholder={placeholder}
                   value={inputValue}
                   //component={renderField}
                   onChange={(event) => setInputValue(event.target.value)}
                   normalize={(value) => value.toLowerCase()}
                   validate={validateEmail}
            />
            <button type="submit" disabled={!inputValue || (inputValue === "")}>
                {
                    loading ?
                        <ClipLoader size={15} loading={true} color={"#123abc"}/>:
                        <EmailSendIcon/>
                }
            </button>
        </form>
    )
};

export default EmailInput