import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import actions from '../../../redux/actions/index';
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import Multiselect  from 'react-multi-select-component';
import {ROLES} from "../../../constants";

const companySelector = (props) => {

    const { t, allCompanies, establishmentsByCompany, selectedCompany } = props;

    const isValidRole = () => {
        // if the user has a profesional role, it doesn't have companies
        if(props.roles.some(rol => (rol.name) === ROLES.AGRICULTURAL_ENGINEER ||
                props.roles.some(rol => (rol.name) === ROLES.VETERINARY )))  {
                return false
        }
        return true;
    }

    if( !isValidRole ) return null;

    const strings = {
        "selectSomeItems": t('selectors.companySelector.selectSomeItems'),
        "allItemsAreSelected": t('selectors.common.allItemsAreSelected_f'),
        "selectAll": t('selectors.common.selectAll_f'),
        "search": t('selectors.companySelector.search')
    }

    const changeValue = (company) => {
        props.selectCompany(company);
        if(company.length){
            // add establishments from newly selected company
            // to selectedEstablishments
            const c = (company[company.length-1])
            if(!isInSelectedCompanies(c.value)) props.addSelectedEstablishments(c.value);
        }
        let ids = company.map(el => ( el.value ));
        if(ids.length === 0) {
           props.resetAllEstablishmentsByCompany();
           props.resetSelectedEstablishment();
           props.resetAllEstablishmentLots();
        }else{
            props.getEstablishmentsByCompanyId(ids);
        }
    };

    const isInSelectedCompanies = (value) => {
        console.log(value)
        for (let c of props.selectedCompany){
            if (c.value === value) return true
        }
        return false
    }

    if(allCompanies && allCompanies.length > 0) {
        return (
            <div>
                {
                <Multiselect
                    options={
                        allCompanies.map(el => ({
                            value: el._id,
                            label: el.name
                        }))
                    }
                    displayValue="name"
                    name="companySelector"
                    value={props.selectedCompany}
                    onChange={(e)=>changeValue(e)}
                    overrideStrings={strings}
                />
                }
            </div>
        );
    }
    return null
};

const mapStateToProps = (state) => ({
    allCompanies: state.user.allCompanies,
    establishmentsByCompany: state.user.allEstablishmentsByCompany,
    selectedCompany: state.user.selectedCompany,
    selectedEstablishment: state.user.selectedEstablishment,
    establishmentsToAdd: state.user.establishmentsToAdd,
    roles: state.user.roles,
    userId: state.user.id

});
const mapDispatchToProps = (dispatch) => ({
    getEstablishmentsByCompanyId: (companyId) =>
        dispatch(actions.user.getUserEstablishmentsByCompany(companyId)),
    addSelectedEstablishments: (companyId) =>
        dispatch(actions.user.addSelectedEstablishments(companyId)),
    selectCompany: (company) => dispatch(actions.user.selectCompany(company)),
    resetSelectedLot: () => dispatch(actions.user.resetSelectedLot()),
    resetSelectedEstablishment: () => dispatch(actions.user.resetSelectedEstablishment()),
    resetAllEstablishmentsByCompany: () => dispatch(actions.user.resetAllEstablishmentsByCompany()),
    resetAllEstablishmentLots: () => dispatch(actions.user.resetAllEstablishmentLots()),
    selectEstablishment: (establishment) => dispatch(actions.user.selectEstablishment(establishment))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(companySelector))
