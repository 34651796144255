import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import {withTranslation} from "react-i18next";
import {Button, ButtonToolbar} from "reactstrap";
import { renderCVPhotoInputField } from '../../../../shared/components/cv/CVFileUpload';
import renderSelectField from "../../../../shared/components/form/Select";
import AddressInput from "../../../../shared/components/cv/AddressInput";
import MaskedInput from 'react-text-mask';
import { formValidators, formMasks } from "../../../../util";
import {CheckBoxField} from '../../../../shared/components/form/CheckBox';
import { connect } from "react-redux";
import i18n from '../../../../translations/config/i18n';
import CVButton from '../../../../shared/components/cv/CVButton';
import CoordinatesInput from "../../../../shared/components/cv/CoordinatesInput";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import MailRuIcon from "mdi-react/MailRuIcon";
import {ERRORS} from "../../../../constants";
import actions from '../../../../redux/actions/index';
const validateRENSPA = value => formValidators.validateRENSPA(value);

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
    <div style={{width: '100%'}}>
        {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
        {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);

const validateEmail = value => !value || (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) ? undefined : ERRORS.invalidEmail

const ProductiveEstablishmentForm = (props) => {
    ProductiveEstablishmentForm.propTypes = {
        t: PropTypes.func.isRequired,
        submitEstablishmentForm: PropTypes.func.isRequired,
        previousPage: PropTypes.func.isRequired,
        pending: PropTypes.bool.isRequired
    };

    const {submitEstablishmentForm, handleSubmit, previousPage, t, availableProductiveSystems,
        availableGroundConditions, editable, showBackButton, submitButtonText, showCompanySelection,
        showImages, allCompanies, productive_establishment_form, availableCountries} = props;

    const availableApts = ["hilton", "china", "others", "none"];

    const [aptSelected, setAptSelected] = useState({
      hilton: false,
      china: false,
      others: false,
      none: false,
    });

    useEffect(() => {
      if(props.initialValues.apts && !editable) {
        setAptSelected(props.initialValues.apts)
      }
    }, [props.initialValues]);

    // useEffect(() => {
    //     if(props.selectedCompany) props.getUserCompany(props.selectedCompany._id);
    //  }, []);

    const selectApt = (apt) => {
      const newValue = {
        ...aptSelected
      };
      newValue[`${apt}`] = !newValue[`${apt}`];
      if(apt==="none"){
        newValue.china = false;
        newValue.hilton = false;
        newValue.others = false;
      }
      else{
        newValue.none = false
      }
      setAptSelected(newValue);
    };

    const noneAptsSelected = (apts) => {
      if(apts.hilton) return false;
      if(apts.china) return false;
      if(apts.others) return false;
      if(apts.none) return false;
      return true;
    };

    const [productiveSystemSelected, setProductiveSystemSelected] = useState({
        natural: false,
        silvoPastoral: false,
        pastoral: false,
        creepFeeding: false,
        feedlot: false,
        strategicFeeding: false,
    });

    const [addressesToAutocomplete, setAddressesToAutocomplete] = useState({
        names: []
    });

    const [addressNameToCopyFrom, setAddressNameToCopyFrom] = useState();

    useEffect(() => {
        if(props.initialValues.productiveSystems) {
            setProductiveSystemSelected(props.initialValues.productiveSystems)
        }
    }, [props.initialValues]);

    useEffect(() => {
        if(props.addressesToAutocomplete){
            setAddressesToAutocomplete(props.addressesToAutocomplete);
        }
    }, [props.addressesToAutocomplete]);

    const showErrors = (invalid) => {
        if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
    }

    const selectProductiveSystem = (productiveSystem) => {
        const newValue = {
            ...productiveSystemSelected
        };
        newValue[`${productiveSystem}`] = !newValue[`${productiveSystem}`];
        setProductiveSystemSelected(newValue);
    };

    const noneProductiveSystemsSelected = (productiveSystems) => {
        if(productiveSystems.natural) return false;
        if(productiveSystems.silvoPastoral) return false;
        if(productiveSystems.pastoral) return false;
        if(productiveSystems.creepFeeding) return false;
        if(productiveSystems.feedlot) return false;
        if(productiveSystems.strategicFeeding) return false;
        return true;
    };

    const submitForm = (values) => {
        if(noneProductiveSystemsSelected(productiveSystemSelected) || noneAptsSelected(aptSelected)) return;
        submitEstablishmentForm({...values, productiveSystems: productiveSystemSelected, apts: aptSelected});
    };

    const changeSelectedCompany = (company) => {
        props.selectCompany([company]);
        let companyData = getCompanyData(company.value);
        if(companyData) {
            setAddressesToAutocomplete({
                names: ["commercial", "personal"],
                commercial: companyData.addresses.commercial,
                personal: props.personalAddress,
            });
        }
    };

    const getCompanyData = (id) => {
        for (let element of allCompanies) {
            if (element._id === id) return element;
        }
        return null;
    }

    // This method fill the establishment address with an address given on 'addressesToAutocomplete' prop.
    const fillEstablishmentAddressWith = (addressName) => {
        setAddressNameToCopyFrom(
            {
                value: addressName,
                label: t(`cv-forms.productive_establishment.autocomplete_address.${addressName}`)
            }
        );
        let addressToAutocomplete = addressesToAutocomplete[`${addressName}`];
        //  Fill the establishment address.
        props.changeFieldValue(`establishmentAddress.streetName`, addressToAutocomplete.streetName);
        props.changeFieldValue(`establishmentAddress.streetNumber`, addressToAutocomplete.streetNumber);
        props.changeFieldValue(`establishmentAddress.apartment`, addressToAutocomplete.apartment);
        props.changeFieldValue(`establishmentAddress.postalCode`, addressToAutocomplete.postalCode);
        props.changeFieldValue(`establishmentAddress.city`, addressToAutocomplete.city);
        props.changeFieldValue(`establishmentAddress.state`, addressToAutocomplete.state);
        props.changeFieldValue(`establishmentAddress.country`, addressToAutocomplete.country);
    };

    const renderImage = (imageName) => {
        // An image was selected and preview is needed
        if(typeof (props.formImages[`${imageName}`]) === 'object' && !Array.isArray(props.formImages[`${imageName}`])) {
            return <img src={URL.createObjectURL(props.formImages[`${imageName}`].files[0])}/>;
        }
        // An image (or images) was previously upload and need to be shown
        else if((typeof (props.formImages[`${imageName}`]) === 'string') ||
            (Array.isArray(props.formImages[`${imageName}`]) && props.formImages[`${imageName}`].length > 0)) {
            return <img src={props.formImages[`${imageName}`]}/>;
        }
        // No image was selected and no image was upload
        else {
            return <label>{t('cv-forms.productive_establishment.no_image')}</label>;
        }
    };

    return (
        <form className="form" onSubmit={handleSubmit(submitForm)}>
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            {showCompanySelection &&
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.company_to_add_establishment')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="company_to_add_establishment"
                        component={renderSelectField}
                        type="text"
                        placeholder={t('cv-forms.productive_establishment.company_to_add_establishment')}
                        validate={formValidators.validateRequired}
                        disabled={!editable}
                        onChange={changeSelectedCompany}
                        options={
                            props.allCompanies.map(el => ({
                                value: el._id,
                                label: el.name
                            }))
                        }
                    />
                </div>
            </div>
            }
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.establishment_name')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="establishmentName"
                        component={renderField}
                        type="text"
                        validate={formValidators.validateRequired}
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.establishment_name')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.renspa_code')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="renspaCode"
                        component={renderField}
                        validate={[formValidators.validateRequired, validateRENSPA]}
                        disabled={!editable}
                        type="text"
                        placeholder={t('cv-forms.productive_establishment.renspa_code')}
                        mask={formMasks.RENSPAMask}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.renspa_credential_photo')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="resnpaCredentialPhoto"
                            component={renderCVPhotoInputField}
                            validate={formValidators.validateRequired}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('resnpaCredentialPhoto')}
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.cuig')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="cuig"
                        component={renderField}
                        type="text"
                        validate={formValidators.validateRequired}
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.cuigPlaceholder')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.fire_mark_photo')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="fireMarkPhoto"
                            component={renderCVPhotoInputField}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('fireMarkPhoto')}
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.ground_condition')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="groundCondition"
                        component={renderSelectField}
                        type="text"
                        placeholder={t('cv-forms.productive_establishment.ground_condition')}
                        validate={formValidators.validateRequired}
                        disabled={!editable}
                        options={[
                            ...availableGroundConditions.map(groundCondition => ({
                                    value: groundCondition,
                                    label: t(`cv-forms.productive_establishment.ground_conditions.${groundCondition}`)}
                            ))
                        ]}
                    />
                </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('cv-forms.productive_establishment.surface_area')}</span>
              <div className="form__form-group-field">
                <Field
                  name="surfaceArea"
                  type="number"
                  component={renderField}
                  validate={formValidators.validateRequired}
                  disabled={!editable}
                  placeholder={t('cv-forms.productive_establishment.surface_area')}
                />
              </div>
            </div>
            <div className="form__form-group">
            <span className="form__form-group-label">{t('cv-forms.productive_establishment.email')}</span>
            <div className="form__form-group-field">
              <Field
                name="email"
                component={renderField}
                validate={validateEmail}
                type="email"
                placeholder={t('cv-forms.productive_establishment.email')}
              />
            </div>
          </div>
          <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.linkedin')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="linkedin"
                        component={renderField}
                        type="text"
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.linkedin')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.webSite')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="webSite"
                        component={renderField}
                        type="text"
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.webSite')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.logotype')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="logotypePhoto"
                            component={renderCVPhotoInputField}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('logotypePhoto')}
                </div>
            </div>
            <AddressInput title={t('cv-forms.productive_establishment.establishment_address')}
                          addressName={'establishmentAddress'} renderInput={renderField} editable={editable}
                          showAutoComplete={true} onAutocompleteChange={(address) => fillEstablishmentAddressWith(address.value)}
                          autocompleteValue={addressNameToCopyFrom} addressesToAutocomplete={addressesToAutocomplete} country={props.country ? props.country.value : "ARGENTINA"}
            />
            {/* <div className='form__form-group address_input horizontal' style={{flexDirection:'column'}}>
              <span className="form__form-group-label">{t('cv-inputs.address.country')}</span>
              <div className="form__form-group-field">
                <Field
                  name='establishmentAddress.country'
                  component={renderSelectField}
                  type="text"
                  placeholder={t('cv-inputs.address.country')}
                  disabled={!editable}
                  options={
                    availableCountries.map(country => ({
                      value: country.value,
                      label: t(`countries.${country.value}`)
                    }))
                  }
                />
              </div>
            </div> */}
            <CoordinatesInput editable={editable}/>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.photo_entrance_optional')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="entrancePhoto"
                            component={renderCVPhotoInputField}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('entrancePhoto')}
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.photo_aerial_optional')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="aerialPhoto"
                            component={renderCVPhotoInputField}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('aerialPhoto')}
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.photo_productive_system_optional')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="productiveSystemPhoto"
                            component={renderCVPhotoInputField}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('productiveSystemPhoto')}
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.photo_welfare_animals_system_optional')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="animalWelfarePhoto"
                            component={renderCVPhotoInputField}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('animalWelfarePhoto')}
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.photos_others_optional')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="otherPhotos"
                            multiple
                            component={renderCVPhotoInputField}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('otherPhotos')}
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.productive_system')}</span>
                <div className="form__form-group-field">
                    {
                        availableProductiveSystems.map((productiveSystem, index) => (
                            <div style={{marginLeft: index === 0 ? '0px' : '20px'}}>
                                <CheckBoxField
                                    onChange={() => selectProductiveSystem(productiveSystem)}
                                    value={productiveSystemSelected[`${productiveSystem}`]}
                                    name={`productiveSystems.${productiveSystem}`}
                                    label={t(`cv-forms.productive_establishment.productive_system_options.${productiveSystem}`)}
                                    disabled={!editable}
                                />
                            </div>
                        ))
                    }
                </div>
                {
                    noneProductiveSystemsSelected(productiveSystemSelected) &&
                    <div className="input-error-container">
                                        <span className="error-message">
                                            {props.t(`errors.REQUIRED`)}
                                        </span>
                    </div>
                }
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('cv-forms.apts')}</span>
              <div className="form__form-group-field">
                {
                  availableApts.map((apt, index) => (
                    <div style={{marginLeft: index === 0 ? '0px' : '20px'}}>
                      <CheckBoxField
                        onChange={() => selectApt(apt)}
                        value={aptSelected[`${apt}`]}
                        name={`apts.${apt}`}
                        label={t(`cv-forms.apt_options.${apt}`)}
                        disabled={!editable}
                      />
                    </div>
                  ))
                }
              </div>
              {
                noneAptsSelected(aptSelected) &&
                <div className="input-error-container">
                                          <span className="error-message">
                                              {props.t(`errors.REQUIRED`)}
                                          </span>
                </div>
              }
            </div>
            <ButtonToolbar className="form__button-toolbar">
                {
                    showBackButton &&
                    <Button color="primary" outline={true} type="button" onClick={previousPage}>{t('common.previous')}</Button>
                }
                {
                    editable &&
                    <CVButton
                    color="primary"
                    type="submit"
                    onClick={() => showErrors(props.invalid)}
                    pending={props.pending}>{submitButtonText}</CVButton>
                }
            </ButtonToolbar>
        </form>
    )
};

const selector = formValueSelector('productive_establishment_form');

// This is hard-code because the default value of the select is not working properly.
const mapStateToProps = (state, props) => ({
    allCompanies: state.user.allCompanies,
    initialValues: props.initialValues ||
        {
            establishmentAddress: {
                country:{
                    value: 'ARGENTINA',
                    label: "Argentina"
                }
            },
            apts: {
              hilton: false,
              china: false,
              others: false,
              none: true,
            }
        },
    accountDetails: state.user.accountDetails,
    availableCountries: state.generalData.availableCountries,
    productive_establishment_form: state.form.productive_establishment_form,
    personalAddress: state.user.profile.homeAddress,
    country: selector(state, 'establishmentAddress.country'),
    formImages: {
        fireMarkPhoto: selector(state, 'fireMarkPhoto'),
        resnpaCredentialPhoto: selector(state, 'resnpaCredentialPhoto'),
        entrancePhoto: selector(state, 'entrancePhoto'),
        aerialPhoto: selector(state, 'aerialPhoto'),
        productiveSystemPhoto: selector(state, 'productiveSystemPhoto'),
        animalWelfarePhoto: selector(state, 'animalWelfarePhoto'),
        otherPhotos: selector(state, 'otherPhotos'),
        logotypePhoto: selector(state, 'logotypePhoto')
    }
});

const mapDispatchToProps = (dispatch) => ({
    selectCompany: (company) => dispatch(actions.user.selectCompany(company)),
    getUserCompany: (companyId) => dispatch(actions.user.getCompany(companyId)),
    changeFieldValue: (field, value) => dispatch(change('productive_establishment_form', field, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'productive_establishment_form'
})(withTranslation('common')(ProductiveEstablishmentForm)))