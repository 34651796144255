import React from 'react';
import { Container, Row } from 'reactstrap';
import CowForm from "./CowForm";
import { connect } from 'react-redux';
import actions from '../../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const AddCowForm = (props) => {
    const alert = useAlert();

    const addCow = (values) => {
        props.addCow(values, () => {
            props.history.push('/animals/table');
            alert.success(props.t('animals.alerts.animal_added_successfully'));
        }, (errorMessage) => {
            if(errorMessage.cause) ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            else ToastsStore.error((errorMessage), 8000);
        });
    };

    return (
        <Container>
          <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Row>
                <CowForm onSubmit={addCow}
                    pending={props.addCowPending}
                    readOnlyField={false}
                    editable/>
            </Row>
        </Container>
    )
};

const mapStateToProps = (state) => ({
    addCowPending: state.ui.addCowPending,
});

const mapDispatchToProps = (dispatch) => ({
    addCow: (data, successCallback, errorCallback) => dispatch(actions.animal.addCow(data, successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AddCowForm));