import {
    GET_EVENTS_PAGE_RESPONSE,
    SEARCH_EVENTS_RESPONSE,
    FILTER_EVENTS_RESPONSE,
    UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_RESPONSE,
    UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD_RESPONSE,
    MASSIVE_EVENT_LOAD_RESPONSE,
    SUBMIT_REIDENTIFICATION_EVENT_RESPONSE,
    SUBMIT_WEIGHT_EVENT_RESPONSE,
    SUBMIT_CASTRATION_EVENT_RESPONSE,
    SUBMIT_DELETION_EVENT_RESPONSE,
    SUBMIT_DENTITION_EVENT_RESPONSE,
    SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT_RESPONSE,
    SUBMIT_RECATEGORIZATION_EVENT_RESPONSE,
    SUBMIT_SLAUGHTER_EVENT_RESPONSE,
    SUBMIT_CATEGORY_CHANGE_EVENT_RESPONSE,
} from "../actions/eventActions";

/*
* The event reducer will have the following structure:
*   {
*       [EVENT_TYPE_NAME] = {events: [ARRAY_OF_EVENTS], pages: NUMBER, total: NUMBER, page: NUMBER} // Object for each event type
*       massiveLoad: { _id: STRING, hash: STRING, status: STRING, events: [ARRAY_OF_EVENTS]}
*   }
* */
const initialState = {
    massiveLoad: {}
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS_PAGE_RESPONSE:
        case SEARCH_EVENTS_RESPONSE:
        case FILTER_EVENTS_RESPONSE:
            return {
                ...state,
                [`${action.eventType}`]: action.response
            };
        case UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_RESPONSE:
            return {
               ...state,
               massiveLoad: action.response
            };
        case MASSIVE_EVENT_LOAD_RESPONSE:
            return {
                events: action.response.results,
                pages: action.response.pages,
                total: action.response.total,
                page: action.response.page,
                massiveLoad: []
            };
        case UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD_RESPONSE:
            return {
                ...state,
                massiveLoad: action.response
            };
        case SUBMIT_REIDENTIFICATION_EVENT_RESPONSE:
            return {
               ...state,
                events: action.response.results,
            };
        case SUBMIT_CASTRATION_EVENT_RESPONSE:
            return {
                ...state,
                events: action.response.results,
            };
        case SUBMIT_CATEGORY_CHANGE_EVENT_RESPONSE:
            return {
                ...state,
                events: action.response.results,
            };
        case SUBMIT_DELETION_EVENT_RESPONSE:
            return {
                ...state,
                events: action.response.results,
            };
        case SUBMIT_DENTITION_EVENT_RESPONSE:
            return {
                ...state,
                events: action.response.results,
            };
        case SUBMIT_WEIGHT_EVENT_RESPONSE:
            return {
                ...state,
                events: action.response.results,
            };
        case SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT_RESPONSE:
            return {
                ...state,
                events: action.response.results,
            };
        case SUBMIT_SLAUGHTER_EVENT_RESPONSE:
            return {
                ...state,
                events: action.response.results,
            };
        case SUBMIT_RECATEGORIZATION_EVENT_RESPONSE:
            return {
                ...state,
                events: action.response.results,
            };
        default:
            return state
    }
};
export default eventReducer;