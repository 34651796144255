import React, {useEffect, useState, Fragment} from 'react';
import { withTranslation } from "react-i18next";
import CVTable from "../../Tables/CVTable/CVTable";
import { connect } from "react-redux";
import actions from '../../../redux/actions/index'
import { withRouter } from 'react-router-dom';
import {useAlert} from "react-alert";
import CVModal from "../../UI/Modals/components/CVModal";
import {ROLES} from "../../../constants";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import CompanySelector from '../../Layout/selectors/companySelector';
import EstablishmentSelector from '../../Layout/selectors/establishmentSelector';
import 'react-tippy/dist/tippy.css'

const EstablishmentLotsTable = (props) => {
    const {
        t,
        allEstablishments,
        establishmentLots,
        widthPercentage,
        selectedEstablishment,
        defaultEstablishment
    } = props;

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [establishmentLotToDelete, setEstablishmentLotToDelete] = useState();
    const [activeLots, setActiveLots] = useState();

    const alert = useAlert();

    useEffect(()=> {
        let establishmentIds = selectedEstablishment.map(el => ( el.value ));
        props.getLotsByEstablishment(establishmentIds)
    }, [])

    useEffect(() => {
        const lotsToShow = getActiveLots();
        setActiveLots(lotsToShow);
    }, [establishmentLots]);

    const getActiveLots = () => {
        let lots = [];
        for(let lot of establishmentLots) {
            if (lot.isActive) lots.push(lot);
        }
        return lots;
    }

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderDate = ({value}) => {
        const date = new Date(value);
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {`${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`}
            </div>
        )
    };

    const isValidRole = () => {
        if(props.userRoles.some((rol) => rol.name === ROLES.SURROGATE)) return true
        if(props.userRoles.some((rol) => rol.name === ROLES.TITULAR)) return true
        return false
    };

    const renderButtons = ({dependentValues}) => {
        return (
          <div style={{display: "flex", justifyContent: "center"}}>
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <MagnifyIcon
                className={"button"}
                style={{marginLeft: "10px"}}
                onClick={() => {
                  props.selectEstablishmentLotToEdit(dependentValues.facilityId, dependentValues._id, dependentValues.name, dependentValues.details)
                  props.history.push(
                    "/manage_establishments/establishment_lot_details",
                  )
                }}
              />
              {
                isValidRole() ? (
                <div>
                <CloseCircleIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    color={"red"}
                    onClick={() => {
                       setEstablishmentLotToDelete({
                            name: dependentValues.name,
                            _id: dependentValues._id
                        });
                        setShowDeleteModal(!showDeleteModal);
                    }}
                />
                </div>
                )
                : null
             }
          </div>
        );
    };

    const heads = [
        {
            key: 'name',
            name: 'Lote',
            percentage: 25,
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'createdAt',
            name: t('establishments.table.headers.creation_date'),
            percentage: 25,
            minWidth: 150,
            formatter: renderDate
        },
        {
            key: 'actions',
            name: t('establishments.table.headers.actions'),
            percentage: 25,
            minWidth: 150,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const renderDeleteEstablishmentBody = () => (
        establishmentLotToDelete ?
            <div>
                {t('establishments.modals.delete_animal_lots.body', { name: establishmentLotToDelete.name })}
            </div>:
            null
    );

    const renderDeleteEstablishmentModal = () => (
        <CVModal
            title={t('establishments.modals.delete_animal_lots.title')}
            body={() => renderDeleteEstablishmentBody()}
            color="danger"
            header
            isOpen={showDeleteModal}
            toggle={() => setShowDeleteModal(!showDeleteModal)}
            acceptFunc={() => {
                props.deleteEstablishmentLotById(establishmentLotToDelete._id, () => {
                    setShowDeleteModal(false);
                    props.getLotsByEstablishment(getEstablishmentIds());
                    alert.success(t('establishments.alerts.establishment_lot_deleted', { name: establishmentLotToDelete.name }));
                })
            }}
        />
    );

    const getEstablishmentIds = () => {
        let ids = props.selectedEstablishment.map(el => ( el.value ));
        if(ids.length === 0) {
            // if there no establishment selected, map values from
            // allEstablishments
            ids = allEstablishments.map(el => (el._id));
        }
        return ids
    }

    const renderIconForAddingLots = () => (
        selectedEstablishment || defaultEstablishment ?
        <div>
            <PlusCircleOutlineIcon
                onClick={() => props.history.push('/manage_establishments/new_establishment_lot')}
            />
        </div>
        : null
    );

    const renderTableSelectors = () => (
        <Fragment>
         <div>
          <CompanySelector/>
         </div>
         <div>
           <EstablishmentSelector />
         </div>
        </Fragment>
    );

    return(
        <div>
            <CVTable
                getPage={props.getEstablishments}
                CVSelect={renderTableSelectors}
                data={activeLots}
                heads={heads}
                title={t(`establishments.lots.lots`)}
                hideFilters
                hidePagination
                SubtitleComponent={renderIconForAddingLots}
                widthPercentage={widthPercentage}
                noDataLabel={t('establishments.lots.no_data_label')}
                pending={props.getUserEstablishmentsPending}
            />
            {renderDeleteEstablishmentModal()}
        </div>
    )

};

const mapStateToProps = (state) => ({
    selectedEstablishment: state.user.selectedEstablishment,
    currentEstablishment: state.user.selectedEstablishment?
        state.user.selectedEstablishment._id : null,
    allEstablishments: state.user.allEstablishments,
    establishments: state.user.establishments.results,
    currentPage: state.user.establishments.page,
    total: state.user.establishments.total,
    totalPages: state.user.establishments.totalPages,
    pageSize: state.user.establishments.pageSize,
    getUserEstablishmentsPending: state.ui.getUserEstablishmentsPending,
    getUserEstablishmentsError: state.ui.getUserEstablishmentsError,
    userRoles: state.user.roles,
    establishmentLots: state.user.allLotsByEstablishment,
    e: state.user.selectedEstablishment? state.user.selectedEstablishment.name: "",
});

const mapDispatchToProps = (dispatch) => ({
    getUserEstablishmentById: (currentEstablishment) => dispatch(actions.user.getEstablishmentById(currentEstablishment)),
    deleteEstablishmentLotById: (establishmentLotId, callback) =>
        dispatch(actions.user.deleteUserEstablishmentLotById(establishmentLotId, callback)),
    getEstablishments: (page, pageSize) => dispatch(actions.user.getEstablishments(page, pageSize)),
    getLotsByEstablishment: (establishmentIds) => dispatch(actions.user.getLotsByEstablishment(establishmentIds)),
    selectEstablishmentLotToEdit: (_id, name, details) =>
        dispatch(actions.user.selectEstablishmentLotToEdit(_id, name, details))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(EstablishmentLotsTable)));