import React, { useState, useEffect } from 'react';
import ProductiveEstablishmentForm from './establishments/ProductiveEstablishmentForm'
import { Card, CardBody, Col } from "reactstrap";
import LeadPencilIcon from "mdi-react/LeadPencilIcon";
import { reset } from 'redux-form';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import actions from '../../../redux/actions/index';
import { ESTABLISHMENT_TYPES } from "../../../constants";
import SlaughterEstablishmentForm from "./establishments/SlaughterEstablishmentForm";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

const EstablishmentDetails = (props) => {
    const [editable, setEditable] = useState(false);

    if (!props.location || !props.location.state || !props.location.state.establishmentId) return null;
    const establishmentId = props.location.state.establishmentId;

    useEffect(() => {
        // resets the establishment details to avoid wrong information render.
        props.resetEstablishmentDetails();
    }, []);

    useEffect(() => {
        if (props.establishmentDetails && (props.establishmentDetails.type === ESTABLISHMENT_TYPES.productive)) {
            props.getAvailableProductiveSystems();
            props.getAvailableGroundConditions();
        }
    }, [props.establishmentDetails]);

    useEffect(() => {
        if (establishmentId) props.getUserEstablishmentById(establishmentId);
    }, [establishmentId]);

    useEffect(() => {
        if (!editable) {
            props.resetProductiveEstablishmentForm();
            props.resetSlaughterEstablishmentForm();
        }
    }, [editable]);

    const editUserEstablishment = (values) => {
        props.editUserEstablishmentById(establishmentId, props.establishmentDetails.type, values,
            () => {
                props.history.push('/manage_establishments/table')
            }, (errorMessage) => {
                ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            });
    };

    const getEstablishmentFormByType = (establishmentType) => {
        switch (establishmentType) {
            case ESTABLISHMENT_TYPES.productive:
                return <div>
                    <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore} />
                    <ProductiveEstablishmentForm submitEstablishmentForm={editUserEstablishment}
                        editable={editable}
                        availableProductiveSystems={props.availableProductiveSystems}
                        availableGroundConditions={props.availableGroundConditions}
                        initialValues={props.establishmentDetails}
                        submitButtonText={props.t('common.save')}
                        showImages
                        pending={props.editIsPending}

                    />
                </div>
            case ESTABLISHMENT_TYPES.slaughterhouse:
            case ESTABLISHMENT_TYPES.commercial:
            case ESTABLISHMENT_TYPES.importer:
                return <SlaughterEstablishmentForm submitEstablishmentForm={editUserEstablishment}
                    editable={editable}
                    initialValues={props.establishmentDetails}
                    submitButtonText={props.t('common.save')}
                    showImages
                    pending={props.editIsPending}
                    establishmentType={props.establishmentDetails.type}
                />
            default:
                return <div>No implementado todavía</div>;
        }
    };

    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <h5 className="bold-text">{props.t('establishment_details.title')}</h5>
                            <div style={{ display: 'flex' }}>
                                <LeadPencilIcon className="pencil--icon" color={editable ? 'green' : 'grey'}
                                    onClick={() => setEditable(!editable)} />
                            </div>
                        </div>
                    </div>
                    {/* only show the details when the account info arrive */}
                    {props.establishmentDetails && getEstablishmentFormByType(props.establishmentDetails.type)}
                </CardBody>
            </Card>
        </Col>
    )
};
const mapStateToProps = (state) => ({
    availableProductiveSystems: state.constant.availableProductiveSystems,
    availableGroundConditions: state.constant.availableGroundConditions,
    establishmentDetails: state.user.establishmentDetails,
    editIsPending: state.ui.editUserEstablishmentByIdPending
});

const mapDispatchToProps = (dispatch) => ({
    getUserEstablishmentById: (establishmentId) => dispatch(actions.user.getEstablishmentById(establishmentId)),
    editUserEstablishmentById: (establishmentId, establishmentType, data, successCallback, errorCallback) =>
        dispatch(actions.user.editEstablishmentById(establishmentId, establishmentType, data, successCallback, errorCallback)),
    getAvailableProductiveSystems: () => dispatch(actions.constant.getAvailableProductiveSystems()),
    getAvailableGroundConditions: () => dispatch(actions.constant.getAvailableGroundConditions()),
    resetProductiveEstablishmentForm: () => dispatch(reset('productive_establishment_form')),
    resetSlaughterEstablishmentForm: () => dispatch(reset('slaughter_establishment_form')),
    resetEstablishmentDetails: () => dispatch(actions.user.resetEstablishmentDetails())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(EstablishmentDetails))