import React from 'react';
import {
    Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderDropZoneField from '../../../../shared/components/form/DropZone';

const ImageUploadDefault = ({ handleSubmit, reset, t, acceptedFormats }) => (
    <form className="form form--justify" onSubmit={handleSubmit}>
        <Field
            name="files"
            acceptedFormats={acceptedFormats}
            dropZonePlaceHolder={t('cv-inputs.photo_upload.drop_zone_placeholder')}
            component={renderDropZoneField}
        />
        <ButtonToolbar className="form__button-toolbar center">
            <Button color="primary" type="submit">{t('profile.buttons.save')}</Button>
            <Button type="reset" onClick={reset}>{t('profile.buttons.cancel')}</Button>
        </ButtonToolbar>
    </form>
);

ImageUploadDefault.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'file_upload_default', // a unique identifier for this form
})(withTranslation('common')(ImageUploadDefault));
