import React from 'react';
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import {Field} from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const renderField = ({ input, placeholder, type, meta: { touched, error, warning } }) => (
    <div style={{width: '100%'}}>
        <input style={{backgroundColor: 'white'}} {...input} placeholder={placeholder} type={type}/>
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{error}</span>) || (warning && <span className="error-message">{warning}</span>))}
        </div>
    </div>
);

const PasswordInput = (props) => {
    PasswordInput.propTypes = {
        t: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        show: PropTypes.bool.isRequired,
        setShow: PropTypes.func.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        icon: PropTypes.elementType
    };

    const { name, show, setShow, label, placeholder, icon, t } = props;

    return (
        <div className="form__form-group" style={{marginBottom: '0px'}}>
            <span className="form__form-group-label">{label}</span>
            <div className="form__form-group-field">
                <div className="form__form-group-icon">
                    {icon ? icon : <KeyVariantIcon />}
                </div>
                <Field
                    name={name}
                    component={renderField}
                    // validate={this.validateRequired}
                    type={show ? 'text' : 'password'}
                    placeholder={placeholder}
                />
                <button
                    type="button"
                    className={`form__form-group-button${show ? ' active' : ''}`}
                    onClick={() => setShow(!show)}
                ><EyeIcon />
                </button>
            </div>
        </div>
    )
};

export default withTranslation('common')(PasswordInput)