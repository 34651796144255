import React, {PureComponent} from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import i18n from '../../../translations/config/i18n';

export class SelectField extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
            }),
        ]).isRequired,
        disabled: PropTypes.bool,
        multiple: PropTypes.bool,
        closeMenuOnSelect: PropTypes.bool
    };

    static defaultProps = {
        placeholder: '',
        options: [],
    };

    handleChange = (selectedOption) => {
        const {onChange} = this.props;
        onChange(selectedOption);
    };

    render() {
        const {value, name, placeholder, options, disabled, multiple, closeMenuOnSelect} = this.props;

        return (
            <Select
                name={name}
                value={value}
                onChange={this.handleChange}
                options={options}
                clearable={false}
                className="react-select"
                placeholder={placeholder}
                classNamePrefix="react-select"
                defaultValue={this.props.defaultValue}
                isDisabled={disabled}
                isMulti={multiple}
                closeMenuOnSelect={closeMenuOnSelect}
            />
        );
    }
}

const renderSelectField = (props) => {
    const {
        input, meta, options, placeholder, className, defaultValue, disabled, multiple, closeMenuOnSelect} = props;
    return (
        <div className={`form__form-group-input-wrap ${className}`}>
            <SelectField
                {...input}
                options={options}
                placeholder={placeholder}
                defaultValue={defaultValue}
                disabled={disabled}
                multiple={multiple}
                closeMenuOnSelect={closeMenuOnSelect}
            />
            <div className="input-error-container">
                {meta.touched && ((meta.error &&
                    <span className="error-message">{i18n.t(`common:errors.${meta.error}`)}</span>) || (meta.warning &&
                    <span className="error-message">{meta.warning}</span>))}
            </div>
        </div>
    );
};

renderSelectField.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool
};

renderSelectField.defaultProps = {
    meta: null,
    options: [],
    placeholder: '',
    className: '',
    closeMenuOnSelect: true
};

export default renderSelectField;
