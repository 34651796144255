import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import CVTable from '../../Tables/CVTable/CVTable';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import CancelIcon from 'mdi-react/CancelIcon';
import {DetailsModal} from "../../../shared/components/cv/DetailsModal";
import CVModal from "../../UI/Modals/components/CVModal";
import { useAlert } from "react-alert";
import adminActions from "../../../redux/actions/adminActions";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

const UsersTable = (props) => {
    const { t, users, currentPage, totalUsers, pageSize, totalPages, getPage } = props;

    const [detailsModalIsOpen, setDetailsModalOpen] = useState(false);
    const [blockModalIsOpen, setBlockModalOpen] = useState(false);
    const [details, setDetails] = useState();
    const [userSelected, setUserSelected] = useState();

    const alert = useAlert();

    const openDetailsModal = (userDetails) => {
        let temporaryDetails = [];

        for(let key in userDetails){
            let temporaryDetail = {
                label: t(`users.modals.details.labels.${key}`),
                value: userDetails[key]
            };
            if(key === 'rol'){
                temporaryDetail.value = t(`common.roles.${temporaryDetail.value.toLowerCase()}`);
            }
            else if(key === 'accounts'){
                temporaryDetail.value = userDetails[key].reduce((acc, account) => acc + account + ", ", "");
                temporaryDetail.value = temporaryDetail.value.substring(0, temporaryDetail.value.length - 2);
            }
            else if(key === 'block'){
                temporaryDetail.value = null
            }
            temporaryDetails.push(temporaryDetail);
        }
        setDetails(temporaryDetails);
        setDetailsModalOpen(true);
    };

    const openBlockModal = (userData) => {
        setUserSelected({
            completeName: `${userData['name']} ${userData['surname']}`,
            id: userData['id']
        });
        setBlockModalOpen(true);
    };

    const editUser = (userData) => {
        props.history.push('/users/edit', {userId: userData['id']});
    };

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const accountsToString = (accounts) => {
        if(accounts.length === 1) return accounts[0].legalName;
        else {
            let result = "";
            accounts.forEach(account => result = result.concat(`${account.legalName}, `));
            return result.substring(0, result.length - 2);
        }
    };

    const renderAccounts = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {accountsToString(value)}
            </div>
        )
    };

    const renderButtons = ({ dependentValues }) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <MagnifyIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => openDetailsModal(dependentValues)}/>
                <LeadPencilIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => editUser(dependentValues)}/>
                <CancelIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => openBlockModal(dependentValues)}/>
            </div>
        )
    };

    const heads = [
        {
            key: 'id',
            name: t('users.table.headers.user_id'),
            percentage: 10,
            minWidth: 85,
            formatter: renderValueDefault
        },
        {
            key: 'name',
            name: t('users.table.headers.name'),
            percentage: 11,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'surname',
            name: t('users.table.headers.surname'),
            percentage: 11,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'cuit',
            name: t('users.table.headers.cuit'),
            percentage: 14,
            minWidth: 80,
            formatter: renderValueDefault
        },
        {
            key: 'accounts',
            name: t('users.table.headers.accounts'),
            percentage: 30,
            minWidth: 150,
            formatter: renderAccounts
        },
        {
            key: 'roles',
            name: t('users.table.headers.rol'),
            percentage: 10,
            minWidth: 100,
            formatter: ({value}) => renderValueDefault({value: t(`common.roles.${value[0].name.toLowerCase()}`)})
        },
        {
            key: 'actions',
            name: t('users.table.headers.actions'),
            percentage: 14,
            minWidth: 75,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const renderBlockBody = () => (
        <div>
            {t('users.modals.block.body', { user: userSelected.completeName})}
        </div>
    );

    const blockModal = () => {
        if(blockModalIsOpen){
            return (
                <CVModal
                    title={t('users.modals.block.title')}
                    body={() => renderBlockBody()}
                    color="danger"
                    header
                    isOpen={blockModalIsOpen}
                    toggle={() => setBlockModalOpen(!blockModalIsOpen)}
                    acceptFunc={() => {
                        setBlockModalOpen(!blockModalIsOpen);
                        props.blockUser(userSelected.id, () => {
                            alert.info(t('users.modals.block.blocked_notification_message', {user: userSelected.completeName}));
                        })
                    }}
                />
            )
        }
        return null;
    };

    return (
        <div>
            <CVTable
                getPage={getPage}
                data={users}
                total={totalUsers}
                currentPage={currentPage}
                totalPages={totalPages}
                pageSize={pageSize}
                heads={heads}
                title={t('users.table.title')}
                hideFilters
                noDataLabel={t('users.table.no_data_label')}
            />
            <DetailsModal isOpen={detailsModalIsOpen} title={t('users.modals.details.title')}
                          toggle={()=> setDetailsModalOpen(!detailsModalIsOpen)} data={details}/>
            {blockModal()}
        </div>
    )
};

const mapDispatchToProps = (dispatch) => ({
    blockUser: (userId, callback) => dispatch(adminActions.blockUser(userId, callback))
});

export default connect(null, mapDispatchToProps)(withTranslation('common')(withRouter (UsersTable)))
