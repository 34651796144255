import React, { useState, useEffect } from "react";
import SearchInput from "../../../shared/components/cv/SearchInput";
import { withTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import { connect } from "react-redux";
import actions from "../../../redux/actions/index";
import _ from "lodash";
import { ClipLoader } from "react-spinners";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import EmailInput from '../../../shared/components/cv/EmailInput';

const AddNewSurrogate = props => {
  const { t, surrogateUserSearch, companyId } = props;

  const search = value => {
    props.getSurrogateUserByDni(value);
  };

  const addUserToCompany = () => {
    props.addUserToCompany(
      companyId,
      surrogateUserSearch.userId,
      surrogateUserSearch.role
    );
  };

  const sendMailToUnregistered = (email) => {
    props.sendMailToUnregistered(companyId, email);
    if(false/*props.inviteSent.cause.statusCode === 500*/){
      //ToastsStore.success('ha ocurrido un error')
    }else{
      ToastsStore.success('Se ha enviando la invitacion a  ' + email)
    }
  };

  return (
    <div className="add_new_professional">
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
      <h4 className="text-modal  modal__title">
        {t(
          "accounts.modals.add_surrogate_to_company.add_new_professional"
        )}
      </h4>
      <SearchInput
        placeholder={t(
          "accounts.modals.add_surrogate_to_company.search_placeholder"
        )}
        search={search}
        loading={props.getSurrogateUserByDniPending}
      />
      {surrogateUserSearch && !_.isEmpty(surrogateUserSearch) && (
        <div className="add_new_professional-result">
          <div className="add_new_professional-result-name">
            <label>
              {surrogateUserSearch.name} {surrogateUserSearch.lastName}
            </label>
          </div>
          <div className="add_new_professional-result-role">
            <label>{t(`common.roles.${surrogateUserSearch.role}`)}</label>
          </div>
          <div className="add_new_professional-result-actions">
            {props.addUserToCompanyPending ? (
              <ClipLoader size={24} loading={true} color={"#123abc"} />
            ) : (
                <CheckCircleOutlineIcon
                  color={"green"}
                  onClick={addUserToCompany}
                />
              )}
          </div>
        </div>
      )}
      {surrogateUserSearch && _.isEmpty(surrogateUserSearch) && (
        <div className="error_container">
          <label>
            {t(
              "accounts.modals.add_surrogate_to_company.no_professional_found"
            )}
          </label>
        </div>
      )}
      {
        //(professionalUserSearch && _.isEmpty(professionalUserSearch)) &&
        <div className="add_new_professional">
          <h4 className="text-modal  modal__title">
            {t(
                "establishments.modals.add_professional_to_establishment.invite_new_professional"
            )}
          </h4>
          <EmailInput
            placeholder={t(
              "accounts.modals.add_surrogate_to_company.send_invite"
            )}
            send={sendMailToUnregistered}
            loading={props.getProfessionalUserByDniPending}
          />
        </div>
      }
    </div>
  );
};

const mapStateToProps = state => ({
  surrogateUserSearch: state.user.surrogateUserSearch,
  getSurrogateUserByDniPending: state.ui.getSurrogateUserByDniPending,
  addUserToCompanyPending: state.ui.addUserToCompanyPending
});
const mapDispatchToProps = dispatch => ({
  getSurrogateUserByDni: dni => dispatch(actions.user.getSurrogateUserByDni(dni)),
  addUserToCompany: (companyId, userId, roleId) => dispatch(actions.user.addUserToCompany(companyId, userId, roleId)),
  sendMailToUnregistered: (companyId, email) => (dispatch(actions.user.sendMailToUnregisteredSurrogate(companyId, email))),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(AddNewSurrogate)
);
