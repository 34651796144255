import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';
import CVTable from '../../Tables/CVTable/CVTable';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import adminActions from '../../../redux/actions/adminActions';
import { connect } from "react-redux";
import CVModal from "../../UI/Modals/components/CVModal";
import EditDataModal from "./EditDataModal";
import AddDataModal from "./AddDataModal";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";

const GeneralDataTable = (props) => {
    const { match: { params }, t, info, filtered, title, data_id } = props;
    const [detailsModalIsOpen, setDetailsModalOpen] = useState(false);
    const [editModalIsOpen, setEditModalOpen] = useState(false);
    const [addModalIsOpen, setAddModalOpen] = useState(false);
    const [selected, setSelected] = useState();
    const [selectedFilter, setSelectedFilter] = useState();
    const [availableFilters, setAvailableFilters] = useState();
    const [data, setData] = useState();
    const [dataFiltered, setDataFiltered] = useState();

    useEffect(() => {
        if(params.data_id !== undefined) props.getGeneralDataInfo(params.data_id);
    }, []);

    useEffect(() => {
        if(info.values && info.values.length > 0) {
            if(!filtered) {
                setData(info.values)
            }
            else {
                setAvailableFilters(info.values.map(e => e.filter_id));
                handleFilterSelection(selectedFilter ? selectedFilter : info.values[0].filter_id);
            }
        }
    }, [info]);

    const handleFilterSelection = (filter) => {
        setSelectedFilter(filter);
        let dataFiltered = info.values.filter(e => e.filter_id === filter)[0];
        setDataFiltered(dataFiltered.values);
    };

    const showDetailsModal = (name) => {
        if(filtered) setSelected(dataFiltered.filter((elem) => elem.name === name)[0]);
        else setSelected(data.filter((elem) => elem.name === name)[0]);
        setDetailsModalOpen(true);
    };

    const showEditModal = (name) => {
        if(filtered) setSelected(dataFiltered.filter((elem) => elem.name === name)[0]);
        else setSelected(data.filter((elem) => elem.name === name)[0]);
        setEditModalOpen(true);
    };

    const showAddModal = () => {
        setAddModalOpen(true);
    };

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderButtons = ({ dependentValues }) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <MagnifyIcon className={"button"} style={{marginLeft: "10px"}}
                             onClick={() => showDetailsModal(dependentValues.name)}/>
                <LeadPencilIcon className={"button"} style={{marginLeft: "10px"}}
                                onClick={() => showEditModal(dependentValues.name)}/>
            </div>
        )
    };

    const heads = [
        {
            key: 'name',
            name: t('general_data.table.headers.name'),
            percentage: 25,
            minWidth: 85,
            formatter: renderValueDefault
        },
        {
            key: 'creation_date',
            name: t('general_data.table.headers.creation_date'),
            percentage: 25,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'last_updated_date',
            name: t('general_data.table.headers.last_updated_date'),
            percentage: 25,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'actions',
            name: t('general_data.table.headers.actions'),
            percentage: 25,
            minWidth: 75,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const renderDetailsBody = () => {
        return (
            selected ?
                <div>
                    <div className={'details-row'}>
                        <h4 className={"bold-text label"}>{t(`general_data.modals.details.keys.name`)}:</h4>
                        <h4 className={"value"}>{selected.name}</h4>
                    </div>
                    <div className={'details-row'}>
                        <h4 className={"bold-text label"}>{t(`general_data.modals.details.keys.creation_date`)}:</h4>
                        <h4 className={"value"}>{selected.creation_date}</h4>
                    </div>
                    <div className={'details-row'}>
                        <h4 className={"bold-text label"}>{t(`general_data.modals.details.keys.last_updated_date`)}:</h4>
                        <h4 className={"value"}>{selected.last_updated_date}</h4>
                    </div>
                </div>
                :
                null
        )
    };

    const detailsModal = () => {
        if(detailsModalIsOpen) return (
            <CVModal
                title={t('animals.modals.details.title')}
                body={() => renderDetailsBody()}
                color="primary"
                header
                isOpen={detailsModalIsOpen}
                toggle={() => setDetailsModalOpen(!detailsModalIsOpen)}
                acceptFunc={() => setDetailsModalOpen(!detailsModalIsOpen)}
                hideButtons={true}
            />
        );
        return null
    };

    const editModal = () => {
        if(editModalIsOpen) return (
            <EditDataModal open={editModalIsOpen} toggle={() => setEditModalOpen(!editModalIsOpen)}
                           acceptFunc={(newValue) => {
                               props.editGeneralDataInfo(params.data_id, selected.name, newValue, selectedFilter);
                               setEditModalOpen(!editModalIsOpen)
                           }}
                           value={selected.name}/>
        );
       return null
    };

    const addModal = () => {
        if(addModalIsOpen) return (
            <AddDataModal open={addModalIsOpen} toggle={() => setAddModalOpen(!addModalIsOpen)}
                           acceptFunc={(value) => {
                               props.addGeneralDataInfo(data_id, value, selectedFilter);
                               setAddModalOpen(!addModalIsOpen)
                           }}/>
        );
        return null
    };

    const renderSubtitleComponent = () => (
        <div style={{display: 'flex', width: '100%', justifyContent: filtered ? 'space-between' : 'flex-end',
            alignItems: 'center'}}>
            {
                filtered && selectedFilter && availableFilters &&
                    <UncontrolledDropdown>
                        <DropdownToggle className="icon icon--right" outline>
                            <p>{selectedFilter}<ChevronDownIcon /></p>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown__menu">
                            {availableFilters.map(filter =>
                                <DropdownItem onClick={() => handleFilterSelection(filter)}>{filter}</DropdownItem>)
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
            }
            <PlusCircleOutlineIcon onClick={() => {showAddModal()}}/>
        </div>
    );

    return (
        <div>
            <CVTable
                getPage={() => {}}
                data={filtered ? dataFiltered : data}
                total={filtered ? (dataFiltered ? dataFiltered.length : 0) : (data ? data.length : 0)}
                currentPage={1}
                heads={heads}
                title={title}
                SubtitleComponent={renderSubtitleComponent}
                widthPercentage={80}
                hideFilters
                hidePagination
                noDataLabel={t('general_data.table.no_data_label')}
            />
            {detailsModal()}
            {editModal()}
            {addModal()}
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        info: state.admin.generalData.info,
        filtered: state.admin.generalData.info.filtered,
        title: state.admin.generalData.info.data_name,
        data_id: state.admin.generalData.info.data_id,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getGeneralDataInfo: (id) => dispatch(adminActions.getGeneralDataInfo(id)),
        editGeneralDataInfo: (id, prevValue, newValue, filter) => dispatch(adminActions.editGeneralDataInfo(id, prevValue, newValue, filter)),
        addGeneralDataInfo: (id, value, filter) => dispatch(adminActions.addGeneralDataInfo(id, value, filter))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(GeneralDataTable))