import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderDropZoneField from '../../components/form/DropZone';
import FileExcelIcon from 'mdi-react/FileExcelIcon'
import renderSelectField from "../../../../src/shared/components/form/Select";
import {formValidators} from '../../../util/index';
import { propTypes } from 'qrcode.react';

const FileUploadDefault = ({ handleSubmit, reset, t,
    acceptedFormats, leftSelectValues, rightSelectValues,
    onLeftSelectChange, onRightSelectChange }) => (
    <form className="form form--justify" onSubmit={handleSubmit}>
        <Field
            name="files"
            acceptedFormats={acceptedFormats}
            dropZoneDefaultImage={FileExcelIcon}
            dropZonePlaceHolder={t('cv-inputs.file_upload.drop_zone_placeholder')}
            component={renderDropZoneField}
        />
        <div className="modal_select"
            style={{flexDirection:'column'}}>
            <span className="form__form-group-label">{t('establishments.lots.company')}</span>
            <div >
                <Field
                    name="company"
                    component={renderSelectField}
                    type="text"
                    placeholder={t('establishments.lots.company')}
                    onChange={(e) => { onLeftSelectChange(e) }}
                    options={
                        leftSelectValues.map(el => ({
                            value: el._id,
                            label: el.name
                        }))
                    }
                />
            </div>
        </div>

        <div className="modal_select"
            style={{flexDirection:'column'}}>
            <span className="form__form-group-label">{t('establishments.lots.establishment')}</span>
            <div >
                <Field
                    name="establishment"
                    component={renderSelectField}
                    type="text"
                    validate={formValidators.validateRequired}
                    placeholder={t('establishments.lots.establishment')}
                    onChange={(e) => { onRightSelectChange(e) }}
                    options={
                        rightSelectValues.map(el => ({
                            value: el._id,
                            label: el.name
                        }))
                    }
                />
            </div>
        </div>
        <ButtonToolbar className="form__button-toolbar center">
            <Button color="primary" type="submit">{t('common.upload')}</Button>
            <Button type="reset" onClick={reset}>{t('common.delete')}</Button>
        </ButtonToolbar>
    </form>
);

FileUploadDefault.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'file_upload_default', // a unique identifier for this form
    touchOnChange: true
})(withTranslation('common')(FileUploadDefault));
