/* eslint-disable react/no-array-index-key */
import React, {PureComponent} from 'react';
import {Collapse} from 'reactstrap';
import {Link} from 'react-router-dom';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import {withTranslation} from "react-i18next";
import { connect } from "react-redux";
import notificationActions from "../../../redux/actions/notificationActions";

class TopbarNotification extends PureComponent {
    state = {
        collapse: false,
    };

    componentDidMount() {
        this.props.getPage(1,10);
    }

    toggle = () => {
        this.setState(prevState => ({collapse: !prevState.collapse}));
    };

    parseDate = (date) => {
        return (`${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`)
    };

    render() {
        const {collapse} = this.state;
        return (
            <div className="topbar__collapse">
                <button className="topbar__btn" type="button" onClick={this.toggle}>
                    <NotificationsIcon/>
                </button>
                {collapse && <button className="topbar__back" type="button" onClick={this.toggle}/>}
                <Collapse
                    isOpen={collapse}
                    className="topbar__collapse-content"
                >
                    <div className="topbar__collapse-title-wrap">
                        <p className="topbar__collapse-title">{this.props.t('notifications.top_bar.title')}</p>
                    </div>
                    {this.props.notifications && this.props.notifications.slice(0,4).map((notification, index) => (
                        <div className="topbar__collapse-item topbar__notifications" key={index}>
                            <p className="topbar__collapse-message">
                                <span className="topbar__collapse-name">{notification.sender} </span>
                                {this.props.t('notifications.table.message',
                                    {role: this.props.t(`notifications.table.roles.${notification.role}`)})}
                            </p>
                            <p className="topbar__collapse-date">{this.parseDate(new Date(notification.date))}</p>
                        </div>
                    ))}
                    <Link className="topbar__collapse-link" to="/notifications/" onClick={this.toggle}>
                        {this.props.t('notifications.top_bar.see_all')}
                    </Link>
                </Collapse>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        notifications: state.notification.notifications
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getPage: (page, pageSize) => dispatch(notificationActions.getPage(page, pageSize)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TopbarNotification))
