import React, {useEffect, useState} from 'react';
import {Col, Card, CardBody, Button, ButtonToolbar} from 'reactstrap';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {change, Field, reduxForm, formValueSelector} from 'redux-form';
import {renderCVFileInputField, renderCVPhotoInputField} from '../../../shared/components/cv/CVFileUpload';
import renderSelectField from "../../../shared/components/form/Select";
import {connect} from "react-redux";
import GeneralDataActions from '../../../redux/actions/generalDataActions';
import actions from "../../../redux/actions";
import MaskedInput from "react-text-mask";
import {CheckBoxField} from '../../../shared/components/form/CheckBox';
import i18n from '../../../translations/config/i18n';
import CVButton from "../../../shared/components/cv/CVButton";
import { formValidators, subtract_x_years_to_date, formMasks } from '../../../util/index';
import { renderDateInputField } from '../../../shared/components/cv/CVDatePicker';
import { withRouter } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import "../../../scss/component/Select2.css"

// todo delete this hard-code data when the api is ready for returning this values
const cowAvailableBreeds = [
    'ANGUS NEGRO','ANGUS COLORADO', 'HEREFORD', 'POLLED HERFORD', 'BRANGUS', 'BRAFORD',
    'CRUZA', 'NEGRO CARA BLANCA', 'LIMANGUS', 'HOLSTEIN', 'CHAROLAIS', 'JERSEY',
    'LIMANGUS COLORADO', 'BRANGUS NEGRO', 'BRANGUS COLORADO', 'SHORTHORN', 'NELORE',
    'BRAHMAN','CARA BLANCA', 'HOLANDO ARGENTINO','PARDO SUIZO',
    'FRISSONA','AYRSHIRE','BLACK/RED SIMMENTAL',"BLONDE D'AQUITAINE",
    'BONSMARA','CHARBRAY','CHIANINA','CRIOLLO','GALLOWAY',
    'INDUSIN','JAFARABADI','LIMOUSIN','MARCHIGIANA',
    'MEDITERRANEA','MURRAH','NORMANDO','PIEMONTESE','RETINTA','ROMAGNOLA',
    'SALERS','SANTA GERTRUDIS','SIMBRAH','SIMENTAL','TABAPUA',
    'TULI','WEST-HIGHLAND','RED ANGUS','SANTA CRUZ','GUERNSEY','BERRENDA',
    'SIMBRAFORD','SIMANGUS','SIMFORD','BELMONT RED','RAGEMAKER','RED POLL',
    'SENEPOL','STABILIZER','CORRIEDALE','COLORADA CARA BLANCA',
    'CUARTINO','FLECKVIEH','CRIBU','ÑATA',
    'CRUZA HOLANDO','CRUZA INDICA','CRUZA CONTINENTAL',
    'CRUZA BRITANICA','LIMANGUS NEGRO','MEDIA SANGRE LIMOUSIN',
    'GELBVIEH','CANCHIM','GIR','SIMBRASIL','GIROLANDO','ANELORADO'
];
const cowAvailableSpecies = ['Bovina'];
const cowAvailableCategories = ['Ternera', 'Vaquillona', 'Vaca', 'Vaca madre', 'Ternero', 'Novillo', 'Toro'];
const cowAvailableFireMarkPlace = [
    {
        value: 'anca',
        label: i18n.t('common:cv-forms.cow.fire_mark_place_options.anca')
    },
    {
        value: 'carretilla',
        label: i18n.t('common:cv-forms.cow.fire_mark_place_options.carretilla')
    }
];

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
    <div style={{width: '100%'}}>
        {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
        {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);

// Validate the cow birthday date, the date must be between 7 years ago and now.
const validateCowBirthDate = formValidators.validateDateBetweenNotRequired(subtract_x_years_to_date(new Date(), 7), new Date());

// Validate the cow age in months, the number must be between 0 and 84 (7 years)
const validateCowAgeInMonths = formValidators.validateNumberBetweenNotRequired(0, 360);

const CowForm = (props) => {
    CowForm.propTypes = {
        t: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    const naturalFood = ["Falaris", "Festuca", "Cebadilla Criolla", "Agropiro", "Lotus", "Pastizal Natural", "Campo Natural", "Rastrojo de Girasol", "Rastrojo de Maiz", "Sales Minerales", "Campo Organico Patagonico"]
    const silvoPastoralFood = ["Raigras Inglesa", "Festuca", "Trébol Subterráneo"]
    const pastoralFood = ["Alfalfa", "Raigras Inglesa", "Raigras Perenne", "Raigras Bianuales","Bromo" , "Falaris", "Festuca", "Pasto Ovillo", "Sorgo", "Trébol Alejandrino", "Trébol Blanco", "Pradera Consociada", "Verdeo Avena", "Verdeo de Centeno", "Ver. Avena / Ras. Maiz", "Trébol Melilotus", "Verdeo de Mijo", "Rollo de Pastura", "Rollo de Alfalfa", "Eragrostis Cúrvula (P. Llorón)", "Gatton Panic"]
    const creepFeedingFood=["Alfalfa", "Avena", "Raigras Inglesa", "Raigras Perenne", "Raigras Bianuales","Bromo" , "Falaris", "Festuca", "Pasto Ovillo", "Trigo", "Cebada", "Centeno", "Maíz (Suplem. NO mayor a 1kg)", "Sorgo", "Trébol Rojo", "Cebadilla Criolla", "Cebadilla", "Agropiro", "Lotus", "Expeller de Girasol", "Verdeo Avena"]
    const strategicFeedingFood=["Avena", "Festuca", "Trigo", "Cebada", "Centeno", "Maíz (Suplem. NO mayor a 1kg)", "Sorgo", "Expeller de Girasol", "Verdeo Avena", "Silo de Pastura", "Silo de Maiz", "Silo de Sorgo", "Maiz Picado"]
    const feedlotFood=["Avena", "Trigo", "Cebada", "Centeno", "Maíz (Suplem. NO mayor a 1kg)", "Sorgo", "Expeller de Girasol", "Núcleo Vitamínico", "Silo de Pastura", "Silo de Maiz", "Silo de Sorgo", "Rollo de Pastura", "Rollo de Alfalfa", "Rollo de Mijo", "Maiz Picado", "Suplement. Feed Lot"]
    let [selectedData, setSelectedData] = useState();
    let selectedFoods = []

    const [validLots, setValidLots] = useState([]);
    const [selectedLot, selectLot] = useState();
    const [establishmentValues, setEstablishmentValues] = useState([]);
    const [selectedFormCompany, setSelectedFormCompany] = useState({});
    const [selectedFormEstablishment, setSelectedFormEstablishment] = useState({});

    const {
        t,
        handleSubmit,
        onSubmit,
        availableProductiveSystems,
        editable,
        readOnlyField,
        hideTitle,
        showImages,
        allAvailableProductiveSystems,
        selectedEstablishment,
        establishmentLots,
        selectedEstablishmentLotMode,
        allEstablishmentsByCompany,
        allEstablishments,
        selectedCompany,
    } = props;

    useEffect(() => {
        props.getAvailableCowBreeds();
        props.getAvailableProductiveSystems();
        props.getDentitionValues();
    }, []);


    useEffect(() => {
       props.getUserEstablishmentById(selectedFormEstablishment.value);
       props.getLotsByEstablishment(selectedFormEstablishment.value);
    }, [setSelectedFormEstablishment])

    useEffect(() => {
        setEstablishmentValues(props.allEstablishmentsByCompany);
    }, [allEstablishmentsByCompany])

    useEffect(() => {
       setValidLots(getActiveLots());
    }, [establishmentLots]);

    const getActiveLots = () => {
        let lots = []
        if(selectedEstablishmentLotMode === "lotModeFlexible") lots.push({
            "_id": "newLot",
            "name": "Crear nuevo Lote"
        })
        lots.push({
            _id: 'unspecified',
            name: t('cv-forms.cow.no-lot')
        })
        for(let lot of establishmentLots) {
            if (lot.isActive === true && lot._id != 0) lots.push(lot)
        }
        return lots
    }

    function changeSelectedFood(e){
        selectedFoods = []
        for (var i = 0; i < e.currentTarget.length; i++) {
            if(e.currentTarget.options[i].selected){selectedFoods.push(e.currentTarget.options[i].value)}
        }
    }

    const getSelectedLot = (e) => {
        selectLot(e.value)
    }

    const changeSelectedCompany = (company) => {
        let addCompany = true
        props.getEstablishmentsByCompanyId(company.value);
        for(let elem of selectedCompany) {
            if(elem.value === company.value) addCompany = false
        }
        if(addCompany) {
            props.selectCompany([...selectedCompany, company]);
        }
        props.changeFieldValue('establishment', {
            value: "",
            label:  t(`selectors.establishmentSelector.establishment`)
        });
    };

    const changeEstablishmentValue = (e) => {
        props.getUserEstablishmentById(e.value);
        props.getLotsByEstablishment(e.value);
        props.changeFieldValue('establishment', {
            value: e.value,
            label: e.label
        });
        props.selectEstablishment([e]);
        setSelectedFormEstablishment(e)
    }

    function searchTrueInProductiveSystem(object){
        let values = Object.values(object)
        let keys = Object.keys(object)
        let index = values.indexOf(true)
        select2Data(keys[index])
    }

    function select2Data(text){
        let res;
        if(text === "natural"){res =  naturalFood}
        if(text === "silvoPastoral"){res = silvoPastoralFood}
        if(text === "pastoral"){res = pastoralFood}
        if(text === "creepFeeding"){res = creepFeedingFood}
        if(text === "strategicFeeding"){res = strategicFeedingFood}
        if(text === "feedlot"){res = feedlotFood}
        setSelectedData(res)
    }
    let renderSelect2 = () => (
      <div style={{width: '100%'}}>
          <Select2
            className={"customSelect2"}
            multiple
            data={selectedData}
            defaultValue={defaultValueFood}
            disabled={!editable}
            onChange={changeSelectedFood}
            options={
                {
                    placeholder: 'Alimentos',
                }
            }
          />
      </div>
    );

    const [productiveSystemSelected, setProductiveSystemSelected] = useState({});

    let [defaultValueFood, setDefaultValueFood] = useState();

    const showErrors = (invalid) => {
        if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
    }

    // On cow details, sets the productive system selected.
    useEffect(() => {
        if(props.initialValues.productive_system) {
            setProductiveSystemSelected(props.initialValues.productive_system)
        }
        if(props.initialValues.feeding){
            searchTrueInProductiveSystem(props.initialValues.productive_system)
            setDefaultValueFood(props.initialValues.feeding)
        }
    }, [props.initialValues]);

    useEffect(() => {
        // Only if the form is not in 'details' mode
        if(props.selectedEstablishment.productiveSystems) {
            let productiveSystems = props.selectedEstablishment[0].productiveSystems;
            setProductiveSystemSelected(productiveSystems)
        }
    }, [props.selectedEstablishment]);

    const selectProductiveSystem = (productiveSystem) => {
        let newValue = {
            ...productiveSystemSelected
        };
        // set all productive systems to false
        Object.keys(newValue).forEach(key => {
            newValue[`${key}`] = false
        });
        newValue[`${productiveSystem}`] = true;
        searchTrueInProductiveSystem(newValue)
        setProductiveSystemSelected(newValue);
    };

    const noneProductiveSystemsSelected = (productiveSystems) => {
        let result = true;
        availableProductiveSystems.forEach(productiveSystem => {
            if (productiveSystems[`${productiveSystem}`]) {
                return result = false
            }
        })
        return result
    }

    const showLotField = () => {
        if(selectedEstablishmentLotMode === "lotModeFlexible") return true;
        const validMode = selectedEstablishmentLotMode != "lotModeDisabled";
        const lotsToShow = establishmentLots && establishmentLots.length > 0;
        return lotsToShow && validMode;
    }

    const submitForm = (values) => {
        if(noneProductiveSystemsSelected(productiveSystemSelected) && props.establishmentDetails.type === 'productive' ){
            showErrors(true);
            return;
        }
        onSubmit({...values, productiveSystems: productiveSystemSelected, foods: selectedFoods});
    };

    return (
        <Col md={12} lg={12}>
           <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Card>
                <CardBody>
                    <div className="card__title">
                        {!hideTitle && <h5 className="bold-text">{t('cv-forms.cow.title')}</h5>}
                    </div>
                    <form className="form" onSubmit={handleSubmit(submitForm)}>
                        <div className="form__form-group">
                                <span className="form__form-group-label">{t('establishments.lots.company')}</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="company"
                                        component={renderSelectField}
                                        disabled={readOnlyField}
                                        type="text"
                                        placeholder={t('establishments.lots.company')}
                                        onChange={changeSelectedCompany}
                                        options={
                                            props.allCompanies.map(el => ({
                                                value: el._id,
                                                label: el.name
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.establishment')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="establishment"
                                    component={renderSelectField}
                                    validate={formValidators.validateRequired}
                                    disabled={readOnlyField}
                                    type="text"
                                    placeholder={t('cv-forms.cow.establishment')}
                                    onChange={changeEstablishmentValue}
                                    options={
                                        [
                                            ...establishmentValues.map(est => ({
                                                value: est._id,
                                                label: est.name,
                                                productiveSystems: est.productiveSystems
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        { showLotField() &&
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.lot')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="establishmentLot"
                                    component={renderSelectField}
                                    disabled={false}
                                    type="text"
                                    placeholder={t('cv-forms.cow.non-assigned-lot')}
                                    disabled={!editable || selectedEstablishmentLotMode  === "lotModeDisabled"}
                                    onChange={getSelectedLot}
                                    options={
                                        [
                                            ...validLots.map(lot => ({
                                                value: lot._id,
                                                label: lot.name
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        }
                        { selectedLot === "newLot" &&
                            <div className="form__form-group">
                                <span className="form__form-group-label">{t('cv-forms.events.lot_event.new_lot')}</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="new_lot"
                                        component={renderField}
                                        hidden={true}
                                        disabled={selectedLot != "newLot"}
                                    />
                                </div>
                            </div>
                        }
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.caravan_id')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="caravan_id"
                                    component={renderField}
                                    validate={[formValidators.validateRequired, formValidators.validateCaravanIdRequired]}
                                    type="text"
                                    placeholder={t('cv-forms.cow.caravan_id')}
                                    mask={formMasks.caravanIdMask}
                                    normalize={(value) => value.toUpperCase()}
                                    disabled={!editable}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.fire_mark_place')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="fire_mark_place"
                                    component={renderSelectField}
                                    disabled={!editable}
                                    type="text"
                                    placeholder={t('cv-forms.cow.fire_mark_place')}
                                    options={cowAvailableFireMarkPlace}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.secondary_id.title')}</span>
                            <div className="form__form-group-field row_direction">
                                <div>
                                    <span className="secondary__label">{t('cv-forms.cow.secondary_id.electronic_id')}</span>
                                    <Field
                                        name="secondary_id.chip"
                                        component={renderField}
                                        disabled={!editable}
                                        type="text"
                                        placeholder={t('cv-forms.cow.secondary_id.electronic_id')}
                                    />
                                </div>
                                <div>
                                    <span className="secondary__label">{t('cv-forms.cow.secondary_id.tattoo')}</span>
                                    <Field
                                        name="secondary_id.button"
                                        component={renderField}
                                        disabled={!editable}
                                        type="text"
                                        placeholder={t('cv-forms.cow.secondary_id.tattoo')}
                                    />
                                </div>
                                <div>
                                    <span className="secondary__label">{t('cv-forms.cow.secondary_id.internal_caravan')}</span>
                                    <Field
                                        name="secondary_id.internal_caravan"
                                        component={renderField}
                                        disabled={!editable}
                                        type="text"
                                        placeholder={t('cv-forms.cow.secondary_id.internal_caravan')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.DNA_analysis')}</span>
                            <div className="form__form-group-field with_image">
                                {
                                    (!showImages || editable) &&
                                    <Field
                                        name="DNA_analysis"
                                        component={renderCVFileInputField}
                                        disabled={!editable}
                                        acceptedFormats={'.pdf'}
                                    />
                                }
                                {
                                    showImages && props.initialValues.DNA_analysis ?
                                        <a href={props.initialValues.DNA_analysis}>{t('cv-forms.cow.download_pdf')}</a>:
                                        typeof (props.dnaAnalysisFile) !== 'object'?
                                            <label>{t('cv-forms.cow.no_dna_file')}</label>:
                                            null
                                }
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.signal_on_ear')}</span>
                            <div className="form__form-group-field with_image">
                                {
                                    (!showImages || editable) &&
                                    <Field
                                        name="signal_on_ear"
                                        component={renderCVPhotoInputField}
                                        disabled={!editable}
                                        hideFileName={true}
                                    />

                                }
                                {
                                    // Image preview if one is selected
                                    typeof (props.signalOnEarImage) === 'object' ?
                                        <img src={URL.createObjectURL(props.signalOnEarImage.files[0])}/>:
                                        // Show image uploaded if there is one
                                        showImages && props.initialValues.signal_on_ear ?
                                            <img src={props.initialValues.signal_on_ear}/>:
                                            <label>{t('cv-forms.cow.no_signal_on_ear_image')}</label>
                                }
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.breed')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="breed"
                                    component={renderSelectField}
                                    disabled={!editable}
                                    validate={formValidators.validateRequired}
                                    type="text"
                                    placeholder={t('cv-forms.cow.breed')}
                                    options={
                                        [
                                            ...cowAvailableBreeds.map(breed => ({
                                                value: breed,
                                                label: breed
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.species')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="specie"
                                    component={renderSelectField}
                                    validate={formValidators.validateRequired}
                                    disabled={!editable}
                                    type="text"
                                    placeholder={t('cv-forms.cow.species')}
                                    options={
                                        [
                                            ...cowAvailableSpecies.map(specie => ({
                                                value: specie,
                                                label: specie
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.category')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="category"
                                    component={renderSelectField}
                                    validate={formValidators.validateRequired}
                                    disabled={!editable}
                                    type="text"
                                    placeholder={t('cv-forms.cow.category')}
                                    options={
                                        [
                                            ...cowAvailableCategories.map(category => ({
                                                value: category,
                                                label: category
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.dentition')}</span>
                            <div className="form__form-group-field">
                                <Field
                                  name="dentition"
                                  component={renderSelectField}
                                  validate={formValidators.validateRequired}
                                  disabled={!editable}
                                  type="text"
                                  placeholder={t('cv-forms.cow.dentition')}
                                  options={
                                      [
                                          ...props.dentitionValues.map(dentition => ({
                                              value: dentition,
                                              label: dentition
                                          }))
                                      ]
                                  }
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.age_in_months')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="age_in_months"
                                    component={renderField}
                                    disabled={!editable}
                                    validate={validateCowAgeInMonths}
                                    type="number"
                                    placeholder={t('cv-forms.cow.age_in_months')}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.weight_at_discharge')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="weight_at_discharge"
                                    component={renderField}
                                    validate={[formValidators.validateRequired, formValidators.validateCowWeight]}
                                    disabled={!editable}
                                    type="number"
                                    placeholder={t('cv-forms.cow.weight_at_discharge')}
                                />
                            </div>
                        </div>
                        { props.establishmentDetails && props.establishmentDetails.type === 'productive' &&
                        <div className="form__form-group">
                            <span
                                className="form__form-group-label">{t('cv-forms.productive_establishment.productive_system')}</span>
                            <div className="form__form-group-field">
                                {
                                    editable &&
                                    availableProductiveSystems.map((productiveSystem, index) => (
                                        <div style={{marginLeft: index === 0 ? '0px' : '20px'}}>
                                            <CheckBoxField
                                                onChange={() => selectProductiveSystem(productiveSystem)}
                                                value={productiveSystemSelected[`${productiveSystem}`]}
                                                disabled={!editable}
                                                name={`productive_system.${productiveSystem}`}
                                                label={t(`cv-forms.productive_establishment.productive_system_options.${productiveSystem}`)}
                                        />
                                        </div>
                                    ))
                                }
                                {
                                    !editable &&
                                    allAvailableProductiveSystems.map((productiveSystem, index) => (
                                        <div style={{marginLeft: index === 0 ? '0px' : '20px'}}>
                                            <CheckBoxField
                                                onChange={() => selectProductiveSystem(productiveSystem)}
                                                value={productiveSystemSelected[`${productiveSystem}`]}
                                                disabled={!editable}
                                                name={`productive_system.${productiveSystem}`}
                                                label={t(`cv-forms.productive_establishment.productive_system_options.${productiveSystem}`)}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                editable && noneProductiveSystemsSelected(productiveSystemSelected) &&
                                <div className="input-error-container">
                                        <span className="error-message">
                                            {t(`errors.REQUIRED`)}
                                        </span>
                                </div>
                            }
                        </div>
                        }
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.productive_establishment.feeding')}</span>
                            <div className="form__form-group-field">
                                <Field
                                  name="food"
                                  component={renderSelect2}
                                  type="text"
                                  value={selectedFoods}
                                />
                            </div>
                        </div>
                        <div className="card__subtitle">
                            <h5 className="bold-text">{t('cv-forms.cow.birth')}</h5>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.father_id')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="birth.father_id"
                                    component={renderField}
                                    disabled={!editable}
                                    type="text"
                                    placeholder={t('cv-forms.cow.father_id')}
                                    mask={formMasks.caravanIdMask}
                                    normalize={(value) => value.toUpperCase()}
                                    validate={formValidators.validateCaravanIdNotRequired}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.mother_id')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="birth.mother_id"
                                    component={renderField}
                                    disabled={!editable}
                                    type="text"
                                    placeholder={t('cv-forms.cow.mother_id')}
                                    mask={formMasks.caravanIdMask}
                                    normalize={(value) => value.toUpperCase()}
                                    validate={formValidators.validateCaravanIdNotRequired}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.birth_date')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="birth.date"
                                    component={renderDateInputField}
                                    validate={validateCowBirthDate}
                                    disabled={!editable}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.born_weight')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="birth.weight"
                                    component={renderField}
                                    validate={formValidators.validateCowWeightNotRequired}
                                    disabled={!editable}
                                    type="number"
                                    placeholder={t('cv-forms.cow.born_weight')}
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar">
                            {
                                editable &&
                                <CVButton
                                  color="primary"
                                  type="submit"
                                  onClick={()=> showErrors(props.invalid)}
                                  pending={props.pending}>{t('common.accept')
                                }</CVButton>
                            }
                            {
                                editable &&
                                <Button type="button"
                                onClick={() => props.history.push('/animals/table')}>
                                {t('common.cancel')}</Button>
                            }

                        </ButtonToolbar>
                    </form>
                </CardBody>
            </Card>
        </Col>)
};

const selector = formValueSelector('cow-form');

const mapStateToProps = (state, props) => ({
    initialValues: props.initialValues || {},
    availableCowBreeds: state.generalData.availableCowBreeds,
    availableProductiveSystems: state.user.selectedEstablishment[0] ?
        state.user.selectedEstablishment[0].productiveSystems ?
            state.user.selectedEstablishment[0].productiveSystems:
            []:
        [],
    selectedCompany: state.user.selectedCompany,
    selectedEstablishment: state.user.selectedEstablishment,
    allAvailableProductiveSystems: state.constant.availableProductiveSystems,
    signalOnEarImage: selector(state, 'signal_on_ear'),
    dnaAnalysisFile: selector(state, 'DNA_analysis'),
    dentitionValues: state.constant.dentitionValues,
    establishmentDetails: state.user.establishmentDetails,
    establishmentLots: state.user.allLotsByEstablishment?
        state.user.allLotsByEstablishment : null,
    selectedEstablishmentLotMode: state.user.establishmentDetails?
        state.user.establishmentDetails.animalLots.mode?
            state.user.establishmentDetails.animalLots.mode: "lotModeDisabled"
            : "lotModeDisabled",
    allCompanies: state.user.allCompanies,
    allEstablishmentsByCompany: state.user.allEstablishmentsByCompany,
    allEstablishments: state.user.allEstablishments,
});

const mapDispatchToProps = (dispatch) => ({
    getAvailableCowBreeds: () => dispatch(GeneralDataActions.getAvailableCowBreeds()),
    getAvailableProductiveSystems: () => dispatch(actions.constant.getAvailableProductiveSystems()),
    getDentitionValues: () => dispatch(actions.constant.getDentitionValues()),
    changeFieldValue: (field, value) => dispatch(change('cow-form', field, value)),
    getUserEstablishmentById: (currentEstablishment, callback) =>
        dispatch(actions.user.getEstablishmentById(currentEstablishment, callback)),
    getEstablishmentsByCompanyId: (companyId) =>
        dispatch(actions.user.getUserEstablishmentsByCompany(companyId)),
    selectCompany: (company) => dispatch(actions.user.selectCompany(company)),
    selectEstablishment: (establishment) => dispatch(actions.user.selectEstablishment(establishment)),
    resetSelectedEstablishment: () => dispatch(actions.user.resetSelectedEstablishment()),
    resetAllEstablishmentsByCompany: () => dispatch(actions.user.resetAllEstablishmentsByCompany()),
    getLotsByEstablishment: (establishmentIds) => dispatch(actions.user.getLotsByEstablishment(establishmentIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'cow-form',
    touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(withRouter(CowForm))));
