import React, {useEffect} from 'react'
import EditUserForm from "./components/EditUserForm";
import adminActions from "../../redux/actions/adminActions";
import { connect } from "react-redux";
import { useAlert } from "react-alert";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';

const EditUser = (props) => {
    const userId = props.location.state.userId;
    const { userData, t } = props;

    const alert = useAlert();

    useEffect(() => {
        props.getUserById(userId)
    }, []);

    const editUser = (values) => {
        props.editUserById(userId, values, () => {
            props.history.push('/users/table');
            alert.success(t('edit_user_form.successful_edit'))
        });
    };

    return(
        <div>
            {userData && <EditUserForm userData={userData} onSubmit={editUser}/>}
        </div>
    )
};

const mapStateToProps = (state) => ({
    userData: state.admin.userSearch
});

const mapDispatchToProps = (dispatch) => ({
    getUserById: (userId) => dispatch(adminActions.getUserById(userId)),
    editUserById: (userId, userModify, callback) => dispatch(adminActions.editUserById(userId, userModify, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(EditUser)));