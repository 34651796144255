import React from 'react';
import { Container, Row } from 'reactstrap';
import CastrationEventForm from "../NewEventForms/CastrationEventForm"
import { connect } from 'react-redux';
import actions from '../../../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const NewCastrationEventContainer = (props) => {
  const alert = useAlert();

  const addCastrationEvent = (values) => {
    props.submitCastrationEvent(values, props.facilityId, () => {
        alert.success(props.t('events.alerts.event_added'));
        props.history.push('/events/castration/table');
    }, (errorMessage) => {
        ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
    });
  };

  return (
    <Container>
      <Row>
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
        <CastrationEventForm
          onSubmit={addCastrationEvent}
          pending={props.addCowPending}
          editable/>
      </Row>
    </Container>
  )
};

const mapStateToProps = (state) => ({
  addCowPending: state.ui.addCowPending, // todo change this!
  facilityId: state.user.selectedEstablishment._id
});

const mapDispatchToProps = (dispatch) => ({
  submitCastrationEvent: (values, facilityId, successCallback, errorCallback) =>
    dispatch(actions.event.submitCastrationEvent(values, facilityId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewCastrationEventContainer));