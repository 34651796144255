import React from 'react';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Card, CardBody, Col} from "reactstrap";
import CompanyForm from './CompanyForm';

const CompanyFormPage = (props) => {
    CompanyFormPage.propTypes = {
        t: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        previousPage: PropTypes.func.isRequired,
    };

    const { handleSubmit, previousPage, t, availableEntityTypes, pending } = props;

    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('cv-forms.company.title')}</h5>
                    </div>
                    <CompanyForm
                        onSubmit={handleSubmit}
                        editable={true}
                        availableEntityTypes={availableEntityTypes}
                        buttonText={t('common.next')}
                        pending={pending}
                    />
                </CardBody>
            </Card>
        </Col>
    )
};

export default (withTranslation('common')(CompanyFormPage))