import {
    GET_AVAILABLE_ROLES_RESPONSE,
    GET_AVAILABLE_ENTITY_TYPES_RESPONSE,
    GET_AVAILABLE_PRODUCTIVE_SYSTEMS_RESPONSE,
    GET_AVAILABLE_ESTABLISHMENT_TYPES_RESPONSE,
    GET_AVAILABLE_GROUND_CONDITIONS_RESPONSE,
    GET_AVAILABLE_RUCA_ACTIVITIES_RESPONSE,
    GET_DENTITION_VALUES_RESPONSE
} from "../actions/constantActions";

const initialState = {
    availableRoles: [],
    availableEntityTypes: [],
    availableProductiveSystems: [],
    availableEstablishmentTypes: [],
    availableGroundConditions: [],
    availableRucaActivities: [],
    dentitionValues: []
};

const constantReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AVAILABLE_ROLES_RESPONSE:
            return {
                ...state,
                availableRoles: action.response.roles
            };
        case GET_AVAILABLE_ENTITY_TYPES_RESPONSE:
            return {
                ...state,
                availableEntityTypes: action.response.entityTypes
            };
        case GET_AVAILABLE_PRODUCTIVE_SYSTEMS_RESPONSE:
            return {
                ...state,
                availableProductiveSystems: action.response.productiveSystems
            };
        case GET_AVAILABLE_ESTABLISHMENT_TYPES_RESPONSE:
            return {
                ...state,
                availableEstablishmentTypes: action.response.establishmentTypes
            };
        case GET_AVAILABLE_GROUND_CONDITIONS_RESPONSE:
            return {
                ...state,
                availableGroundConditions: action.response.groundConditions
            };
        case GET_AVAILABLE_RUCA_ACTIVITIES_RESPONSE:
            return {
                ...state,
                availableRucaActivities: action.response.rucaActivities
            };
        case GET_DENTITION_VALUES_RESPONSE:
            return {
                ...state,
                dentitionValues: action.response.dentitionValues
            }
        default:
            return state
    }
};
export default constantReducer;