import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RegisterForm from './components/RegisterForm';
import {connect} from "react-redux";
import cvLogo from '../../../shared/img/logo/cv-logo-long.png';
import sessionActions from "../../../redux/actions/sessionActions";
import CVModal from "../../UI/Modals/components/CVModal";

const Register = (props) => {

  const { t } = props;

  const [confirmationModalIsOpen, setConfirmationModalOpen] = useState(false);

  const _handleRegisterRequest = (values) => {
      props.registerRequest(values.email, values.password, values.country, () => {
          setConfirmationModalOpen(!confirmationModalIsOpen)
      });
  };

  const renderModalBody = () => (
      <div>
        {t('register.modals.confirmation.body')}
      </div>
  );

  const confirmationModal = () => (
      <CVModal
          title={t('register.modals.confirmation.title')}
          body={() => renderModalBody()}
          color="primary"
          header
          isOpen={confirmationModalIsOpen}
          toggle={() => setConfirmationModalOpen(!confirmationModalIsOpen)}
          acceptFunc={() => {
            setConfirmationModalOpen(!confirmationModalIsOpen);
            props.history.push('/log_in')
          }}
          hideButtons={false}
          showAcceptOnly={true}
      />
  );

  return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <img src={cvLogo} alt="cv-logo"/>
            </div>
            <RegisterForm
                onSubmit={_handleRegisterRequest}
                registerPending={props.registerPending}
                registerError={props.registerError}
                registerErrorMessage={props.registerErrorMessage}
            />
            <div className="account__have-account">
              <p>{t('register.alreadyHaveAccount')} <Link to="/log_in">{t('register.login')}</Link></p>
            </div>
          </div>
        </div>
        {confirmationModal()}
      </div>
  )
};

const mapStateToProps = (state) => {
  return {
    registerPending: state.ui.registerPending,
    registerError: state.ui.registerError,
    registerErrorMessage: state.ui.registerErrorMessage,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerRequest: (email, password, country, callback) =>
        dispatch(sessionActions.registerRequest(email, password, country, callback)),
  }
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Register));
