import React from 'react';
import CVModal from "../../../containers/UI/Modals/components/CVModal";

const renderDetailsBody = (data) => {
    return (
        <div>
            {data.map(dataItem => {
                if(dataItem.value){
                    return (
                        <div className={'details-row'} key={dataItem.label}>
                            <h4 className={"bold-text label"}>{dataItem.label}:</h4>
                            <h4 className={"value"}>{dataItem.value}</h4>
                        </div>
                    )
                }
                return (
                    <div className={'details-row'} key={dataItem.label}>
                        <h4 className={"bold-text label"}>{dataItem.label}</h4>
                    </div>
                )
            })}
        </div>
    )
};

export const  DetailsModal = (props) => {
    const { isOpen, title, toggle, data } = props;

    if(isOpen) {
        return (
            <CVModal
                title={title}
                body={() => renderDetailsBody(data)}
                color="primary"
                header
                isOpen={isOpen}
                toggle={toggle}
                acceptFunc={() => {}}
                hideButtons={true}
            />
        )
    }
    return null;
};