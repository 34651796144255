import React from 'react';
import { Container, Row } from 'reactstrap';
import VaccinationEventForm from "../NewEventForms/VaccinationEventForm"
import { connect } from 'react-redux';
import actions from '../../../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const NewVaccinationEventContainer = (props) => {
    const alert = useAlert();

    const addVaccinationEvent = (values) => {
        props.submitVaccinationEvent(values, props.facilityId, () => {
            alert.success(props.t('events.alerts.event_added'));
            props.history.push('/events/vaccination/table');
        }, (errorMessage) => {
            ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
        });
    };

    return (
        <Container>
          <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Row>
                <VaccinationEventForm
                  onSubmit={addVaccinationEvent}
                  pending={props.addCowPending}
                  editable/>
            </Row>
        </Container>
    )
};

const mapStateToProps = (state) => ({
    addCowPending: state.ui.addCowPending, // todo change this!
    facilityId: state.user.selectedEstablishment._id
});

const mapDispatchToProps = (dispatch) => ({
    submitVaccinationEvent: (values, facilityId, successCallback, errorCallback) =>
        dispatch(actions.event.submitVaccinationEvent(values, facilityId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewVaccinationEventContainer));