import CryptoJS from 'crypto-js';

const encrypt = (string, password) => CryptoJS.AES.encrypt(string, password).toString();
const decrypt = (encryptedStr, password) => CryptoJS.AES.decrypt(encryptedStr, password).toString(CryptoJS.enc.Utf8);

export default ({
    btoa: (string) => {
        const wordArray = CryptoJS.enc.Utf8.parse(string);
        return CryptoJS.enc.Base64.stringify(wordArray);
    },
    atob: (base64) => {
        const parsedWordArray = CryptoJS.enc.Base64.parse(base64);
        return parsedWordArray.toString(CryptoJS.enc.Utf8);
    },
    encrypt,
    decrypt,
    decryptObject: (encryptedStr, password) => JSON.parse(decrypt(encryptedStr, password)),
    encryptObject: (object, password) => encrypt(JSON.stringify(object), password),
});