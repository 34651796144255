/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CVTable from '../../Tables/CVTable/CVTable';
import {Button} from "reactstrap";
import cowTransparent from "../../../shared/img/cow-transparent.gif";
import actions from '../../../redux/actions/index';
import {Redirect} from "react-router-dom";
import CheckOutlineIcon from 'mdi-react/CheckOutlineIcon';
import CloseOutlineIcon from 'mdi-react/CloseOutlineIcon';
import {useAlert} from "react-alert";
import {GENERIC_ERROR} from '../../../util/routes/errors';
import CVButton from "../../../shared/components/cv/CVButton";

const AnimalsMassiveLoad = (props) => {
    const [animals, setAnimals] = useState();
    const [total, setTotal] = useState(0);
    const [redirectRoute, setRedirectRoute] = useState();

    const alert = useAlert();

    useEffect(() => {
        if (props.animals !== undefined) {
            setAnimals(props.animals);
            setTotal(props.animals.length)
        }
    }, [props.animals]);

    const {t, isLoading, thereIsAnError, acceptMassiveAnimalUpload} = props;

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderBreed = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals.table.breed.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderSpecies = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals.table.species.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderSex = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals.table.headers.sex.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderCategory = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals_categories.cow.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderStatus = ({value}) => {
        let Icon;

        switch (value) {
            case 'error':
                Icon = <CloseOutlineIcon color={'red'}/>;
                break;
            default:
                Icon = <CheckOutlineIcon color={'green'}/>;
                break;

        }

        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {Icon}
            </div>
        )
    };

    const renderErrorMessage = ({value}) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {
              value.length > 0 ? t(`errors.${value[0]}`) : '-'
            }
          </div>
        )
    };

    const heads = [
        {
            key: 'status',
            name: t('animals.table.headers.status'),
            percentage: 7.6,
            minWidth: 80,
            formatter: renderStatus
        },
        {
            key: 'errors',
            name: t('animals.table.headers.error'),
            percentage: 15,
            minWidth: 250,
            formatter: renderErrorMessage
        },
        {
            key: 'earId',
            name: t('animals.table.headers.caravan_id'),
            percentage: 5,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'fatherId',
            name: t('animals.table.headers.fatherId'),
            percentage: 5,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'motherId',
            name: t('animals.table.headers.motherId'),
            percentage: 5,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'breed',
            name: t('animals.table.headers.breed'),
            percentage: 5,
            minWidth: 100,
            formatter: renderBreed
        },
        {
            key: 'specie',
            name: t('animals.table.headers.species'),
            percentage: 7.6,
            minWidth: 100,
            formatter: renderSpecies
        },
        {
            key: 'category',
            name: t('animals.table.headers.category'),
            percentage: 7.6,
            minWidth: 100,
            formatter: renderCategory
        },
        {
            key: 'dentition',
            name: t('animals.table.headers.dentition'),
            percentage: 7.6,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'birthDate',
            name: t('animals.table.headers.birth_date'),
            percentage: 7.6,
            minWidth: 110,
            formatter: renderValueDefault
        },
        {
            key: 'weight',
            name: t('animals.table.headers.weight'),
            percentage: 7.6,
            minWidth: 80,
            formatter: renderValueDefault
        },
        {
            key: 'productionSystem',
            name: t('animals.table.headers.production_system'),
            percentage: 11,
            minWidth: 150,
            formatter: renderValueDefault
        },
    ];

    const submit = () => {
        acceptMassiveAnimalUpload(props.excelId, () => {
            setRedirectRoute('/animals/table');
            alert.success(t('animals.alerts.massive_load_successfully'));
        }, (errorMessage) => {
            if(errorMessage === GENERIC_ERROR)
                alert.error(t('animals.alerts.massive_load_error'));
            else
                alert.error(t(`errors.${errorMessage}`));
        });
    };

    const cancel = () => {
        setRedirectRoute('/animals/table')
    };

    return (
        <div className="animal-massive-load">
            {
                isLoading &&
                <div className="loading">
                    <img alt="cow" src={cowTransparent}/>
                    <span>{t('animals.massive_load_table.we_are_processing_the_file')}</span>
                </div>
            }
            {
                !isLoading &&
                <div>
                    <CVTable
                        getPage={() => {}}
                        data={animals}
                        total={total}
                        currentPage={1}
                        heads={heads}
                        hideFilters
                        hidePagination
                        title={t('animals.massive_load_table.title')}
                        noDataLabel={t('animals.massive_load_table.no_data_label')}
                    />
                    <div className="button-container">
                        <CVButton color="primary" pending={props.acceptLoading} onClick={submit}>{t('common.accept')}</CVButton>
                        <Button className="btn btn-secondary" onClick={cancel}>{t('common.cancel')}</Button>
                    </div>
                </div>
            }
            {redirectRoute && <Redirect to={redirectRoute}/>}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        animals: state.animal.massiveLoad.animals,
        isLoading: state.ui.uploadFileForMassiveAnimalsLoadPending,
        thereIsAnError: state.ui.uploadFileForMassiveAnimalsLoadError,
        excelId: state.animal.massiveLoad._id,
        acceptLoading: state.ui.acceptMassiveAnimalsLoadPending,
        acceptError: state.ui.acceptMassiveAnimalsLoadError
    };
};

const mapDispatchToProps = (dispatch) => ({
    acceptMassiveAnimalUpload: (excelId, successCallback, errorCallback) =>
        dispatch(actions.animal.acceptMassiveAnimalsUpload(excelId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(AnimalsMassiveLoad)))
