import React from 'react';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import {withTranslation} from "react-i18next";

const CVPagination = (props) => {

    const setPage = (page) => {
        props.onChangePage(page);
    };

    const { totalItems, totalPages, itemsToShow, currentPage, pageSize, t } = props;

    //  Push the number of the pages into an array for showing the available pages to the user.
    const pages = [];
    for (let i = 1; i <= totalPages; i += 1) {
        pages.push(i);
    }

    const from = ((currentPage * pageSize) - pageSize) + 1;
    const to = from + itemsToShow - 1;

    const goBack = (currentPage)=> {
        // if (currentPage + 10 > totalPages) return totalPages - 10;
        if ((currentPage - 10) < 0 ) return 1;
        return currentPage -1
    }

    const disableGoRight = ()=> {
        return currentPage === totalPages || totalPages - currentPage < 10;
    }

    const getFirstPageToShow = ()=> {
        if(currentPage === 1) return currentPage - 1;
        if (currentPage + 10 > totalPages) return totalPages - 10;
        return currentPage -2;
    }

    return totalItems ? (
        <div className={"pagination__wrap ".concat(props.appendClassName ? props.appendClassName : "")}>
            {(totalItems <= 1) ? ''
                : (
                    <Pagination className="pagination" dir="ltr">
                        <PaginationItem className="pagination__item" disabled={currentPage === 1}>
                            <PaginationLink
                                className="pagination__link pagination__link--arrow"
                                type="button"
                                onClick={() => setPage(1)}
                            >
                                <ChevronDoubleLeftIcon className="pagination__link-icon"/>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="pagination__item" disabled={currentPage === 1}>
                            <PaginationLink
                                className="pagination__link pagination__link--arrow"
                                type="button"
                                onClick={() => setPage(goBack(currentPage))}
                            >
                                <ChevronLeftIcon className="pagination__link-icon"/>
                            </PaginationLink>
                        </PaginationItem>
                        {pages.slice(getFirstPageToShow(), currentPage + 9).map(page => (
                            <PaginationItem
                                className="pagination__item"
                                key={page}
                                active={currentPage === page}
                            >
                                <PaginationLink
                                    className="pagination__link"
                                    type="button"
                                    onClick={() => setPage(page)}
                                >
                                    {page}
                                </PaginationLink>
                            </PaginationItem>
                        ))
                        }
                        <PaginationItem className="pagination__item" disabled={disableGoRight()}>
                            <PaginationLink
                                className="pagination__link pagination__link--arrow"
                                type="button"
                                onClick={() => setPage(currentPage+1)}
                            >
                                <ChevronRightIcon className="pagination__link-icon"/>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="pagination__item" disabled={currentPage === totalPages}>
                            <PaginationLink
                                className="pagination__link pagination__link--arrow"
                                type="button"
                                onClick={() => setPage(totalPages)}
                            >
                                <ChevronDoubleRightIcon className="pagination__link-icon"/>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                )
            }
            <div className="pagination-info">
            <span>{t('cv-table.pagination_info',
                {
                    from: from,
                    to: to,
                    total: totalItems
                })}
            </span>
            </div>
        </div>
    ) : <div/>;
};

CVPagination.propTypes = {
    onChangePage: PropTypes.func.isRequired,
    totalItems: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    itemsToShow: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired
};

export default withTranslation('common')(CVPagination)
