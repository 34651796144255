import api from '../../api/index';
import lightwallet from 'eth-lightwallet';
import walletService from '../../util/storage/wallet/index';
import encryption from '../../util/encryption/index';
import {
    CREATE_WALLET, GET_WALLET, SAVE_WALLET, RECREATE_REMOTE_WALLET,
} from '../actions/walletActions';
import actions from '../actions';

const PATH = "m/44'/60'/0'/0/0";

const wallet = ({dispatch, getState}) => next => action => {
    next(action);
    switch (action.type) {
        case CREATE_WALLET:
            const password = action.secretWord;
            const mnemonic = action.mnemonic.join(" ");

            lightwallet.keystore.createVault({password, seedPhrase: mnemonic, hdPathString: PATH},
                function (err, ks) {
                    ks.keyFromPassword(password, function (err, pwDerivedKey) {
                        if(err) {
                            dispatch(actions.wallet.createWalletError(err));
                        }
                        else {
                            ks.generateNewAddress(pwDerivedKey);
                            //  Get the address from the created wallet
                            const address = ks.getAddresses()[0];
                            //  Encrypts the mnemonic with the user password.
                            const encryptedMnemonic = encryption.encrypt(mnemonic, password);
                            //  Send the address and the encrypted Mnemonic to the api.
                            api.createWallet(getState().user.id, address, encryptedMnemonic)
                                .then(() => {
                                    //On local storage we save the object keystore, the pwDerivedKey (key derived from
                                    // user password) and the address.
                                    const walletToSave = {
                                        keystore: ks.serialize(),
                                        pwDerivedKey,
                                        address
                                    };
                                    dispatch(actions.wallet.saveWallet(walletToSave, action.pin));
                                    dispatch(actions.wallet.createWalletResponse({result: "ok"}));

                                    if(action.callback) action.callback();
                                })
                                .catch(err => {
                                    dispatch(actions.wallet.createWalletError(err))
                                });
                        }
                    })
                });
            break;
        case GET_WALLET:
            //  Checks if there is a wallet on local storage
            walletService.retrieve(getState().user.id)
                .then((localEncryptedWallet) => {
                    //  If there is a wallet on local storage it saves it on the redux store
                    if(localEncryptedWallet) {
                        dispatch(actions.wallet.getLocalWalletResponse({wallet: localEncryptedWallet}));
                        if(action.callback) action.callback();
                    }
                    //  If there is no wallet on local storage it searches for a wallet on the back.
                    else {
                        dispatch(actions.wallet.getLocalWalletError("NO_LOCAL_WALLET"));
                        api.getWallet(getState().user.id)
                            .then((res) => {
                                const remoteEncryptWallet = res.wallet;
                                //  If there is a wallet on the back it saves it on the redux store
                                if(remoteEncryptWallet) {
                                    dispatch(actions.wallet.getRemoteWalletResponse(remoteEncryptWallet))
                                }
                                else dispatch(actions.wallet.getRemoteWalletError("NO_REMOTE_WALLET"));
                                if(action.callback) action.callback();
                            })
                            .catch((err) => actions.wallet.getWalletError(err));
                    }
                })
                .catch((err) => dispatch(actions.wallet.getWalletError(err)));
            break;
        case SAVE_WALLET:
            let wallet = JSON.stringify(action.wallet);
            // Encrypt the wallet with the user pin
            let walletEncrypted = encryption.encrypt(wallet, action.pin);

            // Save the wallet encrypted into local storage.
            walletService.store(getState().user.id, walletEncrypted)
                .then(() => {
                    dispatch(actions.wallet.saveWalletResponse("WALLET_SAVED"));
                    // Get the wallet saved (put into redux store)
                    dispatch(actions.wallet.getWallet());
                })
                .catch(() => {
                    dispatch(actions.wallet.saveWalletError("WALLET_WAS_NOT_SAVED"))
                });
            break;
        case RECREATE_REMOTE_WALLET:
            const encryptedMnemonic = getState().wallet.encryptWallet.remote.encryptedMnemonic;
            const address = getState().wallet.encryptWallet.remote.address;

            // Decrypt the mnemonic with the user password.
            const decryptMnemonic = encryption.decrypt(encryptedMnemonic, action.password);

            // Recreates the keystore with mnemonic, the password and the path
            lightwallet.keystore.createVault({password: action.password, seedPhrase: decryptMnemonic, hdPathString: PATH},
                function (err, ks) {
                    ks.keyFromPassword(action.password, function (err, pwDerivedKey) {
                        if(err) dispatch(actions.wallet.recreateRemoteWalletError(err));
                        else {
                            const walletToSave = {
                                keystore: ks.serialize(),
                                pwDerivedKey: pwDerivedKey,
                                address: address
                            };

                            // Save the recreated wallet on local storage, encrypted with the user pin
                            dispatch(actions.wallet.saveWallet(walletToSave, action.pin));
                        }
                    })
                }
            );
            break;
    }
};


export default wallet;