import user from './userActions';
import session from './sessionActions';
import notification from './notificationActions';
import animal from './animalActions';
import admin from './adminActions'
import event from './eventActions';
import generalData from './generalDataActions';
import wallet from './walletActions';
import auth from './authActions';
import constant from './constantActions';
import image from './imageActions';

export default { user, session, notification, animal, admin, event, generalData, wallet, auth, constant, image }