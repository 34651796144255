import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Animals from '../../../Animals/index';
import AnimalsMassiveLoad from '../../../Animals/components/AnimalsMassiveLoad';
import AddCowForm from '../../../Animals/components/AddCowForm';
import AnimalDetails from '../../../Animals/components/AnimalDetails';

export default () => (
    <Switch>
        <Route path="/animals/table" component={Animals} />
        <Route path="/animals/massiveLoad" component={AnimalsMassiveLoad} />
        <Route path="/animals/singleLoad" component={AddCowForm} />
        <Route path="/animals/animal_detail" component={AnimalDetails} />
    </Switch>
);
