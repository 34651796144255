export const GET_AVAILABLE_COUNTRIES = 'GET_AVAILABLE_COUNTRIES';
export const GET_AVAILABLE_COUNTRIES_RESPONSE = 'GET_AVAILABLE_COUNTRIES_RESPONSE';
export const GET_AVAILABLE_COUNTRIES_ERROR = 'GET_AVAILABLE_COUNTRIES_ERROR';
export const GET_AVAILABLE_COW_BREEDS = 'GET_AVAILABLE_COW_BREEDS';
export const GET_AVAILABLE_COW_BREEDS_RESPONSE = 'GET_AVAILABLE_COW_BREEDS_RESPONSE';
export const GET_AVAILABLE_COW_BREEDS_ERROR = 'GET_AVAILABLE_COW_BREEDS_ERROR';
export const GET_AVAILABLE_FACILITIES = 'GET_AVAILABLE_FACILITIES';
export const GET_AVAILABLE_FACILITIES_RESPONSE = 'GET_AVAILABLE_FACILITIES_RESPONSE';
export const GET_AVAILABLE_FACILITIES_ERROR = 'GET_AVAILABLE_FACILITIES_ERROR';
export const GET_FACILITY_BY_CODE = 'GET_FACILITY_BY_CODE';
export const GET_FACILITY_BY_CODE_RESPONSE = 'GET_FACILITY_BY_CODE_RESPONSE';
export const GET_FACILITY_BY_CODE_ERROR = 'GET_FACILITY_BY_CODE_ERROR';
export const RESET_FACILITY_SEARCH = 'RESET_FACILITY_SEARCH';


const generalDataActions = {
    getAvailableCountries() {
        return {
            type: GET_AVAILABLE_COUNTRIES
        }
    },
    getAvailableCountriesResponse(response) {
        return {
            type: GET_AVAILABLE_COUNTRIES_RESPONSE,
            response
        }
    },
    getAvailableCountriesError(message) {
        return {
            type: GET_AVAILABLE_COUNTRIES_ERROR,
            message
        }
    },
    getAvailableCowBreeds() {
        return {
            type: GET_AVAILABLE_COW_BREEDS
        }
    },
    getAvailableCowBreedsResponse(response) {
        return {
            type: GET_AVAILABLE_COW_BREEDS_RESPONSE,
            response
        }
    },
    getAvailableCowBreedsError(message) {
        return {
            type: GET_AVAILABLE_COW_BREEDS_ERROR,
            message
        }
    },

    getAvailableFacilities() {
        return {
            type: GET_AVAILABLE_FACILITIES
        }
    },

    getAvailableFacilitiesResponse(response) {
        return {
            type: GET_AVAILABLE_FACILITIES_RESPONSE,
            response
        }
    },

    getAvailableFacilitiesError(message) {
        return {
            type: GET_AVAILABLE_FACILITIES_ERROR,
            message
        }
    },

    getFacilityByCode(code, callback) {
        return {
            type: GET_FACILITY_BY_CODE,
            code,
            callback
        }
    },

    getFacilityByCodeResponse(response) {
        return {
            type: GET_FACILITY_BY_CODE_RESPONSE,
            response
        }
    },

    getFacilityByCodeError(message) {
        return {
            type: GET_FACILITY_BY_CODE_ERROR,
            message
        }
    },
    resetFacilitySearch() {
        return {
            type: RESET_FACILITY_SEARCH
        }
    },
};

export default generalDataActions;