import React, { useState } from 'react';
import CVModal from "../../UI/Modals/components/CVModal";
import { useTranslation } from "react-i18next";
import {Button} from "reactstrap";

const AddBody = (props) => {
    const { t } = useTranslation('common');
    const { acceptFunc, cancelFunc } = props;
    const [currentValue, setCurrentValue] = useState('');

    return (
        <div className="form">
            <div className="form__form-group">
                <span className="form__form-group-label">{t(`general_data.modals.add.keys.name`)}</span>
                <div className="form__form-group-field">
                    <input style={{backgroundColor: 'white'}} type="text"
                           value={currentValue} onChange={(event) => {setCurrentValue(event.target.value)}}/>
                </div>
            </div>
            <div>
                <Button className="modal_cancel" onClick={cancelFunc}>{t('common.cancel')}</Button>
                <Button className="modal_ok" outline color='primary' onClick={() => acceptFunc(currentValue)}>{t('common.accept')}</Button>
            </div>
        </div>
    )
};

const AddDataModal = (props) => {
    const { t } = useTranslation('common');
    const { open, toggle, acceptFunc } = props;
    return (
        <CVModal
            title={t('general_data.modals.add.title')}
            body={() => <AddBody acceptFunc={acceptFunc} cancelFunc={() => toggle()}/>}
            color="primary"
            header
            isOpen={open}
            toggle={() => toggle(!open)}
            hideButtons
        />
    )
};

export default AddDataModal;