import React, { useState }  from 'react';
import SearchIcon from "mdi-react/SearchIcon";
import classNames from "classnames";
import { withTranslation } from 'react-i18next';

const Search = (props) => {

    const [inputValue, setInputValue] = useState('');

    const search = () => {
        props.search(inputValue);
    };

    const searchClass = classNames({
        'topbar__search-field': true,
        'topbar__search-field--open': true,
    });

    const { t } = props;

    return (
        <form className="topbar__search">
            <input placeholder={t('cv-table.search-input-placeholder')} className={searchClass}
                   value={inputValue} onChange={(event) => setInputValue(event.target.value)} style={{zIndex: '0'}}/>
            <button className="topbar__btn topbar__search-btn" type="button" onClick={search} style={{zIndex: '0'}}>
                <SearchIcon size={100}/>
            </button>
        </form>
    )
};

export default withTranslation('common')(Search)