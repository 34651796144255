export const GET_EVENTS_PAGE = 'GET_EVENTS_PAGE';
export const GET_EVENTS_PAGE_RESPONSE = 'GET_EVENTS_PAGE_RESPONSE';
export const GET_EVENTS_PAGE_ERROR = 'GET_EVENTS_PAGE_ERROR';
export const SEARCH_EVENTS = 'SEARCH_EVENTS';
export const SEARCH_EVENTS_RESPONSE = 'SEARCH_EVENTS_RESPONSE';
export const SEARCH_EVENTS_ERROR = 'SEARCH_EVENTS_ERROR';
export const FILTER_EVENTS = 'FILTER_EVENTS';
export const FILTER_EVENTS_RESPONSE = 'FILTER_EVENTS_RESPONSE';
export const FILTER_EVENTS_ERROR = 'FILTER_EVENTS_ERROR';
export const SUBMIT_WEIGHT_EVENT = 'SUBMIT_WEIGHT_EVENT';
export const SUBMIT_WEIGHT_EVENT_RESPONSE = 'SUBMIT_WEIGHT_EVENT_RESPONSE';
export const SUBMIT_WEIGHT_EVENT_ERROR = 'SUBMIT_WEIGHT_EVENT_ERROR';
export const SUBMIT_DENTITION_EVENT = 'SUBMIT_DENTITION_EVENT';
export const SUBMIT_DENTITION_EVENT_RESPONSE = 'SUBMIT_DENTITION_EVENT_RESPONSE';
export const SUBMIT_DENTITION_EVENT_ERROR = 'SUBMIT_DENTITION_EVENT_ERROR';
export const SUBMIT_DELETION_EVENT = 'SUBMIT_DELETION_EVENT';
export const SUBMIT_DELETION_EVENT_RESPONSE = 'SUBMIT_DELETION_EVENT_RESPONSE';
export const SUBMIT_DELETION_EVENT_ERROR = 'SUBMIT_DELETION_EVENT_ERROR';
export const SUBMIT_LOT_EVENT = 'SUBMIT_LOT_EVENT';
export const SUBMIT_LOT_EVENT_RESPONSE = 'SUBMIT_LOT_EVENT_RESPONSE';
export const SUBMIT_LOT_EVENT_ERROR = 'SUBMIT_LOT_EVENT_ERROR';
export const SUBMIT_CASTRATION_EVENT = 'SUBMIT_CASTRATION_EVENT';
export const SUBMIT_CASTRATION_EVENT_RESPONSE = 'SUBMIT_CASTRATION_EVENT_RESPONSE';
export const SUBMIT_CASTRATION_EVENT_ERROR = 'SUBMIT_CASTRATION_EVENT_ERROR';
export const SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT = 'SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT';
export const SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT_RESPONSE = 'SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT_RESPONSE';
export const SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT_ERROR = 'SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT_ERROR';
export const SUBMIT_REIDENTIFICATION_EVENT = 'SUBMIT_REIDENTIFICATION_EVENT';
export const SUBMIT_REIDENTIFICATION_EVENT_RESPONSE = 'SUBMIT_REIDENTIFICATION_EVENT_RESPONSE';
export const SUBMIT_REIDENTIFICATION_EVENT_ERROR = 'SUBMIT_REIDENTIFICATION_EVENT_ERROR';
export const SUBMIT_CATEGORY_CHANGE_EVENT = 'SUBMIT_CATEGORY_CHANGE_EVENT';
export const SUBMIT_CATEGORY_CHANGE_EVENT_RESPONSE = 'SUBMIT_CATEGORY_CHANGE_EVENT_RESPONSE';
export const SUBMIT_CATEGORY_CHANGE_EVENT_ERROR = 'SUBMIT_CATEGORY_CHANGE_EVENT_ERROR';
export const SUBMIT_SLAUGHTER_EVENT = 'SUBMIT_SLAUGHTER_EVENT';
export const SUBMIT_SLAUGHTER_EVENT_RESPONSE = 'SUBMIT_SLAUGHTER_EVENT_RESPONSE';
export const SUBMIT_SLAUGHTER_EVENT_ERROR = 'SUBMIT_SLAUGHTER_EVENT_ERROR';
export const SUBMIT_VACCINATION_EVENT = 'SUBMIT_VACCINATION_EVENT';
export const SUBMIT_VACCINATION_EVENT_RESPONSE = 'SUBMIT_VACCINATION_EVENT_RESPONSE';
export const SUBMIT_VACCINATION_EVENT_ERROR = 'SUBMIT_VACCINATION_EVENT_ERROR';
export const UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD = 'UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD';
export const UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_RESPONSE = 'UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_RESPONSE';
export const UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_ERROR = 'UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_ERROR';
export const MASSIVE_EVENT_LOAD = 'MASSIVE_EVENT_LOAD';
export const MASSIVE_EVENT_LOAD_RESPONSE = 'MASSIVE_EVENT_LOAD_RESPONSE';
export const MASSIVE_EVENT_LOAD_ERROR = 'MASSIVE_EVENT_LOAD_ERROR';
export const ACCEPT_MASSIVE_EVENTS_UPLOAD = 'ACCEPT_MASSIVE_EVENTS_UPLOAD';
export const ACCEPT_MASSIVE_EVENTS_UPLOAD_RESPONSE = 'ACCEPT_MASSIVE_EVENTS_UPLOAD_RESPONSE';
export const ACCEPT_MASSIVE_EVENTS_UPLOAD_ERROR = 'ACCEPT_MASSIVE_EVENTS_UPLOAD_ERROR';
export const SUBMIT_RECATEGORIZATION_EVENT = 'SUBMIT_RECATEGORIZATION_EVENT';
export const SUBMIT_RECATEGORIZATION_EVENT_RESPONSE = 'SUBMIT_RECATEGORIZATION_EVENT_RESPONSE';
export const SUBMIT_RECATEGORIZATION_EVENT_ERROR = 'SUBMIT_RECATEGORIZATION_EVENT_ERROR';
export const UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD = 'UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD';
export const UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD_RESPONSE = 'UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD_RESPONSE';
export const UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD_ERROR = 'UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD_ERROR';

const eventActions = {
    getEventsPage(establishmentId, pageNumber, pageSize, eventType){
        return {
            type: GET_EVENTS_PAGE,
            establishmentId,
            pageNumber,
            pageSize,
            eventType
        }
    },
    getEventsPageResponse(response, eventType){
        return {
            type: GET_EVENTS_PAGE_RESPONSE,
            response,
            eventType
        }
    },
    getEventsPageError(message){
        return {
            type: GET_EVENTS_PAGE_ERROR,
            message
        }
    },
    searchEvents(word){
        return {
            type: SEARCH_EVENTS,
            word
        }
    },
    searchEventsResponse(response){
        return {
            type: SEARCH_EVENTS_RESPONSE,
            response
        }
    },
    searchEventsError(message){
        return {
            type: SEARCH_EVENTS_ERROR,
            message
        }
    },
    filterEvents(filters){
        return {
            type: FILTER_EVENTS,
            filters
        }
    },
    filterEventsResponse(response){
        return {
            type: FILTER_EVENTS_RESPONSE,
            response
        }
    },
    filterEventsError(message){
        return {
            type: FILTER_EVENTS_ERROR,
            message
        }
    },
    submitWeightEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_WEIGHT_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitWeightEventResponse(response) {
        return {
            type: SUBMIT_WEIGHT_EVENT_RESPONSE,
            response
        }
    },
    submitWeightEventError(message) {
        return {
            type: SUBMIT_WEIGHT_EVENT_ERROR,
            message
        }
    },
    submitSlaughterEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_SLAUGHTER_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitSlaughterEventResponse(response) {
        return {
            type: SUBMIT_SLAUGHTER_EVENT_RESPONSE,
            response
        }
    },
    submitSlaughterEventError(message) {
        return {
            type: SUBMIT_SLAUGHTER_EVENT_ERROR,
            message
        }
    },
    submitDentitionEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_DENTITION_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitDentitionEventResponse(response) {
        return {
            type: SUBMIT_DENTITION_EVENT_RESPONSE,
            response
        }
    },
    submitDentitionEventError(message) {
        return {
            type: SUBMIT_DENTITION_EVENT_ERROR,
            message
        }
    },
    submitDeletionEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_DELETION_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitDeletionEventResponse(response) {
        return {
            type: SUBMIT_DELETION_EVENT_RESPONSE,
            response
        }
    },
    submitDeletionEventError(message) {
        return {
            type: SUBMIT_DELETION_EVENT_ERROR,
            message
        }
    },
    submitLotEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_LOT_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitLotEventResponse(response) {
        return {
            type: SUBMIT_LOT_EVENT_RESPONSE,
            response
        }
    },
    submitLotEventError(message) {
        return {
            type: SUBMIT_LOT_EVENT_ERROR,
            message
        }
    },
    submitCastrationEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_CASTRATION_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitCastrationEventResponse(response) {
        return {
            type: SUBMIT_CASTRATION_EVENT_RESPONSE,
            response
        }
    },
    submitCastrationEventError(message) {
        return {
            type: SUBMIT_CASTRATION_EVENT_ERROR,
            message
        }
    },
    submitProductiveSystemChangeEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitProductiveSystemChangeEventResponse(response) {
        return {
            type: SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT_RESPONSE,
            response
        }
    },
    submitProductiveSystemChangeEventError(message) {
        return {
            type: SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT_ERROR,
            message
        }
    },
    submitReIdentificationEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_REIDENTIFICATION_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitReIdentificationEventResponse(response) {
        return {
            type: SUBMIT_REIDENTIFICATION_EVENT_RESPONSE,
            response
        }
    },
    submitReIdentificationEventError(message) {
        return {
            type: SUBMIT_REIDENTIFICATION_EVENT_ERROR,
            message
        }
    },
    submitRecategorizationEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_RECATEGORIZATION_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitRecategorizationEventResponse(response) {
        return {
            type: SUBMIT_RECATEGORIZATION_EVENT_RESPONSE,
            response
        }
    },
    submitRecategorizationEventError(message) {
        return {
            type: SUBMIT_RECATEGORIZATION_EVENT_ERROR,
            message
        }
    },
    submitVaccinationEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_VACCINATION_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitVaccinationEventResponse(response) {
        return {
            type: SUBMIT_VACCINATION_EVENT_RESPONSE,
            response
        }
    },
    submitVaccinationEventError(message) {
        return {
            type: SUBMIT_VACCINATION_EVENT_ERROR,
            message
        }
    },
    uploadFileForMassiveEventLoad(file, establishmentId, eventType, callback) {
        return {
            type: UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD,
            file,
            establishmentId,
            eventType,
            callback
        }
    },
    uploadFileForMassiveEventLoadResponse(response) {
        return {
            type: UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_RESPONSE,
            response
        }
    },
    uploadFileForMassiveEventLoadError(message) {
        return {
            type: UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_ERROR,
            message
        }
    },
    uploadFileForTransferEventMassiveLoad(newFacilityId, file, establishmentId, eventType, callback) {
        return {
            type: UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD,
            newFacilityId,
            file,
            establishmentId,
            eventType,
            callback
        }
    },
    uploadFileForTransferEventMassiveLoadResponse(response) {
        return {
            type: UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD_RESPONSE,
            response
        }
    },
    uploadFileForTransferEventMassiveLoadError(message) {
        return {
            type: UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD_ERROR,
            message
        }
    },
    massiveEventLoad(events, callback) {
        return {
            type: MASSIVE_EVENT_LOAD,
            events,
            callback
        }
    },
    massiveEventLoadResponse(response) {
        return {
            type: MASSIVE_EVENT_LOAD_RESPONSE,
            response
        }
    },
    massiveEventLoadError(message) {
        return {
            type: MASSIVE_EVENT_LOAD_ERROR,
            message
        }
    },
    acceptMassiveEventUpload(excelId, eventType, successCallback, errorCallback) {
        return {
            type: ACCEPT_MASSIVE_EVENTS_UPLOAD,
            excelId,
            eventType,
            successCallback,
            errorCallback
        }
    },
    acceptMassiveEventUploadResponse(response) {
        return {
            type: ACCEPT_MASSIVE_EVENTS_UPLOAD_RESPONSE,
            response
        }
    },
    acceptMassiveEventUploadError(message) {
        return {
            type: ACCEPT_MASSIVE_EVENTS_UPLOAD_ERROR,
            message
        }
    }
};

export default eventActions;