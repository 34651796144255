/*
*   The event mapper is use for mapping either the body or the response on a event request. It try to decouple the
*   event middleware.
* */

const parseProductiveSystemOptionsToArray = (productiveSystems) => {
    let result = [];
    if(productiveSystems.natural) result.push('natural');
    if(productiveSystems.silvoPastoral) result.push('silvoPastoral');
    if(productiveSystems.pastoral) result.push('pastoral');
    if(productiveSystems.creepFeeding) result.push('creepFeeding');
    if(productiveSystems.strategicFeeding) result.push('strategicFeeding');
    if(productiveSystems.feedlot) result.push('feedlot');

    return result;
};

const mapper =  {
    body: {
        addProductiveSystemChangeEvent: (values) => ({
            facilityId: values.establishment._id,
            eventDate: values.date,
            productionSystem: parseProductiveSystemOptionsToArray(values.productiveSystems)[0],
            feeding: values.selectedFoods,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addWeightEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            weight: values.weight,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addSlaughterEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addCastrationEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addVaccinationEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            comments: values.comments,
            earId: values.caravan_id
        }),
        addReidentificationEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            newEarId: values.newEarId,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addDentitionEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            value: values.value.value,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addDeletionEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addLotEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            earId: values.caravan_id,
            lot: values.value.value,
            newLot: values.new_lot,
            comments: values.comments,
        }),
    },
    response: {
        getEvents: (response) => ({
            events: response.docs,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        searchEvents: (response) => ({
            events: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        filterEvents: (response) => ({
            events: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        uploadFileForMassiveEventsLoad: (response) => ({
            _id: response._id,
            hash: response.hash,
            status: response.status,
            events: parseValidatedRows(response.validatedRows)
        })
    }
};

const parseValidatedRows = (validatedRows) => {
    let result = [];
    validatedRows.forEach(row => {
        result.push({
            ...row.data,
            status: row.status,
            errors: row.errors
        })
    });
    return result;
};

export default mapper;