import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ManageAccounts from '../../../ManageAccounts/index';
import TitularWizardForm from '../../../ManageAccounts/components/TitularWizard/TitularWizardForm';
import AccountDetails from '../../../ManageAccounts/components/AccountDetails';

export default () => (
    <Switch>
        <Route path="/manage_accounts/table" component={ManageAccounts} />
        <Route path="/manage_accounts/new_account" component={TitularWizardForm} />
        <Route path="/manage_accounts/account_details" component={AccountDetails} />
    </Switch>
);