import React from 'react';
import DatePicker from "react-date-picker";
import i18n from '../../../translations/config/i18n';

const CVDatePicker = (props) => {

    const { value, onChange, disabled } = props;

    return (
        <DatePicker
            dateFormat="MM/dd/YYYY"
            onChange={onChange}
            value={value ? new Date(value) : undefined}
            className={"cv_date_picker"}
            calendarClassName={"cv_date_picker_calendar"}
            disabled={disabled}
        />
    )
};

export const renderDateInputField = ({input, disabled, meta: {touched, error, warning}}) => (
    <div style={{width: '100%'}}>
        <CVDatePicker
            {...input}
            disabled={disabled}
        />
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) ||
                (warning && <span className="error-message">{warning}</span>))}
        </div>
    </div>
);