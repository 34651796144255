import React, { PureComponent } from 'react';
import PropTypes  from 'prop-types';
import { connect } from 'react-redux';
import {Button, ButtonToolbar, Modal} from 'reactstrap';
import { RTLProps } from '../../prop-types/ReducerProps';
import { withTranslation } from "react-i18next";

class CVBasicModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        body: PropTypes.func.isRequired,
        rtl: RTLProps,
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired,
        cancel: PropTypes.func.isRequired,
        accept: PropTypes.func.isRequired,
        cancelText: PropTypes.string,
        acceptText: PropTypes.string
    };

    static defaultProps = {
        title: '',
        rtl: {
            direction: "rtl"
        }
    };

    render() {
        const { title, rtl, isOpen, toggleModal, body, cancel, accept, t, cancelText, acceptText, hideButtons } = this.props;

        if(!isOpen) return null;

        return (
            <Modal
                isOpen={isOpen}
                toggle={toggleModal}
                modalClassName={`${rtl.direction}-support`}
                className={`modal-dialog`}
            >
                <div className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => toggleModal(false)} />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <div className="modal__body">
                    {body()}
                    {
                        !hideButtons &&
                        <ButtonToolbar className="form__button-toolbar center">
                            <Button color="primary" onClick={accept}>{acceptText || t('common.accept')}</Button>
                            <Button type="reset" onClick={cancel}>{cancelText || t('common.cancel')}</Button>
                        </ButtonToolbar>
                    }
                </div>
            </Modal>
        );
    }
}

export class CVBasicModalWithoutButtons extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        body: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired
    };

    render() {
        return (
            <CVBasicModal {...this.props} hideButtons cancel={() => {}} accept={() => {}}/>
        )
    }
}

export default connect(state => ({
    rtl: state.rtl,
}))(withTranslation('common')(CVBasicModal));