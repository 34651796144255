import React, {useEffect, useState} from 'react';
import { withTranslation } from "react-i18next";
import CVTable from "../../Tables/CVTable/CVTable";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import { connect } from "react-redux";
import actions from '../../../redux/actions/index'
import { withRouter } from 'react-router-dom';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import AccountPlusOutlineIcon from 'mdi-react/AccountPlusOutlineIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import {useAlert} from "react-alert";
import CVModal from "../../UI/Modals/components/CVModal";
import {ROLES} from "../../../constants";
import {CVBasicModalWithoutButtons} from "../../../shared/components/cv/CVBasicModal";
import ProfessionalList from "./ProfessionalList";
import AddNewProfessional from "./AddNewProfessional";
import AnimalLotsModal from "./AnimalLotsModal";
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import CowIcon from "mdi-react/CowIcon";
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import { Redirect } from "react-router-dom";
import CompanySelector from '../../Layout/selectors/companySelector';

const EstablishmentTable = (props) => {
    const alert = useAlert();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAnimalLotsModal, setShowAnimalLotsModal] = useState(false);
    const [establishmentToDelete, setEstablishmentToDelete] = useState();
    const [showAddUserToEstablishmentModal, setShowAddUserToEstablishmentModal] = useState(false);
    const [establishmentSelected, setEstablishmentSelected] = useState();
    const [establishments, setEstablishments] = useState();
    const [redirectRoute, setRedirectRoute] = useState();

    const { t,
        allEstablishmentsByCompany,
        userEstablishments,
        widthPercentage,
        currentPage,
        total,
        totalPages,
        pageSize,
        selectedCompany,
        allCompanies,
        allLotsByEstablishment } = props;

    useEffect(() => {
        if (props.userRoles.some(rol => (rol.name) === ROLES.TITULAR )) {
            props.getAllCompanies();
        }
        if(props.userRoles.some((rol) => rol.name === ROLES.SURROGATE)) props.getSurrogateEstablishments(1, 10)
    }, []);

    useEffect(() => {
        if(props.userRoles.some(rol => (rol.name) === ROLES.SURROGATE )) props.getAssignedCompanies(props.userId);
    }, []);

    useEffect(() => {
        let ids = [];
        if(selectedCompany.length > 0) {
            ids = selectedCompany.map(el => (el.value));
            if(props.userRoles.some((rol) => rol.name === ROLES.TITULAR)) props.getEstablishments(ids, 1, 10);
        }else{
            props.resetUserEstablishments();
        }
        //props.getEstablishmentsByCompanyId(ids);
    }, [selectedCompany, allCompanies]);

    useEffect(()=>{
        const e = allEstablishmentsByCompany.map(el => ( {value: el._id, label: el.name} ));
        if(allCompanies.length === selectedCompany.length){
            props.selectEstablishment(e)
        }
    },[allEstablishmentsByCompany])

    useEffect(()=>{
        const ids = allEstablishmentsByCompany.map(el => ( el._id ));
        const e = allEstablishmentsByCompany.map(el => ( {value: el._id, label: el.name} ));
        if(ids.length) props.getLotsByEstablishment(ids);
        props.selectLot(e);
    },[allEstablishmentsByCompany])

    useEffect(()=>{
        // select all lots from selected commpany
        const lots = allLotsByEstablishment.map(el => ( {value: el._id, label: el.name} ));
        props.selectLot(lots);
    },[allLotsByEstablishment])

    useEffect(() => {
        setEstablishments(userEstablishments);
    }, [userEstablishments])

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const editUserEstablishment = () => {
        let values = props.establishmentDetails;
        values.animalLots.mode = props.selectedMode;
        values.animalLots.lots = props.establishmentDetails.animalLots.lots
        props.editUserEstablishmentById(values.id, props.establishmentDetails.type, values,
            () => { setShowAnimalLotsModal(!showAnimalLotsModal)}, (errorMessage) => {
                //ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            });
      };

    const renderDate = ({value}) => {
        const date = new Date(value);
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {`${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`}
            </div>
        )
    };

    const userIsTitular = () => {
        return props.userRoles.some((rol) => rol.name === ROLES.TITULAR)
    };

    const isValidRole = () => {
        if(props.userRoles.some((rol) => rol.name === ROLES.SURROGATE)) return true
        if(props.userRoles.some((rol) => rol.name === ROLES.TITULAR)) return true
        return false
    };

    const renderTableSelectors = () => (
         <div>
           <CompanySelector/>
         </div>
     );

    const handleAnimalIconClic = (values) => {
        props.resetSelectedEstablishment();
        props.selectEstablishment([values]);
        props.history.push(
            "/animals/table"
           // {establishmentId: values., value: values.name}
          )
    }

    const renderButtons = ({dependentValues}) => {
        return (
          <div style={{display: "flex", justifyContent: "center"}}>
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Tooltip
                title="Ver animales"
                position="top"
                trigger="mouseenter"
                animation="scale"
                duration="600"
                size="small"
                theme="transparent"
                distance="0"
              >
                  <CowIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    onClick={() => handleAnimalIconClic({
                        value: dependentValues._id,
                        label: dependentValues.name
                    })}
                  />
              </Tooltip>
              <Tooltip
                title="Gestión de lotes"
                position="top"
                trigger="mouseenter"
                animation="scale"
                duration="600"
                size="small"
                theme="transparent"
                distance="0"
              >
                  <CalendarPlusIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    onClick={() => {
                        setEstablishmentSelected({
                            name: dependentValues.name,
                            _id: dependentValues._id
                        });
                        props.getUserEstablishmentById(dependentValues._id);
                        setShowAnimalLotsModal(true);
                    }}
                  />
              </Tooltip>
              <MagnifyIcon
                className={"button"}
                style={{marginLeft: "10px"}}
                onClick={() =>
                  props.history.push(
                    "/manage_establishments/establishment_details",
                    {establishmentId: dependentValues._id}
                  )
                }
              />
              {
                  isValidRole() ? (
                      <Tooltip
                        title="Agregar profesionales"
                        position="top"
                        trigger="mouseenter"
                        animation="scale"
                        duration="600"
                        size="small"
                        theme="transparent"
                        distance="0"
                      >
                          <AccountPlusOutlineIcon
                            className={"button"} style={{marginLeft: "10px"}}
                            onClick={() => {
                                setEstablishmentSelected(dependentValues._id);
                                setShowAddUserToEstablishmentModal(true);
                            }}
                          />
                      </Tooltip>
                    )
                    : null
              }
              {
                userIsTitular() ? (
                <CloseCircleIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    color={"red"}
                    onClick={() => {
                        setEstablishmentToDelete({
                            name: dependentValues.name,
                            _id: dependentValues._id
                        });
                        setShowDeleteModal(!showDeleteModal);
                    }}
                />
                )
                : null
             }
          </div>
        );
    };

    const renderCompanyName = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value ? value.legalName : ''}
            </div>
        )
    };

    const renderIconsForAddingARole = () => (
        <div>
            <PlusCircleOutlineIcon onClick={() => {
                props.history.push('/manage_establishments/new_establishment')
            }}/>
        </div>
    );

    const heads = [
        {
            key: 'name',
            name: t('establishments.table.headers.establishment_name'),
            percentage: 25,
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'company',
            name: t('establishments.table.headers.establishment_company'),
            percentage: 25,
            minWidth: 150,
            formatter: renderCompanyName
        },
        {
            key: 'createdAt',
            name: t('establishments.table.headers.creation_date'),
            percentage: 25,
            minWidth: 150,
            formatter: renderDate
        },
        {
            key: 'actions',
            name: t('establishments.table.headers.actions'),
            percentage: 25,
            minWidth: 150,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const renderDeleteEstablishmentBody = () => (
        establishmentToDelete ?
            <div>
                {t('establishments.modals.delete_establishment.body', { establishment_name: establishmentToDelete.name })}
            </div>:
            null
    );

    const renderDeleteEstablishmentModal = () => (
        <CVModal
            title={t('establishments.modals.delete_establishment.title')}
            body={() => renderDeleteEstablishmentBody()}
            color="danger"
            header
            isOpen={showDeleteModal}
            toggle={() => setShowDeleteModal(!showDeleteModal)}
            acceptFunc={() => {
                props.deleteEstablishmentById(establishmentToDelete._id, () => {
                    setShowDeleteModal(false);
                    alert.success(t('establishments.alerts.establishment_deleted_successfully'));
                })
            }}
        />
    );

    const renderAnimalLotsModalBody = () => (
            <div>
                <AnimalLotsModal
                  establishmentId={establishmentSelected}
                />
            </div>
    );

    const renderAnimalLotsModal = () => (
        <CVModal
            style={{ width: '95%' }}
            title={t('establishments.modals.animal_lots.title')}
            body={() => renderAnimalLotsModalBody()}
            color="primary"
            header
            isOpen={showAnimalLotsModal}
            toggle={() => setShowAnimalLotsModal(!showAnimalLotsModal)}
            acceptFunc={editUserEstablishment}
            details={props.establishmentDetails}
            // acceptFunc={() => {
            //     // props.editUserEstablishmentById(establishmentSelected._id, () => {
            //     //     setShowAnimalLotsModal(false);
            //     // })
            // }}
        />
    );

    const renderAddProfessionalToEstablishmentBody = () => (
        <div>
            <ProfessionalList establishmentId={establishmentSelected}/>
            <AddNewProfessional establishmentId={establishmentSelected}/>
        </div>
    );

    const addProfessionalToEstablishmentModal = () => (
        <CVBasicModalWithoutButtons
            title={t('establishments.modals.add_professional_to_establishment.title')}
            body={renderAddProfessionalToEstablishmentBody}
            isOpen={showAddUserToEstablishmentModal}
            toggleModal={() => {
                if(showAddUserToEstablishmentModal) props.resetProfessionalUserSearch();
                setShowAddUserToEstablishmentModal(!showAddUserToEstablishmentModal)
            }}
        />
    );

    return(
        <div>
            <CVTable
                getPage={props.getEstablishments}
                //getPage={establishments}
                data={establishments}
                total={total}
                totalPages={totalPages}
                currentPage={currentPage}
                pageSize={pageSize}
                heads={heads}
                title={t('establishments.table.title')}
                hideFilters
                CVSelect={renderTableSelectors}
                SubtitleComponent={renderIconsForAddingARole}
                widthPercentage={widthPercentage}
                noDataLabel={t('establishments.table.no_data_label')}
                pending={props.getUserEstablishmentsPending}
            />
            {renderDeleteEstablishmentModal()}
            {addProfessionalToEstablishmentModal()}
            {renderAnimalLotsModal()}
            { redirectRoute && <Redirect to={redirectRoute}/> }
        </div>
    )

};

const mapStateToProps = (state) => ({
    allCompanies: state.user.allCompanies,
    allEstablishmentsByCompany: state.user.allEstablishmentsByCompany,
    allLotsByEstablishment: state.user.allLotsByEstablishment,
    userEstablishments: state.user.establishments.results,
    currentPage: state.user.establishments.page,
    total: state.user.establishments.total,
    totalPages: state.user.establishments.totalPages,
    pageSize: state.user.establishments.pageSize,
    getUserEstablishmentsPending: state.ui.getUserEstablishmentsPending,
    getUserEstablishmentsError: state.ui.getUserEstablishmentsError,
    userRoles: state.user.roles,
    establishmentDetails: state.user.establishmentDetails,
    selectedCompany: state.user.selectedCompany,
    selectedEstablishment: state.user.selectedEstablishment,
    selectedMode: state.user.selectedEstablishmentLotMode,
    establishmentsToAdd: state.user.establishmentsToAdd,
    userId: state.user.id,
});

const mapDispatchToProps = (dispatch) => ({
    getAllCompanies: () => dispatch(actions.user.getAllUserCompanies()),
    getAssignedCompanies: (userId) => dispatch(actions.user.getAssignedCompanies(userId)),
    getUserEstablishmentById: (establishmentId) => dispatch(actions.user.getEstablishmentById(establishmentId)),
    editUserEstablishmentById: (establishmentId, establishmentType, data, successCallback, errorCallback) =>
        dispatch(actions.user.editEstablishmentById(establishmentId, establishmentType, data, successCallback, errorCallback)),
    getEstablishments: (companyId, page, pageSize) => dispatch(actions.user.getEstablishments(companyId, page, pageSize)),
    getEstablishmentsByCompanyId: (companyId) => dispatch(actions.user.getUserEstablishmentsByCompany(companyId)),
    getSurrogateEstablishments: (page, pageSize) => dispatch(actions.user.getSurrogateEstablishments(page, pageSize)),
    deleteEstablishmentById: (establishmentId, callback) =>
        dispatch(actions.user.deleteUserEstablishmentById(establishmentId, callback)),
    resetProfessionalUserSearch: () => dispatch(actions.user.resetProfessionalUserSearch()),
    resetSelectedEstablishment: () => dispatch(actions.user.resetSelectedEstablishment()),
    resetUserEstablishments: () => dispatch(actions.user.resetUserEstablishments()),
    selectEstablishment: (establishment) => dispatch(actions.user.selectEstablishment(establishment)),
    selectLot: (lot) => dispatch(actions.user.selectLot(lot)),
    getLotsByEstablishment: (establishmentIds) => dispatch(actions.user.getLotsByEstablishment(establishmentIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(EstablishmentTable)));