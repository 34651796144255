import React, { useState } from 'react';
import {reduxForm} from "redux-form";
import {formValidators} from "../../../../util";
import {Button} from "reactstrap";
import { withTranslation } from "react-i18next";
import PasswordInput from "../../../../shared/components/cv/PasswordInput";
import CellphoneKeyIcon from "mdi-react/CellphoneKeyIcon";
import { connect } from "react-redux";
import walletActions from '../../../../redux/actions/walletActions';
import { useAlert } from "react-alert";
import { withRouter } from 'react-router-dom';

const SecretWordAndPinForm = (props) => {

    const [showSecret, setShowSecret] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [secretError, setSecretError] = useState();
    const [pinError, setPinError] = useState();
    const { handleSubmit, t, mnemonic } = props;

    const alert = useAlert();

    const onSubmit = ({secret_word, secret_word_confirmation, pin, pin_confirmation}) => {
        if(secret_word === secret_word_confirmation && pin === pin_confirmation) {
            setSecretError(null);
            setPinError(null);
            props.createWallet(mnemonic, secret_word, pin, () => {
                props.history.push('/');
                alert.success(t('wallet.generate_secret_and_pin.wallet_created_successfully'))
            })
        }
        else {
            if(secret_word !== secret_word_confirmation) setSecretError("secrets_not_match");
            else setSecretError(null);
            if(pin !== pin_confirmation) setPinError("pins_not_match");
            else setPinError(null);
        }
    };

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <PasswordInput name="secret_word" show={showSecret} setShow={() => setShowSecret(!showSecret)}
                           label={t('wallet.generate_secret_and_pin.form.secret_word')}
                           placeholder={t('wallet.generate_secret_and_pin.form.secret_word')}/>

            <PasswordInput name="secret_word_confirmation" show={showSecret} setShow={() => setShowSecret(!showSecret)}
                           label={t('wallet.generate_secret_and_pin.form.confirm_your_secret_word')}
                           placeholder={t('wallet.generate_secret_and_pin.form.confirm_your_secret_word')}/>

            <div className="error-container" style={{height: '20px', justifyContent: 'flex-start', marginBottom: '20px'}}>
                {
                    secretError &&
                    <span className="error-message">{t(`errors.${secretError}`)}</span>
                }
            </div>

            <PasswordInput name="pin" show={showPin} setShow={() => setShowPin(!showPin)}
                           label={t('wallet.generate_secret_and_pin.form.pin')}
                           placeholder={t('wallet.generate_secret_and_pin.form.pin')}
                           icon={<CellphoneKeyIcon/>}
            />

            <PasswordInput name="pin_confirmation" show={showPin} setShow={() => setShowPin(!showPin)}
                           label={t('wallet.generate_secret_and_pin.form.confirm_your_pin')}
                           placeholder={t('wallet.generate_secret_and_pin.form.confirm_your_pin')}
                           icon={<CellphoneKeyIcon/>}
            />

            <div className="error-container" style={{height: '20px', justifyContent: 'flex-start', marginBottom: '20px'}}>
                {
                    pinError &&
                    <span className="error-message">{t(`errors.${pinError}`)}</span>
                }
            </div>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                <Button className="btn btn-primary" type="submit" disabled={props.loginPending}>
                    <span>{t('common.accept')}</span>
                </Button>
            </div>
        </form>
    )
};

const mapDispatchToProps = (dispatch) => ({
    createWallet: (mnemonic, secretWord, pin, callback) =>  dispatch(walletActions.createWallet(mnemonic, secretWord, pin, callback))
});

export default connect(null, mapDispatchToProps)(reduxForm({form: 'secret_word_and_pin_form',})(withTranslation('common')(withRouter (SecretWordAndPinForm))))