import {
    FILTER_ANIMALS_RESPONSE,
    GET_ANIMAL_PAGE_RESPONSE,
    GET_ANIMAL_PAGE_FOR_DOWNLOAD_RESPONSE,
    SEARCH_ANIMALS_RESPONSE,
    UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_RESPONSE,
    GET_USER_ANIMAL_BY_ID_RESPONSE,
    RESET_ANIMAL_DETAILS,
    GET_ANIMAL_PAGE_BY_ESTABLISHMENT_RESPONSE,
    ADD_COW_RESPONSE,
    EDIT_COW_RESPONSE
} from "../actions/animalActions";
import {RESET_SELECTED_ESTABLISHMENT} from "../actions/userActions";

const initialState = {
    animals: {
        results: [],
        page: 1,
        totalPages: 0,
        total: 0,
        pageSize: undefined
    },
    animalsForDownload: {
        results: [],
        page: 1,
        totalPages: 0,
        total: 0,
        pageSize: undefined
    },
    massiveLoad: [],
    animalDetails: undefined
};

const animalReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ANIMAL_PAGE_RESPONSE:
        case SEARCH_ANIMALS_RESPONSE:
        case FILTER_ANIMALS_RESPONSE:
        case GET_ANIMAL_PAGE_BY_ESTABLISHMENT_RESPONSE:
            return {
                ...state,
                animals: action.response
            };
        case GET_ANIMAL_PAGE_FOR_DOWNLOAD_RESPONSE:
            return {
                ...state,
                animalsForDownload: action.response
            };
        case UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_RESPONSE:
            return {
                ...state,
                massiveLoad: action.response
            };
        case ADD_COW_RESPONSE:
            return {
                ...state,
                animals: action.response,
            };
        case EDIT_COW_RESPONSE:
            return {
                ...state,
                animals: action.response,
            };
        case GET_USER_ANIMAL_BY_ID_RESPONSE:
            return {
                ...state,
                animalDetails: action.response
            };
        case RESET_ANIMAL_DETAILS:
            return {
                ...state,
                animalDetails: undefined
            };
        case RESET_SELECTED_ESTABLISHMENT:
            return {
                ...state,
                animals: initialState.animals
            };
        default:
            return state
    }
};
export default animalReducer;