import {
    GET_USER,
    GET_USER_RESPONSE,
    GET_USER_ERROR,
    GET_USER_PROFILE,
    GET_USER_PROFILE_RESPONSE,
    GET_USER_PROFILE_ERROR,
    GET_USER_ROLES,
    GET_USER_ROLES_RESPONSE,
    GET_USER_ROLES_ERROR,
    GET_USER_ACCOUNTS,
    GET_USER_ACCOUNTS_RESPONSE,
    GET_USER_ACCOUNTS_ERROR,
    GET_USER_ESTABLISHMENTS,
    GET_USER_ESTABLISHMENTS_RESPONSE,
    GET_USER_ESTABLISHMENTS_ERROR,
    ADD_ESTABLISHMENT_TO_COMPANY,
    ADD_ESTABLISHMENT_TO_COMPANY_RESPONSE,
    ADD_ESTABLISHMENT_TO_COMPANY_ERROR,
    ADD_USER_COMPANY,
    ADD_USER_COMPANY_RESPONSE,
    ADD_USER_COMPANY_ERROR,
    EDIT_USER_DATA,
    EDIT_USER_DATA_RESPONSE,
    EDIT_USER_DATA_ERROR,
    EDIT_USER_ESTABLISHMENT_BY_ID,
    EDIT_USER_ESTABLISHMENT_BY_ID_RESPONSE,
    EDIT_USER_ESTABLISHMENT_BY_ID_ERROR,
    EDIT_USER_COMPANY,
    EDIT_USER_COMPANY_RESPONSE,
    EDIT_USER_COMPANY_ERROR,
    GET_PROFESSIONAL_USER_BY_DNI,
    GET_PROFESSIONAL_USER_BY_DNI_RESPONSE,
    GET_PROFESSIONAL_USER_BY_DNI_ERROR,
    GET_PROFESSIONALS_BY_ESTABLISHMENT,
    GET_PROFESSIONALS_BY_ESTABLISHMENT_RESPONSE,
    GET_PROFESSIONALS_BY_ESTABLISHMENT_ERROR,
    ADD_PROFESSIONAL_TO_ESTABLISHMENT,
    ADD_PROFESSIONAL_TO_ESTABLISHMENT_RESPONSE,
    ADD_PROFESSIONAL_TO_ESTABLISHMENT_ERROR,
    GET_SURROGATE_USER_BY_DNI,
    GET_SURROGATE_USER_BY_DNI_RESPONSE,
    GET_SURROGATE_USER_BY_DNI_ERROR,
    ADD_SURROGATE_TO_COMPANY, ADD_SURROGATE_TO_COMPANY_RESPONSE, ADD_SURROGATE_TO_COMPANY_ERROR,
} from "../actions/userActions";
import {
    LOGIN_REQUEST, LOGIN_RESPONSE, REGISTER_ERROR, LOGIN_ERROR,
    REGISTER_REQUEST, REGISTER_RESPONSE,
    CONFIRM_EMAIL, CONFIRM_EMAIL_RESPONSE, CONFIRM_EMAIL_ERROR,
    RECOVER_PASSWORD,
    RECOVER_PASSWORD_RESPONSE,
    RECOVER_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_RESPONSE
} from "../actions/sessionActions";

import {
    UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD,
    UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_RESPONSE,
    UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_ERROR,
    ACCEPT_MASSIVE_ANIMALS_UPLOAD,
    ACCEPT_MASSIVE_ANIMALS_UPLOAD_ERROR, ACCEPT_MASSIVE_ANIMALS_UPLOAD_RESPONSE,
    GET_ANIMAL_PAGE, GET_ANIMAL_PAGE_RESPONSE, GET_ANIMAL_PAGE_ERROR,
    GET_ANIMAL_PAGE_BY_ESTABLISHMENT, GET_ANIMAL_PAGE_BY_ESTABLISHMENT_RESPONSE, GET_ANIMAL_PAGE_BY_ESTABLISHMENT_ERROR,
    ADD_COW, ADD_COW_RESPONSE, ADD_COW_ERROR,
    EDIT_COW, EDIT_COW_RESPONSE, EDIT_COW_ERROR
} from "../actions/animalActions"

import {
    UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD,
    UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_RESPONSE,
    UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_ERROR,
    ACCEPT_MASSIVE_EVENTS_UPLOAD,
    ACCEPT_MASSIVE_EVENTS_UPLOAD_RESPONSE,
    ACCEPT_MASSIVE_EVENTS_UPLOAD_ERROR
} from "../actions/eventActions";

const initialState = {
    loginPending: false,
    loginError: false,
    loginErrorMessage: '',

    registerPending: false,
    registerError: false,
    registerErrorMessage: '',

    confirmEmailPending: false,
    confirmEmailError: false,
    confirmEmailErrorMessage: '',
    emailConfirmedSuccessful: false,

    resetPasswordPending: false,
    resetPasswordError: false,
    resetPasswordErrorMessage: '',
    resetPasswordSentSuccessful: false,

    recoverPasswordPending: false,
    recoverPasswordError: false,
    recoverPasswordErrorMessage: '',
    recoverPasswordSentSuccessful: false,

    getUserPending: false,
    getUserError: false,
    getUserErrorMessage: '',

    getProfilePending: false,
    getProfileError: false,
    getProfileErrorMessage: '',

    getRolesPending: false,
    getRolesError: false,
    getRolesErrorMessage: '',

    uploadFileForMassiveAnimalsLoadPending: false,
    uploadFileForMassiveAnimalsLoadError: false,
    uploadFileForMassiveAnimalsLoadErrorMessage: '',

    uploadFileForMassiveEventsLoadPending: false,
    uploadFileForMassiveEventsLoadError: false,
    uploadFileForMassiveEventsLoadErrorMessage: '',

    acceptMassiveAnimalsLoadPending: false,
    acceptMassiveAnimalsLoadError: false,
    acceptMassiveAnimalsLoadErrorMessage: '',

    getAnimalPagePending: false,
    getAnimalPageError: false,
    getAnimalPageErrorMessage: '',

    getUserCompaniesPending: false,
    getUserCompaniesError: false,
    getUserCompaniesErrorMessage: '',

    getUserEstablishmentsPending: false,
    getUserEstablishmentsError: false,
    getUserEstablishmentsErrorMessage: '',

    addEstablishmentToCompanyPending: false,
    addEstablishmentToCompanyError: false,
    addEstablishmentToCompanyErrorMessage: '',

    addCowPending: false,
    addCowError: false,
    addCowErrorMessage: '',

    addUserCompanyPending: false,
    addUserCompanyError: false,
    addUserCompanyErrorMessage: '',

    editUserPending: false,
    editUserError: false,
    editUserErrorMessage: '',

    editUserEstablishmentByIdPending: false,
    editUserEstablishmentByIdError: false,
    editUserEstablishmentByIdErrorMessage: '',

    editCowPending: false,
    editCowError: false,
    editCowErrorMessage: '',

    editUserCompanyPending: false,
    editUserCompanyError: false,
    editUserCompanyErrorMessage: '',

    getProfessionalUserByDniPending: false,
    getProfessionalUserByDniError: false,
    getProfessionalUserByDniErrorMessage: '',

    getProfessionalsByEstablishmentPending: false,
    getProfessionalsByEstablishmentError: false,
    getProfessionalsByEstablishmentMessage: '',

    addProfessionalToEstablishmentPending: false,
    addProfessionalToEstablishmentError: false,
    addProfessionalToEstablishmentMessage: '',

    getSurrogateUserByDniPending: false,
    getSurrogateUserByDniError: false,
    getSurrogateUserByDniErrorMessage: '',

    getSurrogatesByCompanyPending: false,
    getSurrogatesByCompanyError: false,
    getSurrogatesByCompanyMessage: '',

    addSurrogateToCompanyPending: false,
    addSurrogateToCompanyError: false,
    addSurrogateToCompanyMessage: '',

    acceptMassiveEventsLoadPending: false,
    acceptMassiveEventsLoadError: false,
    acceptMassiveEventsLoadErrorMessage: '',

};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return { ...state, loginPending: true, loginError: false, loginErrorMessage: ''};
        case LOGIN_RESPONSE:
            return { ...state, loginPending: false, loginError: false, loginErrorMessage: ''};
        case LOGIN_ERROR:
            return { ...state, loginPending: false, loginError: true, loginErrorMessage: action.message};
        case REGISTER_REQUEST:
            return { ...state, registerPending: true, registerError: false, registerErrorMessage: ''};
        case REGISTER_RESPONSE:
            return { ...state, registerPending: false, registerError: false, registerErrorMessage: ''};
        case REGISTER_ERROR:
            return { ...state, registerPending: false, registerError: true, registerErrorMessage: action.message};
        case CONFIRM_EMAIL:
            return {
                ...state,
                confirmEmailPending: true,
                confirmEmailError: false,
                confirmEmailErrorMessage: '',
                emailConfirmedSuccessful: false
            };
        case CONFIRM_EMAIL_RESPONSE:
            return {
                ...state,
                confirmEmailPending: false,
                confirmEmailError: false,
                confirmEmailErrorMessage: '',
                emailConfirmedSuccessful: true
            };
        case CONFIRM_EMAIL_ERROR:
            return {
                ...state,
                confirmEmailPending: false,
                confirmEmailError: true,
                confirmEmailErrorMessage: action.message,
                emailConfirmedSuccessful: false
            };
        case RECOVER_PASSWORD:
            return {
                ...state,
                recoverPasswordPending: true,
                recoverPasswordError: false,
                recoverPasswordErrorMessage: '',
                recoverPasswordSentSuccessful: false
            };
        case RECOVER_PASSWORD_RESPONSE:
            return {
                ...state,
                recoverPasswordPending: false,
                recoverPasswordError: false,
                recoverPasswordErrorMessage: '',
                recoverSentSuccessful: true
            };
        case RECOVER_PASSWORD_ERROR:
            return {
                ...state,
                recoverPasswordPending: false,
                recoverPasswordError: true,
                recoverPasswordErrorMessage: action.message,
                recoverSentSuccessful: false
            };
        case RESET_PASSWORD:
            return {
                ...state,
                resetPasswordPending: true,
                resetPasswordError: false,
                resetPasswordErrorMessage: '',
                resetPasswordSentSuccessful: false
            };
        case RESET_PASSWORD_RESPONSE:
            return {
                ...state,
                resetPasswordPending: false,
                resetPasswordError: false,
                resetPasswordErrorMessage: '',
                resetPasswordSentSuccessful: true
            };
        case RESET_PASSWORD_ERROR:
            //TODO fix value for resetPasswordSentSuccessful
            return {
                ...state,
                resetPasswordPending: false,
                resetPasswordError: true,
                resetPasswordErrorMessage: action.message,
                resetPasswordSentSuccessful: true
            };
        case GET_USER:
            return { ...state, getUserPending: true, getUserError: false, getUserErrorMessage: ''};
        case GET_USER_RESPONSE:
            return { ...state, getUserPending: false, getUserError: false, getUserErrorMessage: ''};
        case GET_USER_ERROR:
            return { ...state, getUserPending: false, getUserError: true, getUserErrorMessage: action.message};
        case GET_USER_PROFILE:
            return { ...state, getProfilePending: true, getProfileError: false, getProfileErrorMessage: '' };
        case GET_USER_PROFILE_RESPONSE:
            return { ...state, getProfilePending: false, getProfileError: false, getProfileErrorMessage: '' };
        case GET_USER_PROFILE_ERROR:
            return { ...state, getProfilePending: false, getProfileError: true, getProfileErrorMessage: action.message };
        case GET_USER_ROLES:
            return {...state, getRolesPending: true, getRolesError: false, getRolesErrorMessage: ''};
        case GET_USER_ROLES_RESPONSE:
            return {...state, getRolesPending: false, getRolesError: false, getRolesErrorMessage: ''};
        case GET_USER_ROLES_ERROR:
            return {...state, getRolesPending: false, getRolesError: true, getRolesErrorMessage: action.message};
        case UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD:
            return {
                ...state,
                uploadFileForMassiveAnimalsLoadPending: true,
                uploadFileForMassiveAnimalsLoadError: false,
                uploadFileForMassiveAnimalsLoadErrorMessage: ''
            };
        case UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_RESPONSE:
            return {
                ...state,
                uploadFileForMassiveAnimalsLoadPending: false,
                uploadFileForMassiveAnimalsLoadError: false,
                uploadFileForMassiveAnimalsLoadErrorMessage: ''
            };
        case UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_ERROR:
            return {
                ...state,
                uploadFileForMassiveAnimalsLoadPending: false,
                uploadFileForMassiveAnimalsLoadError: true,
                uploadFileForMassiveAnimalsLoadErrorMessage: action.message
            };
        case UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD:
            return {
                ...state,
                uploadFileForMassiveEventsLoadPending: true,
                uploadFileForMassiveEventsLoadError: false,
                uploadFileForMassiveEventsLoadErrorMessage: ''
            };
        case UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_RESPONSE:
            return {
                ...state,
                uploadFileForMassiveEventsLoadPending: false,
                uploadFileForMassiveEventsLoadError: false,
                uploadFileForMassiveEventsLoadErrorMessage: ''
            };
        case UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD_ERROR:
            return {
                ...state,
                uploadFileForMassiveEventsLoadPending: false,
                uploadFileForMassiveEventsLoadError: true,
                uploadFileForMassiveEventsLoadErrorMessage: action.message
            };
        case ACCEPT_MASSIVE_ANIMALS_UPLOAD:
            return {
                ...state,
                acceptMassiveAnimalsLoadPending: true,
                acceptMassiveAnimalsLoadError: false,
                acceptMassiveAnimalsLoadErrorMessage: ''
            };
        case ACCEPT_MASSIVE_ANIMALS_UPLOAD_RESPONSE:
            return {
                ...state,
                acceptMassiveAnimalsLoadPending: false,
                acceptMassiveAnimalsLoadError: false,
                acceptMassiveAnimalsLoadErrorMessage: ''
            };
        case ACCEPT_MASSIVE_ANIMALS_UPLOAD_ERROR:
            return {
                ...state,
                acceptMassiveAnimalsLoadPending: false,
                acceptMassiveAnimalsLoadError: true,
                acceptMassiveAnimalsLoadErrorMessage: action.message
            };
        case GET_ANIMAL_PAGE:
        case GET_ANIMAL_PAGE_BY_ESTABLISHMENT:
            return {
                ...state,
                getAnimalPagePending: true,
                getAnimalPageError: false,
                getAnimalPageErrorMessage: ''
            };
        case GET_ANIMAL_PAGE_RESPONSE:
        case GET_ANIMAL_PAGE_BY_ESTABLISHMENT_RESPONSE:
            return {
                ...state,
                getAnimalPagePending: false,
                getAnimalPageError: false,
                getAnimalPageErrorMessage: ''
            };
        case GET_ANIMAL_PAGE_ERROR:
        case GET_ANIMAL_PAGE_BY_ESTABLISHMENT_ERROR:
            return {
                ...state,
                getAnimalPagePending: false,
                getAnimalPageError: true,
                getAnimalPageErrorMessage: action.message
            };
        case GET_USER_ACCOUNTS:
            return {
                ...state,
                getUserCompaniesPending: true,
                getUserCompaniesError: false,
                getUserCompaniesErrorMessage: ''
            };
        case GET_USER_ACCOUNTS_RESPONSE:
            return {
                ...state,
                getUserCompaniesPending: false,
                getUserCompaniesError: false,
                getUserCompaniesErrorMessage: ''
            };
        case GET_USER_ACCOUNTS_ERROR:
            return {
                ...state,
                getUserCompaniesPending: false,
                getUserCompaniesError: true,
                getUserCompaniesErrorMessage: action.message
            };
        case GET_USER_ESTABLISHMENTS:
            return {
                ...state,
                getUserEstablishmentsPending: true,
                getUserEstablishmentsError: false,
                getUserEstablishmentsErrorMessage: ''
            };
        case GET_USER_ESTABLISHMENTS_RESPONSE:
            return {
                ...state,
                getUserEstablishmentsPending: false,
                getUserEstablishmentsError: false,
                getUserEstablishmentsErrorMessage: ''
            };
        case GET_USER_ESTABLISHMENTS_ERROR:
            return {
                ...state,
                getUserEstablishmentsPending: false,
                getUserEstablishmentsError: true,
                getUserEstablishmentsErrorMessage: action.message
            };
        case ADD_ESTABLISHMENT_TO_COMPANY:
            return {
                ...state,
                addEstablishmentToCompanyPending: true,
                addEstablishmentToCompanyError: false,
                addEstablishmentToCompanyErrorMessage: '',
            };
        case ADD_ESTABLISHMENT_TO_COMPANY_RESPONSE:
            return {
                ...state,
                addEstablishmentToCompanyPending: false,
                addEstablishmentToCompanyError: false,
                addEstablishmentToCompanyErrorMessage: '',
            };
        case ADD_ESTABLISHMENT_TO_COMPANY_ERROR:
            return {
                ...state,
                addEstablishmentToCompanyPending: false,
                addEstablishmentToCompanyError: true,
                addEstablishmentToCompanyErrorMessage: action.message,
            };
        case ADD_COW:
            return {
                ...state,
                addCowPending: true,
                addCowError: false,
                addCowErrorMessage: '',
            };
        case ADD_COW_RESPONSE:
            return {
                ...state,
                addCowPending: false,
                addCowError: false,
                addCowErrorMessage: action.message
            };
        case ADD_COW_ERROR:
            return {
                ...state,
                addCowPending: false,
                addCowError: true,
                addCowErrorMessage: action.message,
            };
        case ADD_USER_COMPANY:
            return {
                ...state,
                addUserCompanyPending: true,
                addUserCompanyError: false,
                addUserCompanyErrorMessage: '',
            };
        case ADD_USER_COMPANY_RESPONSE:
            return {
                ...state,
                addUserCompanyPending: false,
                addUserCompanyError: false,
                addUserCompanyErrorMessage: '',
            };
        case ADD_USER_COMPANY_ERROR:
            return {
                ...state,
                addUserCompanyPending: false,
                addUserCompanyError: true,
                addUserCompanyErrorMessage: action.message,
            };
        case EDIT_USER_DATA:
            return {
                ...state,
                editUserPending: true,
                editUserError: false,
                editUserErrorMessage: '',
            };
        case EDIT_USER_DATA_RESPONSE:
            return {
                ...state,
                editUserPending: false,
                editUserError: false,
                editUserErrorMessage: '',
            };
        case EDIT_USER_DATA_ERROR:
            return {
                ...state,
                editUserPending: false,
                editUserError: true,
                editUserErrorMessage: action.message,
            };
        case EDIT_USER_ESTABLISHMENT_BY_ID:
            return {
                ...state,
                editUserEstablishmentByIdPending: true,
                editUserEstablishmentByIdError: false,
                editUserEstablishmentByIdErrorMessage: ''
            };
        case EDIT_USER_ESTABLISHMENT_BY_ID_RESPONSE:
            return {
                ...state,
                editUserEstablishmentByIdPending: false,
                editUserEstablishmentByIdError: false,
                editUserEstablishmentByIdErrorMessage: ''
            };
        case EDIT_USER_ESTABLISHMENT_BY_ID_ERROR:
            return {
                ...state,
                editUserEstablishmentByIdPending: false,
                editUserEstablishmentByIdError: true,
                editUserEstablishmentByIdErrorMessage: action.message
            };
        case EDIT_COW:
            return {
                ...state,
                editCowPending: true,
                editCowError: false,
                editCowErrorMessage: '',
            };
        case EDIT_COW_RESPONSE:
            return {
                ...state,
                editCowPending: false,
                editCowError: false,
                editCowErrorMessage: '',
            };
        case EDIT_COW_ERROR:
            return {
                ...state,
                editCowPending: false,
                editCowError: true,
                editCowErrorMessage: action.message,
            };
        case EDIT_USER_COMPANY:
            return {
                ...state,
                editUserCompanyPending: true,
                editUserCompanyError: false,
                editUserCompanyErrorMessage: '',
            };
        case EDIT_USER_COMPANY_RESPONSE:
            return {
                ...state,
                editUserCompanyPending: false,
                editUserCompanyError: false,
                editUserCompanyErrorMessage: '',
            };
        case EDIT_USER_COMPANY_ERROR:
            return {
                ...state,
                editUserCompanyPending: false,
                editUserCompanyError: true,
                editUserCompanyErrorMessage: action.message,
            };
        case GET_PROFESSIONAL_USER_BY_DNI:
            return {
                ...state,
                getProfessionalUserByDniPending: true,
                getProfessionalUserByDniError: false,
                getProfessionalUserByDniErrorMessage: '',
            };
        case GET_PROFESSIONAL_USER_BY_DNI_RESPONSE:
            return {
                ...state,
                getProfessionalUserByDniPending: false,
                getProfessionalUserByDniError: false,
                getProfessionalUserByDniErrorMessage: '',
            };
        case GET_PROFESSIONAL_USER_BY_DNI_ERROR:
            return {
                ...state,
                getProfessionalUserByDniPending: false,
                getProfessionalUserByDniError: true,
                getProfessionalUserByDniErrorMessage: action.message,
            };
        case GET_PROFESSIONALS_BY_ESTABLISHMENT:
            return {
                ...state,
                getProfessionalsByEstablishmentPending: true,
                getProfessionalsByEstablishmentError: false,
                getProfessionalsByEstablishmentMessage: '',
            };
        case GET_PROFESSIONALS_BY_ESTABLISHMENT_RESPONSE:
            return {
                ...state,
                getProfessionalsByEstablishmentPending: false,
                getProfessionalsByEstablishmentError: false,
                getProfessionalsByEstablishmentMessage: '',
            };
        case GET_PROFESSIONALS_BY_ESTABLISHMENT_ERROR:
            return {
                ...state,
                getProfessionalsByEstablishmentPending: false,
                getProfessionalsByEstablishmentError: true,
                getProfessionalsByEstablishmentMessage: action.message,
            };
        case ADD_PROFESSIONAL_TO_ESTABLISHMENT:
            return {
                ...state,
                addProfessionalToEstablishmentPending: true,
                addProfessionalToEstablishmentError: false,
                addProfessionalToEstablishmentMessage: ''
            };
        case ADD_PROFESSIONAL_TO_ESTABLISHMENT_RESPONSE:
            return {
                ...state,
                addProfessionalToEstablishmentPending: false,
                addProfessionalToEstablishmentError: false,
                addProfessionalToEstablishmentMessage: ''
            };
        case ADD_PROFESSIONAL_TO_ESTABLISHMENT_ERROR:
            return {
                ...state,
                addProfessionalToEstablishmentPending: false,
                addProfessionalToEstablishmentError: true,
                addProfessionalToEstablishmentMessage: action.message
            };
        case GET_SURROGATE_USER_BY_DNI:
            return {
                ...state,
                getSurrogateUserByDniPending: true,
                getSurrogateUserByDniError: false,
                getSurrogateUserByDniErrorMessage: ''
            };
        case GET_SURROGATE_USER_BY_DNI_RESPONSE:
            return {
                ...state,
                getSurrogateUserByDniPending: false,
                getSurrogateUserByDniError: false,
                getSurrogateUserByDniErrorMessage: ''
            };
        case GET_SURROGATE_USER_BY_DNI_ERROR:
            return {
                ...state,
                getSurrogateUserByDniPending: false,
                getSurrogateUserByDniError: true,
                getSurrogateUserByDniErrorMessage: action.message
            };
        case ADD_SURROGATE_TO_COMPANY:
            return {
                ...state,
                addSurrogateToCompanyPending: true,
                addSurrogateToCompanyError: false,
                addSurrogateToCompanyMessage: '',
            };
        case ADD_SURROGATE_TO_COMPANY_RESPONSE:
            return {
                ...state,
                addSurrogateToCompanyPending: false,
                addSurrogateToCompanyError: false,
                addSurrogateToCompanyMessage: '',
            };
        case ADD_SURROGATE_TO_COMPANY_ERROR:
            return {
                ...state,
                addSurrogateToCompanyPending: false,
                addSurrogateToCompanyError: true,
                addSurrogateToCompanyMessage: action.message,
            };
        case ACCEPT_MASSIVE_EVENTS_UPLOAD:
            return {
                ...state,
                acceptMassiveEventsLoadPending: true,
                acceptMassiveEventsLoadError: false,
                acceptMassiveEventsLoadErrorMessage: '',
            };
        case ACCEPT_MASSIVE_EVENTS_UPLOAD_RESPONSE:
            return {
                ...state,
                acceptMassiveEventsLoadPending: false,
                acceptMassiveEventsLoadError: false,
                acceptMassiveEventsLoadErrorMessage: '',
            };
        case ACCEPT_MASSIVE_EVENTS_UPLOAD_ERROR:
            return {
                ...state,
                acceptMassiveEventsLoadPending: false,
                acceptMassiveEventsLoadError: true,
                acceptMassiveEventsLoadErrorMessage: action.message,
            };
        default:
            return state
    }
};
export default uiReducer;