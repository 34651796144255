import React, {useEffect, useState} from 'react'
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CVTable from "../../../Tables/CVTable/CVTable";
import { withTranslation } from "react-i18next";
import eventActions from '../../../../redux/actions/eventActions';
import { connect } from 'react-redux';
import FileUploadOutlineIcon from "mdi-react/FileUploadOutlineIcon";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import FileUploadModal from "../../../../shared/components/cv/FileUploadModal";
import { withRouter } from 'react-router-dom';
import { EVENT_TYPES } from '../../../../constants/index';
import {parse_UTC_date_to_DD_MM_YYYY, filterEstablishments, filter, useIsMount} from "../../../../util";
import {Tooltip} from "react-tippy";
import FileDownloadOutlineIcon from "mdi-react/FileDownloadOutlineIcon";
import settings from "../../../../util/settings";
import actions from '../../../../redux/actions/index';
import {renderTableSelectors} from '../EventTableSelectors';

const DentitionTable = (props) => {
    const { t, events, total, page, getPage, totalPages, pageSize, allCompanies,
      selectedEstablishment, allEstablishments, allEstablishmentsByCompany } = props;
    const [fileUploadModalIsOpen, setFileUploadModalOpen] = useState(false);
    const templatesUrl = settings.templatesUrl;
    const isMount = useIsMount();

    useEffect(() => {
      let ids = selectedEstablishment.map(el => ( el.value ));
      if(ids.length === 0) {
          ids = null
      }
      getPage(ids, 1, 10)
  }, [selectedEstablishment, allEstablishmentsByCompany, allEstablishments]);

  useEffect(()=>{
    const selected = filterEstablishments(selectedEstablishment, allEstablishmentsByCompany)
    props.selectEstablishment(selected);
  },
  [allEstablishmentsByCompany])

  useEffect(()=>{
    // select all lots from selected commpany
    const lots = props.allLotsByEstablishment.map(el => ( {value: el._id, label: el.name} ));
    props.selectLot(lots);
  },[props.allLotsByEstablishment])

  useEffect(()=>{
    // refresh selected establishments
    const establishmentsFromselectedCompany = filter(props.establishmentsToAdd, props.selectedEstablishment)
    props.selectEstablishment([...props.selectedEstablishment, ...establishmentsFromselectedCompany])
  },[props.establishmentsToAdd])

  useEffect(()=>{
    const e = allEstablishmentsByCompany.map(el => ( {value: el._id, label: el.name} ));
    if(allCompanies.length === props.selectedCompany.length && !isMount){
      props.selectEstablishment(e)
    }
  },[allEstablishmentsByCompany])

  const getEstablishmentPage = (page, pageSize) => {
    let ids = selectedEstablishment.map(el => ( el.value ));
    getPage(ids, page, pageSize)
  };

    const renderValueDefault = ({value}) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
              {value}
          </div>
        )
    };

    const onLeftSelectChange = (e) => {
      props.getEstablishmentsByCompanyId(e.value);
    }

    const onRightSelectChange = (e) => {
        props.selectEstablishment([e]);
    }

    const renderDate = ({value}) => {
          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value ?
                  parse_UTC_date_to_DD_MM_YYYY(value):
                  "-"
                }
            </div>
          )
    };

    const renderButtons = ({ dependentValues }) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
              <MagnifyIcon className={"button"} style={{marginLeft: "10px"}}
                           onClick={() => props.history.push('/events/dentition/details', {event: dependentValues})}/>
          </div>
        )
    };

    const renderIconsForNewEvent = () => (
      <div>
          <Tooltip
            title="Descargar plantilla"
            position="top"
            trigger="mouseenter"
            animation="scale"
            duration="600"
            size="small"
            theme="transparent"
            distance="0"
          >
              <a href={templatesUrl + "modelo evento denticion.xlsx"} download><FileDownloadOutlineIcon/></a>
          </Tooltip>
          <FileUploadOutlineIcon onClick={() => setFileUploadModalOpen(true)}/>
          <PlusCircleOutlineIcon onClick={() => props.history.push('/events/dentition/new_event')}/>
      </div>
    );

    const heads = [
        {
            key: 'earId',
            name: t('events.dentition.table.headers.caravan_id'),
            percentage: 50,
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'date',
            name: t('events.dentition.table.headers.date'),
            percentage: 25,
            minWidth: 210,
            formatter: renderDate
        },
        {
            key: 'value',
            name: 'Dentición',
            percentage: 25,
            minWidth: 210,
            formatter: renderValueDefault
        },
        {
            key: 'actions',
            name: t('events.table.headers.actions'),
            percentage: 25,
            minWidth: 135,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    return(
      <div>
          <CVTable
            CVSelect={renderTableSelectors}
            getPage={getEstablishmentPage}
            data={events}
            total={total}
            currentPage={page}
            totalPages={totalPages}
            pageSize={pageSize}
            heads={heads}
            hideFilters
            title={t('events.dentition.table.title')}
            SubtitleComponent={renderIconsForNewEvent}
            noDataLabel={t('events.dentition.table.no_data_label')}
          />
          <FileUploadModal title={t('events.modals.massive_upload.title')} isOpen={fileUploadModalIsOpen}
                           toggleModal={() => setFileUploadModalOpen(!fileUploadModalIsOpen)} acceptedFormats={".xls,.xlsx"}
                           leftSelectValues={props.allCompanies}
                           rightSelectValues={props.allEstablishmentsByCompany}
                           onLeftSelectChange={onLeftSelectChange}
                           onRightSelectChange={onRightSelectChange}
                           onSubmit={(file) => {
                            props.uploadFileForMassiveLoad(file.files, props.selectedEstablishment[0].value, undefined);
                            props.history.push('/events/dentition/massive_load');
                           }}/>
      </div>

    );
};

const mapStateToProps = (state) => ({
    establishmentsToAdd: state.user.establishmentsToAdd,
    selectedCompany: state.user.selectedCompany,
    selectedEstablishment: state.user.selectedEstablishment,
    allCompanies: state.user.allCompanies,
    allEstablishmentsByCompany: state.user.allEstablishmentsByCompany,
    allLotsByEstablishment: state.user.allLotsByEstablishment,
    events: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].events : [],
    total: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].total : 0,
    page: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].page : 1,
    totalPages: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].totalPages : 0 ,
    pageSize: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].pageSize : 0,
    establishmentId: state.user.selectedEstablishment != undefined ? state.user.selectedEstablishment._id : null
});

const mapDispatchToProps = (dispatch) => ({
  getLotsByEstablishment: (establishmentIds) => dispatch(actions.user.getLotsByEstablishment(establishmentIds)),
  selectLot: (lot) => dispatch(actions.user.selectLot(lot)),
  getEstablishmentsByCompanyId: (companyId) =>
      dispatch(actions.user.getUserEstablishmentsByCompany(companyId)),
  selectEstablishment: (establishment) => dispatch(actions.user.selectEstablishment(establishment)),
  getPage: (establishmentId, page, pageSize) => dispatch(eventActions.getEventsPage(establishmentId, page, pageSize, EVENT_TYPES.dentition)),
  uploadFileForMassiveLoad: (file, establishmentId, callback) =>
      dispatch(eventActions.uploadFileForMassiveEventLoad(file, establishmentId, EVENT_TYPES.dentition, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(DentitionTable)));