import api from '../../api/index';
import {
    UPLOAD_IMAGE
} from '../actions/imageActions';
import actions from '../actions';

const image = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {
        case UPLOAD_IMAGE:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.uploadImage(jwt, action.image)
                    .then(res => {
                        if(action.callback) action.callback(res);
                    })
                    .catch(err => {
                        dispatch(actions.image.uploadImageError(err))
                    })
            }));
            break;
    }
};

export default image;