import React,{ Fragment } from 'react';
import CompanySelector from '../../Layout/selectors/companySelector';
import EstablishmentSelector from '../../Layout/selectors/establishmentSelector';

export const renderTableSelectors = () => (
    <Fragment>
     <div>
      <CompanySelector/>
     </div>
     <div>
       <EstablishmentSelector />
     </div>
    </Fragment>
);