import React, {useEffect, useState} from 'react';
import {Col, Card, CardBody, Button, ButtonToolbar} from 'reactstrap';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Field, change, reduxForm} from 'redux-form';
import {connect} from "react-redux";
import MaskedInput from "react-text-mask";
import {CheckBoxField} from '../../../../shared/components/form/CheckBox';
import i18n from '../../../../translations/config/i18n';
import {useAlert} from "react-alert";
import CVButton from "../../../../shared/components/cv/CVButton";
import {formValidators, formMasks, subtract_x_years_to_date} from '../../../../util/index';
import { withRouter } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {renderDateInputField} from "../../../../shared/components/cv/CVDatePicker";
import renderSelectField from "../../../../../src/shared/components/form/Select";
import actions from "../../../../redux/actions/index";

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
    <div style={{width: '100%'}}>
        {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
        {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);

const validateDateRequired = formValidators.validateDateBetweenRequired(
    subtract_x_years_to_date(new Date(), 100), // 100 years ago date
    new Date(), // today date
);

const VaccinationEventForm = (props) => {
    VaccinationEventForm.propTypes = {
        t: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    const {t, reset, handleSubmit, onSubmit, establishmentProductiveSystems, editable,
        hideTitle, allAvailableProductiveSystems} = props;

    // const [productiveSystemSelected, setProductiveSystemSelected] = useState({});

    const [selectedEstablishmentId, setselectedEstablishmentId] = useState();

    const showErrors = (invalid) => {
        if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
    }

    const submitForm = (values) => {
        values.facilityId = props.selectedEstablishment[0].value;
        onSubmit({...values});
    };

    const changeSelectedCompany = (company) => {
        props.getEstablishmentsByCompanyId(company.value);
        props.changeFieldValue('establishment', {
          value: "",
          label:  t(`selectors.establishmentSelector.establishment`)
        });
    }

    const changeSelectedEstablishment = (estabishment) => {
        setselectedEstablishmentId(estabishment.value)
    }

    return (
        <Col md={12} lg={12}>
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Card>
                <CardBody>
                    <div className="card__title">
                        {!hideTitle && <h5 className="bold-text">{'EVENTO DE VACUNACIÓN'}</h5>}
                    </div>
                    <form className="form" onSubmit={handleSubmit(submitForm)}>

                    <div className="form__form-group">
                            <span className="form__form-group-label">{t('establishments.lots.company')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="company"
                                    component={renderSelectField}
                                    type="text"
                                    placeholder={t('establishments.lots.company')}
                                    onChange={changeSelectedCompany}
                                    options={
                                        props.allCompanies.map(el => ({
                                            value: el._id,
                                            label: el.name
                                        }))
                                    }
                                />
                            </div>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('establishments.lots.establishment')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="establishment"
                                    component={renderSelectField}
                                    type="text"
                                    placeholder={t('establishments.lots.establishment')}
                                    onChange={changeSelectedEstablishment}
                                    options={
                                        props.establishments.map(el => ({
                                            value: el._id,
                                            label: el.name
                                        }))
                                    }
                                />
                            </div>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.events.change_productive_system.caravan_id')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="caravan_id"
                                    component={renderField}
                                    validate={[formValidators.validateRequired, formValidators.validateCaravanIdRequired]}
                                    type="text"
                                    placeholder={t('cv-forms.events.change_productive_system.caravan_id')}
                                    mask={formMasks.caravanIdMask}
                                    normalize={(value) => value.toUpperCase()}
                                    disabled={!editable}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.events.change_productive_system.date')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="date"
                                    component={renderDateInputField}
                                    validate={[validateDateRequired]}
                                    disabled={!editable}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{'Comentarios'}</span>
                            <div className="form__form-group-field">
                                <Field
                                  name="comments"
                                  component={renderField}
                                  type="text"
                                  placeholder={'Comentarios'}
                                  disabled={!editable}
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar">
                            {
                                editable &&
                                <CVButton
                                    color="primary"
                                    type="submit"
                                    onClick={()=> showErrors(props.invalid)}
                                    pending={props.pending}>
                                        {t('common.accept')}
                                </CVButton>
                            }
                            {
                                editable &&
                                <Button type="button" onClick={() => props.history.push('/animals/table')}>
                                        {t('common.cancel')}
                                </Button>
                            }

                        </ButtonToolbar>
                    </form>
                </CardBody>
            </Card>
        </Col>)
};

const mapStateToProps = (state, props) => ({
    initialValues: props.initialValues || {},
    selectedEstablishment: state.user.selectedEstablishment,
    allCompanies: state.user.allCompanies,
    establishments: state.user.allEstablishmentsByCompany
});

const mapDispatchToProps = (dispatch) => ({
    changeFieldValue: (field, value) => dispatch(change('vaccination-event-form', field, value)),
    getAvailableProductiveSystems: () => dispatch(actions.constant.getAvailableProductiveSystems()),
    getEstablishmentsByCompanyId: (companyId) =>
        dispatch(actions.user.getUserEstablishmentsByCompany(companyId)),
    resetAllEstablishmentsByCompany: () => dispatch(actions.resetAllEstablishmentsByCompany())
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'vaccination-event-form',
    touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(withRouter(VaccinationEventForm))));