/*
*   The notification mapper is use for mapping either the body or the response on a notification request. It try to
*   decouple the notification middleware.
* */

const mapper =  {
    body: {
    },
    response: {
        getNotifications: (response) => ({
            notifications: mapNotifications(response.results),
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        searchNotifications: (response) => ({
            notifications: mapNotifications(response.results),
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        filterNotifications: (response) => ({
            notifications: mapNotifications(response.results),
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
    }
};

const mapAddressToString = (address) => {
    return `${address.streetName} ${address.number}, ${address.apartment}, ${address.postalCode}, ${address.city}, ${address.state}, ${address.country.name}`
};

const mapNotifications = (notifications) => {
    let customNotifications = [];

    notifications.forEach((notification) => {
        const customNotification = {
            ...notification,
            details: {
                ...notification.details,
                address: mapAddressToString(notification.details.address)
            }
        };
        customNotifications.push(customNotification)
    });
    return customNotifications;
};

export default mapper;