import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import cowTransparent from "../../../../shared/img/cow-transparent.gif";
import { connect } from "react-redux";
import {formValidators} from "../../../../util";
import sessionActions from "../../../../redux/actions/sessionActions";

class ResetPasswordForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showRepeatPassword: false,
    };

    this.showPassword = this.showPassword.bind(this);
    this.showRepeatPassword = this.showRepeatPassword.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  showRepeatPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showRepeatPassword: !prevState.showRepeatPassword }));
  }

  validateEqual = value => this.props.password === value ? undefined : 'passwords_not_match';
  minLength8 = formValidators.minLength(8);

  renderField = ({ input, placeholder, type, meta: { touched, error, warning } }) => (
      <div style={{width: '100%'}}>
        <input style={{backgroundColor: 'white'}} {...input} placeholder={placeholder} type={type}/>
        <div className="input-error-container">
          {touched && ((error && <span className="error-message">{this.props.t(`errors.${error}`)}</span>) || (warning && <span className="error-message">{warning}</span>))}
        </div>
      </div>
  );

  render() {
    const { handleSubmit, t } = this.props;
    const { showPassword, showRepeatPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('reset_password.password')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={this.renderField}
              validate={[formValidators.validateRequired, this.minLength8]}
              type={showPassword ? 'text' : 'password'}
              placeholder={t('reset_password.password')}
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('reset_password.repeatPassword')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="repeat_password"
              component={this.renderField}
              validate={[formValidators.validateRequired, this.validateEqual, this.minLength8]}
              type={showRepeatPassword ? 'text' : 'password'}
              placeholder={t('reset_password.repeatPassword')}
            />
            <button
              type="button"
              className={`form__form-group-button${showRepeatPassword ? ' active' : ''}`}
              onClick={e => this.showRepeatPassword(e)}
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="error-container">
          {
            this.props.resetPasswordError &&
            <span className="error-message">{t(`errors.${this.props.resetPasswordErrorMessage.code}`)}</span>
          }
        </div>
        <div className="account__btns">
          <Button className="btn btn-primary account__btn cow__btn" type="submit" disabled={this.props.resetPasswordPending}>
              {
                  this.props.resetPasswordPending ?
                      <img className="cow__loader button_loader" alt="cow" src={cowTransparent}/> :
                      <span>{t('reset_password.resetPassword')}</span>
              }
          </Button>
        </div>
      </form>
    );
  }
}
const selector = formValueSelector('reset_password_form');

const mapStateToProps = (state) => ({
    password: selector(state, 'password'),
    passwordRepeated: selector(state, 'repeat_password')
});

const mapDispatchToProps = (dispatch) => {
  return {
      //resetPassword: (token) => dispatch(sessionActions.confirmMailRequest(token)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(reduxForm({
  form: 'reset_password_form', // a unique identifier for this form
})(ResetPasswordForm)));
