import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import renderDropZoneField from '../../components/form/DropZone';
import FileExcelIcon from 'mdi-react/FileExcelIcon';
import SearchInput from "../../../shared/components/cv/SearchInput";
import EmailInput from "../../../shared/components/cv/EmailInput";
import renderSelectField from "../form/Select";
import actions from "../../../redux/actions/index";
import {formValidators} from '../../../util/index';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import _ from "lodash";

const FileUploadExtended = props => {
    const { handleSubmit, reset, t, acceptedFormats, searchPlaceholder, facilitySearch,
        leftSelectValues, rightSelectValues,
        onLeftSelectChange, onRightSelectChange, selectedEstablishment} = props;

    const search = value => {
        props.getFacilityByCode(value);
    };

    const sendMailToUnregistered = (email) => {
        if(props.selectedEstablishment.length > 0) {
            props.sendMailToUnregisteredFacility(props.selectedEstablishment[0].value, email);
            ToastsStore.success('Se ha enviando la invitacion a  ' + email)
        }else {
            ToastsStore.error('Debe seleccionar un establecimiento');
        }
    };

    return (
        <div className="add_new_professional">
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <SearchInput
                placeholder={searchPlaceholder}
                search={search}
            />
            {facilitySearch.fetched && !_.isEmpty(facilitySearch.res) && (
                <div className="add_new_professional-result">
                    <br/>
                    <div className="add_new_professional-result-name text-center">
                        <label style={{width: "100%"}}>
                            Los animales serán transferidos a: <br/>{facilitySearch.res.name}
                        </label>
                    </div>
                </div>
            )}
            {facilitySearch.fetched && _.isEmpty(facilitySearch.res) && (
                <div className="error_container">
                    <label>
                        {t(
                            "events.modals.massive_upload.search_error"
                        )}
                    </label>
                </div>
            )}
          {(
          <form className="form form--justify" onSubmit={handleSubmit}>
            <Field
              name="files"
              acceptedFormats={acceptedFormats}
              dropZoneDefaultImage={FileExcelIcon}
              dropZonePlaceHolder={t('cv-inputs.file_upload.drop_zone_placeholder')}
              component={renderDropZoneField}
            />

            <div className="modal_select"
                style={{flexDirection:'column'}}>
                <span className="form__form-group-label">{t('establishments.lots.company')}</span>
                <div >
                    <Field
                        name="company"
                        component={renderSelectField}
                        type="text"
                        placeholder={t('establishments.lots.company')}
                        onChange={(e) => { onLeftSelectChange(e) }}
                        options={
                            leftSelectValues.map(el => ({
                                value: el._id,
                                label: el.name
                            }))
                        }
                    />
                </div>
            </div>

            <div className="modal_select"
                style={{flexDirection:'column'}}>
                <span className="form__form-group-label">{t('establishments.lots.establishment')}</span>
                <div >
                    <Field
                        name="establishment"
                        component={renderSelectField}
                        type="text"
                        validate={formValidators.validateRequired}
                        placeholder={t('establishments.lots.establishment')}
                        onChange={(e) => { onRightSelectChange(e) }}
                        options={
                            rightSelectValues.map(el => ({
                                value: el._id,
                                label: el.name
                            }))
                        }
                    />
                </div>
            </div>

            <ButtonToolbar className="form__button-toolbar center">
              <Button disabled={!facilitySearch.fetched || _.isEmpty(facilitySearch.res)} color="primary" type="submit">{t('common.upload')}</Button>
              <Button type="reset" onClick={reset}>{t('common.delete')}</Button>
            </ButtonToolbar>
          </form>
          )}
            {facilitySearch.fetched && _.isEmpty(facilitySearch.res) && (
                <div>
                    <label>
                        {t(
                            "establishments.modals.transfer_event.invite"
                        )}
                    </label>
                    <EmailInput
                        placeholder={t(
                            "establishments.modals.transfer_event.invite_email"
                        )}
                        send={sendMailToUnregistered}
                        loading={props.getProfessionalUserByDniPending}
                    />
                </div>
            )}
        </div>

    );
};

FileUploadExtended.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    facilitySearch: state.generalData.facilitySearch,
    selectedEstablishment: state.user.selectedEstablishment
});

const mapDispatchToProps = dispatch => ({
    getFacilityByCode: code =>
        dispatch(actions.generalData.getFacilityByCode(code)),
    sendMailToUnregistered: (establishmentId, email) => (dispatch(actions.user.sendMailToUnregistered(establishmentId, email))),
    sendMailToUnregisteredFacility: (establishmentId, email) => (dispatch(actions.user.sendMailToUnregisteredFacility(establishmentId, email))),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'file_upload_default', // a unique identifier for this form
})(withTranslation('common')(FileUploadExtended)));

