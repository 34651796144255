import React from 'react';
import {Container, Row} from 'reactstrap';
import RecategorizationEventForm from "../NewEventForms/RecategorizationEventForm"

const ReidentificationEventDetailsContainer = (props) => {
  const event = props.location.state.event;

  const mapInitialValues = (event) => {
    if (event)
      return {
        caravan_id: event.earId,
        newCategory: event.newCategory,
        fromCategory: event.fromCategory,
        date: event.date
      };
    return {}
  };

  return (
    <Container>
      <Row>
        {
          event &&
          <RecategorizationEventForm onSubmit={() => {}} pending={props.addCowPending}
                           editable={false} initialValues={mapInitialValues(event)}/>
        }
      </Row>
    </Container>
  )
};

export default ReidentificationEventDetailsContainer;