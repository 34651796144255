import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import LockScreen from '../../Account/LockScreen/index';
import LogIn from '../../Account/LogIn/index';
import ResetPassword from '../../Account/ResetPassword/index';
import Register from '../../Account/Register/index';
import FullRegister from '../../Account/FullRegister/index';
import CVAuthRoute from "./CVRoutes/CVAuthRoute";
import { connect } from "react-redux";
import WrappedRoutes from './WrappedRoutes';

const Router = (props) => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/404" component={NotFound404} />
        <Route path="/lock_screen" component={LockScreen} />
        <Route path="/log_in/:token?" component={LogIn} />
        <Route path="/reset_password/:userId/:token" component={ResetPassword} />
        <Route path="/register" component={Register} />
        <CVAuthRoute path="/full_register" component={FullRegister} isAuthenticated={props.isAuthenticated}/>
        <CVAuthRoute path="/" component={WrappedRoutes} isAuthenticated={props.isAuthenticated}/>
      </Switch>
    </main>
  </MainWrapper>
);

const mapStateToProps = (state) => ({
    isAuthenticated: state.session.isAuthenticated
});

export default connect(mapStateToProps)(Router);
