/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { withTranslation } from 'react-i18next';
import CVTable from '../../Tables/CVTable/CVTable';

const NotificationTable = (props) =>  {

    const [notifications, setNotifications] = useState();

    const { total, totalPages, currentPage, pageSize } = props;

    useEffect(() => {
        if(props.notifications !== undefined) setNotifications(props.notifications);
    }, [props.notifications]);

    const { t } = props;

    const renderButtons = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <CheckCircleIcon className={"button"} color="green" onClick={() => props.toggleAcceptModal(value)}/>
                <CloseCircleIcon className={"button"} color="red" style={{marginLeft: "10px"}} onClick={() => props.toggleRejectModal(value)}/>
                <MagnifyIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => props.toggleDetailsModal(value)}/>
            </div>
        )
    };

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderMessage = ({value}) => {
        return (
            <div style={{display: 'flex'}}>
                {t('notifications.table.message', {role: t(`notifications.table.roles.${value}`)})}
            </div>
        )
    };

    const heads = [
        {
            key: 'sender',
            name: t('notifications.table.headers.sender'),
            percentage: 25,
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'role',
            name: t('notifications.table.headers.message'),
            percentage: 30,
            minWidth: 210,
            formatter: renderMessage
        },
        {
            key: 'date',
            name: t('notifications.table.headers.date-and-time'),
            percentage: 22.5,
            minWidth: 135,
            formatter: renderValueDefault
        },
        {
            key: 'id',
            name: t('notifications.table.headers.actions'),
            percentage: 22.5,
            minWidth: 120,
            formatter: renderButtons
        }
    ];

    const filters = [
        {
            group: 'notifications',
            filterId: 'byRole',
            label: 'role',
            values: [
                {
                    id: 'VETERINARY',
                    label: 'veterinary'
                },
                {
                    id: 'ESTABLISHMENT',
                    label: 'establishment'
                }
            ]
        }
    ];

    return (
        <CVTable
            getPage={props.getPage}
            data={notifications}
            total={total}
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            heads={heads}
            title={t('notifications.table.title')}
            showSearchInput={true}
            onSearch={props.search}
            onFilter={props.filter}
            filters={filters}
            noDataLabel={t('notifications.table.no_data_label')}
        />
    );
};

export default withTranslation('common')(NotificationTable)
