import React, {useState, useEffect} from 'react';
import SearchInput from "../../../shared/components/cv/SearchInput";
import {withTranslation} from "react-i18next";
import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import {connect} from "react-redux";
import actions from '../../../redux/actions/index';
import _ from 'lodash';
import {ClipLoader} from "react-spinners";
import EmailInput from '../../../shared/components/cv/EmailInput';
import {useAlert} from "react-alert";
import { Route , withRouter} from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const AddNewProfessional = (props) => {
    const { t, professionalUserSearch, establishmentId } = props;

    const search = value => {
      props.getProfessionalUserByDni(value);
    };

    const alert = useAlert();

    const addProfessionalToEstablishment = () => {
        props.addProfessionalToEstablishment(
           establishmentId,
           professionalUserSearch.userId,
           professionalUserSearch.role
        );
        sendMailToEstablishment(establishmentId, professionalUserSearch.userId);
    };

    const sendMailToEstablishment = (establishmentId, userId) => {
        props.sendMailToEstablishment(establishmentId, userId);
    };

    const sendMailToUnregistered = (email) => {
      props.sendMailToUnregistered(props.establishmentId, email);
      if(false/*props.inviteSent.cause.statusCode === 500*/){
        //ToastsStore.success('ha ocurrido un error')
      }else{
        ToastsStore.success('Se ha enviando la invitacion a  ' + email)
      }
    };

    return (
      <div className="add_new_professional">
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
        <h4 className="text-modal  modal__title">
          {t(
            "establishments.modals.add_professional_to_establishment.add_new_professional"
          )}
        </h4>
        <SearchInput
          placeholder={t(
            "establishments.modals.add_professional_to_establishment.search_placeholder"
          )}
          search={search}
          loading={props.getProfessionalUserByDniPending}
        />
        {professionalUserSearch && !_.isEmpty(professionalUserSearch) && (
          <div className="add_new_professional-result">
            <div className="add_new_professional-result-name">
              <label>
                {professionalUserSearch.name} {professionalUserSearch.lastName}
              </label>
            </div>
            <div className="add_new_professional-result-role">
              <label>{t(`common.roles.${professionalUserSearch.role}`)}</label>
            </div>
            <div className="add_new_professional-result-actions">
              {props.addProfessionalToEstablishmentPending ? (
                <ClipLoader size={24} loading={true} color={"#123abc"} />
              ) : (
                <CheckCircleOutlineIcon
                  color={"green"}
                  onClick={addProfessionalToEstablishment}
                />
              )}
            </div>
          </div>
        )}
        {professionalUserSearch && _.isEmpty(professionalUserSearch) && (
          <div className="error_container">
            <label>
              {t("establishments.modals.add_professional_to_establishment.no_professional_found")}
            </label>
          </div>
        )}
        {
          //(professionalUserSearch && _.isEmpty(professionalUserSearch)) &&
          <div className="add_new_professional">
            <h4 className="text-modal  modal__title">
              {t(
                "establishments.modals.add_professional_to_establishment.invite_new_professional"
              )}
            </h4>
            <EmailInput
              placeholder={t(
                "establishments.modals.add_professional_to_establishment.send_invite"
              )}
              send={sendMailToUnregistered}
              loading={props.getProfessionalUserByDniPending}
            />
          </div>
        }
      </div>
    );
};

const mapStateToProps = (state) => ({
    professionalUserSearch: state.user.professionalUserSearch,
    getProfessionalUserByDniPending: state.ui.getProfessionalUserByDniPending,
    addProfessionalToEstablishmentPending: state.ui.addProfessionalToEstablishmentPending,
    inviteSent: state.user.inviteSent
});
const mapDispatchToProps = (dispatch) => ({
    getProfessionalUserByDni: (dni) => dispatch(actions.user.getProfessionalUserByDni(dni)),
    addProfessionalToEstablishment: (establishmentId, userId, roleId) =>
        (dispatch(actions.user.addProfessionalToEstablishment(establishmentId, userId, roleId))),
    sendMailToEstablishment: (establishmentId, userId) => (dispatch(actions.user.sendMailToEstablishment(establishmentId, userId))),
    sendMailToUnregistered: (establishmentId, email) => (dispatch(actions.user.sendMailToUnregistered(establishmentId, email))),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(AddNewProfessional)));