export const GET_USERS_PAGE = 'GET_USERS_PAGE';
export const GET_USERS_PAGE_RESPONSE = 'GET_USERS_PAGE_RESPONSE';
export const GET_USERS_PAGE_ERROR = 'GET_USERS_PAGE_ERROR';
export const GET_ROLES_PAGE = 'GET_ROLES_PAGE';
export const GET_ROLES_PAGE_RESPONSE = 'GET_ROLES_PAGE_RESPONSE';
export const GET_ROLES_PAGE_ERROR = 'GET_ROLES_PAGE_ERROR';
export const GET_GENERAL_DATA_TITLES_PAGE = 'GET_GENERAL_DATA_TITLES_PAGE';
export const GET_GENERAL_DATA_TITLES_PAGE_RESPONSE = 'GET_GENERAL_DATA_TITLES_PAGE_RESPONSE';
export const GET_GENERAL_DATA_TITLES_PAGE_ERROR = 'GET_GENERAL_DATA_TITLES_PAGE_ERROR';
export const GET_GENERAL_DATA_INFO = 'GET_GENERAL_DATA_INFO';
export const GET_GENERAL_DATA_INFO_RESPONSE = 'GET_GENERAL_DATA_INFO_RESPONSE';
export const GET_GENERAL_DATA_INFO_ERROR = 'GET_GENERAL_DATA_INFO_ERROR';
export const EDIT_GENERAL_DATA_INFO = 'EDIT_GENERAL_DATA_INFO';
export const EDIT_GENERAL_DATA_INFO_RESPONSE = 'EDIT_GENERAL_DATA_INFO_RESPONSE';
export const EDIT_GENERAL_DATA_INFO_ERROR = 'EDIT_GENERAL_DATA_INFO_ERROR';
export const ADD_GENERAL_DATA_INFO = 'ADD_GENERAL_DATA_INFO';
export const ADD_GENERAL_DATA_INFO_RESPONSE = 'ADD_GENERAL_DATA_INFO_RESPONSE';
export const ADD_GENERAL_DATA_INFO_ERROR = 'ADD_GENERAL_DATA_INFO_ERROR';
export const ADD_GENERAL_DATA = 'ADD_GENERAL_DATA';
export const ADD_GENERAL_DATA_RESPONSE = 'ADD_GENERAL_DATA_RESPONSE';
export const ADD_GENERAL_DATA_ERROR = 'ADD_GENERAL_DATA_ERROR';
export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_RESPONSE = 'BLOCK_USER_RESPONSE';
export const BLOCK_USER_ERROR = 'BLOCK_USER_ERROR';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_RESPONSE = 'GET_USER_BY_ID_RESPONSE';
export const GET_USER_BY_ID_ERROR = 'GET_USER_BY_ID_ERROR';
export const EDIT_USER_BY_ID = 'EDIT_USER_BY_ID';
export const EDIT_USER_BY_ID_RESPONSE = 'EDIT_USER_BY_ID_RESPONSE';
export const EDIT_USER_BY_ID_ERROR = 'EDIT_USER_BY_ID_ERROR';

const adminActions = {
    getUsersPage(pageNumber, pageSize){
        return {
            type: GET_USERS_PAGE,
            pageNumber,
            pageSize
        }
    },
    getUsersPageResponse(response){
        return {
            type: GET_USERS_PAGE_RESPONSE,
            response
        }
    },
    getUsersPageError(message){
        return {
            type: GET_USERS_PAGE_ERROR,
            message
        }
    },
    getRolesPage(pageNumber, pageSize){
        return {
            type: GET_ROLES_PAGE,
            pageNumber,
            pageSize
        }
    },
    getRolesPageResponse(response){
        return {
            type: GET_ROLES_PAGE_RESPONSE,
            response
        }
    },
    getRolesPageError(message){
        return {
            type: GET_ROLES_PAGE_ERROR,
            message
        }
    },
    getGeneralDataTitlesPage(pageNumber, pageSize){
        return {
            type: GET_GENERAL_DATA_TITLES_PAGE,
            pageNumber,
            pageSize
        }
    },
    getGeneralDataTitlesPageResponse(response){
        return {
            type: GET_GENERAL_DATA_TITLES_PAGE_RESPONSE,
            response
        }
    },
    getGeneralDataTitlesPageError(message){
        return {
            type: GET_GENERAL_DATA_TITLES_PAGE_ERROR,
            message
        }
    },
    getGeneralDataInfo(id){
        return {
            type: GET_GENERAL_DATA_INFO,
            id,
        }
    },
    getGeneralDataInfoResponse(response){
        return {
            type: GET_GENERAL_DATA_INFO_RESPONSE,
            response
        }
    },
    getGeneralDataInfoError(message){
        return {
            type: GET_GENERAL_DATA_INFO_ERROR,
            message
        }
    },
    editGeneralDataInfo(id, prevValue, newValue, filter){
        return {
            type: EDIT_GENERAL_DATA_INFO,
            id,
            prevValue,
            newValue,
            filter
        }
    },
    editGeneralDataInfoResponse(response){
        return {
            type: EDIT_GENERAL_DATA_INFO_RESPONSE,
            response
        }
    },
    editGeneralDataInfoError(message){
        return {
            type: EDIT_GENERAL_DATA_INFO_ERROR,
            message
        }
    },
    addGeneralDataInfo(id, value, filter){
        return {
            type: ADD_GENERAL_DATA_INFO,
            id,
            value,
            filter
        }
    },
    addGeneralDataInfoResponse(response){
        return {
            type: ADD_GENERAL_DATA_INFO_RESPONSE,
            response
        }
    },
    addGeneralDataInfoError(message){
        return {
            type: ADD_GENERAL_DATA_INFO_ERROR,
            message
        }
    },
    addGeneralData(generalData){
        return {
            type: ADD_GENERAL_DATA,
            generalData
        }
    },
    addGeneralDataResponse(response){
        return {
            type: ADD_GENERAL_DATA_RESPONSE,
            response
        }
    },
    addGeneralDataError(message){
        return {
            type: ADD_GENERAL_DATA_ERROR,
            message
        }
    },
    blockUser(userId, callback){
        return {
            type: BLOCK_USER,
            userId,
            callback
        }
    },
    blockUserResponse(response){
        return {
            type: BLOCK_USER_RESPONSE,
            response
        }
    },
    blockUserError(message){
        return {
            type: BLOCK_USER_ERROR,
            message
        }
    },
    getUserById(userId) {
        return {
            type: GET_USER_BY_ID,
            userId
        }
    },
    getUserByIdResponse(response) {
        return {
            type: GET_USER_BY_ID_RESPONSE,
            response
        }
    },
    getUserByIdError(message) {
        return {
            type: GET_USER_BY_ID_ERROR,
            message
        }
    },
    editUserById(userId, userModify, callback) {
        return {
            type: EDIT_USER_BY_ID,
            userId,
            userModify,
            callback
        }
    },
    editUserByIdResponse(response) {
        return {
            type: EDIT_USER_BY_ID_RESPONSE,
            response
        }
    },
    editUserByIdError(message) {
        return {
            type: EDIT_USER_BY_ID_ERROR,
            message
        }
    }
};

export default adminActions;