import React from 'react';
import ResetPasswordForm from './components/ResetPasswordForm';
import cvLogo from '../../../shared/img/logo/cv-logo-long.png';
import {connect} from "react-redux";
import sessionActions from "../../../redux/actions/sessionActions"
const ResetPassword = (props) => {

    const { t } = props;

    const _handleResetPasswordRequest = (values) => {
        props.resetPasswordRequest (props.match.params.userId, props.match.params.token, values.password)
        props.history.push('/log_in');
    };

    return (
        <div className="account">
            <div className="account__wrapper">
                <div className="account__card">
                    <div className="account__head">
                        <img src={cvLogo} alt="cv-logo"/>
                    </div>
                    <ResetPasswordForm
                        onSubmit={_handleResetPasswordRequest}
                        resetPasswordPending={props.resetPasswordPending}
                        resetPasswordError={props.resetPasswordError}
                        resetPasswordErrorMessage={props.resetPasswordErrorMessage}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        resetPasswordPending: state.ui.resetPasswordPending,
        resetPasswordError: state.ui.resetPasswordError,
        resetPasswordErrorMessage: state.ui.resetPasswordErrorMessage,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetPasswordRequest: (userId, token, password) => dispatch(sessionActions.resetPasswordRequest(userId, token, password))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
