/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import _ from 'lodash';

//  This is our own data table due to the template table has some problems.
const DataTable = (props) => {
    const { rows, heads, style } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        if(rows) setData(rows);
    }, [rows]);

    const rowGetter = (index) => {
        return _.clone(data[index]);
    };

    if(data.length === 0) return null;

    return (
        <div className="table" style={{...style}}>
            <ReactDataGrid
                key={Math.random()}
                columns={heads}
                rowGetter={rowGetter}
                rowsCount={data.length}
            />
        </div>
    )
};

DataTable.prototype = {
    heads: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    rows: PropTypes.array.isRequired
};

export default DataTable;