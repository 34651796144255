//  Note 'api.mixed' is a mix of production methods and mocked ones. The purpose of this is to start the integration
//  of some methods and left the some dummy ones to avoid application crashes.

//  Production imports (needs for production)
import settings from '../util/settings';
import { POST, GET, PUT, DELETE } from '../util/routes/methods';
import { TIMEOUT } from "../util/routes/errors";
import { sessionRoutes, userRoutes, constantRoutes, companyRoutes, establishmentRoutes,
    animalRoutes, imageRoutes, eventRoutes} from "../util/routes/routes";

//  Mocked imports (only needs for mocked requests)
import _ from 'lodash';
import {EVENT_TYPES} from "../constants";
import { ROLES } from '../constants';

const baseUrl = settings.baseUrl;
const timeout = settings.timeout;

const performRequest = (route, {body, headers} = {}) => {
    let promise;
    switch (route.method) {
        case POST:
            promise = postRequest(baseUrl, route.url, body, headers);
            break;
        case GET:
            promise = getRequest(baseUrl, route.url, headers);
            break;
        case PUT:
            promise = putRequest(baseUrl, route.url, body, headers);
            break;
        case DELETE:
            promise = deleteRequest(baseUrl, route.url, headers);
            break;
        default:
            throw Error(`Invalid method ${route.method}`)
    }

    const withTimeoutPromise = Promise.race([
        promise,
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error(TIMEOUT)), timeout)
        )
    ]);

    return withTimeoutPromise
        .then((res) => {
            if(res.ok) return res.json();
            // If res is NOT ok, is an error.
            return res.json()
                .then((e) => {
                    const error = {statusCode: res.status, errorCode: e.code};
                    throw error;
                });
        })
        .catch(error => {
            if(error.message === TIMEOUT) throw new Error(TIMEOUT);
            throw route.parseError(error);
        })
};

export const performJWTAuthenticatedRequest = (jwt, route, {body, headers} = {}) => {
    if(!jwt) console.warn("Trying to perform an authenticated request without jwt");
    return performRequest(route,
        {
            body,
            headers: {
                'Authorization': `JWT ${jwt}`,
                ...headers,
            }
        }
    );
};

const postRequest = (baseUrl, url, body, headers) => {

    let resultHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...headers
    };

    //  This is done because in some request the default headers can not be appended.
    if(headers && headers['default-headers'] === false) {
        resultHeaders = {...headers}
    }

    const resultBody = resultHeaders['Content-Type'] === 'application/json' ? JSON.stringify(body) : body;

    return fetch(baseUrl + url, {
        method: POST,
        headers: resultHeaders,
        body: resultBody
    });
};

const getRequest = (baseUrl, url, headers) => {
    return fetch(baseUrl + url, {headers})
};

const putRequest = (baseUrl, url, body, headers) => {
    let resultHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...headers
    };

    //  This is done because in some request the default headers can not be appended.
    if(headers && headers['default-headers'] === false) {
        resultHeaders = {...headers}
    }

    const resultBody = resultHeaders['Content-Type'] === 'application/json' ? JSON.stringify(body) : body;

    return fetch(baseUrl + url, {
        method: PUT,
        headers: resultHeaders,
        body: resultBody
    });
};

const deleteRequest = (baseUrl, url, headers) => {
    return fetch(baseUrl + url, {
        method: DELETE,
        headers
    });
};


/* ~~~~~~~~~~~~~~~~~~ Api Production Requests ~~~~~~~~~~~~~~~~~~ */
export function login(email, password) {
    const body = {email, password};
    return performRequest(sessionRoutes.login, { body })
}

export function refresh(jwt) {
    const body = {jwt: jwt};
    return performRequest(sessionRoutes.refresh, { body })
}

export function basicRegister(email, password, country) {
    const body = {email, password, country: country.value};
    return performRequest(sessionRoutes.basicUserRegister, { body });
}

export function confirmEmail(token) {
    return performRequest(sessionRoutes.emailConfirmation(token))
}

export function resendEmailConfirmation(email) {
    return performRequest(sessionRoutes.resendEmailConfirmation(email))
}

export function fullRegister(jwt, userId, body) {
    return performJWTAuthenticatedRequest(jwt, sessionRoutes.fullRegister(userId), { body })
}

export function passwordRecovery(email) {
    return performRequest(sessionRoutes.passwordRecovery(email))
}

export function resetPassword(userId, token, password) {
    const body = {password};
    return performRequest(sessionRoutes.resetPassword(userId, token), {body})
}

export function editUserData(jwt, userId, body) {
    return performJWTAuthenticatedRequest(jwt, userRoutes.editUserData(userId), { body })
}

export function getUser(jwt) {
    return performJWTAuthenticatedRequest(jwt, userRoutes.getUser());
}

export function editUserAvatar(jwt, userId, body) {
    const headers = {'Content-Type': 'multipart/form-data'};
    return performJWTAuthenticatedRequest(jwt, userRoutes.editUserData(userId), { body, headers});
}

export function getAvailableRoles(jwt) {
    return performJWTAuthenticatedRequest(jwt, constantRoutes.getAvailableRoles())
}

export function getDentitionValues(jwt) {
    return performJWTAuthenticatedRequest(jwt, constantRoutes.getDentitionValues())
}


export function addRoleToUser(jwt, userId, role, body) {
    if(body) return performJWTAuthenticatedRequest(jwt, userRoutes.addRole(userId, role), { body });
    return performJWTAuthenticatedRequest(jwt, userRoutes.addRole(userId, role))
}

export function getUserAccounts(jwt, page, limit) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.getUserCompaniesPaginated(page, limit))
}

export function getSurrogateUserAccounts(jwt, page, limit) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.getSurrogateUserCompaniesPaginated(page, limit))
}

export function getSurrogateUserEstablishments(jwt, page, limit) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.getSurrogateUserEstablishmentsPaginated(page, limit))
}

export function getAvailableEntityTypes(jwt) {
    return performJWTAuthenticatedRequest(jwt, constantRoutes.getAvailableEntityTypes())
}

export function getAvailableProductiveSystems(jwt) {
    return performJWTAuthenticatedRequest(jwt, constantRoutes.getAvailableProductiveSystems())
}

export function addUserCompany(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.addUserCompany(), { body })
}

export function addEstablishmentToCompany(jwt, establishmentType, body) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.addEstablishmentToCompany(establishmentType), { body })
}

export function addEstablishmentLot(jwt, establishmentId, body) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.addEstablishmentLot(establishmentId), { body })
}

export function getAvailableEstablishmentTypes(jwt) {
    return performJWTAuthenticatedRequest(jwt, constantRoutes.getAvailableEstablishmentTypes())
}

export function getAvailableGroundConditions(jwt) {
    return performJWTAuthenticatedRequest(jwt, constantRoutes.getAvailableGroundConditions())
}

export function getUserCompanyById(jwt, companyId) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.getUserCompanyById(companyId));
}

export function editUserCompanyById(jwt, companyId, body) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.editUserCompanyById(companyId), { body })
}

export function getUserEstablishments(jwt, id, page, limit) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.getUserEstablishmentsPaginated(id, page, limit))
}

export function getAllUserEstablishments(jwt) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.getAllUserEstablishments())
}

export function getUserEstablishmentById(jwt, establishmentId) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.getUserEstablishmentById(establishmentId));
}

export function getLotsByEstablishment(jwt, establishmentIds) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.getLotsByEstablishment(establishmentIds))
}

export function editUserEstablishmentById(jwt, establishmentId, body) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.editUserEstablishmentById(establishmentId), {body});
}

export function getAllUserCompanies(jwt) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.getAllUserCompanies())
}

export function deleteUserCompanyById(jwt, companyId) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.deleteUserCompanyById(companyId))
}

export function deleteUserEstablishmentById(jwt, establishmentId) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.deleteUserEstablishmentById(establishmentId))
}

export function deleteUserEstablishmentLotById(jwt, establishmentLotId) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.deleteUserEstablishmentLotById(establishmentLotId))
}

export function editEstablishmentLot(jwt, establishmentId, body) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.editEstablishmentLot(establishmentId), {body})
}

export function addAnimal(jwt, body, animalType) {
    return performJWTAuthenticatedRequest(jwt, animalRoutes.addAnimal(animalType), { body })
}

export function addProductiveSystemChangeEvent(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.addAnimalEvent('productiveSystemChangeEvent'), { body })
}

export function addWeightEvent(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.addAnimalEvent('weightEvent'), { body })
}

export function addVaccinationEvent(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.addAnimalEvent('vaccinationEvent'), { body })
}

export function addSlaughterEvent(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.addAnimalEvent('slaughterEvent'), { body })
}

export function addCastrationEvent(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.addAnimalEvent('castrationEvent'), { body })
}

export function addReidentificationEvent(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.addAnimalEvent('reidentificationEvent'), { body })
}

export function addDentitionEvent(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.addAnimalEvent('dentitionEvent'), { body })
}

export function addDeletionEvent(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.addAnimalEvent('deletionEvent'), { body })
}

export function addLotEvent(jwt, body) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.addAnimalEvent('lotEvent'), { body })
}

export function getUserAnimals(jwt, page, limit) {
    return performJWTAuthenticatedRequest(jwt, animalRoutes.getUserAnimalsPaginated(page, limit))
}

export function getUserAnimalById(jwt, animalId) {
    return performJWTAuthenticatedRequest(jwt, animalRoutes.getUserAnimalById(animalId))
}

export function massiveAnimalUpload(jwt, establishmentId, animalType, file) {
    let body = new FormData();
    body.append('excel', file[0]);

    // this header is set to avoid appending wrong headers on 'postRequest'
    let headers = {
        "default-headers": false
    };

    return performJWTAuthenticatedRequest(jwt, animalRoutes.massiveAnimalUpload(establishmentId, animalType), {body, headers})
}

export function acceptMassiveAnimalUpload(jwt, excelId) {
    return performJWTAuthenticatedRequest(jwt, animalRoutes.acceptMassiveAnimalUpload(excelId))
}

export function uploadImage(jwt, image, name) {
    let body = new FormData();
    body.append('photo', image);

    if(name) body.append('name', name);

    // this header is set to avoid appending wrong headers on 'postRequest'
    let headers = {
        "default-headers": false
    };

    return performJWTAuthenticatedRequest(jwt, imageRoutes.uploadImage(), {body, headers});
}

export function editAnimalById(jwt, animalId, body) {
    return performJWTAuthenticatedRequest(jwt, animalRoutes.editAnimalById(animalId), { body })
}

export function getUserEstablishmentsByCompany(jwt, companyId) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.getUserEstablishmentsByCompany(companyId));
}

export function getUserAnimalsByEstablishment(jwt, establishmentId, lotId, page, limit) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.getEstablishmentAnimals(establishmentId, lotId, page, limit))
}

export function getAvailableRucaActivities(jwt) {
    return performJWTAuthenticatedRequest(jwt, constantRoutes.getAvailableRucaActivities());
}

export function getProfessionalUserByDni(jwt, dni) {
    return performJWTAuthenticatedRequest(jwt, userRoutes.getProfessionalUserByDni(dni));
}

export function addProfessionalToEstablishment(jwt, establishmentId, userId, role) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.addProfessionalToEstablishment(establishmentId, userId, role))
}

export function deleteProfessionalFromEstablishment(jwt, establishmentId, userId, role) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.deleteProfessionalFromEstablishment(establishmentId, userId, role))
}

export function getAssignedEstablishments(jwt, userId) {
    return performJWTAuthenticatedRequest(jwt, userRoutes.getAssignedEstablishments(userId))
}

export function getAssignedCompanies(jwt, userId) {
    return performJWTAuthenticatedRequest(jwt, userRoutes.getAssignedCompanies(userId))
}

export function getSurrogateUserByDni(jwt, dni) {
    return performJWTAuthenticatedRequest(jwt, userRoutes.getSurrogateUserByDni(dni));
}

export function getFacilityByCode(jwt, code) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.getEstablishmentByCode(encodeURIComponent(code)));
}

export function addUserToCompany(jwt, companyId, userId, role) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.addUserToCompany(companyId, userId, role))
}
export function deleteUserFromCompany(jwt, companyId, userId) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.deleteUserFromCompany(companyId, userId))
}

export function uploadFileForMassiveEventsLoad(jwt, establishmentId, eventType, file) {
    let body = new FormData();
    body.append('excel', file[0]);

    // this header is set to avoid appending wrong headers on 'postRequest'
    let headers = {
        "default-headers": false
    };

    return performJWTAuthenticatedRequest(jwt, eventRoutes.massiveEventUpload(establishmentId, eventType), {body, headers})
}

export function uploadFileForMassiveTransferEvent(jwt, establishmentId, newEstablishmentId, eventType, file) {
    let body = new FormData();
    body.append('facilityId', newEstablishmentId);
    body.append('excel', file[0]);

    // this header is set to avoid appending wrong headers on 'postRequest'
    let headers = {
        "default-headers": false
    };

    return performJWTAuthenticatedRequest(jwt, eventRoutes.massiveEventUpload(establishmentId, eventType), {body, headers})
}

export function acceptMassiveEventsUpload(jwt, excelId, eventType) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.acceptMassiveEventUpload(excelId, eventType))
}

export function sendMailToEstablishment(jwt,establishmentId, userId) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.sendMailToEstablishment(establishmentId, userId))
}

export function sendMailToUnregistered(jwt,establishmentId, email, body) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.sendMailToUnregistered(establishmentId, email), {body})
}

export function sendMailToUnregisteredSurrogate(jwt,companyId, email) {
    return performJWTAuthenticatedRequest(jwt, companyRoutes.sendMailToUnregisteredSurrogate(companyId, email))
}


/* ~~~~~~~~~~~~~~~~~~ Api Mocked Requests ~~~~~~~~~~~~~~~~~~ */

const requestTimeout = 500;
const okResponse = { result: "ok"};

/* ~~~~~~~~~~~~~~~~~~ DUMMY DATA ~~~~~~~~~~~~~~~~~~ */
const countriesList = [
    {
        value: 'ARGENTINA'
    }/*,
    {
        value: 'URUGUAY'
    },
    {
        value: 'BRASIL'
    },
    {
        value: 'PARAGUAY'
    }*/
];
const address1 = {
    streetName: "Av. Rivadavia",
    streetNumber: "1245",
    apartment: "2B",
    postalCode: "1033",
    city: "Monserrat",
    state: "Buenos Aires",
    country: {value: 'ARGENTINA'}
};
const address2 = {
    streetName: "Ruta 8",
    streetNumber: "3420",
    apartment: "",
    postalCode: "S2600",
    city: "Venado Tuerto",
    state: "Santa Fe",
    country: {value: 'ARGENTINA'}
};
const adminProfile = {
    document: '30546192',
    taxId: '12341234',
    name: 'Juan',
    lastName: 'Lupanni',
    birthday: new Date("December 1, 1995 03:24:00"),
    deviceId: '12341234',
    avatar: 'https://picsum.photos/200',
    linkedin: 'juanLupanni@gmail.com',
    web: 'www.juanLupanni.com.ar',
    bio: '',
    homeAddress: address1,
    commercialAddress: address2,
    cellular: '01130406012',
    telephone: '0230453863',
};
const titularProfile = {
    document: '30546192',
    taxId: '12341234',
    name: 'Pedro',
    lastName: 'Rodriguez',
    birthday: new Date("December 17, 1995 03:24:00"),
    deviceId: '12341234',
    avatar: 'https://picsum.photos/200',
    linkedin: 'pedrorodriguez@gmail.com',
    web: 'www.pedrorodriguez.com.ar',
    bio: '',
    homeAddress: address1,
    commercialAddress: address2,
    cellular: '01130406098',
    telephone: '0230453890'
};
const roles = {
    titular: {
        name: ROLES.TITULAR,
        defaultPanel: '',
        actions: []
    },
    surrogate: {
        name: ROLES.SURROGATE,
        defaultPanel: '',
        actions: []
    },
    admin: {
        name: ROLES.ADMIN,
        defaultPanel: '',
        actions: []
    },
    with_out_role: {
        name: ROLES.WITH_OUT_ROLE,
        defaultPanel: '',
        actions: []
    }
};
const adminUser = {
    id: '1',
    createdAt: Date.now(),
    updatedAt: Date.now(),
    appId: {
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        name: undefined,
        apiKey: undefined
    },
    email: 'admin@mail.com',
    password: 'admin',
    profile: adminProfile,
    roles: [roles.admin],
    accounts: [],
    name: 'Juan',
    surname: 'Lupanni',
    cuit: 2234367324,
};
const titularUser = {
    id: '2',
    createdAt: Date.now(),
    updatedAt: Date.now(),
    appId: {
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        name: undefined,
        apiKey: undefined
    },
    email: 'titular@mail.com',
    password: 'titular',
    profile: titularProfile,
    roles: [roles.titular],
    accounts: [{id: 1, legalName: "Frigorificos Roberto", createdAt: new Date().toDateString()}],
    name: 'Pedro',
    surname: 'Rodriguez',
    cuit: 2234367234,
};

const cowBreedsConst = {
    ANGUS: 'ANGUS',
    HEREFORD: 'HEREFORD'
};

const generalDataInfo = [
    {
        data_id: 0,
        data_name: 'Especies',
        values: [
            {
                name: "Bos taurus",
                creation_date: new Date(2018, 1, 1).toLocaleString(),
                last_updated_date: new Date(2018, 12, 20).toLocaleString()
            }
        ]
    },
    {
        data_id: 1,
        data_name: 'Razas',
        values: [
            {
                name: cowBreedsConst.ANGUS,
                creation_date: new Date(2018, 1, 1).toLocaleString(),
                last_updated_date: new Date(2018, 12, 20).toLocaleString()
            },
            {
                name: cowBreedsConst.HEREFORD,
                creation_date: new Date(2018, 1, 1).toLocaleString(),
                last_updated_date: new Date(2018, 12, 20).toLocaleString()
            }
        ]
    },
    {
        data_id: 2,
        data_name: 'Cortes de carne',
        filtered: true,
        values: [
            {
                filter_id: "Argentina",
                values: [
                    {
                        name: "Lomo",
                        creation_date: new Date(2018, 1, 1).toLocaleString(),
                        last_updated_date: new Date(2018, 12, 20).toLocaleString()
                    },
                    {
                        name: "Nalga",
                        creation_date: new Date(2018, 4, 2).toLocaleString(),
                        last_updated_date: new Date(2018, 5, 17).toLocaleString()
                    }
                ]
            },
            {
                filter_id: "Uruguay",
                values: []
            },
            {
                filter_id: "Brasil",
                values: []
            },
            {
                filter_id: "Kazajistan",
                values: []
            }
        ]
    }
];
const productiveSystemsConst = {
    PASTURELAND: 'PASTURELAND',
    FEEDLOT: 'FEEDLOT',
    MIXED: 'MIXED'
};
const allEstablishments = [
    {
        id: 1,
        name: 'Establecimiento 1',
        userId: 1
    },
    {
        id: 2,
        name: 'Establecimiento 2',
        userId: 1
    },
    {
        id: 3,
        name: 'Establecimiento 3',
        userId: 2
    },
    {
        id: 4,
        name: 'Establecimiento 4',
        userId: 2
    },
    {
        id: 5,
        name: 'Establecimiento 5',
        userId: 2
    }
];

const cowBreedsList = [
    {
        value: cowBreedsConst.ANGUS
    },
    {
        value: cowBreedsConst.HEREFORD
    }
];

const createUserWallet = (address, encryptedMnemonic) => (
    {
        id: _.uniqueId('wallet_'),
        createdAt: Date.now(),
        updatedAt: Date.now(),
        address: address,
        encryptedMnemonic: encryptedMnemonic,
        active: true
    }
);

const allUserWallets = [];

/* ~~~~~~~~~~~~~~~~~~ USEFUL FUNCTIONS ~~~~~~~~~~~~~~~~~~ */
const createUser = (id, email, password, country) => (
    {
        id: id.toString(),
        createdAt: Date.now().toLocaleString(),
        updatedAt: Date.now().toLocaleString(),
        appId: {
            id: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            name: undefined,
            apiKey: undefined
        },
        email: email,
        password: password,
        profile: undefined,
        roles: [roles.with_out_role],
        accounts: [],
        name: undefined,
        surname: undefined,
        cuit: undefined,
    }
);

const getRandomDate = (startDate, endDate) => {
    const date = new Date(+startDate + (Math.random() * (endDate - startDate)));
    return `${date.getUTCFullYear()}/${date.getUTCDay()}/${date.getUTCMonth()}`
};

const getRandomDateAndTime = (startDate, endDate) => {
    const date = new Date(+startDate + Math.random() * (endDate - startDate));
    const hour = startDate.getHours() + Math.random() * (endDate.getHours() - startDate.getHours()) | 0;
    const minutes = startDate.getMinutes() + Math.random() * (endDate.getMinutes() - startDate.getMinutes()) | 0;
    date.setHours(hour, minutes);
    return `${date.getUTCFullYear()}/${date.getUTCDay() + 1}/${date.getUTCMonth()} ${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`
};

const getRandomDNI = () => (Math.floor(10000000 + Math.random() * 90000000)).toString();

const getRandomCellular = () => "11" + (Math.floor(10000000 + Math.random() * 90000000)).toString();

const getRandomTelephone = () => "4" + (Math.floor(1000000 + Math.random() * 9000000)).toString();

const generateRandomNotifications = (numberOfNotifications) => {
    const notifications = [];
    for (let i = 1; i < numberOfNotifications + 1; i += 1) {
        const nameAndSurname = ['Maria de lo Angeles', 'Federico Lagomarsino', 'Juan Pedro', 'Romina Villegas'][Math.floor((Math.random() * 4))];

        notifications.push({
            id: i,
            sender: nameAndSurname,
            role: ['VETERINARY', 'ESTABLISHMENT'][Math.floor((Math.random() * 2))],
            date: getRandomDateAndTime(new Date(2019, 3, 1, 0,0),
                new Date(2019, 9, 1, 23,59)).toLocaleString(),
            details: {
                nameAndSurname: nameAndSurname,
                dateOfBirth: getRandomDate(new Date(1980, 1, 1),
                    new Date(1995, 1, 1)),
                dni: getRandomDNI(),
                cellular: getRandomCellular(),
                telephone: getRandomTelephone(),
                address: {
                    streetName: "Zapiola",
                    number: "4248",
                    apartment: "2B",
                    postalCode: "8174",
                    city: "Saavedra",
                    state: "Buenos Aires",
                    country: {
                        name: "Argentina"
                    }
                }
            }
        });
    }
    return notifications;
};

const generateRandomAnimals = (numberOfAnimals, idStartIndex) => {
    const animals = [];
    for (let i = 1; i < numberOfAnimals + 1; i += 1) {
        animals.push({
            id: idStartIndex + i,
            caravan_id: Math.round(Math.random() * 1000),
            breed: [cowBreedsConst.ANGUS, cowBreedsConst.HEREFORD][Math.floor((Math.random() * 2))],
            species: ['BT', 'BT'][Math.floor((Math.random() * 2))],
            sex: ['male', 'female'][Math.floor((Math.random() * 2))],
            category: ['VACA', 'VAQUILLONA', 'NOVILLO', 'TERNERO'][Math.floor((Math.random() * 4))],
            birth_date: getRandomDateAndTime(new Date(2019, 3, 1, 0,0),
                new Date(2019, 9, 1, 23,59)).toLocaleString(),
            born_weight: [20, 25, 30, 35][Math.floor((Math.random() * 4))],
            age_in_months: [10, 30, 50, 60][Math.floor((Math.random() * 4))],
            father_id: ['VV101', 'VV132', 'VV142', 'VV173'][Math.floor((Math.random() * 4))],
            mother_id: ['VV201', 'VV232', 'VV242', 'VV293'][Math.floor((Math.random() * 4))],
        });
    }
    return animals;
};

const getRandomAccount = () => {
    return [
        {id: 2, legalName: 'Carnes Pacheco', createdAt: new Date().toDateString()},
        {id: 3, legalName: 'Frigorifico Mato', createdAt: new Date().toDateString()},
        {id: 4, legalName: 'Carnes del Sur', createdAt: new Date().toDateString()},
        {id: 5, legalName: 'Frigorifico Ruben', createdAt: new Date().toDateString()}][Math.floor((Math.random() * 4))];
};

const generateRandomUsers = (numberOfUsers) => {
    const users = [];
    for (let i = 3; i <= numberOfUsers + 2; i += 1) {
        const name = ['Maria', 'Federico', 'Juan', 'Romina'][Math.floor((Math.random() * 4))];
        const surname = ['Lagomarsino', 'Pedro', 'Villegas', 'Rodriguez'][Math.floor((Math.random() * 4))];
        const cuit = ['203456761', '223436751', '216236351', '176096151'][Math.floor((Math.random() * 4))];
        const account1 = getRandomAccount();
        let account2 = getRandomAccount();

        while(account2 === account1) account2 = getRandomAccount();

        users.push({
            id: i,
            name: name,
            surname: surname,
            cuit: cuit,
            accounts: [account1, account2],
            roles: [roles.titular]
        })
    }
    return users;
};

const getAllTheRoles = () => {
    return [
        {
            id: 0,
            role_name: ROLES.ADMIN,
            created_by: 'Carnes Validadas'
        },
        {
            id: 1,
            role_name: ROLES.TITULAR,
            created_by: 'Martina Gonzales'
        },
        {
            id: 2,
            role_name: ROLES.VETERINARY,
            created_by: 'Jose Carlos'
        }
    ];
};

const generateGeneralDataTitles = (numberOfGeneralDataTitles) => {
    let generalDataTitles = [
        {
            data_name: 'Especies',
            data_id: 0,
        },
        {
            data_name: 'Razas',
            data_id: 1,
        },
        {
            data_name: 'Cortes de carne',
            data_id: 2,
        }
    ];
    for(let i = 3; i <= numberOfGeneralDataTitles; i++){
        generalDataTitles.push({
            data_name: `Dato General ${i}`,
            data_id: i
        })
    }
    return generalDataTitles;
};

// const getEventData = (eventName) => {
//     switch (eventName) {
//         case EVENT_TYPES.weight:
//             return {
//                 weight_kg: Math.floor((Math.random() * 750)) + 35
//             };
//         case EVENT_TYPES.dentition:
//             return {
//                 teeth_table_value: 5
//             };
//         case EVENT_TYPES.change_of_productive_system:
//             return {
//                 productive_system: [productiveSystemsConst.PASTURELAND, productiveSystemsConst.FEEDLOT,
//                     productiveSystemsConst.MIXED][Math.floor((Math.random() * 3))],
//                 feeding: ['Alimentación 1', 'Alimentación 2',
//                     'Alimentación 3'][Math.floor((Math.random() * 3))]
//             };
//         case EVENT_TYPES.caravan_re_identification:
//             return {
//                 new_caravan: Math.floor((Math.random() * 10000))
//             };
//         case EVENT_TYPES.change_of_category:
//             return {
//                 new_category: ['Categoría 1', 'Categoría 2',
//                     'Categoría 3'][Math.floor((Math.random() * 3))]
//             };
//         default:
//             return {};
//     }
// };

// const generateRandomEvents = (numberOfEvents, startIndex) => {
//     let events = [];
//     for (let i = 1; i < numberOfEvents; i ++) {
//         const name = [EVENT_TYPES.weight, EVENT_TYPES.dentition, EVENT_TYPES.castration,
//             EVENT_TYPES.change_of_productive_system, EVENT_TYPES.caravan_re_identification,
//             EVENT_TYPES.change_of_category, EVENT_TYPES.fain, EVENT_TYPES.vaccination][Math.floor((Math.random() * 8))];
//         const date = getRandomDateAndTime(new Date(2019, 3, 1, 0,0),
//             new Date(2019, 9, 1, 23,59)).toLocaleString();

//         events.push({
//             id: startIndex + i,
//             name: name,
//             date: date,
//             ...getEventData(name)
//         })
//     }
//     return events;
// };

// const generateRandomChangeProductiveSystemEvents = (numberOfEvents, startIndex) => {
//     let events = [];
//     for (let i = 1; i < numberOfEvents; i ++) {
//         const caravanId = ["AS123-DAS1-2", "FH654-K6J3-4", "KG456-KL64-6", "GR345-J234-5"][Math.floor((Math.random() * 4))];
//         const dateAndTime = getRandomDate(new Date(2019, 3, 1),
//             new Date(2019, 9, 1)).toLocaleString();
//         const productiveSystem = ["feedlot", "natural", "silvoPastoral", "pastoral", "creepFeeding", "strategicFeeding"][Math.floor((Math.random() * 6))];

//         events.push({
//             id: startIndex + i,
//             caravanId: caravanId,
//             date: dateAndTime,
//             productiveSystem: productiveSystem,
//             feeding: ["a", "b", "c", "d", "e"][Math.floor((Math.random() * 5))]
//         })
//     }
//     return events;
// };

const searchUserById = (id) => {
    return allUsers.filter(user => user.id === id)[0]
};

const searchUserByEmail = (email) => {
    return allUsers.filter(user => user.email === email)[0]
};

const modifyUserById = (userId, modifyUser) => {
    for(let i = 0; i < allUsers.length; i++){
        if(allUsers[i].id === userId){
            allUsers[i] = modifyUser;
            return allUsers[i];
        }
    }
};

//  Todo delete this mocked method.
/*const getUserEstablishments = (userId) => {
    return allEstablishments.filter((establishment) => (establishment.userId.toString() === userId));
};*/

const checkCredentials = (email, password) => {
    return searchUserByEmail(email).password === password
};

//  Todo delete this mocked method.
/*const addRoleToUser = (userId, role) => {
    let user = searchUserById(userId);
    let userRolesModify = user.roles.filter((userRol) => userRol.name !== roles.with_out_role.name);
    userRolesModify.push({
        name: role,
        defaultPanel: '',
        actions: []
    });
    user.roles = userRolesModify;
    modifyUserById(userId, user);

    return user.roles;
};*/

const generateUserWallet = (userId, address, encryptedMnemonic) => {
    const walletCreated = createUserWallet(address, encryptedMnemonic);
    allUserWallets.push({userId, wallet: walletCreated});
    return walletCreated;
};

const getUserWallet = (userId) => {
    return allUserWallets.filter(wallet => wallet.userId === userId)[0]
};

const addNewGeneralData = (name) => {
    const data_id = allGeneralDataTitles[allGeneralDataTitles.length-1].data_id + 1;
    allGeneralDataTitles.push({
        data_name: name,
        data_id: data_id,
    });
    generalDataInfo.push({
        data_id: data_id,
        data_name: name,
        values: []
    });
    return allGeneralDataTitles;
};

/* ~~~~~~~~~~~~~~~~~~ VARIABLES FOR PAGINATED REQUEST ~~~~~~~~~~~~~~~~~~ */

let allUsers = [adminUser, titularUser, ...generateRandomUsers(100)];

let allNotifications = generateRandomNotifications(300);

let allRoles = getAllTheRoles();

let allAnimals = generateRandomAnimals(300, 0);

let allGeneralDataTitles = generateGeneralDataTitles(6);

// let allEvents = generateRandomEvents(100, 0);

// let allChangeProductiveSystemEvents = generateRandomChangeProductiveSystemEvents(100, 0);

const getPage = (allResults, pageNumber, pageSize) => {
    const from = pageSize * (pageNumber - 1);
    const to = from + pageSize;
    return allResults.slice(from, to);
};

const createPaginatedResultsResponseBody = (allResults, pageNumber, pageSize, page) => {
    return {
        page: pageNumber,
        pages: allResults.length % pageSize === 0 ?
            Math.floor(allResults.length / pageSize) :
            Math.floor(allResults.length / pageSize) + 1,
        total: allResults.length,
        limit: pageSize,
        results: page
    }
};

const searchInNotifications = (word) => {
    allNotifications = allNotifications.filter((notification) => {
        return notification.sender.toUpperCase().includes(word.toUpperCase()) ||
            notification.role.toUpperCase().includes(word.toUpperCase()) ||
            notification.date.includes(word)
    });
    return allNotifications
};

const filterInNotifications = (filters) => {
    const filterArray = Object.entries(filters);
    let filteredNotifications = [];

    filterArray.forEach(filter => {
        //  If the filter is 'activated' (true)
        if (filter[1]) {
            filteredNotifications = filteredNotifications.concat(allNotifications.filter(notification => {
                return notification.role === filter[0];
            }));
        }
    });
    allNotifications = filteredNotifications;
    return allNotifications;
};

const searchInAnimals = (word) => {
    allAnimals = allAnimals.filter((animal) => {
        return animal.sender.toUpperCase().includes(word.toUpperCase()) ||
            animal.role.toUpperCase().includes(word.toUpperCase()) ||
            animal.date.includes(word)
    });
    return allAnimals
};

const filterInAnimals = (filters) => {
    const filterArray = Object.entries(filters);
    let filteredAnimals = [];

    filterArray.forEach(filter => {
        //  If the filter is 'activated' (true)
        if (filter[1]) {
            filteredAnimals = filteredAnimals.concat(allAnimals.filter(animal => {
                return (animal.breed === filter[0] || animal.species === filter[0] || animal.sex === filter[0] || animal.category === filter[0]);
            }));
        }
    });
    allAnimals = filteredAnimals;
    return allAnimals;
};

// const searchInEvents = (word) => {
//     allEvents = allEvents.filter((event) => {
//         return event.name.toUpperCase().includes(word.toUpperCase()) ||
//             event.date.includes(word)
//     });
//     return allEvents;
// };

// const filterInEvents = (filters) => {
//     const filterArray = Object.entries(filters);
//     let filteredEvents = [];

//     filterArray.forEach(filter => {
//         //  If the filter is 'activated' (true)
//         if (filter[1]) {
//             filteredEvents = filteredEvents.concat(allEvents.filter(event => {
//                 return event.name === filter[0];
//             }));
//         }
//     });
//     allEvents = filteredEvents;
//     return allEvents;
// };

const setUserAsBlocked = (userId) => {
    for (let i = 0 ; i < allUsers.length; i++){
        if(allUsers[i].id === userId) {
            allUsers[i].block = true;
            break;
        }
    }
};

const getUserAccountsService = (userId) => {
    let user = searchUserById(userId);
    if(user) return user.accounts;
    return []
};

const addCompanyToUserService = (userId, company) => {
    let userModify;
    allUsers.map(user => {
        if(user.id.toString() === userId) {
            let id = user.accounts.length === 0 ? 1 : user.accounts[user.accounts.length -1].id + 1;
            user.accounts.push({id: id, ...company});
            userModify = user;
        }
        return user;
    });
    return userModify.accounts;
};

const addEstablishmentToCompanyService = (userId, accountId, establishment) => {
    let userModify;
    allUsers.map(user => {
        if(user.id.toString() === userId) {
            user.accounts.map(account => {
                if(account.id === accountId) {
                    if(account.establishments) account.establishments.push(establishment);
                    else account[establishment] = [establishment];
                    userModify = user;
                }
                return account;
            });
        }
        return user;
    });
    return userModify.accounts;
};



/* ~~~~~~~~~~~~~~~~~~ MOCK API FUNCTIONS ~~~~~~~~~~~~~~~~~~ */

// Todo delete this mocked method.
/*export function login(email, password) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const response = {
                tokens: {
                    jwt: "string",
                    jwtExpiration: "string",
                    refreshToken: "string",
                    refreshExpiration: "string",
                }
            };
            let user = searchUserByEmail(email);
            if(user){
                if(user.password === password){
                    response.userId = user.id;
                    resolve(response);
                }
                else reject({error: "WRONG_CREDENTIAL", code: 'LG001'});
            }
            else reject({error: "USER_NOT_FOUND", code: 'LG001'});
        }, requestTimeout)
    });
}*/

// Todo delete this mocked method.
/* export function basicRegister(email, password, country) {
    return new Promise((resolve) => {
        setTimeout(() => {
            const newUser = createUser(allUsers[allUsers.length-1].id + 1, email, password, country);
            allUsers.push(newUser);
            resolve({user: newUser})
        }, requestTimeout)
    })
}*/

//  Todo delete this mocked method.
/*export function fullRegister(userId, info) {
    let userUpdated = {};
    return new Promise((resolve) => {
        setTimeout(() => {
            allUsers.map((user) => {
                if (user.id === userId) {
                    user.name = info.name;
                    user.surname = info.surname;
                    user.profile = {
                        birthday: info.birthday,
                        document: info.dni,
                        cellular: info.cellular,
                        telephone: info.telephone,
                        homeAddress: {
                            streetName: info.address.streetName,
                            streetNumber: info.address.streetNumber,
                            apartment: info.address.apartment,
                            postalCode: info.address.postalCode,
                            city: info.address.city,
                            state: info.address.state,
                            country: info.address.country
                        },
                        avatar: 'https://picsum.photos/200'
                    };
                    userUpdated = user;
                }
                return user;
            });
            resolve({user: userUpdated})
        }, requestTimeout)
    })
}*/

export function getAvailableCountries() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                countries: countriesList
            })
        }, requestTimeout)
    })
}

export function getUserProfile(userId) {
    return new Promise(resolve => {
        setTimeout(() => {
            const userFound = searchUserById(userId);
            resolve({
                profile: userFound.profile
            })
        }, requestTimeout)
    })
}

export function getUserRoles() {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({
                roles: roles
            })
        }, requestTimeout)
    })
}

//  Todo delete this mocked method.
/*export function editUserData(values) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(values)
        }, requestTimeout)
    })
}*/

// Todo delete this mocked method.
/*export function editUserAvatar(image) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(image)
        }, requestTimeout)
    })
}*/

export function getNotifications(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageNotification = getPage(allNotifications, pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allNotifications, pageNumber, pageSize, pageNotification))
        }, requestTimeout)
    })
}

export function acceptNotificationRol(notificationId) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({...okResponse, notificationId});
        }, requestTimeout)
    })
}

export function rejectNotificationRol(notificationId) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({...okResponse, notificationId});
        }, requestTimeout)
    })
}

export function searchNotifications(word) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterNotification = getPage(searchInNotifications(word), 1, 10);
            resolve(createPaginatedResultsResponseBody(allNotifications, 1, 10, pageFilterNotification))
        }, requestTimeout)
    })
}

export function filterNotifications(filters) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterNotification = getPage(filterInNotifications(filters), 1, 10);
            resolve(createPaginatedResultsResponseBody(allNotifications, 1, 10, pageFilterNotification))
        }, requestTimeout)
    })
}

// todo delete this mocked method
/*export function getAnimals(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageAnimal = getPage(allAnimals, pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allAnimals,pageNumber, pageSize, pageAnimal))
        }, requestTimeout)
    })
}*/

export function searchAnimals(word) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterAnimal = getPage(searchInAnimals(word), 1, 10);
            resolve(createPaginatedResultsResponseBody(allAnimals,1, 10, pageFilterAnimal));
        }, requestTimeout)
    })
}

export function filterAnimals(filters) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterAnimal = getPage(filterInAnimals(filters), 1, 10);
            resolve(createPaginatedResultsResponseBody(allAnimals,1, 10, pageFilterAnimal));
        }, requestTimeout)
    })
}

// todo delete this mocked method.
// export function uploadFileForMassiveAnimalsLoad(file) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             const response = generateRandomAnimals(50, allAnimals.length);
//             resolve({...okResponse, result: response});
//         }, requestTimeout*10)
//     })
// }

// todo delete this mocked method
/*export function massiveAnimalsLoad(animals) {
    return new Promise(resolve => {
        setTimeout(() => {
            allAnimals = [...animals, ...allAnimals];
            const pageAnimal = getPage(allAnimals, 1, 10);
            resolve(createPaginatedResultsResponseBody(allAnimals,1,10, pageAnimal));
        }, requestTimeout*5)
    })
}*/

export function getUsers(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const userPage = getPage(allUsers.slice(1, allUsers.length), pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allUsers.slice(1, allUsers.length), pageNumber, pageSize, userPage));
        }, requestTimeout)
    })
}

export function getRoles(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const rolesPage = getPage(allRoles, pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allRoles, pageNumber, pageSize, rolesPage))
        }, requestTimeout)
    })
}

export function getGeneralDataTitlesPage(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const generalDataTitlesPage = getPage(allGeneralDataTitles, pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allGeneralDataTitles, pageNumber, pageSize, generalDataTitlesPage))
        }, requestTimeout)
    })
}

export function getGeneralDataInfo(id) {
    return new Promise(resolve => {
        setTimeout(() => {
            let response = generalDataInfo.filter(generalData => generalData.data_id.toString() === id)[0];
            if(!response){
                response = {
                    data_id: id,
                    data_name: `Dato General ${id}`,
                    values: [
                        {
                            name: "Valor 1",
                            creation_date: new Date(2018, 1, 1).toLocaleString(),
                            last_updated_date: new Date(2018, 12, 20).toLocaleString()
                        },
                        {
                            name: "Valor 2",
                            creation_date: new Date(2018, 1, 1).toLocaleString(),
                            last_updated_date: new Date(2018, 12, 20).toLocaleString()
                        },
                        {
                            name: "Valor 3",
                            creation_date: new Date(2018, 1, 1).toLocaleString(),
                            last_updated_date: new Date(2018, 12, 20).toLocaleString()
                        },
                        {
                            name: "Valor 4",
                            creation_date: new Date(2018, 1, 1).toLocaleString(),
                            last_updated_date: new Date(2018, 12, 20).toLocaleString()
                        }
                    ]
                };
                generalDataInfo.push(response);
            }
            resolve(response);
        }, requestTimeout)
    })
}

export function editGeneralDataInfo(id, prevValue, newValue, filter) {
    return new Promise(resolve => {
        setTimeout(() => {
            generalDataInfo.map(generalData => {
                if(generalData.data_id.toString() === id){
                    if(filter) {
                        generalData.values.map(generalDataWithFilter => {
                            if(generalDataWithFilter.filter_id === filter) {
                                generalDataWithFilter.values.map(element => {
                                    if(element.name === prevValue) {
                                        element.name = newValue;
                                        element.last_updated_date = (new Date()).toLocaleString();
                                    }
                                    return element;
                                });
                            }
                            return generalDataWithFilter;
                        })
                    }
                    else {
                        generalData.values.map( value => {
                            if(value.name === prevValue) {
                                value.name = newValue;
                                value.last_updated_date = (new Date()).toLocaleString();
                            }
                            return value;
                        })
                    }
                }
                return generalData;
            });
            let response = generalDataInfo.filter(generalData => generalData.data_id.toString() === id)[0];
            resolve(response)
        }, requestTimeout)
    })
}

export function addGeneralDataInfo(id, value, filter) {
    return new Promise(resolve => {
        setTimeout(() => {
            generalDataInfo.map(generalData => {
                if(generalData.data_id === id){
                    if(filter){
                        generalData.values.map(generalDataWithFilter => {
                            if(generalDataWithFilter.filter_id === filter){
                                return generalDataWithFilter.values.push({
                                    name: value,
                                    creation_date: new Date().toLocaleString(),
                                    last_updated_date: new Date().toLocaleString(),
                                })
                            }
                            return generalDataWithFilter;
                        })
                    }
                    else {
                        return generalData.values.push({
                            name: value,
                            creation_date: new Date().toLocaleString(),
                            last_updated_date: new Date().toLocaleString(),
                        });
                    }
                }
                return generalData;
            });
            let response = generalDataInfo.filter(generalData => generalData.data_id === id)[0];
            resolve(response)
        }, requestTimeout)
    })
}

export function getEvents(jwt, establishmentId, pageNumber, pageSize, eventType) {
    return performJWTAuthenticatedRequest(jwt, eventRoutes.getEventsPaginated(establishmentId, pageNumber, pageSize, eventType))
}

// export function searchEvents(word) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             const pageFilterEvent = getPage(searchInEvents(word), 1, 10);
//             resolve(createPaginatedResultsResponseBody(allEvents,1, 10, pageFilterEvent));
//         }, requestTimeout)
//     })
// }

// export function filterEvents(filters) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             const pageFilterEvent = getPage(filterInEvents(filters), 1, 10);
//             resolve(createPaginatedResultsResponseBody(allEvents,1, 10, pageFilterEvent));
//         }, requestTimeout)
//     })
// }

export function blockUser(userId) {
    return new Promise(resolve => {
        setTimeout(() => {
            setUserAsBlocked(userId);
            resolve(okResponse);
        }, requestTimeout)
    })
}

export function getUserById(userId) {
    return new Promise(resolve => {
        setTimeout(() => {
            const user = searchUserById(userId);
            resolve({...okResponse, result: user});
        }, requestTimeout)
    })
}

export function editUserById(userId, modifyUser) {
    return new Promise(resolve => {
        setTimeout(() => {
            const userModify = modifyUserById(userId, modifyUser);
            resolve({...okResponse});
        }, requestTimeout)
    })
}

export function getEstablishments(userId) {
    return new Promise(resolve => {
        setTimeout(() => {
            const userEstablishments = getUserEstablishments(userId);
            resolve(userEstablishments);
        }, requestTimeout)
    })
}

// export function addDentitionEvent(dentition, date) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             const newId = allEvents[allEvents.length-1].id + 1;
//             allEvents.push({
//                 id: newId,
//                 name: EVENT_TYPES.dentition,
//                 date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
//                 teeth_table_value: dentition
//             });
//             resolve({...okResponse})
//         }, requestTimeout)
//     })
//}

// export function addCaravanReIdentificationEvent(newIdentification, date) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             const newId = allEvents[allEvents.length-1].id + 1;
//             allEvents.push({
//                 id: newId,
//                 name: EVENT_TYPES.caravan_re_identification,
//                 new_caravan: newIdentification,
//                 date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
//             });
//             resolve({...okResponse})
//         }, requestTimeout)
//     })
// }

// export function addCategoryChangeEvent(id, newCategory, date) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             const newId = allEvents[allEvents.length-1].id + 1;
//             allEvents.push({
//                 id: newId,
//                 name: EVENT_TYPES.change_of_category,
//                 new_category: newCategory,
//                 date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
//             });
//             resolve({...okResponse})
//         }, requestTimeout)
//     })
// }


// export function massiveEventsLoad(events) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             allEvents = [...allEvents, ...events];
//             resolve(getEvents(1,10));
//         }, requestTimeout*5)
//     })
// }

export function getAvailableCowBreeds() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                breeds: cowBreedsList
            })
        }, requestTimeout)
    })
}

export function getAvailableFacilities(jwt) {
    return performJWTAuthenticatedRequest(jwt, establishmentRoutes.getAllUserEstablishments())
}

//  Todo delete this mocked method.
/*export function getAccounts(userId) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({accounts: getUserAccountsService(userId)});
        }, requestTimeout)
    })
}*/

//  Todo delete this mocked method.
/*export function addCompany(userId, company) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({accounts: addCompanyToUserService(userId, company)});
        }, requestTimeout)
    })
}*/

//  Todo delete this mocked method.
/*export function addEstablishment(userId, accountId, info) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({accounts: addEstablishmentToCompanyService(userId, accountId, info)})
        }, requestTimeout)
    })
}*/

//  Todo delete this mocked method.
/*export function addRole(userId, role) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({roles: addRoleToUser(userId, role)})
        }, requestTimeout)
    })
}*/

export function createWallet(userId, address, encryptedMnemonic){
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({wallet: generateUserWallet(userId, address, encryptedMnemonic)})
        }, requestTimeout)
    })
}

export function getWallet(userId){
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({wallet: getUserWallet(userId)})
        }, requestTimeout)
    })
}

export function addGeneralData(generalDataName) {
    return new Promise(resolve => {
        setTimeout(() => {
            addNewGeneralData(generalDataName);
            const generalDataTitlesPage = getPage(allGeneralDataTitles, 1, 10);
            resolve(createPaginatedResultsResponseBody(allGeneralDataTitles, 1, 10, generalDataTitlesPage));
        }, requestTimeout)
    })
}

export function getEstablishmentUploadURL() {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({url: `http://DonAmazon/give_me_my_url/${_.random()}`})
        }, requestTimeout)
    })
}

export function uploadImageToUrl(uploadUrl, image) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({result: 'ok'})
        }, requestTimeout)
    })
}