import api from '../../api/index';
import actions from '../actions';
import {
  FULL_REGISTER_REQUEST,
  LOG_OUT,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  RETRIEVE_USERNAME,
  CONFIRM_EMAIL,
  REFRESH_REQUEST,
  RECOVER_PASSWORD,
  RESET_PASSWORD
} from "../actions/sessionActions";
import {push} from 'connected-react-router'
import {services} from '../../util/storage/storage';
import mapper from './mappers/sessionMapper';
import {sessionErrors} from "../../util/routes/errors";

const session = ({dispatch, getState}) => next => action => {
  next(action);
  switch (action.type) {
    case LOGIN_REQUEST:
      api
        .login(action.email, action.password)
        .then((res) => {
          // If the user request to remember username, save this value into localstorage.
          if (action.rememberUsername) {
            services.insertData("USERNAME", action.email);
          }

          dispatch(actions.session.setTokens(res));
          dispatch(actions.session.loginResponse());

          dispatch(
            actions.user.getUser((res) => {
              // if the user didn't make the full register, redirect to the full register screen.
              if (res.fullRegister === false) {
                dispatch(push("/full_register"));
              } else {
                dispatch(actions.wallet.getWallet(() => dispatch(push("/"))));
              }
            })
          );
        })
        .catch((err) => {
          // If the log in fails due to email no verified, re send the email verification.
          if (err.message === sessionErrors.EMAIL_NOT_VERIFIED) {
            api.resendEmailConfirmation(action.email);
          }
          dispatch(actions.session.loginError(err.message));
        });
      break;
    case REFRESH_REQUEST:
      api
        .refresh(action.refreshToken)
        .then((res) => {
          dispatch(actions.session.setTokens(res));
        })
        .catch((err) => {
          dispatch(actions.session.logout());
        });
      break;
    case REGISTER_REQUEST:
      api
        .basicRegister(action.email, action.password, action.country)
        .then((res) => {
          dispatch(
            actions.session.registerResponse(mapper.response.basicRegister(res))
          );
          if (action.callback) action.callback();
        })
        .catch((err) => {
          dispatch(actions.session.registerError(err.message));
        });
      break;
    case CONFIRM_EMAIL:
      api
        .confirmEmail(action.token)
        .then((res) => {
          dispatch(actions.session.confirmMailResponse(res));
        })
        .catch((err) => {
          dispatch(actions.session.confirmMailError(err));
        });
      break;
    case RECOVER_PASSWORD:
      api.
        passwordRecovery(action.email)
        .then((res)=>{
          dispatch(actions.session.recoverPasswordResponse(res))
        })
        .catch((err)=>{
          dispatch(actions.session.recoverPasswordError(err))
        })
      break
    case RESET_PASSWORD:
      api
        .resetPassword(action.userId, action.token, action.password)
        .then((res) => {
          dispatch(actions.session.resetPasswordResponse(res));
        })
        .catch((err) => {
          dispatch(actions.session.resetPasswordError(err));
        });
      break;
    case FULL_REGISTER_REQUEST:
      dispatch(
        actions.auth.authenticatedRequest((jwt) => {
          api
            .fullRegister(
              jwt,
              action.userId,
              mapper.body.fullRegister(action.info)
            )
            .then((res) => {
              dispatch(actions.session.fullRegisterResponse(res));
              dispatch(actions.wallet.getWallet());
              dispatch(actions.user.getUser());
              if(action.successCallback) action.successCallback();
            })
            .catch((err) => {
              dispatch(actions.session.fullRegisterError(err));
              if(action.errorCallback) action.errorCallback((err));
            });
        })
      );
      break;
    case RETRIEVE_USERNAME:
      services.retrieveData("USERNAME").then((result) => {
        action.callback(result);
      });
      break;
    case LOG_OUT:
      // Checks if a username is saved, if it saved clear the storage and write this value again
      services.retrieveData("USERNAME").then((username) => {
        services.clearData();
        if (username) {
          services.insertData("USERNAME", username);
        }
      });
      break;
  }
};

export default session;