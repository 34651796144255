import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Card, CardBody, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {CheckBoxField} from '../../../shared/components/form/CheckBox';
import lightwallet from 'eth-lightwallet';
// import spanishWordList from '../../../util/wordlists/spanish';
// const bip39 = require('bip39');

const GenerateMnemonic = (props) => {

    const { t, history } = props;

    const [mnemonic, setMnemonic] = useState([]);

    const [savedMnemonic, setSavedMnemonic] = useState(true);

    useEffect(() => {
        let secretSeed = lightwallet.keystore.generateRandomSeed();
        // Todo the library generates an invalid mnemonic (for the keystore of eth light wallet). Ask what to do.
        //let secretSeed = bip39.generateMnemonic(128, undefined, spanishWordList);
        setMnemonic(secretSeed.split(' '))
    }, []);


    return (
        <Container className="generate__mnemonic">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{t('wallet.generateMnemonic.title')}</h3>
                    <h3 className="page-subhead subhead">{t('wallet.generateMnemonic.body')}</h3>
                    <h3 className="page-subhead subhead warning">{t('wallet.generateMnemonic.warning')}</h3>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={{size: 8, offset: 2}}>
                    <Card>
                        <CardBody>
                            <div className="mnemonic__container">
                                {mnemonic.map((word, index) => <span key={index} className="mnemonic__word">{word}</span>)}
                            </div>
                            <CheckBoxField
                                onChange={() => setSavedMnemonic(!savedMnemonic)}
                                value={savedMnemonic}
                                name={'accepted'}
                                label={t('wallet.generateMnemonic.checkboxMessage')}
                            />
                            <Button style={{marginTop: '10px'}} className="btn btn-primary" disabled={!savedMnemonic}
                                    onClick={() => history.push('/wallet/verify_mnemonic', {mnemonic})}>
                                {t('common.continue')}
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
};

GenerateMnemonic.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(GenerateMnemonic);
