/*
*   The user mapper is use for mapping either the body or the response on a user request. It try to decouple the
*   user middleware.
* */

import { ROLES } from "../../../constants";
import i18n from '../../../translations/config/i18n';
import {parse_UTC_date_to_YYYY_MM_DD} from "../../../util";
import {ESTABLISHMENT_TYPES} from "../../../constants";

const mapper =  {
    body: {
        editUserData: (data) => {
            let result = {
                name: data.name,
                lastName: data.lastname,
                birthday: data.birthday,
                document: data.dni,
                mobilePhoneNumber: data.cellular,
                linkedin: data.linkedin,
                web: data.web,
                address: {
                    ...data.homeAddress,
                    country: data.homeAddress.country.value
                }
            };

            if(data.telephone) {
                result['phoneNumber'] = data.telephone;
            }

            return result;
        },
        editUserAvatar: (element) => ({
            avatar: element.images.cvImage
        }),
        addUserCompany: (info, uploadedImagesAndUrls) => {
            return companyBody(info, uploadedImagesAndUrls);
        },
        addEstablishmentToCompany: (info, companyId, establishmentType, uploadedImagesAndUrls) => {
            let body = {};
            switch (establishmentType) {
                case ESTABLISHMENT_TYPES.productive:
                    body = productiveEstablishmentBody(info, uploadedImagesAndUrls);
                    body['company'] = companyId;
                    return body;
                case ESTABLISHMENT_TYPES.slaughterhouse:
                case ESTABLISHMENT_TYPES.commercial:
                case ESTABLISHMENT_TYPES.importer:
                    body = slaughterEstablishmentBody(info, uploadedImagesAndUrls);
                    body['company'] = companyId;
                    return body;
                default :
                    return body;
            }
        },
        editUserEstablishmentById: (info, establishmentType, uploadedImagesAndUrls = undefined) => {
            switch (establishmentType) {
                case ESTABLISHMENT_TYPES.productive:
                    return productiveEstablishmentBody(info, uploadedImagesAndUrls);
                case ESTABLISHMENT_TYPES.slaughterhouse:
                case ESTABLISHMENT_TYPES.commercial:
                case ESTABLISHMENT_TYPES.importer:
                    return slaughterEstablishmentBody(info, uploadedImagesAndUrls);
                default:
                    return {}
            }
        },
        editUserCompanyById: (info, uploadedImagesAndUrls = undefined) => {
            return companyBody(info, uploadedImagesAndUrls);
        },
        editEstablishmentLot: (info) => {
            return editLotBody(info.data);
        },
        addRoleToUser: (rol, license) => {
            if(rol !== ROLES.VETERINARY && rol !== ROLES.AGRICULTURAL_ENGINEER) return null;
            return ({
                licenseNumber: license
            })
        }
    },
    response: {
        editUserData: (response) => {
            let result = {
                email:response.email,
                profile: {
                    name: response.name,
                    lastName: response.lastName,
                    birthday: parse_UTC_date_to_YYYY_MM_DD(response.birthday),
                    dni: response.document,
                    cellular: response.mobilePhoneNumber,
                    linkedin: response.linkedin,
                    web: response.web,
                    avatar: response.avatar,
                    homeAddress: {
                        ...response.address,
                        country: {
                            value: response.address.country,
                            label: i18n.t(`common:countries.${response.address.country}`)
                        }
                    }
                }
            };

            if(response.phoneNumber) {
                result['profile.telephone'] = response.phoneNumber;
            }

            if(response.avatar) {
                result['avatar'] = response.avatar
            }
            return result;
        },
        getUser: (response) => ({
            id: response._id,
            country: response.country,
            email: response.email,
            profile: {
                dni: response.document,
                taxId: response.taxId,
                name: response.name,
                lastName: response.lastName,
                birthday: response.birthday,
                deviceId: response.deviceId,
                avatar: response.avatar,
                linkedin: response.linkedin,
                web: response.web,
                bio: response.bio,
                homeAddress: {
                    ...response.address,
                    country: {
                        value: response.country || response.address.country,
                        label: i18n.t(`common:countries.${response.country || response.address.country}`)
                    }
                },
                cellular: response.mobilePhoneNumber,
                telephone: response.phoneNumber
            },
            roles: response.roles.length === 0 ? [{name: ROLES.WITH_OUT_ROLE}] : response.roles
        }),
        addRole: (response) => ({
            roles: response
        }),
        getUserAccounts: (response) => ({
            results: response.docs,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getSurrogateUserAccounts: (response) => ({
            results: response.docs,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getSurrogateUserEstablishments: (response) => ({
            results: response.docs,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getUserCompany: (response) => {
            return ({
                id: response._id,
                cuitOrCuil: response.tin,
                businessName: response.legalName,
                logotypePhoto: response.logotypeImage,
                webSite: response.webSite,
                email: response.email,
                linkedin: response.linkedin,
                personType: {
                    value: response.entityType,
                    label: i18n.t(`common:cv-forms.company.person_types.${response.entityType}`)
                },
                companyCommercialAddress: {
                    ...response.commercialAddress,
                    country: {
                        value: response.commercialAddress.country,
                        label: i18n.t(`common:countries.${response.commercialAddress.country}`)
                    }
                }
            });
        },
        editUserCompanyById: (response) => {
            return ({
                id: response._id,
                cuitOrCuil: response.tin,
                businessName: response.legalName,
                logotypePhoto: response.logotypeImage,
                webSite: response.webSite,
                email: response.email,
                linkedin: response.linkedin,
                personType: {
                    value: response.entityType
                },
                companyCommercialAddress: {
                    ...response.commercialAddress,
                    country: {
                        value: response.commercialAddress.country,
                        label: i18n.t(`common:countries.${response.commercialAddress.country}`)
                    }
                }
            });
        },
        getUserEstablishments: (response) => ({
            results: response.docs,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getAllUserEstablishments: (response) => (mapEstablishmentNameAndId(response.docs)),
        getAllEstablishments: (response) => (mapEstablishmentNameAndId(response.docs)),
        getUserEstablishmentById: (response) => {
            switch (response.type) {
                case ESTABLISHMENT_TYPES.productive:
                    return mapProductiveEstablishmentResponse(response);
                case ESTABLISHMENT_TYPES.slaughterhouse:
                case ESTABLISHMENT_TYPES.commercial:
                case ESTABLISHMENT_TYPES.importer:
                    return mapSlaughterEstablishmentResponse(response);
                default:
                    return {}
            }
        },
        getAssignedCompanies: (response) => (mapCompaniesNamesIdsAndAddresses(response)),
        getAllUserCompanies: (response) => (mapCompaniesNamesIdsAndAddresses(response.docs)),
        getUserEstablishmentsByCompany: (response) => (mapEstablishmentNameAndId(response.docs)),
        getValuesForSelector: (response) => (getValuesForSelector(response)),
        addUserCompany: (response) => (mapCompaniesNamesIdsAndAddresses([response])[0]),
        getProfessionalUserByDni: (response, currentProfessionals) => {
            if(!response.user) return {};
            // if the professional is already added to the establishment don't show it
            if(currentProfessionals.some((professional) => (professional.userId === response.user._id))) return {};
            return {
                name: response.user.name,
                lastName: response.user.lastName,
                userId: response.user._id,
                role: response.role[0].name
            }
        },
        getProfessionalsByEstablishment: (response) => {
            let result = [];
            response.assignedUsers.filter(professional => (professional.isActive)).forEach(professional => {
                result.push({
                    name: professional.name,
                    lastName: professional.lastName,
                    userId: professional.userId,
                    role: professional.role
                })
            });
            return result;
        },
        getSurrogatesByCompany: (response) => {
            let result = [];
            response.assignedUsers.filter(surrogate => (surrogate.isActive)).forEach(surrogate => {
                result.push({
                    name: surrogate.name,
                    lastName: surrogate.lastName,
                    userId: surrogate.userId,
                    role: surrogate.role
                })
            });
            return result;
        },
        getSurrogateUserByDni: (response, currentsSurrogates) => {
            if(!response.user) return {};
            // if the surrogate is already added to the company don't show it
            if(currentsSurrogates.some((surrogate) => (surrogate.userId === response.user._id))) return {};
            return {
                name: response.user.name,
                lastName: response.user.lastName,
                userId: response.user._id,
                role: response.role[0].name
            }
        },
        getLotsByEstablishment: (response) => {
                response.push({
                    _id: '0',
                    name: i18n.t(`common:selectors.lotSelector.no_lot`)
                });
            return response;
        }
    },
    data: {
        uploadEstablishmentImages: (info, establishmentType) => {
            switch (establishmentType) {
                case ESTABLISHMENT_TYPES.productive:
                    return mapProductiveEstablishmentImages(info);
                case ESTABLISHMENT_TYPES.slaughterhouse:
                case ESTABLISHMENT_TYPES.commercial:
                case ESTABLISHMENT_TYPES.importer:
                    return mapSlaughterEstablishmentImages(info);
                default:
                    return []
            }
        },
        uploadCompanyImages: (info) => {
            return mapCompanyImages(info);
        }
    }
};

const editLotBody = (data) => {
    return {
        lotId : data.establishmentLot,
        name: data.name,
        details: data.details
    }
}

const companyBody = (info, uploadedImagesAndUrls) => {
    let body = {
        legalName: info.businessName,
        tin: info.cuitOrCuil,
        entityType: info.personType.value,
        webSite: info.webSite,
        email: info.email,
        linkedin: info.linkedin,
        address: {
            country: info.companyCommercialAddress.country.value,
        },
        commercialAddress: {
            ...info.companyCommercialAddress,
            country: info.companyCommercialAddress.country.value,
        },
    };
    if(uploadedImagesAndUrls) {
        // this check is done because the current method is use to add a new company and to edit it
        if(uploadedImagesAndUrls.logotypeImage) body['logotypeImage'] = uploadedImagesAndUrls.logotypeImage;
    }
    return body;
};

const parseProductiveSystemOptionsToArray = (productiveSystems) => {
    let result = [];
    if(productiveSystems.natural) result.push('natural');
    if(productiveSystems.silvoPastoral) result.push('silvoPastoral');
    if(productiveSystems.pastoral) result.push('pastoral');
    if(productiveSystems.creepFeeding) result.push('creepFeeding');
    if(productiveSystems.strategicFeeding) result.push('strategicFeeding');
    if(productiveSystems.feedlot) result.push('feedlot');

    return result;
};

const parseProductiveSystemOptionsToJson = (productiveSystemsArray) => {
    let result = {
        natural: false,
        silvoPastoral: false,
        pastoral: false,
        creepFeeding: false,
        strategicFeeding: false,
        feedlot: false,
    };
    productiveSystemsArray.forEach(productiveSystem => {
        result[`${productiveSystem}`] = true;
    });
    return result;
};

// todo delete this method when not necessary. It was added because the 'get all establishment' route was not finish.
const mapEstablishmentNameAndId = (establishments) => {
    let result = [];
    establishments.forEach(establishment => {
        result.push({
            _id: establishment._id,
            name: establishment.name,
            companyId: establishment._id,
            productiveSystems: establishment.productionSystems
        })
    });
    return result
};
const getValuesForSelector = (elements) => {
    let result = [];
    elements.forEach(elements => {
        result.push({
            value: elements._id,
            label: elements.name,
        })
    });
    return result
};
// todo delete this method when not necessary. It was added because the 'get all companies' route was not finish.
const mapCompaniesNamesIdsAndAddresses = (companies) => {
    let result = [];
    companies.forEach(company => {
        result.push({
            _id: company._id,
            name: company.legalName,
            addresses: {
                legal: {
                    ...company.address,
                    country: {
                        value: company.address.country,
                        label: i18n.t(`common:countries.${company.address.country}`)
                    }
                },
                commercial: {
                    ...company.commercialAddress,
                    country: {
                        value: company.commercialAddress.country,
                        label: i18n.t(`common:countries.${company.commercialAddress.country}`)
                    }
                }
            }
        })
    });
    return result;
};

const productiveEstablishmentBody = (info, uploadedImagesAndUrls) => {
    let body = {
        name: info.establishmentName,
        cuig: info.cuig,
        webSite: info.webSite,
        email: info.email,
        linkedin: info.linkedin,
        renspaCode: info.renspaCode,
        surfaceArea: info.surfaceArea,
        location: {
            type: 'coordinates',
            coordinates: [
                Number.parseFloat(info.coordinates.lat),
                Number.parseFloat(info.coordinates.long)
            ]
        },
        groundCondition: info.groundCondition.value,
        productionSystems: parseProductiveSystemOptionsToArray(info.productiveSystems),
        apts: info.apts,
        address: {
            ...info.establishmentAddress,
            country: info.establishmentAddress.country.value
        },
        animalLots: mapLotsToBody(info)
    };

    if(uploadedImagesAndUrls) {
        // this check is done because the current method is use to add a new productive establishment and to edit it
        if(uploadedImagesAndUrls.renspaImage) body['renspaImage'] = uploadedImagesAndUrls.renspaImage;

        //  Optional values are not in body unless it has a value.
        if(uploadedImagesAndUrls.entryImage) body['entryImage'] = uploadedImagesAndUrls.entryImage;

        if(uploadedImagesAndUrls.logotypeImage) body['logotypeImage'] = uploadedImagesAndUrls.logotypeImage;

        //  Count all the 'other' images.
        const otherImagesTotal = Object.keys(uploadedImagesAndUrls)
            .filter((key) => key.includes("otherImages")).length;

        //  If there is at least one 'other' image save into array an pass into body.
        if(otherImagesTotal > 0){
            let otherImagesTemp = [];
            for (let i = 0; i < otherImagesTotal; i++) {
                otherImagesTemp.push(uploadedImagesAndUrls[`otherImages${i}`])
            }
            body['otherImages'] = otherImagesTemp;
        }

        if(uploadedImagesAndUrls.fireMarkImage) body['fireMarkImage'] = uploadedImagesAndUrls.fireMarkImage;
        if(uploadedImagesAndUrls.productionSystemImage) body['productionSystemImage'] = uploadedImagesAndUrls.productionSystemImage;
        if(uploadedImagesAndUrls.airViewImage) body['airViewImage'] = uploadedImagesAndUrls.airViewImage;
    }

    return body;
};

const slaughterEstablishmentBody = (info, uploadedImagesAndUrls) => {
    let body = {
        name: info.establishmentName,
        location: {
            type: 'coordinates',
            coordinates: [
                Number.parseFloat(info.coordinates.lat),
                Number.parseFloat(info.coordinates.long)
            ]
        },
        webSite: info.webSite,
        email: info.email,
        linkedin: info.linkedin,
        healthAccreditation: info.healthAccreditation,
        enrollment: info.enrollment,
        surfaceArea: info.surfaceArea,
        apts: info.apts,
        ruca: {
            ruca: info.ruca.id,
            activities: info.ruca.activities.map(activity => ({
                activityCode: activity.value,
                activity: activity.label
            })),
            rucaImage: info.ruca.image
        },
        address: {
            ...info.establishmentAddress,
            country: info.establishmentAddress.country.value
        },
        animalLots: mapLotsToBody(info)
    };

    if(uploadedImagesAndUrls) {
        // this check is done because the current method is use to add a new productive establishment and to edit it
        if(uploadedImagesAndUrls.enrollmentImage) body['enrollmentImage'] = uploadedImagesAndUrls.enrollmentImage;
        // this check is done because the current method is use to add a new productive establishment and to edit it
        if(uploadedImagesAndUrls.rucaImage) body.ruca['rucaImage'] = uploadedImagesAndUrls.rucaImage;
    }

    return body;
};

const mapProductiveEstablishmentResponse = (response) => {
    let result = {
        id: response._id,
        type: response.type,
        establishmentName: response.name,
        cuig: response.cuig,
        apts: response.apts,
        resnpaCredentialPhoto: response.renspaImage,
        surfaceArea: response.surfaceArea,
        email: response.email,
        animalLots:{
            lots: mapActiveLots(response.animalLots.lots),
            mode: response.animalLots.mode
        },
        linkedin: response.linkedin,
        webSite: response.webSite,
        logotypePhoto: response.logotypeImage,
        groundCondition: {
            value: response.groundCondition,
            label: i18n.t(`common:cv-forms.productive_establishment.ground_conditions.${response.groundCondition}`)
        },
        establishmentAddress: {
            ...response.address,
            country: {
                value: response.address.country,
                label: i18n.t(`common:countries.${response.address.country}`)
            }
        },
        coordinates: {
            lat: response.location.coordinates[0],
            long: response.location.coordinates[1]
        },
        productiveSystems: parseProductiveSystemOptionsToJson(response.productionSystems),
        renspaCode: response.renspaCode
    };

    // Optional Images
    if(response.fireMarkImage) result['fireMarkPhoto'] = response.fireMarkImage;
    if(response.entryImage) result['entrancePhoto'] = response.entryImage;
    if(response.airViewImage) result['aerialPhoto'] = response.airViewImage;
    if(response.productionSystemImage) result['productiveSystemPhoto'] = response.productionSystemImage;
    // image not implemented in the api
    //if(response.animalWelfarePhoto) result['animalWelfarePhoto'] = response.animalWelfarePhoto;
    if(response.entryImage) result['entrancePhoto'] = response.entryImage;
    if(response.otherImages) result['otherPhotos'] = response.otherImages;

    return result;
};

const mapSlaughterEstablishmentResponse = (response) => {
    let result = {
        id: response._id,
        type: response.type,
        establishmentName: response.name,
        apts: response.apts,
        email: response.email,
        //animalLots: response.animalLots,
        animalLots:{
            lots: mapActiveLots(response.animalLots.lots),
            mode: response.animalLots.mode
        },
        linkedin: response.linkedin,
        webSite: response.webSite,
        logotypePhoto: response.logotypeImage,
        establishmentAddress: {
            ...response.address,
            country: {
                value: response.address.country,
                label: i18n.t(`common:countries.${response.address.country}`)
            }
        },
        coordinates: {
            lat: response.location.coordinates[0],
            long: response.location.coordinates[1]
        },
        healthAccreditation: response.healthAccreditation,
        enrollment: response.enrollment,
        surfaceArea: response.surfaceArea,
        enrollmentImage: response.enrollmentImage,
        ruca: {
            id: response.ruca.ruca,
            image: response.ruca.rucaImage,
            activities: response.ruca.activities.map(activity => ({
                value: activity.activityCode,
                label: activity.activity
            }))
        }
    };

    return result;
};

const mapProductiveEstablishmentImages = (info) => {
    let images = [];

    //  Clarification: If any image is an string this means that the image is an url, so the image is already
    // upload.

    if(info.fireMarkPhoto && typeof info.fireMarkPhoto !== 'string') {
        images.push({
            id: 'fireMarkImage',
            image: info.fireMarkPhoto.files[0]
        });
    }

    if(info.resnpaCredentialPhoto && typeof info.resnpaCredentialPhoto !== 'string') {
        images.push({
            id: 'renspaImage',
            image: info.resnpaCredentialPhoto.files[0]
        });
    }

    if(info.logotypePhoto && typeof info.logotypePhoto !== 'string') {
        images.push({
            id: 'logotypeImage',
            image: info.logotypePhoto.files[0]
        });
    }

    if(info.entrancePhoto && typeof info.entrancePhoto !== 'string') {
        images.push({
            id: 'entryImage',
            image: info.entrancePhoto.files[0]
        })
    }

    //  If 'otherPhotos' is an array this means that the images are already uploaded.
    if(info.otherPhotos && !(info.otherPhotos instanceof Array)) {
        info.otherPhotos.files.forEach((otherPhoto, index) => {
            images.push({
                id: `otherImages${index}`,
                image: otherPhoto
            })
        })
    }

    if(info.productiveSystemPhoto && typeof info.productiveSystemPhoto !== 'string') {
        images.push({
            id: 'productionSystemImage',
            image: info.productiveSystemPhoto.files[0]
        });
    }

    if(info.aerialPhoto && typeof info.aerialPhoto !== 'string') {
        images.push({
            id: 'airViewImage',
            image: info.aerialPhoto.files[0]
        });
    }

    return images;
};

const mapSlaughterEstablishmentImages = (info) => {
    let images = [];

    //  Clarification: If any image is an string this means that the image is an url, so the image is already
    // uploaded.

    if(info.enrollmentImage && typeof info.enrollmentImage !== 'string') {
        images.push({
            id: 'enrollmentImage',
            image: info.enrollmentImage.files[0]
        });
    }

    if(info.ruca.image && typeof info.ruca.image !== 'string') {
        images.push({
            id: 'rucaImage',
            image: info.ruca.image.files[0]
        });
    }

    return images;
};

const mapCompanyImages = (info) => {
    let images = [];
    // If any image is an string this means that the image is an url, so the image is already uploaded.
    if(info.logotypePhoto && typeof info.logotypePhoto !== 'string') {
        images.push({
            id: 'logotypeImage',
            image: info.logotypePhoto.files[0]
        });
    }
    return images;
};


const mapEstablishmentLots = (data) => {
    let result = []
    for (let lot of data) {
        result.push(lot)
    }
    return result
};

const mapActiveLots = (data) => {
    let result = []
    // TODO fix this
    // this logic is probably to be soon deprecated
    // but name should at least be a constant
    //result.push({_id: 0, name: "Todos los lotes"})
    for (let lot of data) {
        if(lot.isActive) result.push(lot)
    }
    return result
};

const mapLotsToBody = (info) => {
    if (info.animalLots) {
        return {
            mode : info.animalLots.mode,
            lots: mapEstablishmentLots(info.animalLots.lots)
        }
    }
};

export default mapper;