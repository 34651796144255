import React from 'react';
import { Route, Switch } from 'react-router-dom';

//import NewEvent from '../../../Events/NewEvent';
import MassiveLoad from '../../../Events/MassiveLoad';

import NewChangeProductiveSystemEventContainer from '../../../Events/components/NewEventContainers/NewChangeProductiveSystemEventContainer';
import ChangeProductiveSystemEventTable from '../../../Events/components/EventTables/ChangeProductiveSystemEventTable';
import ChangeProductiveSystemEventDetailsContainer from '../../../Events/components/EventDetailContainers/ChangeProductiveSystemEventDetailsContainer';
import ChangeProductionSystemEventMassiveLoadTable from '../../../Events/components/EventMassiveLoadTables/ChangeProductionSystemEventMassiveLoadTable';

import NewReidentificationEventContainer from '../../../Events/components/NewEventContainers/NewCaravanReidentificationEventContainer';
import ReidentificationTable from '../../../Events/components/EventTables/ReidentificationTable';
import ReidentificationEventDetails from '../../../Events/components/EventDetailContainers/ReidentificationEventDetailsContainer'
import ReidentificationEventMassiveLoadTable from '../../../Events/components/EventMassiveLoadTables/ReidentificationEventMassiveLoadTable';

import NewVaccinationEventContainer from '../../../Events/components/NewEventContainers/NewVaccinationEventContainer'
import VaccinationTable  from '../../../Events/components/EventTables/VaccinationTable';
import VaccinationEventDetailsContainer from '../../../Events/components/EventDetailContainers/VaccinationEventDetailsContainer';

import NewSlaughterEventContainer from '../../../Events/components/NewEventContainers/NewSlaughterEventContainer';
import SlaughterTable  from '../../../Events/components/EventTables/SlaughterTable';
import SlaughterEventDetailsContainer  from '../../../Events/components/EventDetailContainers/SlaughterEventDetailsContainer';
import SlaughterEventMassiveLoadTable from '../../../Events/components/EventMassiveLoadTables/SlaughterEventMassiveLoadTable';

import NewCastrationEventContainer from '../../../Events/components/NewEventContainers/NewCastrationEventContainer';
import CastrationEventDetailsContainer from '../../../Events/components/EventDetailContainers/CastrationEventDetailsContainer';
import CastrationEventMassiveLoadTable from '../../../Events/components/EventMassiveLoadTables/CastrationEventMassiveLoadTable';
import CastrationTable from '../../../Events/components/EventTables/CastrationTable';

import NewWeightEventContainer from '../../../Events/components/NewEventContainers/NewWeightEventContainer';
import WeightEventMassiveLoadTable from '../../../Events/components/EventMassiveLoadTables/WeightEventMassiveLoadTable';
import WeightTable from '../../../Events/components/EventTables/WeightTable';
import WeightEventDetailsContainer from '../../../Events/components/EventDetailContainers/WeightEventDetailsContainer';

import RecategorizationTable from '../../../Events/components/EventTables/RecategorizationTable'
import RecategorizationEventMassiveLoadTable from '../../../Events/components/EventMassiveLoadTables/RecategorizationEventMassiveLoadTable'
import RecategorizationEventDetailsContainer from '../../../Events/components/EventDetailContainers/RecategorizationEventDetailsContainer';

import TransferTable from "../../../Events/components/EventTables/TransferTable";
import TransferEventMassiveLoadTable from "../../../Events/components/EventMassiveLoadTables/TransferEventMassiveLoadTable";

import NewDentitionEventContainer from "../../../Events/components/NewEventContainers/NewDentitionEventContainer";
import DentitionEventDetailsContainer from "../../../Events/components/EventDetailContainers/DentitionDetailsContainer";
import DentitionTable from '../../../Events/components/EventTables/DentitionTable';
import DentitionEventMassiveLoadTable from "../../../Events/components/EventMassiveLoadTables/DentitionEventMassiveLoadTable";

import NewDeletionEventContainer from "../../../Events/components/NewEventContainers/NewDeletionEventContainer";
import DeletionEventDetailsContainer from "../../../Events/components/EventDetailContainers/DeletionEventDetailsContainer";
import DeletionTable from '../../../Events/components/EventTables/DeletionEventTable';
import DeletionEventMassiveLoadTable from "../../../Events/components/EventMassiveLoadTables/DeletionEventMassiveLoadTable";

import NewLotEventContainer from "../../../Events/components/NewEventContainers/NewLotEventContainer";
import LotEventDetailsContainer from "../../../Events/components/EventDetailContainers/LotEventDetailsContainer";
import LotTable from '../../../Events/components/EventTables/LotEventTable';
import LotEventMassiveLoadTable from "../../../Events/components/EventMassiveLoadTables/LotEventMassiveLoadTable";

export default () => (
    <Switch>

        {/*<Route path="/events/newEvent" render={props => <NewEvent {...props} />} />*/}
        <Route path="/events/massive_load" component={MassiveLoad} />

        <Route path="/events/change_productive_system/new_event" component={NewChangeProductiveSystemEventContainer} />
        <Route path="/events/change_productive_system/details" component={ChangeProductiveSystemEventDetailsContainer} />
        <Route path="/events/change_productive_system/massive_load" component={ChangeProductionSystemEventMassiveLoadTable} />
        <Route path="/events/change_productive_system/table" component={ChangeProductiveSystemEventTable} />

        <Route path="/events/weight/new_event" component={NewWeightEventContainer} />
        <Route path="/events/weight/details" component={WeightEventDetailsContainer} />
        <Route path="/events/weight/table" component={WeightTable} />
        <Route path="/events/weight/massive_load" component={WeightEventMassiveLoadTable} />

        <Route path="/events/castration/new_event" component={NewCastrationEventContainer} />
        <Route path="/events/castration/details" component={CastrationEventDetailsContainer} />
        <Route path="/events/castration/table" component={CastrationTable} />
        <Route path="/events/castration/massive_load" component={CastrationEventMassiveLoadTable} />

        <Route path="/events/dentition/table" component={DentitionTable} />
        <Route path="/events/dentition/new_event" component={NewDentitionEventContainer} />
        <Route path="/events/dentition/details" component={DentitionEventDetailsContainer} />
        <Route path="/events/dentition/massive_load" component={DentitionEventMassiveLoadTable} />

        <Route path="/events/deletion/table" component={DeletionTable} />
        <Route path="/events/deletion/new_event" component={NewDeletionEventContainer} />
        <Route path="/events/deletion/details" component={DeletionEventDetailsContainer} />
        <Route path="/events/deletion/massive_load" component={DeletionEventMassiveLoadTable} />

        <Route path="/events/lot/table" component={LotTable} />
        <Route path="/events/lot/new_event" component={NewLotEventContainer} />
        <Route path="/events/lot/details" component={LotEventDetailsContainer} />
        <Route path="/events/lot/massive_load" component={LotEventMassiveLoadTable} />

        <Route path="/events/vaccination/new_event" component={NewVaccinationEventContainer} />
        <Route path="/events/vaccination/table" component={VaccinationTable} />
        <Route path="/events/vaccination/details" component={VaccinationEventDetailsContainer} />

        <Route path="/events/recategorization/table" component={RecategorizationTable} />
        <Route path="/events/recategorization/details" component={RecategorizationEventDetailsContainer} />
        <Route path="/events/recategorization/massive_load" component={RecategorizationEventMassiveLoadTable} />

        <Route path="/events/reidentification/new_event" component={NewReidentificationEventContainer} />
        <Route path="/events/reidentification/table" component={ReidentificationTable} />
        <Route path="/events/reidentification/details" component={ReidentificationEventDetails} />
        <Route path="/events/reidentification/massive_load" component={ReidentificationEventMassiveLoadTable} />

        <Route path="/events/transfer/table" component={TransferTable} />
        <Route path="/events/transfer/massive_load" component={TransferEventMassiveLoadTable} />

        <Route path="/events/slaughter/new_event" component={NewSlaughterEventContainer} />
        <Route path="/events/slaughter/table" component={SlaughterTable} />
        <Route path="/events/slaughter/details" component={SlaughterEventDetailsContainer} />
        <Route path="/events/slaughter/massive_load" component={SlaughterEventMassiveLoadTable} />

    </Switch>
);