import React, {useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import LogInForm from './components/LogInForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import cvLogo from '../../../shared/img/logo/cv-logo-long.png';
import {connect} from "react-redux";
import actions from "../../../redux/actions";
import CVModal from "../../UI/Modals/components/CVModal";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import sessionActions from "../../../redux/actions/sessionActions";
import cowTransparent from "../../../shared/img/cow-transparent.gif";

const LogIn = (props) => {
    const { t } = props;
    const [forgotPasswordModalIsOpen, setForgotPasswordModalOpen] = useState(false);
    const [emailSentModalIsOpen, setEmailSentModalOpen] = useState(false);
    const [usernameSaved, setUsernameSaved] = useState();

    useEffect(() => {
        props.retrieveUsername((userName) => {
            if(userName) setUsernameSaved(userName)
        });
        // if there is a token on the route means that email confirmation needs to be done.
        if(props.match.params.token) {
            props.confirmEmail(props.match.params.token);
        }
    }, []);

    useEffect(() => {
        if(!props.emailConfirmationPending) {
            if(props.emailConfirmationError) {
                ToastsStore.error(props.t('full_register.alerts.error_email_confirmation'), 60000);
            }
            else if(props.emailConfirmSuccessful) {
                ToastsStore.success(props.t('full_register.alerts.successful_email_confirmation'), 10000)
            }
        }
    }, [props.emailConfirmationPending]);

    useEffect(() => {
        if(props.resetPasswordSentSuccessful) {
                ToastsStore.success(props.t('reset_password.success'), 10000)
        }
    }, [props.resetPasswordPending]);

    const _handleForgotPassword = (values) => {
        props.recoverPasswordRequest(values.email);
        setForgotPasswordModalOpen(!forgotPasswordModalIsOpen);
        setEmailSentModalOpen(!emailSentModalIsOpen);
    };

    const renderModalBody = () => (
        <div>
            <h5 style={{marginBottom: 42}}>{t('login.modals.forgotPassword.body')}</h5>
            <ForgotPasswordForm
                onSubmit={_handleForgotPassword}
            />
        </div>
    );

    const forgotPasswordModal = () => (
        <CVModal
            title={t('login.modals.forgotPassword.title')}
            body={() => renderModalBody()}
            color="primary"
            header
            isOpen={forgotPasswordModalIsOpen}
            toggle={() => setForgotPasswordModalOpen(!forgotPasswordModalIsOpen)}
            hideButtons={true}
            showAcceptOnly={false}
        />
    );

    const emailSentModal = () => (
        <CVModal
            title={t('login.modals.emailSent.title')}
            body={() => <div>{t('login.modals.emailSent.body')}</div>}
            color="success"
            header
            isOpen={emailSentModalIsOpen}
            toggle={() => setEmailSentModalOpen(!emailSentModalIsOpen)}
            acceptFunc={() => {
                setEmailSentModalOpen(!emailSentModalIsOpen);
                props.history.push('/reset_password');
            }}
            hideButtons={false}
            showAcceptOnly={true}
        />
    );

    const _handleLoginRequest = (values) => {
        props.loginRequest(values.email, values.password, values.remember_me);
    };

    return (
        <div>
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <div className="account">
                <div className="account__wrapper">
                    <div className="account__card">
                        <div className="account__head">
                            <img src={cvLogo} alt="cv-logo"/>
                        </div>
                        {
                            props.emailConfirmationPending ?
                                <div className="loading">
                                    <img alt="cow" src={cowTransparent}/>
                                </div>:
                                props.emailConfirmationError ?
                                    null:
                                    <LogInForm
                                        onSubmit={_handleLoginRequest}
                                        loginPending={props.loginPending}
                                        loginError={props.loginError}
                                        loginErrorMessage={props.loginErrorMessage}
                                        toggleForgotPassword={() => setForgotPasswordModalOpen(!forgotPasswordModalIsOpen)}
                                        usernameSaved={usernameSaved}
                                    />
                        }
                    </div>
                </div>
                {forgotPasswordModal()}
                {emailSentModal()}
            </div>
        </div>

    );
};

const mapStateToProps = (state) => {
    return {
        resetPasswordPending: state.ui.resetPasswordPending,
        resetPasswordError: state.ui.resetPasswordError,
        resetPasswordSentSuccessful: state.ui.resetPasswordSentSuccessful,
        emailConfirmationPending: state.ui.confirmEmailPending,
        emailConfirmationError: state.ui.confirmEmailError,
        emailConfirmSuccessful: state.ui.emailConfirmedSuccessful,
        loginPending: state.ui.loginPending,
        loginError: state.ui.loginError,
        loginErrorMessage: state.ui.loginErrorMessage,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        recoverPasswordRequest: (email) => dispatch(sessionActions.recoverPasswordRequest(email)),
        confirmEmail: (token) => dispatch(sessionActions.confirmMailRequest(token)),
        loginRequest: (email, password, rememberUsername) =>
            dispatch(actions.session.loginRequest(email, password, rememberUsername)),
        retrieveUsername: (callback) => dispatch(actions.session.retrieveUserName(callback))
    }
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(LogIn));
