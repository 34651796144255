/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import CVTable from '../../../Tables/CVTable/CVTable';
import {Button} from "reactstrap";
import cowTransparent from "../../../../shared/img/cow-transparent.gif";
import actions from '../../../../redux/actions/index';
import {Redirect} from "react-router-dom";
import CheckOutlineIcon from 'mdi-react/CheckOutlineIcon';
import CloseOutlineIcon from 'mdi-react/CloseOutlineIcon';
import {useAlert} from "react-alert";
import {GENERIC_ERROR} from '../../../../util/routes/errors';
import CVButton from "../../../../shared/components/cv/CVButton";
import { EVENT_TYPES } from '../../../../constants/index';

const ReidentificationEventMassiveLoadTable = (props) => {
  const [events, setEvents] = useState();
  const [total, setTotal] = useState(0);
  const [redirectRoute, setRedirectRoute] = useState();

  const alert = useAlert();

  useEffect(() => {
    if (props.events !== undefined) {
      setEvents(props.events);
      setTotal(props.events.length)
    }
  }, [props.events]);

  const {t, isLoading, thereIsAnError, acceptMassiveEventUpload} = props;

  const renderValueDefault = ({value}) => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {value}
      </div>
    )
  };

  const renderStatus = ({value}) => {
    let Icon;

    switch (value) {
      case 'error':
        Icon = <CloseOutlineIcon color={'red'}/>;
        break;
      default:
        Icon = <CheckOutlineIcon color={'green'}/>;
        break;

    }

    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {Icon}
      </div>
    )
  };

  const renderErrorMessage = ({value}) => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {
          value.length > 0 ? t(`errors.${value[0]}`) : '-'
        }
      </div>
    )
  };

  const heads = [
    {
      key: 'status',
      name: t('events.change_productive_system.table.headers.status'),
      percentage: 5,
      minWidth: 80,
      formatter: renderStatus
    },
    {
      key: 'errors',
      name: t('events.change_productive_system.table.headers.errors'),
      percentage: 10,
      minWidth: 200,
      formatter: renderErrorMessage
    },
    {
      key: 'earId',
      name: t('events.change_productive_system.table.headers.caravan_id'),
      percentage: 7.6,
      minWidth: 120,
      formatter: renderValueDefault
    },
    {
      key: 'newEarId',
      name: 'Nuevo Id de Caravana',
      percentage: 7.6,
      minWidth: 140,
      formatter: renderValueDefault
    },
    {
      key: 'eventDate',
      name: t('events.change_productive_system.table.headers.date'),
      percentage: 7.6,
      minWidth: 120,
      formatter: renderValueDefault
    }
  ];

  const submit = () => {
    acceptMassiveEventUpload(props.excelId, () => {
      setRedirectRoute('/events/reidentification/table');
      alert.success(t('events.change_productive_system.alerts.massive_events_added'));
    }, (errorMessage) => {
      if(errorMessage === GENERIC_ERROR)
        alert.error(t('events.change_productive_system.alerts.massive_events_generic_error'));
      else
        alert.error(t(`errors.${errorMessage}`));
    });
  };

  const cancel = () => {
    setRedirectRoute('/events/reidentification/table')
  };

  return (
    <div className="animal-massive-load">
      {
        isLoading &&
        <div className="loading">
          <img alt="cow" src={cowTransparent}/>
          <span>{t('events.change_productive_system.massive_load_table.we_are_processing_the_file')}</span>
        </div>
      }
      {
        !isLoading &&
        <div>
          <CVTable
            getPage={() => {}}
            data={events}
            total={total}
            currentPage={1}
            heads={heads}
            hideFilters
            hidePagination
            title={t('events.change_productive_system.massive_load_table.title')}
            noDataLabel={t('events.change_productive_system.massive_load_table.no_data_label')}
          />
          <div className="button-container">
            <CVButton color="primary" pending={props.acceptLoading} onClick={submit}>{t('common.accept')}</CVButton>
            <Button className="btn btn-secondary" onClick={cancel}>{t('common.cancel')}</Button>
          </div>
        </div>
      }
      {redirectRoute && <Redirect to={redirectRoute}/>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  events: state.event.massiveLoad.events,
  isLoading: state.ui.uploadFileForMassiveEventsLoadPending,
  thereIsAnError: state.ui.uploadFileForMassiveEventsLoadError,
  excelId: state.event.massiveLoad._id,
  acceptLoading: state.ui.acceptMassiveEventsLoadPending,
  acceptError: state.ui.acceptMassiveEventsLoadError
});

const mapDispatchToProps = (dispatch) => ({
  acceptMassiveEventUpload: (excelId, successCallback, errorCallback) =>
    dispatch(actions.event.acceptMassiveEventUpload(excelId, 'reidentificationEvent', successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ReidentificationEventMassiveLoadTable))
