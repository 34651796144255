import {
    GET_AVAILABLE_COUNTRIES_RESPONSE,
    GET_AVAILABLE_COW_BREEDS_RESPONSE,
    GET_AVAILABLE_FACILITIES_RESPONSE,
    GET_FACILITY_BY_CODE_RESPONSE, RESET_FACILITY_SEARCH
} from "../actions/generalDataActions";

const initialState = {
    availableCountries: [],
    availableCowBreeds: [],
    availableFacilities: [],
    facilitySearch: {fetched: false, res: undefined}
};

const generalDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AVAILABLE_COUNTRIES_RESPONSE:
            return {
                ...state,
                availableCountries: action.response.countries
            };
        case GET_AVAILABLE_COW_BREEDS_RESPONSE:
            return {
                ...state,
                availableCowBreeds: action.response.breeds
            };
        case GET_AVAILABLE_FACILITIES_RESPONSE:
            return {
                ...state,
                availableFacilities: action.response
            };
        case GET_FACILITY_BY_CODE_RESPONSE:
            return {
                ...state,
                facilitySearch: !action.response ? undefined : action.response
            };
        case RESET_FACILITY_SEARCH:
            return {
                ...state,
                facilitySearch: {fetched: false, res: undefined},
            };
        default:
            return state
    }
};
export default generalDataReducer;