import React from 'react';
import EstablishmentTable from "./components/EstablishmentTable";

const ManageEstablishments = (props) => {
    return (
        <div>
            <EstablishmentTable/>
        </div>
    )
};

export default ManageEstablishments;