import api from '../../api/index';
import {
    GET_NOTIFICATION_PAGE, ACCEPT_ROL, REJECT_ROL, SEARCH_NOTIFICATIONS, FILTER_NOTIFICATIONS
} from '../actions/notificationActions';
import actions from '../actions';
import mapper from './mappers/notificationMapper';

const notification = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {
        case GET_NOTIFICATION_PAGE:
            api.getNotifications(action.page, action.pageSize)
                .then(res => {
                    dispatch(actions.notification.getPageResponse(mapper.response.getNotifications(res)))
                })
                .catch(err => {
                    dispatch(actions.notification.getPageError(err))
                });
            break;
        case ACCEPT_ROL:
            api.acceptNotificationRol(action.notificationId)
                .then(res => {
                    dispatch(actions.notification.acceptRolResponse(res))
                })
                .catch(err => {
                    dispatch(actions.notification.acceptRolError(err))
                });
            break;
        case REJECT_ROL:
            api.rejectNotificationRol(action.notificationId)
                .then(res => {
                    dispatch(actions.notification.rejectRolResponse(res))
                })
                .catch(err => {
                    dispatch(actions.notification.rejectRolError(err))
                });
            break;
        case SEARCH_NOTIFICATIONS:
            api.searchNotifications(action.word)
                .then(res => {
                    dispatch(actions.notification.searchResponse(mapper.response.searchNotifications(res)))
                })
                .catch(err => {
                    dispatch(actions.notification.searchError(err))
                });
            break;
        case FILTER_NOTIFICATIONS:
            api.filterNotifications(action.filters)
                .then(res => {
                    dispatch(actions.notification.filterResponse(mapper.response.filterNotifications(res)))
                })
                .catch(err => {
                    dispatch(actions.notification.filterError(err))
                });
            break;
    }
};

const mapAddressToString = (address) => {
  return `${address.streetName} ${address.number}, ${address.apartment}, ${address.postalCode}, ${address.city}, ${address.state}, ${address.country.name}`
};

// const mapNotificationResponse = (response) => {
//   const notifications = response.results;
//   let customNotifications = [];

//   notifications.forEach((notification) => {
//       const customNotification = {
//           ...notification,
//           details: {
//               ...notification.details,
//               address: mapAddressToString(notification.details.address)
//           }
//       };
//       customNotifications.push(customNotification)
//   });

//   response.results = customNotifications;
//   return response;
// };

export default notification;