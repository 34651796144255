import React, {useEffect, useState} from 'react';
import CompanyForm from './TitularWizard/CompanyForm';
import {Card, CardBody, Col} from "reactstrap";
import {withTranslation} from "react-i18next";
import actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import LeadPencilIcon from "mdi-react/LeadPencilIcon";
import { reset } from 'redux-form'
import {ROLES} from "../../../constants";
import { ToastsStore } from 'react-toasts';

const AccountDetails = (props) => {

    const [editable, setEditable] = useState(false);

    if(!props.location || !props.location.state || !props.location.state.companyId ) return null;
    const companyId = props.location.state.companyId;

    const isValidRole = () => {
        return props.userRoles.some((rol) => rol.name === ROLES.TITULAR)
    };

    useEffect(() => {
        // resets the account details to avoid wrong information render.
        props.resetAccountDetails();
        props.getAvailableEntityTypes();
    }, []);

    useEffect(() => {
       if(companyId) {
            props.getUserCompany(companyId);
       }
    }, [companyId]);

    useEffect(() => {
        if(!editable) props.resetCompanyForm()
    }, [editable]);

    const editUserCompany = (values) => {
        props.editUserCompany(companyId, values,
            () => {
                props.history.push('/manage_accounts/table');
            }, (errorMessage) => {
                ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            });
    };

    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between'}}>
                            <h5 className="bold-text">{props.t('company_details.title')}</h5>
                            {isValidRole() ? (
                            <div style={{display: 'flex'}}>
                                <LeadPencilIcon className="pencil--icon" color={editable ? 'green' : 'grey'}
                                                onClick={() => setEditable(!editable)} />
                            </div>
                            ) : null}
                        </div>
                    </div>
                    {/* only show the details when the account info arrive */}
                    {
                        props.accountDetails &&
                        <CompanyForm
                            onSubmit={editUserCompany}
                            editable={editable}
                            availableEntityTypes={props.availableEntityTypes}
                            initialValues={props.accountDetails}
                            buttonText={props.t('common.save')}
                            pending={props.editUserCompanyPending}
                            userRole={props.userRoles}
                        />
                    }
                </CardBody>
            </Card>
        </Col>
    )
};

const mapStateToProps = (state) => ({
    accountDetails: state.user.accountDetails,
    availableEntityTypes: state.constant.availableEntityTypes,
    editUserCompanyPending: state.ui.editUserCompanyPending,
    userRoles: state.user.roles
});

const mapDispatchToProps = (dispatch) => ({
    getUserCompany: (companyId) => dispatch(actions.user.getCompany(companyId)),
    resetCompanyForm: () => dispatch(reset('company_form')),
    editUserCompany: (companyId, data, successCallback, errorCallback) =>
        dispatch(actions.user.editCompany(companyId, data, successCallback, errorCallback)),
    getAvailableEntityTypes: () => dispatch(actions.constant.getAvailableEntityTypes()),
    resetAccountDetails: () => dispatch(actions.user.resetAccountDetails())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AccountDetails))