import React from 'react';
import {Container, Row} from 'reactstrap';
import ChangeProductiveSystemEventForm from "../NewEventForms/ChangeProductiveSystemEventForm"

const ChangeProductiveSystemEventDetailsContainer = (props) => {
    const event = props.location.state.event;

    const mapInitialValues = (event) => {
        if (event)
            return {
                caravan_id: event.earId,
                productive_system: {
                    [`${event.productionSystem}`]: true
                },
                feeding: event.feeding,
                date: event.date,
                comments: event.comments,
            };
        return {}
    };

    return (
        <Container>
            <Row>
                {
                    event &&
                    <ChangeProductiveSystemEventForm onSubmit={() => {}} pending={props.addCowPending}
                                                     editable={false} initialValues={mapInitialValues(event)}/>
                }
            </Row>
        </Container>
    )
};

export default ChangeProductiveSystemEventDetailsContainer;