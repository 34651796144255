import React, { useState } from 'react';
import {reduxForm} from "redux-form";
import {Button} from "reactstrap";
import { withTranslation } from "react-i18next";
import PasswordInput from "./PasswordInput";
import CellphoneKeyIcon from "mdi-react/CellphoneKeyIcon";

const PasswordAndPinForm = (props) => {

    const [showSecret, setShowSecret] = useState(false);
    const [showPin, setShowPin] = useState(false);
    // const [secretError, setSecretError] = useState();
    // const [pinError, setPinError] = useState();
    const { handleSubmit, t } = props;

    return (
        <form className="form" onSubmit={handleSubmit}>
            <PasswordInput name="password" show={showSecret} setShow={() => setShowSecret(!showSecret)}
                           label={t('wallet.restore.modal.write_your_secret_word')}
                           placeholder={t('wallet.restore.modal.write_your_secret_word')}/>

            {/*<div className="error-container" style={{height: '20px', justifyContent: 'flex-start', marginBottom: '20px'}}>
                {
                    secretError &&
                    <span className="error-message">{t(`errors.${secretError}`)}</span>
                }
            </div>*/}

            <PasswordInput name="pin" show={showPin} setShow={() => setShowPin(!showPin)}
                           label={t('wallet.restore.modal.write_your_pin')}
                           placeholder={t('wallet.restore.modal.write_your_pin')}
                           icon={<CellphoneKeyIcon/>}
            />

            {/*<div className="error-container" style={{height: '20px', justifyContent: 'flex-start', marginBottom: '20px'}}>
                {
                    pinError &&
                    <span className="error-message">{t(`errors.${pinError}`)}</span>
                }
            </div>*/}

            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                <Button className="btn btn-primary" type="submit" disabled={false}>
                    <span>{t('common.accept')}</span>
                </Button>
            </div>
        </form>
    )
};

export default reduxForm({form: 'password_and_pin_form',})(withTranslation('common')(PasswordAndPinForm))