import { services } from '../storage';
const WALLET_KEY = "WALLET_";

const store = (userId, wallet) => {
    return services.insertData(WALLET_KEY + userId, wallet);
};

const retrieve =  (userId) => {
    return services.retrieveData(WALLET_KEY + userId);
};

export default {
    store,
    retrieve,
}
