/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState } from 'react';
import FilterVariantIcon from 'mdi-react/FilterVariantIcon';
import {Field, reduxForm} from "redux-form";
import renderCheckBoxField from "../../../../shared/components/form/CheckBox";
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const ColumnFilter = (props) =>  {
    const [showMenu, setShowMenu] = useState(false);

    const { columns, handleSubmit, setColumnVisibility, t } = props;
    
    const toggleMenu = () => (setShowMenu(!showMenu));

    const customHandleSubmit = (values) => {
        Object.keys(values).forEach(columnId => {
            setColumnVisibility(columnId, values[columnId]);
        });
        toggleMenu();
    };

    return (
        <div className="column-filter">
            <FilterVariantIcon onClick={toggleMenu}/>
            {
                showMenu ?
                    <div className="column-filter-menu">
                        <form className="form" onSubmit={handleSubmit(customHandleSubmit)}>
                            <div className="form__form-group">
                                {
                                    columns.map(column => (
                                        <div className="form__form-group-field">
                                            <Field
                                                name={`${column.key}`}
                                                component={renderCheckBoxField}
                                                label={column.name}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                            <Button type={'submit'} outline >{t('common.accept')}</Button>
                        </form>
                    </div>
                    :
                    null
            }
        </div>
    );
};

ColumnFilter.prototype = {
    columns: PropTypes.array.isRequired,
    setColumnVisibility: PropTypes.func.isRequired,
    initialValues: PropTypes.object
};

const mapStateToProps = (state, props)=> ({
    initialValues: props.initialValues ? props.initialValues : {}
});

const columnFilterForm = reduxForm({
    form: 'column-filter-form'
})(withTranslation('common')(ColumnFilter));

export default connect(mapStateToProps)(columnFilterForm)