import { AUTHENTICATED_REQUEST } from "../actions/authActions";
import actions from '../actions';

// Used for avoiding expiration during the request.
const BUFFER_TIME = 5000;

const isExpired = (expiration) => (Date.now() + BUFFER_TIME) >= expiration;

const authMiddleware = ({dispatch, getState}) => next => action => {
    next(action);
    switch (action.type) {
        case AUTHENTICATED_REQUEST:
            const jwtExpiration = getState().session.jwtExpiration;
            const refreshExpiration = getState().session.refreshExpiration;

            // If the jwt is NOT expired it makes the request with it.
            if (!isExpired(jwtExpiration)) {
                action.request(getState().session.jwt);
            }
            else {
                // If the refresh token is NOT expired, makes the request for refreshing the token.
                if (!isExpired(refreshExpiration)) {
                    dispatch(actions.session.refreshRequest(getState().session.refreshToken));
                    setTimeout(()=>{ action.request(getState().session.jwt)}, 2500);
                }
                // If the jwt and the refresh token are expired, logout the user.
                else {
                    dispatch(actions.session.logout())
                }
            }
    }
};

export default authMiddleware;
