import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
import { Provider as AlertProvider } from 'react-alert'
import { CVAlertTemplate, options} from "./shared/components/cv/CVAlert";


render(
    <AlertProvider template={CVAlertTemplate} {...options}>
        <App />
    </AlertProvider>,
  document.getElementById('root'),
);
