export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_RESPONSE = 'UPLOAD_IMAGE_RESPONSE';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';

const imageMiddleware = {
    uploadImage(image, callback){
        return {
            type: UPLOAD_IMAGE,
            image,
            callback
        }
    },
    uploadImageResponse(response){
        return {
            type: UPLOAD_IMAGE_RESPONSE,
            response
        }
    },
    uploadImageError(message){
        return {
            type: UPLOAD_IMAGE_ERROR,
            message
        }
    }
};
export default imageMiddleware;