import api from '../../api/index';
import {
  GET_EVENTS_PAGE,
  SEARCH_EVENTS,
  FILTER_EVENTS,
  SUBMIT_WEIGHT_EVENT,
  SUBMIT_DENTITION_EVENT,
  SUBMIT_DELETION_EVENT,
  SUBMIT_LOT_EVENT,
  SUBMIT_CASTRATION_EVENT,
  SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT,
  SUBMIT_REIDENTIFICATION_EVENT,
  SUBMIT_CATEGORY_CHANGE_EVENT,
  SUBMIT_SLAUGHTER_EVENT,
  SUBMIT_VACCINATION_EVENT,
  UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD,
  UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD,
  MASSIVE_EVENT_LOAD,
  ACCEPT_MASSIVE_EVENTS_UPLOAD,
} from '../actions/eventActions';
import actions from '../actions';
import mapper from './mappers/eventMapper';

const event = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {
        case GET_EVENTS_PAGE:
            dispatch(actions.auth.authenticatedRequest(jwt => {
              api.getEvents(jwt, action.establishmentId, action.pageNumber, action.pageSize, action.eventType)
                .then(res => {
                  dispatch(actions.event.getEventsPageResponse(mapper.response.getEvents(res), action.eventType))
                })
                .catch(err => {
                  dispatch(actions.event.getEventsPageError(err))
                });
            }));
            break;
        case SEARCH_EVENTS:
            api.searchEvents(action.word)
                .then(res => {
                    dispatch(actions.event.searchEventsResponse(mapper.response.searchEvents(res)))
                })
                .catch(err => {
                    dispatch(actions.event.searchEventsError(err))
                });
            break;
        case FILTER_EVENTS:
            api.filterEvents(action.filters)
                .then(res => {
                    dispatch(actions.event.filterEventsResponse(mapper.response.filterEvents(res)))
                })
                .catch(err => {
                    dispatch(actions.event.filterEventsError(err))
                });
            break;
        case SUBMIT_DENTITION_EVENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addDentitionEvent(jwt, mapper.body.addDentitionEvent(action.values), 'dentitionEvent')
                  .then(res => {
                    dispatch(actions.event.submitDentitionEventResponse(res));
                    if(action.successCallback) action.successCallback();
                })
                  .catch(err => {
                    dispatch(actions.event.submitDentitionEventError(err))
                    if(action.errorCallback) action.errorCallback((err));
                  });
              }));
            break;
        case SUBMIT_DELETION_EVENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addDeletionEvent(jwt, mapper.body.addDeletionEvent(action.values), 'deletionEvent')
                    .then(res => {
                        dispatch(actions.event.submitDeletionEventResponse(res));
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.event.submitDeletionEventError(err))
                        if(action.errorCallback) action.errorCallback((err));
                    });
            }));
            break;
        case SUBMIT_LOT_EVENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addLotEvent(jwt, mapper.body.addLotEvent(action.values), 'lotEvent')
                    .then(res => {
                        dispatch(actions.event.submitLotEventResponse(res));
                        if (action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.event.submitLotEventError(err))
                        if (action.errorCallback) action.errorCallback((err));
                    });
            }));
            break;
        case SUBMIT_CASTRATION_EVENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
              api.addCastrationEvent(jwt, mapper.body.addCastrationEvent(action.values), 'castrationEvent')
                .then(res => {
                  dispatch(actions.event.submitCastrationEventResponse(res));
                  if(action.successCallback) action.successCallback();
                })
                .catch(err => {
                  dispatch(actions.event.submitCastrationEventError(err))
                  if(action.errorCallback) action.errorCallback((err));
                });
            }));
            break;
        case SUBMIT_WEIGHT_EVENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addWeightEvent(jwt, mapper.body.addWeightEvent(action.values), 'weightEvent')
                    .then(res => {
                        dispatch(actions.event.submitWeightEventResponse(res));
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.event.submitWeightEventError(err))
                        if(action.errorCallback) action.errorCallback((err));
                    });
            }));
            break;
        case SUBMIT_VACCINATION_EVENT:
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.addVaccinationEvent(jwt, mapper.body.addVaccinationEvent(action.values), 'vaccinationEvent')
                        .then(res => {
                            dispatch(actions.event.submitVaccinationEventResponse(res));
                            if(action.successCallback) action.successCallback();
                        })
                        .catch(err => {
                            dispatch(actions.event.submitVaccinationEventError(err))
                            if(action.errorCallback) action.errorCallback((err));
                        });
                }));
                break;
        case SUBMIT_SLAUGHTER_EVENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addSlaughterEvent(jwt, mapper.body.addSlaughterEvent(action.values), 'slaughterEvent')
                    .then(res => {
                        dispatch(actions.event.submitSlaughterEventResponse(res));
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.event.submitSlaughterEventError(err));
                        if(action.errorCallback) action.errorCallback((err));
                    });
            }));
            break;
        case SUBMIT_PRODUCTIVE_SYSTEM_CHANGE_EVENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addProductiveSystemChangeEvent(jwt, mapper.body.addProductiveSystemChangeEvent(action.values), 'productiveSystemChangeEvent')
                    .then(res => {
                        dispatch(actions.event.submitProductiveSystemChangeEventResponse(res));
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.event.submitProductiveSystemChangeEventError(err))
                        if(action.errorCallback) action.errorCallback((err));
                    });
            }));
            break;
        case SUBMIT_REIDENTIFICATION_EVENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addReidentificationEvent(jwt, mapper.body.addReidentificationEvent(action.values), 'productiveSystemChangeEvent')
                    .then(res => {
                        dispatch(actions.event.submitReIdentificationEventResponse(res));
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.event.submitReIdentificationEventError(err))
                        if(action.errorCallback) action.errorCallback((err));
                    });
            }));
            break;
        case SUBMIT_CATEGORY_CHANGE_EVENT:
            api.addCategoryChangeEvent(action.id, action.newCategory, action.date)
                .then(res => {
                    dispatch(actions.event.submitCategoryChangeEventResponse(res));
                    if(action.successCallback) action.successCallback();
                })
                .catch(err => {
                    dispatch(actions.event.submitCategoryChangeEventError(err))
                    if(action.errorCallback) action.errorCallback((err));
                });
            break;
        case UPLOAD_FILE_FOR_MASSIVE_EVENTS_LOAD:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.uploadFileForMassiveEventsLoad(jwt, action.establishmentId, action.eventType, action.file)
                    .then(res => {
                        dispatch(actions.event.uploadFileForMassiveEventLoadResponse(mapper.response.uploadFileForMassiveEventsLoad(res)));
                        if(action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.event.uploadFileForMassiveEventLoadError(err))
                    });
            }))
            break;
        case UPLOAD_FILE_FOR_TRANSFER_EVENT_MASSIVE_LOAD:
          dispatch(actions.auth.authenticatedRequest(jwt => {
            api.uploadFileForMassiveTransferEvent(jwt, action.establishmentId, action.newFacilityId, action.eventType, action.file)
              .then(res => {
                dispatch(actions.event.uploadFileForTransferEventMassiveLoadResponse(mapper.response.uploadFileForMassiveEventsLoad(res)));
                if(action.callback) action.callback();
              })
              .catch(err => {
                dispatch(actions.event.uploadFileForTransferEventMassiveLoadError(err))
              });
          }))
          break;
        case MASSIVE_EVENT_LOAD:
            api.massiveEventsLoad(action.events)
                .then(res => {
                    dispatch(actions.event.massiveEventLoadResponse(res));
                    if(action.callback) action.callback();
                })
                .catch(err => {
                    dispatch(actions.event.massiveEventLoadError(err))
                });
            break;
        case ACCEPT_MASSIVE_EVENTS_UPLOAD:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.acceptMassiveEventsUpload(jwt, action.excelId, action.eventType)
                    .then(res => {
                        dispatch(actions.event.getEventsPage(1, 10, action.eventType));
                        dispatch(actions.event.acceptMassiveEventUploadResponse(res));
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.event.acceptMassiveEventUploadError(err));
                        if(action.errorCallback) action.errorCallback(err.message);
                    })
            }))
    }
};

export default event;