import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import actions from '../../redux/actions/index';
import AnimalsTable from "./components/AnimalsTable";
import CVModal from "../UI/Modals/components/CVModal";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import FileUploadModal from "../../shared/components/cv/FileUploadModal";
import { Redirect } from "react-router-dom";
import CVBasicModal from "../../shared/components/cv/CVBasicModal";
import setting from '../../util/settings';
import { filterEstablishments, filterLots, filter, useIsMount } from '../../../src/util'

const Animals = (props) => {

    const [animals, setAnimals] = useState();
    const [animalsForDownload, setAnimalsForDownload] = useState();
    const [detailsModalIsOpen, setDetailsModalOpen] = useState(false);
    const [fileUploadModalIsOpen, toggleFileUploadModal] = useState(false);
    const [redirectRoute, setRedirectRoute] = useState();
    const [selectedAnimal, selectAnimal] = useState();
    const [qrModalIsOpen, toggleQrModal] = useState(false);
    const [animalId, setAnimalId] = useState();
    const isMount = useIsMount();

    const {
        allCompanies,
        allEstablishmentsByCompany,
        selectedLots,
        selectedCompany,
        establishmentSelected,
        totalPages,
        pageSize,
        total,
        page,
        t } = props;

    const establishmentId = props.location.state ? props.location.state.establishmentId : undefined;
    const title = props.location.state ? t('animals.table.title') + ' - ' + props.location.state.establishmentName : t('animals.table.title');
    const QRCode = require('qrcode.react');

    useEffect(()=>{
        /*
        if a company is deselected and a selected establishment no
        longer corresponds with selected companies, remove it from
        selectedEstablishments
        */
        const selected = filterEstablishments(establishmentSelected, allEstablishmentsByCompany)
        props.selectEstablishment(selected);
    },[allEstablishmentsByCompany])

    useEffect(()=>{
        const establishmentsFromselectedCompany = filter(props.establishmentsToAdd, props.establishmentSelected)
        props.selectEstablishment([...props.establishmentSelected, ...establishmentsFromselectedCompany])
    },[props.establishmentsToAdd])

    useEffect(()=>{
        props.selectLot([...props.selectedLots, ...props.lotsToAdd])
    },[props.lotsToAdd])

    useEffect(()=>{
        if(!isMount){
            const ids = allEstablishmentsByCompany.map(el => ( el._id ));
            const e = allEstablishmentsByCompany.map(el => ( {value: el._id, label: el.name} ));
            props.getLotsByEstablishment(ids);
            props.selectLot(e);
        }
    },[allEstablishmentsByCompany])

    useEffect(()=>{
        selectLotsFromSelectedEstablishments();
    },[props.allLotsByEstablishment])

    useEffect(() => {
        const lotIds = getLotidsFromProps();
        let establishmentIds = establishmentSelected.map(el => ( el.value ));
        if (!establishmentIds.length) establishmentIds = null;
        if(true) {
            props.getPageByEstablishment(establishmentIds, lotIds, 1, 10);
        }
    }, [establishmentSelected, establishmentId, selectedLots]);

    const selectLotsFromSelectedEstablishments = () => {
        const lots = props.allLotsByEstablishment.map(el => ( {value: el._id, label: el.name} ));
        if(allEstablishmentsByCompany.length === establishmentSelected.length){
            props.selectLot(lots)
        }
    }

    useEffect(()=>{
        const e = allEstablishmentsByCompany.map(el => ( {value: el._id, label: el.name} ));
        if(allCompanies.length === selectedCompany.length && !isMount){
           props.selectEstablishment(e)
        }
    },[allEstablishmentsByCompany])

    useEffect(()=>{
        const selected = filterLots(establishmentSelected, props.allLotsByEstablishment, selectedLots)
        props.selectLot(selected);
    },[establishmentSelected])

    useEffect(() => {
        setAnimals(props.animals);
    }, [props.animals]);

    useEffect(() => {
        setAnimalsForDownload(props.animalsForDownload);
    }, [props.animalsForDownload]);

    useEffect(()=>{
        props.resetEstablishmentsToAdd()
    },[props.location])

    const getLotidsFromProps = () => {
        if(selectedLots != 0) {
            let lotIds = selectedLots.map(lot => ( lot.value ));
            if(lotIds.length === 0) return null;
            return lotIds;
        }
        return null;
    }

    const findAnimalById = (id) => new Promise((resolve, reject) => {
        animals.forEach(animal => {
            if(animal.id === id) resolve(animal)
        });
        reject();
    });

    const filterAnimal = (filters) => {
        props.filter(filters)
    };

    const renderDetailsBody = () => {
        return (
            selectedAnimal ?
                <div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.id`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.id}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.caravan_id`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.caravan_id}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.breed`)}:</h4>
                        <h4 className={"value"}>{t(`animals.table.breed.${selectedAnimal.breed}`)}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.species`)}:</h4>
                        <h4 className={"value"}>{t(`animals.table.species.${selectedAnimal.species}`)}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.sex`)}:</h4>
                        <h4 className={"value"}>{t(`animals.table.headers.sex.${selectedAnimal.sex}`)}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.category`)}:</h4>
                        <h4 className={"value"}>{t(`animals_categories.cow.${selectedAnimal.category}`)}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.birth_date`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.birth_date}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.born_weight`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.born_weight}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.age_in_months`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.age_in_months}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.father_id`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.father_id}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.mother_id`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.mother_id}</h4>
                    </div>
                </div>
                :
                null
        )
    };

    const renderQRBody = () => (
        <div>
            <QRCode value={`${setting.baseUrlForAnimalQR}/${animalId}`} size={250} includeMargin id={"generated_qr"}
                    imageSettings={{src: require(`../../shared/img/carnes-validadas-logo-qr.jpeg`), height: 60, width: 60}}
                    level={'H'}
            />
        </div>
    );

    const toggleDetailsModal = (id) => {
        setDetailsModalOpen(!detailsModalIsOpen);
        findAnimalById(id).then((animal) => selectAnimal(animal))
    };

    const detailsModal = () => (
        <CVModal
            title={t('animals.modals.details.title')}
            body={() => renderDetailsBody()}
            color="primary"
            header
            isOpen={detailsModalIsOpen}
            toggle={() => setDetailsModalOpen(!detailsModalIsOpen)}
            acceptFunc={() => setDetailsModalOpen(!detailsModalIsOpen)}
            hideButtons={true}
        />
    );

    const onLeftSelectChange = (e) => {
        props.getEstablishmentsByCompanyId(e.value);
        localStorage.setItem('selectedCompanyId', e.value);
        localStorage.setItem('selectedCompanyName', e.label);
    }

    const onRightSelectChange = (e) => {
        props.selectEstablishment([e]);
        localStorage.setItem('selectedEstablishmentId', e.value);
        localStorage.setItem('selectedEstablishmentName', e.label);
    }

    const fileUploadModal = () => (
        <FileUploadModal
            title={t('animals.modals.massive_upload_modal.title')}
            isOpen={fileUploadModalIsOpen}
            toggleModal={toggleFileUploadModal} acceptedFormats={".xls,.xlsx"}
            leftSelectValues={props.allCompanies}
            rightSelectValues={props.allEstablishmentsByCompany}
            onLeftSelectChange={onLeftSelectChange}
            onRightSelectChange={onRightSelectChange}
            onSubmit={(file) => {
                props.uploadFileForBulkLoad(file.files, establishmentSelected[0].value);
                setRedirectRoute("/animals/massiveLoad");
            }
        }/>
    );

    const downloadQR = () => {
        let canvas = document.getElementById('generated_qr');
        let pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${animalId.toString()}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toggleQrModal(false);
    };

    const qrModal = () => (
        <CVBasicModal
            title={t('animals.modals.qr.title')}
            body={renderQRBody}
            isOpen={qrModalIsOpen}
            toggleModal={() => toggleQrModal(!qrModalIsOpen)}
            cancel={() => toggleQrModal(false)}
            accept={downloadQR}
            acceptText={t('animals.modals.qr.download')}
        />
    );

    const getEstablishmentPage = (page, pageSize) => {
        const lotIds = getLotidsFromProps();
        if(establishmentSelected) {
            let establishmentIds = establishmentSelected.map(el => ( el.value ));
            props.getPageByEstablishment(establishmentIds, lotIds, page, pageSize)
        }
    };

    const getPageForDownload = (page) => {
        if(establishmentSelected) {
            const lotIds = getLotidsFromProps();
            let establishmentIds = establishmentSelected.map(el => ( el.value ));
            if(establishmentIds.length === 0) {
                establishmentIds = null
            }
            props.getPageForDownload(establishmentIds, lotIds, page, 1000)
        }
    };

    return (
        <div>
            <AnimalsTable
                title={title}
                animals={animals}
                animalsForDownload={animalsForDownload}
                total={total ? total : 0}
                currentPage={page ? page : 1}
                getPage={getEstablishmentPage}
                getPageForDownload={getPageForDownload}
                totalPages={totalPages}
                pageSize={pageSize}
                toggleDetailsModal={(id) => toggleDetailsModal(id)}
                toggleFileUploadModal={(value) => toggleFileUploadModal(value)}
                openQrModal={(animalId) => {
                    setAnimalId(animalId);
                    toggleQrModal(!qrModalIsOpen)
                }}
                redirectTo={(route) => setRedirectRoute(route)}
                filter={filterAnimal}
                pending={props.getAnimalPagePending}
                establishmentSelected={establishmentSelected}
            />
            {detailsModal()}
            {fileUploadModal()}
            {qrModal()}
            { redirectRoute && <Redirect to={redirectRoute}/> }
        </div>
    );
};

const mapStateToProps = (state) => ({
    animals: state.animal.animals.results,
    animalsForDownload: state.animal.animalsForDownload?
        state.animal.animalsForDownload.results : [],
    total: state.animal.animals.total,
    page: state.animal.animals.page,
    totalPages: state.animal.animals.totalPages,
    pageSize: state.animal.animals.pageSize,
    establishmentSelected: state.user.selectedEstablishment,
    getAnimalPagePending: state.ui.getAnimalPagePending,
    getAnimalPageError: state.ui.getAnimalPageError,
    selectedCompany: state.user.selectedCompany,
    selectedLot: state.user.selectedLot? state.user.selectedLot : '0',
    selectedLots: state.user.selectedLots? state.user.selectedLots : '0',
    allCompanies: state.user.allCompanies,
    allEstablishmentsByCompany: state.user.allEstablishmentsByCompany,
    allEstablishments: state.user.allEstablishments,
    allLotsByEstablishment: state.user.allLotsByEstablishment,
    establishmentsToAdd: state.user.establishmentsToAdd,
    lotsToAdd: state.user.lotsToAdd
});

const mapDispatchToProps = (dispatch) => ({
    getPage: (page, pageSize) => dispatch(actions.animal.getPage(page, pageSize)),
    filter: (filters) => dispatch(actions.animal.filter(filters)),
    uploadFileForBulkLoad: (file, establishmentId) =>
        dispatch(actions.animal.uploadFileForBulkLoad(file, establishmentId)),
    getPageByEstablishment: (establishmentId, lotId, page, pageSize) =>
        dispatch(actions.animal.getPageByEstablishment(establishmentId, lotId, page, pageSize)),
    getPageForDownload: (establishmentId, lotId, page, pageSize) =>
        dispatch(actions.animal.getPageForDownload(establishmentId, lotId, page, pageSize)),
    getEstablishmentsByCompanyId: (companyId) =>
        dispatch(actions.user.getUserEstablishmentsByCompany(companyId)),
    getLotsByEstablishment: (establishmentIds) =>
        dispatch(actions.user.getLotsByEstablishment(establishmentIds)),
    selectEstablishment: (establishment) => dispatch(actions.user.selectEstablishment(establishment)),
    selectLot: (lot) => dispatch(actions.user.selectLot(lot)),
    resetEstablishmentsToAdd: () => dispatch(actions.user.resetEstablishmentsToAdd())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(Animals)));

