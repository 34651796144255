import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import sessionActions from "../../../redux/actions/sessionActions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import profileImage from '../../../shared/img/no_profile.png'


class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  avatar = () => this.props.user.profile.avatar ? this.props.user.profile.avatar : profileImage;

  render() {
    const { collapse } = this.state;
    const { user, t } = this.props;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={this.avatar()} alt="avatar" />
          <p className="topbar__avatar-name">{user.profile.name} {user.profile.lastName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink
              title={t('cv-top-bar.profile.myProfile')}
              icon="user"
              path="/account/profile"
              onClick={this.toggle}
            />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink
                title={t('cv-top-bar.profile.logOut')}
              icon="exit"
              path="/log_in"
              onClick={() => {
                this.toggle();
                this.props.logout();
              }}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(sessionActions.logout())
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(TopbarProfile));
