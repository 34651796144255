import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarWallet from './TopbarWallet';
import TopbarNotification from './TopbarNotification';
import TopbarLanguage from './TopbarLanguage';
import {connect} from "react-redux";
import { ROLES } from '../../../constants';

class Topbar extends PureComponent {
    static propTypes = {
        changeMobileSidebarVisibility: PropTypes.func.isRequired,
        changeSidebarVisibility: PropTypes.func.isRequired,
    };

    userDoesNotHaveARol = () => (this.props.userRoles[0].name === ROLES.WITH_OUT_ROLE);

    render() {
        const {changeMobileSidebarVisibility, changeSidebarVisibility} = this.props;

        return (
            <div className="topbar">
                <div className="topbar__wrapper">
                    <div className="topbar__left">
                        <TopbarSidebarButton
                            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                            changeSidebarVisibility={changeSidebarVisibility}
                        />
                        {
                            this.userDoesNotHaveARol() ?
                                <Link className="topbar__logo" to="/manage_accounts/table" /> :
                                <div style={{display: 'flex'}}>
                                    <Link className="topbar__logo" to="/" />
                                </div>
                        }
                    </div>
                    <div className="topbar__right">
                        <div style={{display: 'flex'}}>
                            {/*<TopbarWallet />*/}
                            {/*<TopbarNotification />*/}
                            <TopbarProfile />
                            <TopbarLanguage />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userRoles: state.user.roles
    }
}

export default connect(mapStateToProps, null)(Topbar);
