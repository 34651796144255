/*
*   The constant mapper is use for mapping either the body or the response on a user request. It try to decouple the
*   constant middleware.
* */

const mapper =  {
    body: {},
    response: {
        getAvailableRoles: (response)  => ({
            roles: response
        }),
        getAvailableEntityTypes: (response) => ({
            entityTypes: response
        }),
        getAvailableProductiveSystems: (response) => ({
            productiveSystems: response
        }),
        getDentitionValues: (response) => ({
            dentitionValues: response
        }),
        getAvailableEstablishmentTypes: (response) => ({
            establishmentTypes: modifyEstablishmentTypesOrder(response)
        }),
        getAvailableGroundConditions: (response) => ({
            groundConditions: response
        }),
        getAvailableRucaActivities: (response) => ({
            rucaActivities: response
        })
    }
};


/*
* This method is for returning the 'productive' element first. Only because the others are not supported yet, and for
* styling is prefer the 'productive' first.
* todo delete this method when all establishment types are supported.
* */
const modifyEstablishmentTypesOrder = (establishmentTypes) => (
    establishmentTypes.sort((a,b) => {
        if(a === 'productive') return -1;
        if(b === 'productive') return 1;
        else return 0;
    })
);

export default mapper;