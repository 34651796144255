import {
    GET_USERS_PAGE_RESPONSE,
    GET_ROLES_PAGE_RESPONSE,
    GET_GENERAL_DATA_TITLES_PAGE_RESPONSE,
    GET_GENERAL_DATA_INFO_RESPONSE,
    EDIT_GENERAL_DATA_INFO_RESPONSE,
    ADD_GENERAL_DATA_INFO_RESPONSE,
    GET_GENERAL_DATA_TITLES_PAGE,
    GET_USER_BY_ID_RESPONSE,
    ADD_GENERAL_DATA_RESPONSE
} from "../actions/adminActions";

const initialState = {
    users: {
        users: [],
        page: 1,
        totalPages: 0,
        total: 0,
        pageSize: undefined
    },
    roles: {
        roles: [],
        page: 1,
        totalPages: 0,
        total: 0,
        pageSize: undefined
    },
    generalData: {
        titles: {
            titles: [],
            page: 1,
            totalPages: 0,
            total: 0,
            pageSize: undefined
        },
        info: {
            values: []
        }
    },
    userSearch: {}
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_PAGE_RESPONSE:
            return {
                ...state,
                users: {
                    ...action.response
                }
            };
        case GET_ROLES_PAGE_RESPONSE:
            return {
                ...state,
                roles: {
                    ...action.response
                }
            };
        case GET_GENERAL_DATA_TITLES_PAGE_RESPONSE:
        case ADD_GENERAL_DATA_RESPONSE:
            return {
                ...state,
                generalData: {
                    ...state.generalData,
                    titles: {
                        ...action.response
                    }
                }
            };
        case GET_GENERAL_DATA_INFO_RESPONSE:
        case EDIT_GENERAL_DATA_INFO_RESPONSE:
        case ADD_GENERAL_DATA_INFO_RESPONSE:
            return {
                ...state,
                generalData: {
                    ...state.generalData,
                    info: {
                        ...action.response
                    }
                }
            };
        /* This resets the info value, it done for avoiding a wrong render on GeneralDataTable*/
        case GET_GENERAL_DATA_TITLES_PAGE:
            return {
                ...state,
                generalData: {
                    ...state.generalData,
                    info: {}
                }
            };
        case GET_USER_BY_ID_RESPONSE:
            return {
                ...state,
                userSearch: {...action.response.result}
            };
        default:
            return state
    }
};
export default adminReducer;