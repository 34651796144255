import React from 'react';
import { Container, Row } from 'reactstrap';
import ReidentificationForm from "../NewEventForms/CaravanReIdentificationEventForm"
import { connect } from 'react-redux';
import actions from '../../../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const NewCaravanReidentificationEventContainer = (props) => {
    const alert = useAlert();

    const addReidentificacionEvent = (values) => {
        props.submitReidentificationEvent(values, props.facilityId, () => {
            props.history.push('/events/reidentification/table');
            alert.success(props.t('events.alerts.event_added'));
        }, (errorMessage) => {
                ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            }
        );
    };

    return (
        <Container>
            <Row>
              <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
                <ReidentificationForm
                  onSubmit={addReidentificacionEvent}
                  pending={props.addCowPending}
                  editable/>
            </Row>
        </Container>
    )
};

const mapStateToProps = (state) => ({
    addCowPending: state.ui.addCowPending, // todo change this!
    facilityId: state.user.selectedEstablishment._id
});

const mapDispatchToProps = (dispatch) => ({
    submitReidentificationEvent: (values, facilityId, successCallback, errorCallback) =>
        dispatch(actions.event.submitReIdentificationEvent(values, facilityId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewCaravanReidentificationEventContainer));