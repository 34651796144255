import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

const DEFAULT_FILE_FORMATS_ACCEPTED = ".jpg,.png,.pdf,.doc,.docx";

const CVFileUpload = (props) => {
    CVFileUpload.propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.shape({
                name: PropTypes.string,
            }),
            PropTypes.string,
        ]),
        multiple: PropTypes.bool,
        acceptedFormats: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        hideFileName: PropTypes.bool
    };

    const { onChange, name, value, multiple, acceptedFormats, label, disabled, hideFileName } = props;

    return (
        <div className="form__form-group-file">
            <label htmlFor={name}>{label}</label>
            {(!hideFileName && value.name) && <span>{value.name}</span>}
            <input
                type="file"
                name={name}
                accept={acceptedFormats ? acceptedFormats : DEFAULT_FILE_FORMATS_ACCEPTED}
                multiple={multiple}
                id={name}
                onChange={
                    (e) => {
                        e.preventDefault();
                        // convert files to an array
                        const files = [...e.target.files];
                        // check to avoid error when 'cancel' button is press.
                        onChange( files[0] ? { files: files, name: files[0].name } : undefined);
                    }
                }
                disabled={disabled}
            />
        </div>
    )
};

const renderInputField = (props) => {
    const { input, meta: {touched, error, warning}, multiple, labelSingle, labelMultiple, acceptedFormats, disabled,
        hideFileName } = props;
    const { t } = useTranslation('common');

    return (
        <div>
            <div className="form__form-group-input-wrap">
                <CVFileUpload
                    {...input}
                    label={multiple ? labelMultiple  : labelSingle}
                    multiple={multiple} acceptedFormats={acceptedFormats}
                    disabled={disabled}
                    hideFileName={hideFileName}
                />
            </div>
            <div className="input-error-container">
                {touched && ((error && <span className="error-message">{t(`errors.${error}`)}</span>) || (warning &&
                    <span className="error-message">{warning}</span>))}
            </div>
        </div>
    );
};

const renderCVFileInputField = (props) => {
    const { t } = useTranslation('common');

    return renderInputField( {...props,
        labelSingle: t('cv-inputs.file_upload.select_the_file'),
        labelMultiple: t('cv-inputs.file_upload.select_the_files'),
        acceptedFormats: props.acceptedFormats ? props.acceptedFormats: ".pdf"
    } )
};

const renderCVPhotoInputField = (props) => {
    const { t } = useTranslation('common');

    return renderInputField({ ...props,
        labelSingle: t('cv-inputs.photo_upload.select_the_photo'),
        labelMultiple: t('cv-inputs.photo_upload.select_the_photos'),
        acceptedFormats: props.acceptedFormats ? props.acceptedFormats: "image/jpeg, image/png"
    } )
};

renderCVFileInputField.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
        disabled: PropTypes.bool,
        hideFileName: PropTypes.bool
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderCVPhotoInputField.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
        disabled: PropTypes.bool,
        hideFileName: PropTypes.bool
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

export { renderCVFileInputField, renderCVPhotoInputField }