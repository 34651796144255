/*
*   The animals mapper is use for mapping either the body or the response on a animal request. It try to decouple the
*   animals middleware.
* */

import i18n from "../../../translations/config/i18n";

const mapper =  {
    body: {
        addCow: (data, uploadedFilesAndUrls = {}) => {
            let result = {
                earId: data.caravan_id,
                category: data.category.value,
                breed: data.breed.value,
                specie: data.specie.value,
                weight: data.weight_at_discharge,
                facility: data.establishment.value,
                productionSystem: parseProductiveSystemOptionsToArray(data.productiveSystems)[0],
                feeding: data.foods,
                fireMarkPlace: data.fire_mark_place.value,
                dentition: data.dentition.value,
                fireMarkPlace: data.fire_mark_place.value,
                newLot: data.new_lot,
            };

            // Optionals
            if(data.age_in_months) result['age'] = data.age_in_months;
            if(data.secondary_id){
                if(data.secondary_id.chip) result['chip'] = data.secondary_id.chip;
                if(data.secondary_id.button) result['button'] = data.secondary_id.button;
                if(data.secondary_id.internal_caravan) result['internalEarId'] = data.secondary_id.internal_caravan;
            }
            if(uploadedFilesAndUrls.DNA_analysis) result['dnaFile'] = uploadedFilesAndUrls.DNA_analysis;
            if(uploadedFilesAndUrls.signal_on_ear) result['earImage'] = uploadedFilesAndUrls.signal_on_ear;

            if(data.birth) {
                if(data.birth.father_id) result['fatherId'] = data.birth.father_id;
                if(data.birth.mother_id) result['motherId'] = data.birth.mother_id;
                if(data.birth.date) result['birthDate'] = data.birth.date;
                if(data.birth.weight) result['birthWeight'] = data.birth.weight;
            }
            if(data.establishment) {
                data.facility = data.establishment.value;
            }
            if(data.establishmentLot && data.establishmentLot.value){
                result['tags'] = [{
                    _id: data.establishmentLot.value,
                    type: 'animalLot'
                }]
                result['animalLot'] = {
                    _id: data.establishmentLot.value,
                    name : data.new_lot,
                    type: 'animalLot'
                }
            }
            return result;
        },
        editCow: (data, uploadedFilesAndUrls = {}) => {
            return mapper.body.addCow(data, uploadedFilesAndUrls)
        }
    },
    response: {
        getAnimals: (response) => ({
            results: response.docs,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getAnimalsForDownload: (response) => ({
            results: parseAnimalsForDownload(response.docs),
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        searchAnimals: (response) => ({
            animals: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        filterAnimals: (response) => ({
            animals: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getUserAnimalById: (response) => {
            let result = {
                caravan_id: response.earId,
                breed: {
                    value: response.breed,
                    label: response.breed
                },
                specie: {
                    value: response.specie,
                    label: response.specie
                },
                dentition: {
                    value: response.dentition,
                    label: response.dentition,
                },
                sex: {
                    value: response.gender,
                    label: i18n.t(`common:cv-forms.cow.sex.${response.gender}`)
                },
                weight_at_discharge: response.weight,
                category: {
                    value: response.category,
                    label: response.category
                },
                establishment: {
                    value: response.facility._id,
                    label: response.facility.name
                },
                company: {
                    value: response.company._id,
                    label: response.company.legalName
                },
                productive_system: parseProductiveSystemOptionsToJson([response.productionSystem]),
                feeding: formatFeeding(response.feeding),
                fire_mark_place: {
                    value: response.fireMarkPlace,
                    label: i18n.t(`common:cv-forms.cow.fire_mark_place_options.${response.fireMarkPlace}`)
                }
            };

            // optionals
            if(response.age) {
                result['age_in_months'] = response.age
            }
            let secondary_id = {};
            if(response.chip) secondary_id['chip'] = response.chip;
            if(response.button) secondary_id['button'] = response.button;
            if(response.internalEarId) secondary_id['internal_caravan'] = response.internalEarId;

            if (Object.entries(secondary_id).length !== 0) result['secondary_id'] = secondary_id;

            if(response.dnaFile) result['DNA_analysis'] = response.dnaFile;
            if(response.earImage) result['signal_on_ear'] = response.earImage;

            if(response.fatherId) result['birth'] = {...result.birth, father_id: response.fatherId};
            if(response.motherId) result['birth'] = {...result.birth, mother_id: response.motherId};
            if(response.birthDate) result['birth'] = {...result.birth, date: response.birthDate};
            if(response.birthWeight) result['birth'] = {...result.birth, weight: response.birthWeight};
            if(response.establishmentLot ) result['establishmentLot'] = {
                _id: response.establishmentLot._id,
                label : response.establishmentLot.name
            }
            return result;
        },
        massiveAnimalUpload: (response) => ({
            _id: response._id,
            hash: response.hash,
            status: response.status,
            animals: parseValidatedRows(response.validatedRows)
        })
    },
    data: {
        uploadCowFormFiles: (info) => {
            let files = [];

            // Check is need on DNA_analysis file because is not required.
            if(info.DNA_analysis) {
                files.push({
                    id: 'DNA_analysis',
                    file: info.DNA_analysis.files[0]
                });
            }

            // Check is need on signal_on_ear file because is not required.
            if(info.signal_on_ear) {
                files.push({
                    id: 'signal_on_ear',
                    file: info.signal_on_ear.files[0]
                });
            }

            return files;
        },
        editCowFormFiles: (info) => {
            let files = [];

            // Check is need on DNA_analysis file because is not required.
            if(info.DNA_analysis) {
                // If the dna analysis is an string these means that the file didn't change ant it is already upload.
                if(typeof info.DNA_analysis !== 'string') {
                    files.push({
                        id: 'DNA_analysis',
                        file: info.DNA_analysis.files[0]
                    });
                }
            }

            // Check is need on signal_on_ear file because is not required.
            if(info.signal_on_ear) {
                // If the signal on ear is an string these means that the file didn't change ant it is already upload.
                if(typeof info.signal_on_ear !== 'string') {
                    files.push({
                        id: 'signal_on_ear',
                        file: info.signal_on_ear.files[0]
                    });
                }
            }
            return files;
        }
    }
};

const parseProductiveSystemOptionsToArray = (productiveSystems) => {
    let result = [];
    if(productiveSystems.natural) result.push('natural');
    if(productiveSystems.silvoPastoral) result.push('silvoPastoral');
    if(productiveSystems.pastoral) result.push('pastoral');
    if(productiveSystems.creepFeeding) result.push('creepFeeding');
    if(productiveSystems.strategicFeeding) result.push('strategicFeeding');
    if(productiveSystems.feedlot) result.push('feedlot');
    return result;
};

const parseAnimalsForDownload = (data) => {
    let results = []
    for (let row of data) {
        let parsedData = {}
        try {
            parsedData[i18n.t('common:excelHeaders.earId')] = row.earId;
            parsedData[i18n.t('common:excelHeaders.specie')] = row.specie;
            parsedData[i18n.t('common:excelHeaders.fireMarkPlace')]  = row.fireMarkPlace;
            parsedData[i18n.t('common:excelHeaders.chip')] = row.chip;
            parsedData[i18n.t('common:excelHeaders.breed')]  = row.breed;
            parsedData[i18n.t('common:excelHeaders.weight')] = row.weight;
            parsedData[i18n.t('common:excelHeaders.category')]  = row.category;
            parsedData[i18n.t('common:excelHeaders.dentition')] = row.dentition;
            parsedData[i18n.t('common:excelHeaders.gender')] = row.gender;
            parsedData[i18n.t('common:excelHeaders.productionSystem')] = row.productionSystem;
            if(row.feeding.length > 0) addFeedingData(parsedData, row.feeding)
            results.push(parsedData);
        } catch (error) {
            console.log(error)
        }
    }
    return results;
}

const addFeedingData = (parsedData, values) => {
    let i = 1;
    for(let value of values) {
        parsedData[i18n.t('common:excelHeaders.feeding')+i] = value;
        i++;
    }
}

const formatFeeding = (values)=> {
    if(values.length > 0 && values[0] != "") {
        return formatValues(values);
    }
}

const formatValues = (arr) => arr.map(value =>
    value.split(' ').map(word =>
		word[0].toUpperCase() + word.slice(1).toLowerCase()
	).join(' ')
);

const parseProductiveSystemOptionsToJson = (productiveSystemsArray) => {
    let result = {
        natural: false,
        silvoPastoral: false,
        pastoral: false,
        creepFeeding: false,
        strategicFeeding: false,
        feedlot: false,
    };
    productiveSystemsArray.forEach(productiveSystem => {
        result[`${productiveSystem}`] = true;
    });
    return result;
};

const parseValidatedRows = (validatedRows) => {
    let result = [];
    validatedRows.forEach(row => {
        result.push({
            ...row.data,
            status: row.status,
            errors: row.errors
        })
    });
    return result;
};

export default mapper;