import React, {useEffect, useState} from 'react'
import CVTable from "../../../Tables/CVTable/CVTable";
import { withTranslation } from "react-i18next";
import eventActions from '../../../../redux/actions/eventActions';
import { connect } from 'react-redux';
import FileUploadOutlineIcon from "mdi-react/FileUploadOutlineIcon";
import FileUploadExtendedModal from "../../../../shared/components/cv/FileUploadExtendedModal";
import { withRouter } from 'react-router-dom';
import { EVENT_TYPES } from '../../../../constants/index';
import {parse_UTC_date_to_DD_MM_YYYY, filterEstablishments, filter, useIsMount} from "../../../../util";
import GeneralDataActions from "../../../../redux/actions/generalDataActions";
import {Tooltip} from "react-tippy";
import FileDownloadOutlineIcon from "mdi-react/FileDownloadOutlineIcon";
import settings from "../../../../util/settings";
import actions from '../../../../redux/actions/index';
import {renderTableSelectors} from '../EventTableSelectors';

const TransferEventTable = (props) => {
  const { t, events, total, page, getPage, totalPages, pageSize, allCompanies,
    selectedEstablishment, allEstablishments, allEstablishmentsByCompany } = props;
  const [fileUploadModalIsOpen, setFileUploadModalOpen] = useState(false);
  const templatesUrl = settings.templatesUrl;
  const isMount = useIsMount();

  useEffect(() => {
    let ids = selectedEstablishment.map(el => ( el.value ));
    if(ids.length === 0) {
        ids = null
    }
    getPage(ids, 1, 10)
  }, [selectedEstablishment, allEstablishmentsByCompany, allEstablishments]);

  useEffect(()=>{
    const selected = filterEstablishments(selectedEstablishment, allEstablishmentsByCompany)
    props.selectEstablishment(selected);
  },
  [allEstablishmentsByCompany])

  useEffect(()=>{
    // select all lots from selected commpany
    const lots = props.allLotsByEstablishment.map(el => ( {value: el._id, label: el.name} ));
    props.selectLot(lots);
  },[props.allLotsByEstablishment])

  useEffect(()=>{
    // refresh selected establishments
    const establishmentsFromselectedCompany = filter(props.establishmentsToAdd, props.selectedEstablishment)
    props.selectEstablishment([...props.selectedEstablishment, ...establishmentsFromselectedCompany])
  },[props.establishmentsToAdd])

  useEffect(()=>{
    const e = allEstablishmentsByCompany.map(el => ( {value: el._id, label: el.name} ));
    if(allCompanies.length === props.selectedCompany.length && !isMount){
      props.selectEstablishment(e)
    }
  },[allEstablishmentsByCompany])

  const getEstablishmentPage = (page, pageSize) => {
    let ids = selectedEstablishment.map(el => ( el.value ));
    getPage(ids, page, pageSize)
  };

  const renderValueDefault = ({value}) => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {value}
      </div>
    )
  };

  const onLeftSelectChange = (e) => {
    props.getEstablishmentsByCompanyId(e.value);
  }

  const onRightSelectChange = (e) => {
    props.selectEstablishment([e]);
  }

  const renderValueEstablishment = ({value}) => {
    let facility = props.facilities.find(function (facility) {
      return facility._id === value;
    });

    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {facility ? facility.name : '-'}
      </div>
    )
  };

  const renderDate = ({value}) => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {value ?
          parse_UTC_date_to_DD_MM_YYYY(value):
          "-"
        }
      </div>
    )
  };

  const renderIconsForNewEvent = () => (
    <div>
      <Tooltip
        title="Descargar plantilla"
        position="top"
        trigger="mouseenter"
        animation="scale"
        duration="600"
        size="small"
        theme="transparent"
        distance="0"
      >
        <a href={templatesUrl + "modelo evento transferencia.xlsx"} download><FileDownloadOutlineIcon/></a>
      </Tooltip>
      <FileUploadOutlineIcon onClick={() => setFileUploadModalOpen(true)}/>
    </div>
  );

  const heads = [
    {
      key: 'earId',
      name: t('events.transfer.table.headers.caravan_id'),
      percentage: 33,
      minWidth: 150,
      formatter: renderValueDefault
    },
    {
      key: 'date',
      name: t('events.transfer.table.headers.date'),
      percentage: 33,
      minWidth: 210,
      formatter: renderDate
    },
    {
      key: 'newFacilityId',
      name: t('events.transfer.table.headers.new_establishment_id'),
      percentage: 33,
      minWidth: 210,
      formatter: renderValueEstablishment
    },
  ];

  return(
    <div>
      <CVTable
        CVSelect={renderTableSelectors}
        getPage={getEstablishmentPage}
        data={events}
        total={total}
        currentPage={page}
        totalPages={totalPages}
        pageSize={pageSize}
        heads={heads}
        hideFilters
        title={t('events.transfer.table.title')}
        SubtitleComponent={renderIconsForNewEvent}
        noDataLabel={t('events.transfer.table.no_data_label')}
      />
      <FileUploadExtendedModal title={t('events.modals.massive_upload.title')} isOpen={fileUploadModalIsOpen}
                               searchPlaceholder={t('events.modals.massive_upload.search_placeholder')}
                               toggleModal={() => {if(fileUploadModalIsOpen) props.resetFacilitySearch(); setFileUploadModalOpen(!fileUploadModalIsOpen) }} acceptedFormats={".xls,.xlsx"}
                               availableFacilities={props.facilities}
                               leftSelectValues={props.allCompanies}
                               rightSelectValues={props.allEstablishmentsByCompany}
                               onLeftSelectChange={onLeftSelectChange}
                               onRightSelectChange={onRightSelectChange}
                               selectEstablishment={props.selectedEstablishment}
                               onSubmit={(file) => {
                                props.uploadFileForMassiveLoad(props.facilitySearch._id, file.files, props.selectedEstablishment[0].value, undefined);
                                 props.history.push('/events/transfer/massive_load');
                              }}/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  establishmentsToAdd: state.user.establishmentsToAdd,
  selectedEstablishment: state.user.selectedEstablishment,
  selectedCompany: state.user.selectedCompany,
  allCompanies: state.user.allCompanies,
  allEstablishmentsByCompany: state.user.allEstablishmentsByCompany,
  allLotsByEstablishment: state.user.allLotsByEstablishment,
  events: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].events : [],
  total: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].total : 0,
  page: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].page : 1,
  totalPages: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].totalPages : 0 ,
  pageSize: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].pageSize : 0,
  establishmentId: state.user.selectedEstablishment != undefined ? state.user.selectedEstablishment._id : null,
  facilitySearch: state.generalData.facilitySearch ? state.generalData.facilitySearch.res : undefined,
  facilities: state.generalData.availableFacilities,
});

const mapDispatchToProps = (dispatch) => ({
  getLotsByEstablishment: (establishmentIds) => dispatch(actions.user.getLotsByEstablishment(establishmentIds)),
  selectLot: (lot) => dispatch(actions.user.selectLot(lot)),
  getEstablishmentsByCompanyId: (companyId) =>
      dispatch(actions.user.getUserEstablishmentsByCompany(companyId)),
  selectEstablishment: (establishment) => dispatch(actions.user.selectEstablishment(establishment)),
  resetFacilitySearch: () => dispatch(GeneralDataActions.resetFacilitySearch()),
  getAvailableFacilities: () => dispatch(GeneralDataActions.getAvailableFacilities()),
  getPage: (establishmentId, page, pageSize) => dispatch(eventActions.getEventsPage(establishmentId, page, pageSize, EVENT_TYPES.transfer)),
  uploadFileForMassiveLoad: (newFacility, file, establishmentId, callback) =>
    dispatch(eventActions.uploadFileForTransferEventMassiveLoad(newFacility, file, establishmentId, EVENT_TYPES.transfer, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(TransferEventTable)));