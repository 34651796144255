import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import UsersTable from "./components/UsersTable";
import actions from '../../redux/actions/index'

const Users = (props) => {
    const [users, setUsers] = useState();

    const { currentPage, total, totalPages, pageSize } = props;

    useEffect(() => {
        props.getPage(1,10);
    }, []);

    useEffect(() => {
        setUsers(props.users)
    }, [props.users]);

    return (
        <div>
            <UsersTable users={users} currentPage={currentPage} totalUsers={total} getPage={props.getPage}
                        totalPages={totalPages} pageSize={pageSize}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        users: state.admin.users.users,
        total: state.admin.users.total,
        currentPage: state.admin.users.page,
        totalPages: state.admin.users.totalPages,
        pageSize: state.admin.users.pageSize
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPage: (pageNumber, pageSize) => dispatch(actions.admin.getUsersPage(pageNumber, pageSize))
    }
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Users));
